import React, { useState } from "react";
import { connect } from "react-redux";
import DadosCondutor from "./DadosCondutor";
import ImprimirTermo from "./ImprimirTermo";
import LayoutInternal from "../LayoutInternal";
import EnvioTermo from "./EnvioTermo";
import EnvioCNH from "./EnvioCNH";
import ModalMensagem from "../ModalMensagem/ModalMensagem";
import Loading from "../Loading";
import moment from "moment";
import { Grid, makeStyles, Container } from "@material-ui/core";
import {
  alterarDataNascimento,
  alterarNomeCondutor,
  alterarEmailCondutor,
  alterarRgCondutor,
  alterarCpfCliente,
  alterarOrgaoEmissor,
  alterarEstadoRg,
  alterarCnhCondutor,
  alterarPrimeiraHabilitacao,
  alterarEstadoCnh,
  alterarTipoDocumento,
  alterarDocumentoEstrangeiro,
  alterarLicencaHabilitacao,
  alterarDataEntrada,
  alterarCondutorEstrangeiro,
  limparDadosCondutor,
  buscarDadosCpfDataNascimento,
  verificarCpfClienteValido,
  verificarDataNascimentoValido,
  verificarEmailCondutorValido,
  verificarPrimeiraHabilitacaoValido,
  verificarDataEntradaValido,
  exibirCarregando,
  removerCarregando,
} from "../Multas/CardMultaAction";
import {
  GerarTermoDeResponsabilidade,
  IndicarCondutorTermoDeResponsabilidade,
} from "../../services/IndicarCondutorService";
import { base64ToArrayBuffer } from "../../helpers/downloadFile";
import Tracking from "../../hooks/Tracking";

const useStyles = makeStyles((theme) => ({
  grid: {
    marginBottom: 15,
  },
  title: {
    fontFamily: "Metropolis-semiBold",
    fontSize: "12px",
  },
  subtitle: {
    fontFamily: "Metropolis",
    fontSize: "12px",
    color: "#7B808C",
  },
}));

function IndicarCondutorTermoResponsabilidade({
  situacao,
  handleIndicar,
  handleVoltar,
  handleFinalizarProcesso,
  loading,
  condutor,
  multa,
  termoStep,
  cpfClienteValido,
  dataNascimentoValida,
  emailCondutorValido,
  primeiraHabilitacaoValida,
  dataEntradaValida,
  bloqueadoDadosCondutor,
  limparDadosCondutor,
  alterarDataNascimento,
  alterarNomeCondutor,
  alterarEmailCondutor,
  alterarRgCondutor,
  alterarCpfCliente,
  alterarOrgaoEmissor,
  alterarEstadoRg,
  alterarCnhCondutor,
  alterarPrimeiraHabilitacao,
  alterarEstadoCnh,
  alterarTipoDocumento,
  alterarDocumentoEstrangeiro,
  alterarLicencaHabilitacao,
  alterarDataEntrada,
  alterarCondutorEstrangeiro,
  verificarCpfClienteValido,
  verificarDataNascimentoValido,
  verificarEmailCondutorValido,
  verificarPrimeiraHabilitacaoValido,
  verificarDataEntradaValido,
  buscarDadosCpfDataNascimento,
  exibirCarregando,
  removerCarregando,
}) {
  const classes = useStyles();
  const [step, setStep] = useState(termoStep);
  const [imagemTermo, setImagemTermo] = useState(null);
  const [imagemTermoBase64, setImagemTermoBase64] = useState(null);
  const [imagemCNH, setImagemImagemCNH] = useState(null);
  const [imagemCNHBase64, setImagemImagemCNHBase64] = useState(null);
  const [mensagemErro, setMensagemErro] = useState(
    "Não foi possível enviar a CNH e o termo de responsabilidade. Favor tentar novamente."
  );
  const [showMsgErro, setShowMsgErro] = useState(false);
  const gaCategory = "indicacao_condutor_termo";

  const isFormValid = () => {
    switch (step) {
      case 0:
        return condutor.condutorEhEstrangeiro === 0
          ? condutor.dataNascimento &&
          condutor.nomeCondutor &&
          condutor.emailCondutor &&
          condutor.rgCondutor &&
          condutor.cpfCliente &&
          condutor.orgaoEmissor &&
          condutor.estadoRg &&
          condutor.cnhCondutor &&
          condutor.primeiraHabilitacao &&
          condutor.estadoCnh &&
          cpfClienteValido &&
          dataNascimentoValida &&
          emailCondutorValido &&
          primeiraHabilitacaoValida
          : condutor.nomeCondutor &&
          condutor.tipoDocumento &&
          condutor.documentoEstrangeiro &&
          condutor.licencaHabilitacao &&
          condutor.dataEntrada &&
          dataEntradaValida;
      case 3:
        return imagemCNH;
      default:
        return imagemTermo;
    }
  };

  const handleSetImagemTermo = (event) => {
    Tracking.sendEvent({
      category: gaCategory,
      action: "IND_TERMO_IMAGEM_TERMO_ATRIBUIDA",
      label: "Imagem do termo atribuída",
    });

    handleFileSelect(event.target.files[0], (base64String) => {
      setImagemTermoBase64(base64String);
    });
    setImagemTermo(event.target.files[0]);
  };

  const handleSetImagemCNH = (event) => {
    Tracking.sendEvent({
      category: gaCategory,
      action: "IND_TERMO_IMAGEM_CNH_ATRIBUIDA",
      label: "Imagem da cnh atribuída",
    });

    handleFileSelect(event.target.files[0], (base64String) => {
      setImagemImagemCNHBase64(base64String);
    });
    setImagemImagemCNH(event.target.files[0]);
  };

  const handleLimparImagemTermo = () => {
    Tracking.sendEvent({
      category: gaCategory,
      action: "IND_TERMO_IMAGEM_TERMO_LIMPA",
      label: "Imagem do termo limpa",
    });

    setImagemTermo(null);
  };

  const handleLimparImagemCNH = () => {
    Tracking.sendEvent({
      category: gaCategory,
      action: "IND_TERMO_IMAGEM_CNH_LIMPA",
      label: "Imagem da cnh limpa",
    });

    setImagemImagemCNH(null);
  };

  const handleNextToDownload = () => {
    Tracking.sendEvent({
      category: gaCategory,
      action: "IND_TERMO_BOTAO_PROXIMO_CLICADO",
      label: "Botão de próximo para download clicado",
    });

    setStep(1);
  };

  const handleNextToCNH = () => {
    Tracking.sendEvent({
      category: gaCategory,
      action: "IND_TERMO_BOTAO_PROXIMO_CLICADO",
      label: "Botão de próximo para cnh clicado",
    });

    setStep(3);
  };

  const handleConcluir = () => {
    Tracking.sendEvent({
      category: gaCategory,
      action: "IND_TERMO_BOTAO_CONCLUIR_CLICADO",
      label: "Botão de concluir clicado",
    });

    exibirCarregando();
    let dadosIndicacao = {
      Ait: multa.AIT,
      CodigoOrgao: multa.CodigoOrgao,
      Cnpj: localStorage.getItem("cnpj-atual"),
      Placa: localStorage.getItem("placa-atual"),
      ArquivoCnhBase64: imagemCNHBase64,
      ExtensaoCNH: imagemCNH.type,
      ArquivoTermoBase64: imagemTermoBase64,
      ExtensaoTermo: imagemTermo.type,
    };

    IndicarCondutorTermoDeResponsabilidade(dadosIndicacao)
      .then((result) => {
        Tracking.sendEvent({
          category: gaCategory,
          action: "IND_TERMO_INDICACAO_SUCESSO",
          label: "Sucesso ao indicar condutor com termo de responsabilidade",
          nonInteraction: true,
        });

        if (result.data.Mensagem === "") {
          removerCarregando();
          setStep(4);
        } else {
          setMensagemErro(result.data.Mensagem);
          removerCarregando();
          setStep(5);
        }
      })
      .catch((error) => {
        Tracking.sendEvent({
          category: gaCategory,
          action: "IND_TERMO_INDICACAO_ERRO",
          label: "Erro ao indicar condutor com termo de responsabilidade",
          nonInteraction: true,
        });

        removerCarregando();
        setStep(5);
      });
    limparDadosCondutor();
  };

  const handleFileSelect = (file, callbackFunc) => {
    Tracking.sendEvent({
      category: gaCategory,
      action: "IND_TERMO_ARQUIVO_SELECIONADO",
      label: "Arquivo selecionado",
    });

    var reader = new FileReader();

    reader.onload = function (e) {
      var binaryData = e.target.result;
      var base64String = window.btoa(binaryData);
      callbackFunc(base64String);
    };

    reader.readAsBinaryString(file);
  };

  const handleBackToSelectionPage = () => {
    Tracking.sendEvent({
      category: gaCategory,
      action: "IND_TERMO_BOTAO_VOLTAR_CLICADO",
      label: "Botão de voltar para a página de seleção clicado",
    });

    limparDadosCondutor();

    if (situacao === 100) {
      handleVoltar();
    } else {
      handleIndicar();
    }
  };

  const handleGerarTermo = () => {
    setShowMsgErro(false);
    Tracking.sendEvent({
      category: gaCategory,
      action: "IND_TERMO_BOTAO_GERAR_TERMO_CLICADO",
      label: "Botão de gerar termo clicado",
    });

    let dadosIndicacao = "";
    exibirCarregando();

    if (condutor.condutorEhEstrangeiro === 1) {
      dadosIndicacao = {
        Nome: condutor.nomeCondutor,
        DataEntrada: moment(condutor.dataEntrada).format("YYYY-MM-DD"),
        DocumentoEstrangeiro: condutor.documentoEstrangeiro,
        LicencaHabilitacao: condutor.licencaHabilitacao,
        TipoDocumento: condutor.tipoDocumento,
        Ait: multa.AIT,
        CodigoOrgao: multa.CodigoOrgao,
        Cnpj: localStorage.getItem("cnpj-atual"),
        CondutorEstrangeiro: true,
        Placa: localStorage.getItem("placa-atual"),
      };
    } else {
      dadosIndicacao = {
        Nome: condutor.nomeCondutor,
        DataNascimento: moment(condutor.dataNascimento).format("YYYY-MM-DD"),
        Cpf: condutor.cpfCliente,
        Cnh: condutor.cnhCondutor,
        CnhUf: condutor.estadoCnh,
        DataPrimeiraHabilitacao: moment(condutor.primeiraHabilitacao).format(
          "YYYY-MM-DD"
        ),
        Email: condutor.emailCondutor,
        Rg: condutor.rgCondutor,
        RgOrgaoEmissor: condutor.orgaoEmissor,
        RgUf: condutor.estadoRg,
        Ait: multa.AIT,
        CodigoOrgao: multa.CodigoOrgao,
        Cnpj: localStorage.getItem("cnpj-atual"),
        CondutorEstrangeiro: false,
        IdPessoa: condutor.idPessoa,
        Placa: localStorage.getItem("placa-atual"),
      };
    }

    GerarTermoDeResponsabilidade(dadosIndicacao)
      .then((response) => {
        Tracking.sendEvent({
          category: gaCategory,
          action: "IND_TERMO_GERAR_TERMO_SUCESSO",
          label: "Sucesso ao gerar termo de responsabilidade",
          nonInteraction: true,
        });

        if (response.data && response.data !== null && response.data !== "") {
          const byteArray = base64ToArrayBuffer(response.data.TermoBase64);
          const url = window.URL.createObjectURL(
            new Blob([byteArray], { type: "application/pdf" })
          );
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "TermoResponsabilidade.pdf");
          link.target = "_blank";
          document.body.appendChild(link);
          link.click();
          link.parentNode.removeChild(link);
          removerCarregando();
        }
        handleFinalizarProcesso();
      })
      .catch((error) => {
        Tracking.sendEvent({
          category: gaCategory,
          action: "IND_TERMO_GERAR_TERMO_ERRO",
          label: "Erro ao gerar termo de responsabilidade",
          nonInteraction: true,
        });
        setMensagemErro('Ocorreu um erro ao gerar o termo, por favor tente novamente!');
        removerCarregando();
        setStep(5);
      });
    limparDadosCondutor();
  };

  return (
    <LayoutInternal title="Multa">
      <main style={{ marginTop: "10px" }}>
        <React.Fragment>
          <Container maxWidth="lg">
            {loading && <Loading />}

            <div
              style={{
                border: "1px solid #eeeeee",
                borderRadius: 5,
                padding: 10,
              }}
            >
              {(() => {
                switch (step) {
                  case 0:
                    return (
                      <div>
                        <DadosCondutor
                          condutor={condutor}
                          bloqueadoDadosCondutor={bloqueadoDadosCondutor}
                          verificarCpfClienteValido={verificarCpfClienteValido}
                          verificarDataNascimentoValido={
                            verificarDataNascimentoValido
                          }
                          verificarEmailCondutorValido={
                            verificarEmailCondutorValido
                          }
                          verificarPrimeiraHabilitacaoValido={
                            verificarPrimeiraHabilitacaoValido
                          }
                          verificarDataEntradaValido={
                            verificarDataEntradaValido
                          }
                          cpfClienteValido={cpfClienteValido}
                          dataNascimentoValida={dataNascimentoValida}
                          emailCondutorValido={emailCondutorValido}
                          primeiraHabilitacaoValida={primeiraHabilitacaoValida}
                          dataEntradaValida={dataEntradaValida}
                          limparDadosCondutor={limparDadosCondutor}
                          onChangeDataNascimento={alterarDataNascimento}
                          onChangeNomeCondutor={alterarNomeCondutor}
                          onChangeEmailCondutor={alterarEmailCondutor}
                          onChangeRgCondutor={alterarRgCondutor}
                          onChangeCpfCliente={alterarCpfCliente}
                          onChangeOrgaoEmissor={alterarOrgaoEmissor}
                          onChangeEstadoRg={alterarEstadoRg}
                          onChangeCnhCondutor={alterarCnhCondutor}
                          onChangePrimeiraHabilitacao={
                            alterarPrimeiraHabilitacao
                          }
                          onChangeEstadoCnh={alterarEstadoCnh}
                          onChangeTipoDocumento={alterarTipoDocumento}
                          onChangeDocumentoEstrangeiro={
                            alterarDocumentoEstrangeiro
                          }
                          onChangeLicencaHabilitacao={alterarLicencaHabilitacao}
                          onChangeDataEntrada={alterarDataEntrada}
                          onChangeCondutorEstrangeiro={
                            alterarCondutorEstrangeiro
                          }
                          onChangesDadosCpfDataNascimento={
                            buscarDadosCpfDataNascimento
                          }
                        />

                        <div className="ml-auto mt-3 col-md-6">
                          <div className="row">
                            <div className="col">
                              <button
                                className="button"
                                onClick={handleBackToSelectionPage}
                              >
                                Voltar
                              </button>
                            </div>
                            <div className="col">
                              <button
                                disabled={!isFormValid()}
                                onClick={handleNextToDownload}
                                className="button"
                              >
                                Próximo
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  case 1:
                    return (
                      <div>
                        <ImprimirTermo />

                        <Grid
                          container
                          justify="center"
                          spacing={1}
                          style={{ paddingTop: "15px", textAlign: "center" }}
                        >
                          <Grid item sm={3} xs={12}>
                            <button
                              className="button"
                              onClick={handleGerarTermo}
                            >
                              Baixar
                            </button>
                          </Grid>
                        </Grid>
                      </div>
                    );
                  case 2:
                    return (
                      <div>
                        <EnvioTermo
                          step={"0"}
                          imagemTermo={imagemTermo}
                          onChangeImagemTermo={handleSetImagemTermo}
                          onLimparImagemTermo={handleLimparImagemTermo}
                        />

                        <Grid
                          container
                          justify="center"
                          spacing={1}
                          className={classes.grid}
                          style={{ paddingTop: "10px" }}
                        >
                          <Grid item sm={3} xs={12}>
                            <button
                              disabled={!isFormValid()}
                              onClick={handleNextToCNH}
                              className="button"
                            >
                              Próximo
                            </button>
                          </Grid>
                        </Grid>
                      </div>
                    );
                  case 3:
                    return (
                      <div>
                        <EnvioCNH
                          imagemCNH={imagemCNH}
                          loadCNH={handleSetImagemCNH}
                          limparImagemCNH={handleLimparImagemCNH}
                        />

                        <Grid
                          container
                          justify="center"
                          spacing={1}
                          className={classes.grid}
                          style={{ paddingTop: "10px" }}
                        >
                          <Grid item sm={3} xs={12}>
                            <button
                              disabled={!isFormValid()}
                              onClick={handleConcluir}
                              className="button"
                            >
                              Concluir
                            </button>
                          </Grid>
                        </Grid>
                      </div>
                    );
                  case 4:
                    return (
                      <div>
                        <ModalMensagem
                          tipoModalMensagem="info"
                          icon="icon-check-circle2"
                          mensagemPrincipal="Enviado"
                          OnToggled={handleFinalizarProcesso}
                          mensagemSecondaria="Seu Termo de Responsabilidade foi enviado com sucesso! Você receberá por e-mail um parecer após análise da Localiza Gestão de Frotas"
                          textoBotao="OK"
                        />
                      </div>
                    );
                  case 5:
                    return (
                      <div>
                        <ModalMensagem
                          tipoModalMensagem="warning"
                          icon="icon-x-circle"
                          mensagemPrincipal=""
                          OnToggled={handleFinalizarProcesso}
                          mensagemSecondaria={mensagemErro}
                          textoBotao="OK"
                        />
                      </div>
                    );
                  default:
                    return null;
                }
              })()}
            </div>
          </Container>
        </React.Fragment>
      </main>
    </LayoutInternal>
  );
}

const mapStateToProps = (state) => {
  return {
    condutor: state.multa.condutor,
    bloqueadoDadosCondutor: state.multa.bloqueadoDadosCondutor,
    cpfClienteValido: state.multa.cpfClienteValido,
    dataNascimentoValida: state.multa.dataNascimentoValida,
    emailCondutorValido: state.multa.emailCondutorValido,
    primeiraHabilitacaoValida: state.multa.primeiraHabilitacaoValida,
    dataEntradaValida: state.multa.dataEntradaValida,
    loading: state.multa.loading,
  };
};

export default connect(mapStateToProps, {
  alterarDataNascimento,
  alterarNomeCondutor,
  alterarEmailCondutor,
  alterarRgCondutor,
  alterarCpfCliente,
  alterarOrgaoEmissor,
  alterarEstadoRg,
  alterarCnhCondutor,
  alterarPrimeiraHabilitacao,
  alterarEstadoCnh,
  alterarTipoDocumento,
  alterarDocumentoEstrangeiro,
  alterarLicencaHabilitacao,
  alterarDataEntrada,
  alterarCondutorEstrangeiro,
  limparDadosCondutor,
  buscarDadosCpfDataNascimento,
  verificarCpfClienteValido,
  verificarDataNascimentoValido,
  verificarEmailCondutorValido,
  verificarPrimeiraHabilitacaoValido,
  verificarDataEntradaValido,
  exibirCarregando,
  removerCarregando,
})(IndicarCondutorTermoResponsabilidade);
