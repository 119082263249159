export const formatDateAbbreviated =  (date) => {
  var dateObj = new Date(date);
  var monthNames = [
    "JAN", "FEV", "MAR",
    "ABR", "MAI", "JUN", "JUL",
    "AGO", "SET", "OUT",
    "NOV", "DEZ"
  ];

  var day = dateObj.getDate();
  var monthIndex = dateObj.getMonth();
  var year = dateObj.getFullYear();

  return day + ' ' + monthNames[monthIndex] + ' ' + year;
}

export const formatDate =  (date) => {
  var dateObj = new Date(date);
  var day = dateObj.getDate();
  var month = dateObj.getMonth()+1;
  var year = dateObj.getFullYear();

  return day + '/' + month + '/' + year;
}

export const formatDateAndHour =  (date) => {
  var dateObj = new Date(date);
  var day = dateObj.getDate();
  var month = dateObj.getMonth()+1;
  var year = dateObj.getFullYear();
  var hora = date.match(/(\d\d):(\d\d):(\d\d)/)

  return day + '/' + month + '/' + year + ' - ' + hora[0];
}

export const formatDateAbbreviatedShorter =  (date) => {
  var formatted = formatDateAbbreviated(date);
  return formatted.substring(0, formatted.length - 4) + formatted.substring(formatted.length - 2);
}

export const formatDateHours=  (date) => {
  var dateObj = new Date(date);
  var monthNames = [
    "Janeiro", "Fevereiro", "Março",
    "Abril", "Maio", "Junho", "Julho",
    "Agosto", "Setembro", "Outubro",
    "Novembro", "Dezembro"
  ];

  var day = dateObj.getDate();
  var monthIndex = dateObj.getMonth();
  var hours = dateObj.getHours()

  return day + ' de ' + monthNames[monthIndex] + ' às ' + hours + 'h';
}

export const formatWeekNameDateHours=  (date) => {
  var dateObj = new Date(date);
  var monthNames = [
    "Janeiro", "Fevereiro", "Março",
    "Abril", "Maio", "Junho", "Julho",
    "Agosto", "Setembro", "Outubro",
    "Novembro", "Dezembro"
  ];

  var weekDaysNames = [
    "Domingo", "Segunda Feira", "Terça Feira", "Quarta Feira",
    "Quinta Feira", "Sexta Feira", "Sábado"
  ];

  var hora = date.match(/(\d\d):(\d\d):(\d\d)/)
  var day = dateObj.getDate();
  var weekDay = dateObj.getDay();
  var monthIndex = dateObj.getMonth();

  return `${weekDaysNames[weekDay]}, ${day} de ${monthNames[monthIndex]} ${hora[1]}h${hora[2]}`;
}

export const formatDayMonth=  (date) => {
  var dateObj = new Date(date);
  var monthNames = [
    "JAN", "FEV", "MAR",
    "ABR", "MAI", "JUN", "JUL",
    "AGO", "SET", "OUT",
    "NOV", "DEZ"
  ];

  var day = dateObj.getDate();
  var monthIndex = dateObj.getMonth();
  return day + ' ' + monthNames[monthIndex];
}
