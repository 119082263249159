import styled from "styled-components";

//WEB
export const WebSection = styled.section`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0px 0px 10px 0px;
  background-color: #ffffff;
  width: 75%;
  position: absolute;
  left: 332px;
  @media (max-width: 1025px) {
    width: 100%;
    text-align: center;
    left: 0;
  }

  @media (min-width: 1550px) {
    width: 68%;
    padding: 0 0 0 110px;
  }

  @media (min-width: 1850px) {
    width: 68%;
    padding: 0 0 0 200px;
  }

  @media (min-width: 2200px) {
    width: 68%;
    padding: 0 0 0 380px;
  }

  @media (min-width: 2550px) {
    width: 68%;
    padding: 0 0 0 400px;
  }

  @media (min-width: 3000px) {
    width: 68%;
    padding: 0 0 0 560px;
  }
`;

//MOBILE
export const MobileSectionHome = styled.section`
  width: 100%;
  height: 100%;
  background: #fafafa;
  display: flex;
  flex-direction: column;
  padding: 60px 10px 10px 10px;
  position: absolute;
  top: 100px;
  border-radius: 24px 24px 0px 0px;
`;
