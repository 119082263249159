import React, { useState, useEffect }  from 'react'
import LayoutInternal from '../../components/LayoutInternal';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import { Container, Grid, useMediaQuery } from '@material-ui/core';
import { urlAPI } from '../../config/consts';
import axios from 'axios';
import { base64ToArrayBuffer } from '../../helpers/downloadFile';
import Loading from '../../components/Loading';
import Tracking from '../../hooks/Tracking';

function ManualCondutorPage() {
    Tracking.sendPageview('Guia Condutor');
    useEffect(() => {
        axios.get(urlAPI + '/ManualCondutor')
            .then((result) => {
                if (result.data && result.data !== null && result.data !== "") {
                    const byteArray = base64ToArrayBuffer(result.data);
                    setUrlManualCondutor(window.URL.createObjectURL(new Blob([byteArray], { type: 'application/pdf' })));
                }
            })
            .catch((error) => {
                console.log('Não foi possível carregar o manual');
            });
      },[]);

    const [numeroPagina, setNumeroPagina] = useState(1);
    const [urlManualCondutor, setUrlManualCondutor] = useState(null);
    const mobile = useMediaQuery(theme => theme.breakpoints.down('xs'));

    const handleAvancarPagina = () => {
        setNumeroPagina(numeroPagina + 1);
    }

    const handleVoltarPagina = () => {
        setNumeroPagina(numeroPagina - 1);
    }
    
    const downloadArquivo = () => {
        axios.post(urlAPI + '/RegistrarDownload');

        const link = document.createElement('a');
        link.href = urlManualCondutor;
        link.setAttribute('download', 'ManualCondutor.pdf');
        link.target = '_blank';
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
    }

    return (
        <LayoutInternal title="Guia do Condutor">
            <main>

                <Container maxWidth="lg" >
                    { urlManualCondutor === null && <Loading /> }
                    <h1>Guia do Condutor</h1>
                    <Grid container spacing={1}>
                        {
                            urlManualCondutor && 
                            <Grid item xs={12}>
                                <Document file={urlManualCondutor}>
                                    <Page scale={mobile ? 0.45 : 1.2} pageNumber={numeroPagina} />
                                </Document>
                            </Grid>
                        }
                        <Grid item xs={6} sm={3}> 
                            <button disabled={numeroPagina === 1} className="button" onClick={handleVoltarPagina}>Anterior</button>
                        </Grid>
                        <Grid item xs={6} sm={3}>
                            <button disabled={numeroPagina === 24} className="button" onClick={handleAvancarPagina}>Próximo</button>
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <button className="button" onClick={downloadArquivo}>Download</button>
                        </Grid>
                    </Grid>
                </Container>
            </main>
        </LayoutInternal>
    )
}
 
export default ManualCondutorPage