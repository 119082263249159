
import moment from 'moment';

export const validaEmail = (email) => {
    let er = /^[a-zA-Z0-9][a-zA-Z0-9\._-]+@([a-zA-Z0-9\._-]+\.)[a-zA-Z-0-9]{2}/;
    return (er.exec(email) != null);      
}

export const somenteNumerosFromString = (string) => {
    return string.replace(/\D/g, "");
}

export const retiraEspacoDuplicado = (texto) => {
    return texto.replace(/\s+/g, ' ');
}

export const dataValida = (data) => {
    var date = moment(data.toString(), 'DD/MM/YYYY');
    return date.isValid();
}

export const horaValida = (data) => {
    if(!data || data === "") return false;
    if(data.indexOf(":") === -1) return false;

    data = data.replace('_','').replace('__','');

    let _data = data.split(":");
    if((!_data[0]) || (!_data[1]) || _data.length > 2 || parseInt(_data[0]) > 23 || parseInt(_data[1])>59){
        return false;
    }
    return true;
}

export const validarCpf = (cpf) => {
    if(!cpf || cpf===""){
        return false;
    }
    
    cpf = cpf.replace(/[^\d]+/g, '');

    if (cpf === '')
        return false;

    if (cpf.length !== 11 ||
        cpf === "00000000000" ||
        cpf === "11111111111" ||
        cpf === "22222222222" ||
        cpf === "33333333333" ||
        cpf === "44444444444" ||
        cpf === "55555555555" ||
        cpf === "66666666666" ||
        cpf === "77777777777" ||
        cpf === "88888888888" ||
        cpf === "99999999999")
        return false;

    let add = 0;
    for (let i = 0; i < 9; i++)
        add += parseInt(cpf.charAt(i)) * (10 - i);
    let rev = 11 - (add % 11);
    if (rev === 10 || rev === 11)
        rev = 0;
    if (rev !== parseInt(cpf.charAt(9)))
        return false;

    add = 0;
    for (let j = 0; j < 10; j++)
        add += parseInt(cpf.charAt(j)) * (11 - j);
    rev = 11 - (add % 11);
    if (rev === 10 || rev === 11)
        rev = 0;
    if (rev !== parseInt(cpf.charAt(10)))
        return false;
    return true;
}

export const validarCnpj = (cnpj) => {
    cnpj = cnpj.replace(/[^\d]+/g, '');

    if (cnpj === '')
        return false;

    if (cnpj.length !== 14 ||
        cnpj === "00000000000000" ||
        cnpj === "11111111111111" ||
        cnpj === "22222222222222" ||
        cnpj === "33333333333333" ||
        cnpj === "44444444444444" ||
        cnpj === "55555555555555" ||
        cnpj === "66666666666666" ||
        cnpj === "77777777777777" ||
        cnpj === "88888888888888" ||
        cnpj === "99999999999999")
        return false;

    let tamanho = cnpj.length - 2
    let numeros = cnpj.substring(0, tamanho);
    let digitos = cnpj.substring(tamanho);
    let soma = 0;
    let pos = tamanho - 7;

    for (let i = tamanho; i >= 1; i--) {
        soma += numeros.charAt(tamanho - i) * pos;
        pos--;
        if (pos < 2)
            pos = 9;
    }

    let resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
    if (resultado != digitos.charAt(0))
        return false;

    tamanho = tamanho + 1;
    numeros = cnpj.substring(0, tamanho);
    soma = 0;
    pos = tamanho - 7;

    for (let j = tamanho; j >= 1; j--) {
        soma += numeros.charAt(tamanho - j) * pos;
        pos--;
        if (pos < 2)
            pos = 9;
    }

    resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
    if (resultado != digitos.charAt(1))
        return false;

    return true;
}