import axios from "axios";
import { urlAPI } from "../config/consts";
import {
  NomeCookie,
  NomeCookiePlaca,
  NomeAtributoHeaderHttpAutorizacao,
  AuthorizationName,
} from "../config/consts";
import * as Cookie from "./Cookies";
import { isNewAppOnly } from "../helpers/checkPlatform";
import { Mutex } from "async-mutex";
import licenseAndPlateCriptografy from "../helpers/licenseAndPlateCriptografy";
import { getApiGeeAccessToken } from "../services/AuthApigeeToken";
import fire from "../components/fire";



const firebaseLock = new Mutex(); //necessário para o "lock"

const API = axios.create({
  baseURL: urlAPI,
});

export const HttpPost = async (urlApi, userJWT, param) => {
  if (!userJWT) userJWT = await getUpdatedToken();

  return await HttpPostInterno(urlApi, userJWT, param)
    .then((data) => data)
    .catch(trataErro);
};

export const HttpPostNew = async (urlApi, obj) => {
  let userJWT = await getUpdatedToken();

  return await HttpPostInternoBody(urlApi, userJWT, obj)
    .then((data) => data)
    .catch(trataErro);
};

export const HttpGet = async (urlApi, param, userJWT) => {
  if (!userJWT) userJWT = await getUpdatedToken();
  return await HttpGetInterno(
    urlApi,
    (param = {
      headers: {
        "x-license-client": localStorage.getItem("renavam"),

        "x-plate-client": licenseAndPlateCriptografy(
          localStorage.getItem("placa-atual")
        ),
      },
    }),
    userJWT
  )
    .then((data) => data)
    .catch(trataErro);
};

export const HttpDelete = async (urlApi, userJWT, param) => {
  return await HttpDeleteInterno(urlApi, userJWT, param)
    .then((data) => data)
    .catch(trataErro);
};

export const HttpGetFile = async (urlApi, userJWT, filename) => {
  if (!userJWT) userJWT = await getUpdatedToken();

  addHttpAutorizacao(userJWT);

  try {
    const response = await API.get(urlApi, { responseType: "blob" });
    if (!isNewAppOnly()) {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", filename);
      document.body.appendChild(link);
      link.click();
      return;
    } else {
      try {
        const base64String = await blobToBase64(response);
        window.ReactNativeWebView.postMessage(
          JSON.stringify({
            type: "DOWNLOAD_BASE64",
            payload: {
              fileName: filename,
              data: base64String,
            },
          })
        );
        return;
      } catch (error) {
        trataErro(error);
      }
    }
  } catch (error) {
    trataErro(error);
  }
};

const HttpPostInterno = async (urlApi, userJWT, param) => {
  addHttpAutorizacao(userJWT);
  return await API.post(urlApi, param);
};

const HttpPostInternoBody = async (urlApi, userJWT, obj) => {
  addHttpAutorizacao(userJWT);
  return await API.post(urlApi, obj);
};

const HttpGetInterno = async (urlApi, param = null, userJWT = null) => {
  addHttpAutorizacao(userJWT);
  return await API.get(urlApi, param);
};

const HttpDeleteInterno = async (urlApi, userJWT, param) => {
  addHttpAutorizacao(userJWT);
  return await API.delete(urlApi, param);
};

function trataErro(erro) {
  if (
    (erro.response && erro.response.status === 401) ||
    (erro.response && erro.response.status === 403)
  ) {
    window.location.href = "/Login";
  }
  throw erro;
}



const addHttpAutorizacao = async (userJWT) => {
  API.interceptors.request.use(async config => {
    const accessToken = await getApiGeeAccessToken();
    config.headers.Authorization = `Bearer ${accessToken}`;
    return config;
  });
  API.defaults.headers['client_id'] = process.env.REACT_APP_CLIENT_ID
  API.defaults.headers.common[NomeAtributoHeaderHttpAutorizacao] =
    "Bearer " + userJWT;
};

const blobToBase64 = (blob) => {
  const reader = new FileReader();
  reader.readAsDataURL(blob.data);
  return new Promise((resolve) => {
    reader.onloadend = () => {
      const b64Text = reader.result.replace(
        "data:application/octet-stream;base64,",
        ""
      );
      resolve(b64Text);
    };
  });
};

const getUpdatedToken = async () => {
  const refreshFirebaseToken = async (token) => {
    const requestData = {
      RefreshToken: token,
    };

    return await API.post("auth/provider/RefreshFirebaseToken", requestData)
      .then((result) => {
        return result;
      })
      .catch((error) => {
        return error;
      });
  };
  let releaseLock = await firebaseLock.acquire(); //para fazer lock do recurso. toda chamada subsequente ficará travada antes de executar o releaseLock()

  let currentToken = localStorage.getItem("token-id");
  let decodedToken = JSON.parse(
    Buffer.from(currentToken.split(".")[1], "base64").toString()
  );
  let tokenExpireDate = new Date(decodedToken.exp * 1000); //por ser unix epoch, multiplica-se por 1000 para pegar a data correta no construtor Date()
  let now = new Date();

  return scoped_getToken(); //apenas para a recursão sem "lockar" novamente

  function scoped_getToken() {
    return new Promise((resolve) => {
      if (tokenExpireDate < now) {
        try {
          refreshFirebaseToken(localStorage.getItem("refresh-token"))
            .then((res) => {
              localStorage.setItem(
                "token-id",
                res.data.Authenticated.FirebaseToken
              );
              resolve(res.data.Authenticated.FirebaseToken);
            })
            .catch((err) => {
              resolve(currentToken);
            })
            .finally(() => {
              releaseLock();
            });
        } catch (err) {
          resolve(currentToken);
          releaseLock();
        }
      } else {
        resolve(currentToken);
        releaseLock();
      }
    });
  }
};
