import React, { useState, useEffect } from "react";
import SwipeableViews from "react-swipeable-views";
import { connect } from "react-redux";
import '../../styles/css/style-wizard.css'

import {
  RegistrarAcesso,
  RegistrarVisualizacao,
  RegistrarRecusa,
  RegistrarNaoExibeNovamente,
  TutorialJaFoiExibido,
} from "../../components/Tutorial/TutorialAction";

import { Button } from "./Button";
import logo_localiza from "../../images/logo/logo_localiza_azul.svg";
import hashtag from "../../images/logo/hashtag.svg";
import Icone from "../../images/wizard/icn.svg";
import AgendamentoImg from "../../images/wizard/agendamento.svg";
import MultasImg from "../../images/wizard/multas.svg";
import CarroImg from "../../images/wizard/carroImg.svg";

import {
  Logo,
  HStack,
  Container,
  FlexContainer,
  SliderCard,
  ImagemCelular,
  Hashtag,
  VStack,
  Subtitle,
  Title,
  ImagemAgendamento,
  ImagemMultas,
  ImagemCarro,
  ButtonContainer,
} from "./styles";

function Wizard({
  RegistrarAcesso,
  RegistrarVisualizacao,
  RegistrarRecusa,
  RegistrarNaoExibeNovamente,
  TutorialJaFoiExibido,
}) {
  const MAX_STATE = 3;
  const [slideState, setSlideState] = useState(0);
  const [buttonLabel, setButtonLabel] = useState("PRÓXIMO");
  const [tutorialVisualizado, setTutorialVisualizado] = useState(false);

  useEffect(() => {
    RegistrarAcesso();
  }, [RegistrarAcesso]);

  useEffect(() => {
    if (slideState === 3) {
      setButtonLabel("PROSSEGUIR");
    } else {
      setTutorialVisualizado(true);
      setButtonLabel("PRÓXIMO");
    }
  }, [slideState]);

  const handleRegistrarVisualizacao = (close) => {
    RegistrarVisualizacao();

    if (close) {
      TutorialJaFoiExibido();
      document.location.href = "/";
    }
  };

  const handleRegistrarRecusa = () => {
    localStorage.setItem("wizardSeen", true);
    if (!tutorialVisualizado) {
      RegistrarRecusa();
      TutorialJaFoiExibido();
    }

    document.location.href = "/";
  };

  const handleRegistrarNaoExibeNovamente = () => {
    if (!tutorialVisualizado) {
      RegistrarNaoExibeNovamente();
      TutorialJaFoiExibido();
    }

    document.location.href = "/";
  };

  function onChangeIndexSlider(index, indexLatest, meta) {
    setSlideState(index);
  }

  function handleClick() {
    if (slideState < MAX_STATE) {
      setSlideState(slideState + 1);
    }
    if (buttonLabel === "PROSSEGUIR") {
      handleRegistrarVisualizacao();
      document.location.href = "/";
      localStorage.setItem("wizardSeen", true);
    }
  }

  const dotClick1 = () => {setSlideState(0)}

  const dotClick2 = () => {setSlideState(1)}

  const dotClick3 = () => {setSlideState(2)}

  const dotClick4 = () => {setSlideState(3)}
 
  return ( 
        <Container>
          <Logo src={logo_localiza} />
          <SwipeableViews
            resistance
            enableMouseEvents
            containerStyle={{
              height: "100%",
              alignItems: "center",
              justifyContent: "space-between",
            }}
            style={{
              height: "100%",
            }}
            slideStyle={{
              paddingHorizontal: 16,
              marginBottom: '105px',
            }}
            index={slideState}
            onChangeIndex={onChangeIndexSlider}
          >
            <SliderCard>
              <VStack>
                <ImagemCelular src={Icone} />
                <FlexContainer>
                  <Hashtag src={hashtag} />
                  <Subtitle>
                    Aqui você tem acesso a todas informações
                    <br /> do seu veículo.
                  </Subtitle>
                </FlexContainer>
              </VStack>
            </SliderCard>
            <SliderCard>
              <VStack>
                <ImagemMultas src={MultasImg} />
                <Title>Multas</Title>
                <Subtitle>
                  Você consegue visualizar o histórico de <br />
                  multas e indicar o condutor da infração.
                </Subtitle>
              </VStack>
            </SliderCard>
            <SliderCard>
              <VStack>
                <ImagemAgendamento src={AgendamentoImg} />
                <Title>Agendamento</Title>
                <Subtitle>
                  Você também pode agendar serviços para <br />o seu carro e
                  consultar o histórico.
                </Subtitle>
              </VStack>
            </SliderCard>
            <SliderCard>
              <VStack>
                <ImagemCarro src={CarroImg} />
                <Title>Informações adicionais</Title>
                <Subtitle>
                  No <b>#meucarro</b> você também pode: <br />
                  Reportar roubo, visualizar política de <br />
                  frotas e outras informações do seu veículo.
                </Subtitle>
              </VStack>
            </SliderCard>
          </SwipeableViews>           
          <HStack>
          <ButtonContainer>
          <button onClick={dotClick1} className={slideState === 0 ? 'on' : 'off'}/> 
          <button onClick={dotClick2} className={slideState === 1 ? 'on' : 'off'}/> 
          <button onClick={dotClick3} className={slideState === 2 ? 'on' : 'off'}/> 
          <button onClick={dotClick4} className={slideState === 3 ? 'on' : 'off'}/>   
          </ButtonContainer>   
            <Button onClick={handleRegistrarRecusa} isOutlined>
              pular
            </Button>
            <Button onClick={handleClick}>{buttonLabel}</Button>
          </HStack>
        </Container>    
  );
}

export default connect(null, {
  RegistrarAcesso,
  RegistrarVisualizacao,
  RegistrarRecusa,
  RegistrarNaoExibeNovamente,
  TutorialJaFoiExibido,
})(Wizard);
