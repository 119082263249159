
import * as Cookie from '../../functions/Cookies';
import { NomeCookieUrlForcada, NomeCookieBanner } from '../../config/consts';

import {
    ON_LOGIN_FAIL,
    ON_LOGIN,
    DESLOGAR,
    SET_PLATE,
    RESET,
    VALIDAR_CODIGO_SMS,
    DESLOGAR_COM_LINK,
    LOGIN_LOADING,
    LOGIN_LOADED
} from './LoginTypes'

const INITIAL_STATE = {
    erro: null,
    resposta: null,
    usuario: null,
    codigoUsuario: null,
    loading: false   
}

export default (state = INITIAL_STATE, action) => {

    switch (action.type) {
        case ON_LOGIN:
            Cookie.set(NomeCookieBanner, "naolida", null, true);
            return { ...state, erro: null, resposta: action.payload, usuario: action.payload.usuario, codigoUsuario: action.payload.codigoUsuario };
        case SET_PLATE:
            Cookie.set(NomeCookieBanner, "naolida", null, true);
            return { ...state, erro: null, resposta: null, usuario: {P: action.payload} };
        case VALIDAR_CODIGO_SMS:
            Cookie.set(NomeCookieUrlForcada,'', -1);  
            return { ...state, erro: null, resposta: action.payload, usuario: null };
        case ON_LOGIN_FAIL:
            return { ...state, erro: action.payload, resposta: null, usuario: null };
        case DESLOGAR_COM_LINK:
            return { ...state, erro: null, resposta: action.payload, usuario: null };
        case DESLOGAR:
            return { ...state, erro: action.payload, resposta: null, usuario: null };
        case RESET:
            return { ...state, erro: null, resposta: null, usuario: null };
        case LOGIN_LOADING: 
            return {...state, loading: true };
        case LOGIN_LOADED:
            return {...state, loading: false}
        default:
            return state;
    }
}
