import styled, { css } from "styled-components";

export const FormContainer = styled.div`

  @media (max-width: 1025px) {
    width: 100%;
    height: 100%;
  }

  {
  background: #018444;
}
`;

export const ImageRedirectApp = styled.img`

  width: 100%;
  height: 100%;
`;




