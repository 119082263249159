import React from 'react';
import { Button, makeStyles } from '@material-ui/core';
import ExibirImagem from './ExibirImagem';

const useStyles = makeStyles(theme => ({
    divButton: {
      marginTop: 20,
      marginBottom: 20,
      textAlign: 'center'
    }
  }));

function ButtonUploadImage({ title, image, handleUpload, handleClearState }) {
    const classes = useStyles();
        
    return image ?
        (<ExibirImagem 
            handleClearState = {handleClearState}
            image = {image}
            />) :
        (<div className={`col-md-12 ${classes.divButton}`}>
            <Button
                variant="outlined"
                component="label"
                color="primary"
            >
                + {title}
                <input
                    type="file"
                    style={{ display: "none" }}
                    onChange={handleUpload}
                    accept="image/jpeg,image/png,application/pdf"
                />
            </Button>
        </div>)
}

export default ButtonUploadImage;