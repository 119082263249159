import React, { useState, useEffect } from "react";
import "./styles";
import { Link, useHistory } from "react-router-dom";
import {
  FormContainer,
  LabelWrapper,
  InputContainer,
  TextFieldMaterial,
  LinkContainer,
  Ancora,
  ButtonContainer,
  Button,
  ErrorContainer,
  MessageError,
  AvisoContainer,
  Aviso,
  WrapperAviso,
  TextContainer,
  Text,
  OkLink,
} from "./styles";
import fire from "../../fire";
import { useAuth } from "../../../providers/auth";
import checkAviso from "../../../images/checkAviso.svg";
import Loading from "../../Loading";
import Tracking from "../../../hooks/Tracking";
import { SHA256 } from "crypto-js";

export const LoginSenhaRecuperacao = () => {
  const {
    email,
    setEmail,
    error,
    setError,
    clearErrors,
    resetState,
    loading,
    setLoading,
    fetchSignInMethodsForEmailFb,
    sendPasswordResetEmailFb,
  } = useAuth();
  var now;
  var timeToken;
  var originToken;

  const [notification, setNotification] = useState(false);
  const history = useHistory();
  const [confirmEmail, setConfirmEmail] = useState("");

  const redirectToLogin = () => {
    history.push("/Login");
  };

  useEffect(() => {
    clearErrors();
    resetState();
  }, []);

  const special =
    /^(?=(?:.*?[!@#$%*()_+^&}{:;?.]){1})(?!.*\s)[0-9a-zA-Z!@#;$%*(){}_+^&]/;

  const sendEmail = () => {
    if (email === "") {
      setError("Campo e-mail é obrigatório");
    } else if (email != confirmEmail) {
      setError("Os e-mails devem ser iguais");
    } else if (email.length <= 6 || !special.exec(email)) {
      setError("Digite um e-mail válido");
    } else {
      Tracking.sendEvent({
        category: "login_recuperacao_senha",
        action: "LOGIN_SENHA_RECUPERACAO_ENVIANDO_EMAIL",
        label: "Clicou no botão para recuperar a senha.",
      });
      setLoading(true);
      now = new Date();
      timeToken = (now.getTime()).toString();
      originToken = SHA256(process.env.REACT_APP_ENCRYPT_HASH_KEY + timeToken).toString();
      fetchSignInMethodsForEmailFb(email, originToken, timeToken)
        .then((result) => {
          if (result.status === 200) {
            sendPasswordResetEmailFb(email, originToken, timeToken).then(() => {
              Tracking.sendEvent({
                category: "login_recuperacao_senha",
                action: "LOGIN_SENHA_RECUPERACAO_ENVIANDO_SUCESSO",
                label: "E-mail de recuperação enviado com sucesso.",
              });
              setLoading(false);
              setNotification(true);
            });
            return;
          }
          if (result.response.status === 400) {
            setLoading(false);
            setError("Este e-mail não está cadastrado");
          }
        })
        .catch(() => {
          Tracking.sendEvent({
            category: "login_recuperacao_senha",
            action: "LOGIN_SENHA_RECUPERACAO_ENVIANDO_ERRO",
            label: "E-mail de recuperação não enviado.",
          });
          setError("Não foi possível reenviar o e-mail de verificação.");
        });
    }
  };

  return (
    <React.Fragment>
      <FormContainer>
        <LabelWrapper>
          Digite seu e-mail para <br /> recuperar sua senha
        </LabelWrapper>
        <InputContainer>
          <TextFieldMaterial
            className="textFieldPassword"
            id="Email"
            label="E-mail"
            variant="outlined"
            name="email"
            type="text"
            onChange={(e) => setEmail(e.target.value.trim())}
            value={email}
            InputLabelProps={{
              style: { color: "#3A3D42", fontSize: "14px" },
            }}
          />
          <TextFieldMaterial
            className="textFieldPassword"
            id="ConfirmEmail"
            label="Confirme o e-mail"
            variant="outlined"
            name="email"
            type="text"
            onChange={(e) => setConfirmEmail(e.target.value)}
            value={confirmEmail}
            InputLabelProps={{
              style: { color: "#3A3D42", fontSize: "14px" },
            }}
          />
        </InputContainer>
        {error ? (
          <ErrorContainer>
            <MessageError>{error}</MessageError>
          </ErrorContainer>
        ) : (
          ""
        )}
        <TextContainer>
          <Text>
            Caso não receba o e-mail, verifique seu spam ou lixo eletrônico.
          </Text>
        </TextContainer>
        <ButtonContainer>
          <Button onClick={sendEmail}>Prosseguir</Button>
        </ButtonContainer>
        <LinkContainer>
          <Link to="/Login">
            <Ancora onClick={resetState}>Voltar para a tela de login</Ancora>
          </Link>
        </LinkContainer>
      </FormContainer>
      {loading === true ? <Loading /> : ""}
      {notification === true ? (
        <React.Fragment>
          <AvisoContainer>
            <Aviso>
              <img src={checkAviso} alt="" />
              <WrapperAviso>E-mail enviado com sucesso!</WrapperAviso>
              <OkLink onClick={redirectToLogin}>OK</OkLink>
            </Aviso>
          </AvisoContainer>
        </React.Fragment>
      ) : null}
    </React.Fragment>
  );
};
