import React from "react";
import { makeStyles, Typography } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

const useStyles = makeStyles((theme) => ({
  dialogPaper: {
    [theme.breakpoints.down(480)]: {
      margin: 24,
    },
    borderTop: "4px solid #01602A",
    borderRadius: 0,
  },
}));

function DialogMensagem({ titulo, conteudo, open, handleClose, scroll }) {
  const classes = useStyles();

  const descriptionElementRef = React.useRef(null);
  React.useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  return (
    <React.Fragment>
      <Dialog
        open={open}
        onClose={handleClose}
        scroll={scroll}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        classes={{ paper: classes.dialogPaper }}
      >
        <DialogTitle id="scroll-dialog-title" style={{ textAlign: "left" }}>
          <Typography style={{ fontFamily: "Metropolis-semiBold" }}>
            {titulo}
          </Typography>
        </DialogTitle>
        <DialogContent dividers={scroll === "paper"}>
          <DialogContentText
            id="scroll-dialog-description"
            ref={descriptionElementRef}
            tabIndex={-1}
            style={{ fontFamily: "Metropolis", fontSize: 12 }}
          >
            {conteudo}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <button className="button" onClick={handleClose} color="primary">
            OK
          </button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}

export default DialogMensagem;
