import React, { Component } from 'react';
import { selecionarCidadePorNomeEUf, limparCidadeSelecionada, inputarCidadeSelecionada } from '../../components/InputCidades/InputCidadesAction';
import { connect } from 'react-redux';


class TerceiroEnvolvido extends Component {

    constructor(props) {
        super(props);
        this.state = {erroValidacoes:{
            terceiroEnvolvido:false,
            terceiroCarroEnvolvido: false,
            causadorAcidente: false
        },
        };
    }

    componentDidMount() {
        window.scrollTo(0, 0);
    }

    handleAvancar = () => {
        this.props.onIrParaEtapa(3);
    }

    handleVoltar = () => {
        this.handleReiniciaCampos();
        this.props.onIrParaEtapa(1);
    }

    handleAlteraTerceiroEnvolvido=(event)=> {
        if(event.target.value !== null && event.target.value !== ""){
          this.props.onChangeTerceiroEnvolvido(event.target.value);

          if(event.target.value ==="não"){
            this.props.onChangeOutroEnvolvido("");
            this.props.onChangeCausadorAcidente("");
            let newState = {...this.state}
            newState.erroValidacoes.terceiroCarroEnvolvido = false;
            this.setState(newState); 
          }
        }
    }

    handleAlteraCausadorAcidente = (event) => {
        if (event.target.value !== null && event.target.value !== "") {
            this.props.onChangeCausadorAcidente(event.target.value);
        }
    }

    handleAlteraOutroEnvolvido=(event)=> {
        if(event.target.value !== null && event.target.value !== ""){
          this.props.onChangeOutroEnvolvido(event.target.value);
        }
    }

    handleValidacao = () => {

        return (
            this.state.erroValidacoes.causadorAcidente === false &&
            this.state.erroValidacoes.terceiroCarroEnvolvido === false &&
            this.state.erroValidacoes.terceiroEnvolvido === false
        )
    }

    handleReiniciaCampos = () =>{
        this.props.onChangeOutroEnvolvido("");
        this.props.onChangeCausadorAcidente("");
        this.props.onChangeTerceiroEnvolvido("");
    }

    render() {
        const {terceiroEnvolvido, outroEnvolvido, causadorAcidente} = this.props.modelo;
        const disabledButton = terceiroEnvolvido === "" || terceiroEnvolvido === "sim" && (outroEnvolvido === "" || causadorAcidente === "");
        let existeTerceiroEnvolvido = terceiroEnvolvido === "sim" ?
        <React.Fragment>   
            <div className="col-12">
                <h2 className="htmlForm-title">Existe outro veículo envolvido?</h2>
                <div className="custom-radio d-inline-flex mr-3">
                    <input type="radio" id="outroVeiculo_sim" name="outroVeiculo" className="custom-control-input" value="sim"
                        onChange={this.handleAlteraOutroEnvolvido} checked={outroEnvolvido === "sim"}/>
                    <label className={`custom-control-label  ${(this.state.erroValidacoes.terceiroCarroEnvolvido === true) ? 'red' : ""}`} htmlFor="outroVeiculo_sim">Sim</label>
                </div>
                        <div className="custom-radio d-inline-flex">
                            <input type="radio" id="outroVeiculo_nao" name="outroVeiculo" className="custom-control-input" value="não"
                                    onChange={this.handleAlteraOutroEnvolvido} checked={outroEnvolvido === "não"}/>
                            <label className={`custom-control-label  ${(this.state.erroValidacoes.terceiroCarroEnvolvido === true) ? 'red' : ""}`} htmlFor="outroVeiculo_nao">Não</label>
                </div>
            </div>
            <div className="col-12">
                <h2 className="htmlForm-title">Causador do acidente</h2>
                <div className="custom-radio d-inline-flex mr-3">
                    <input type="radio" id="causador_terceiro" name="causador" className="custom-control-input" value="terceiro"
                        onChange={this.handleAlteraCausadorAcidente} checked={causadorAcidente === "terceiro"} />
                    <label className={`custom-control-label ${(causadorAcidente === true) ? 'red' : ""}`}
                        htmlFor="causador_terceiro">Terceiro</label>
                </div>
                <div className={`custom-radio d-inline-flex ${(causadorAcidente === true) ? 'has-error' : ""}`}>
                    <input type="radio" id="causador_condutor" name="causador" className="custom-control-input" value="condutor"
                        onChange={this.handleAlteraCausadorAcidente} checked={causadorAcidente === "condutor"} />
                    <label className={`custom-control-label ${(causadorAcidente === true) ? 'red' : ""}`}
                        htmlFor="causador_condutor">Condutor Localiza</label>
                </div>
            </div>     
        </React.Fragment> : ""; 

        return (
            <React.Fragment>
                <div className="row w-xl-66">
                    <div className="col-12">
                        <h2 className="Form-title">Houve algum terceiro ou bem público/privado envolvido no acidente?</h2>
                        <div className="custom-radio d-inline-flex mr-3">
                            <input type="radio" id="terceiro_sim" name="terceiro" className="custom-control-input" value="sim"
                                onChange={this.handleAlteraTerceiroEnvolvido} checked={terceiroEnvolvido === "sim"}/>
                            <label className={`custom-control-label  ${(this.state.erroValidacoes.terceiroEnvolvido===true) ? 'red' : ""}`}  htmlFor="terceiro_sim">Sim</label>
                        </div>
                        <div className="custom-radio d-inline-flex">
                            <input type="radio" id="terceiro_nao" name="terceiro" className="custom-control-input" value="não"
                                onChange={this.handleAlteraTerceiroEnvolvido} checked={terceiroEnvolvido === "não"}/>
                            <label className={`custom-control-label  ${(this.state.erroValidacoes.terceiroEnvolvido===true) ? 'red' : ""}`}  htmlFor="terceiro_nao">Não</label>
                        </div>                      
                    </div>
                    {existeTerceiroEnvolvido}
                    <div className="w-100"></div>
                    <div className="ml-auto mt-3 col-md-6">
                        <div className="row">
                            <div className="col">
                                <button className="button" onClick={this.handleVoltar}>Voltar</button>
                            </div>
                            <div className="col">
                                <button className="button" disabled={disabledButton} onClick={this.handleAvancar}>Continuar</button>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default TerceiroEnvolvido;