import React, { Component } from "react";
import { connect } from "react-redux";
import {
  buscarSSMae,
  listarFeriados,
  buscarAgendamentosDoDia,
  resetIsHoliday,
  isHolidayFunc,
} from "./AgendamentoActions";
import Loading from "../../../components/Loading";
import PainelData from "./PainelData";
import ModalSSComplementar from "./ModalSSComplementar";
import moment from "moment";
import { montaHorariosPossiveis } from "./PainelDataHelper";
import * as dataHelper from "./PainelDataHelper";
import ModalMensagem from "../../../components/ModalMensagem/ModalMensagem";
import ErrorBoundary from "../../../components/ErrorBoundary"

class PainelConcluir extends Component {
  constructor(props) {
    super(props);

    this.state = {
      validacaoModal: null,
      model: null,
    };

    this.ValidarMae = this.ValidarMae.bind(this);
    this.HandleDateChange = this.HandleDateChange.bind(this);
    this.HandleTimeChange = this.HandleTimeChange.bind(this);
    this.toggleModal = this.toggleModal.bind(this);
    this.GetExcludedDates = this.GetExcludedDates.bind(this);
  }

  componentWillMount() {
    this.props.listarFeriados();
  }

  componentDidMount() {
    this.ValidarMae();
  }

  verificarPrimeiroDiaPossivel(diasExcluidos) {
    let data = moment();
    for (let i = 0; i < 100; i++) {
      let encontrado = diasExcluidos.find((element) => {
        return element.format("YYYY-MM-DD") === data.format("YYYY-MM-DD");
      });
      if (encontrado == null) {
        return data;
      }
      data = data.add(1, "days");
    }
    return moment();
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.fornecedor &&
      this.props.fornecedor != prevProps.fornecedor
    ) {
      var model = this.props.model;
      model.date = null;
      model.time = null;
      this.setState({ validacaoModal: null, model: model });
      this.GetExcludedDates();
      model.minDate = this.verificarPrimeiroDiaPossivel(
        this.GetExcludedDates()
      );
      this.ValidarMae();
    }
    if (this.props.ssMae && this.props.ssMae != prevProps.ssMae) {
      if (this.props.ssMae.PossuiMae === false) {
        this.setState({ validacaoModal: false, model: this.props.model });
      } else if (!this.props.ssMae.Textos) {
        //não é necessário verificar os dados com o usuário
        this.setState({ validacaoModal: true, model: this.props.model });
      }
    }
    if (this.props.datasDia !== prevProps.datasDia) {
      var includedTimes = montaHorariosPossiveis(
        this.state.model.date,
        this.props.shiftList,
        this.props.datasDia.agendamentos
      );
      this.setState({ includedTimes });
      this.props.isHolidayFunc(
        this.state.model.date.format("DD/MM/YYYY HH:mm:ss"),
        this.props.model.cidade[0].mIdCidadeField
      );
    }
    if (this.props.isHoliday !== prevProps.isHoliday) {
      var modelo = this.state.model;
      modelo.isHoliday = this.props.isHoliday;
      this.setState({ model: modelo });
    }
    if (this.props.feriados !== prevProps.feriados) {
      var excludedDates = this.GetExcludedDates();
      var model = this.props.model;
      model.date = null;
      model.time = null;
      model.excludedDates = excludedDates;
      model.minDate = this.verificarPrimeiroDiaPossivel(excludedDates);
      this.setState({ validacaoModal: null, model: model });
    }
  }

  toggleModal(resposta) {
    this.setState({ validacaoModal: resposta, model: this.props.model });
  }

  ValidarMae() {
    let objeto = {
      AttendanceTypeCode: "08",
      Driver: "",
      ContactName: "",
      ContactPhoneLandline: "",
      Email: "",
      ContactCellPhone: "",
      CellMessage: "1",
      AttendanceValidated: true,
      ValidateTowTruckIntegration: false,
      SupplierCGC: this.props.fornecedor,
      SupplierShiftList: this.props.shiftList.map(this.formatarShiftList),
      Plate: localStorage.getItem("placa-atual"),
      User: "",
      AttendanceTreeIds: this.props.model.servicos,
      SelectedDate: "",
      CurrentKm: this.props.model.kmAtual,
      CityId: "",
      Sscomplementar: false,
    };

    this.props.buscarSSMae(objeto);
  }

  formatarShiftList(x) {
    return {
      dia: x.mPKField.dayTypeIdField,
      entrada: x.mPKField.shiftBeginnigHourField,
      saida: x.mShiftEndHourField,
    };
  }

  HandleDateChange(date) {
    this.props.buscarAgendamentosDoDia(
      date.format("DD/MM/YYYY HH:mm:ss"),
      this.props.fornecedor
    );
    var modelo = this.state.model;
    modelo.date = date;
    modelo.time = null;
    this.setState({ model: modelo });
  }

  HandleTimeChange(date) {
    var modelo = this.state.model;
    modelo.time = date;
    this.setState({ model: modelo });
  }

  HandleOnModalHolidayToggle = () => {
    this.props.resetIsHoliday();

    var modelo = this.state.model;
    modelo.date = null;
    this.setState({ model: modelo });
  };

  GetExcludedDates() {
    var excludedDates = [];
    var feriados = dataHelper.getHolidays(this.props.feriados);
    excludedDates = excludedDates.concat(feriados);
    var fSemana = dataHelper.getWeekendDates();
    excludedDates = excludedDates.concat(fSemana);
    var diasFolga = dataHelper.getDiasFolga(this.props.shiftList);
    excludedDates = excludedDates.concat(diasFolga);
    var availability = dataHelper.getAvailability(this.props.availability);
    excludedDates = excludedDates.concat(availability);

    //não é possível agendar apos 12h
    var agora = moment(new Date());
    var includedTimes = dataHelper.montaHorariosPossiveis(
      agora,
      this.props.shiftList
    );
    if (includedTimes.length === 0) {
      excludedDates.push(moment(agora));
    }
    return excludedDates;
  }

  OnModalToggle() {
    document.location.href = "/";
  }

  render() {
    if (this.props.loading) return <Loading />;

    if (this.props.resposta)
      return (
        <ModalMensagem
          tipoModalMensagem=""
          icon="icon-check-circle2"
          textoBotao="OK"
          mensagemPrincipal={this.props.resposta}
          mensagemSecondaria="A Localiza não se responsabiliza por objetos pessoais deixados no veículo. Ao deixar seu carro no fornecedor, preencha o check list e solicite a previsão de conclusão do serviço"
          OnToggled={this.OnModalToggle}
        />
      );

    if (this.state.validacaoModal === null && this.props.ssMae)
      return (
        <ModalSSComplementar
          ssMae={this.props.ssMae}
          onToggle={this.toggleModal}
        />
      );

    if (this.state.model === null) return null;

    var model = this.state.model;
    if (this.props.ssMae) {
      model.sscomplementar =
        this.props.ssMae.PossuiMae && this.state.validacaoModal === true;
      if (model.sscomplementar === true) {
        model.date = this.props.ssMae.DataEntradaPrevista
          ? moment(this.props.ssMae.DataEntradaPrevista)
          : null;
        model.time = this.props.ssMae.DataEntradaPrevista
          ? moment(this.props.ssMae.DataEntradaPrevista)
          : null;
      }
    }

    return (
      <ErrorBoundary texto={"neste painel"}>
        <PainelData
          model={model}
          fornecedor={this.props.fornecedor}
          shiftList={this.props.shiftList}
          feriados={this.props.feriados}
          includedTimes={this.state.includedTimes}
          isHoliday={model.isHoliday}
          OnDateChange={this.HandleDateChange}
          OnTimeChange={this.HandleTimeChange}
          OnModalHolidayToggle={this.HandleOnModalHolidayToggle}
          time={model.time}
        />
      </ErrorBoundary>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    datasDia: state.Agendamento.datasDia,
    feriados: state.Agendamento.feriados,
    placa: state.menu.placa,
    isHoliday: state.Agendamento.isHoliday,
    loading: state.Agendamento.loading,
    resposta: state.Agendamento.resposta,
    ssMae: state.Agendamento.ssMae,
  };
};

export default connect(mapStateToProps, {
  buscarSSMae,
  listarFeriados,
  buscarAgendamentosDoDia,
  resetIsHoliday,
  isHolidayFunc,
})(PainelConcluir);
