import React, { Component } from "react";
import CardAgendamento from "../../components/Agendamentos/CardAgendamento";
import Panel from "../../components/Panel";
import {
  listarAgendamentos,
  exibirDetalheAgendamento,
  limparMensageErro,
} from "./CardAgendamentoAction";
import { formatDayMonth } from "../../helpers/formatDate";
import { connect } from "react-redux";
import ButtonLightIcon from "../ButtonLightIcon";
import DetalhesAgendamento from "./DetalhesAgendamento";
import ModalMensagem from "../../components/ModalMensagem/ModalMensagem";
import CabecalhoAgendamento from "./Cabecalho";
import { Link } from "react-router-dom";
import {
  Container,
  Historico,
  Abertas,
  Texto,
  ImgContainer,
  HistoricoMobile,
} from "./styles";
import "../../styles/css/container-multas.css";
import imgAgendamentos from "../../images/imgAgendamentos.svg";
import MediaQuery from "react-responsive";
import ErrorBoundary from "../ErrorBoundary";
import Loading from "../../components/Loading";
import { gerarLinkAgendamento } from "../../pages/Agendamento/Novo/AgendamentoActions";

class Painel extends Component {
  render() {
    var i = 0;

    return (
      <Panel
        titulo={this.props.titulo}
        quantidade={this.props.itens.length}
        openDefault
      >
        {this.props.itens.map((element) => {
          return (
            <div
              key={i++}
              onClick={() => {
                this.props.ExibirDetalhes(
                  localStorage.getItem("placa-atual"),
                  element.NumeroSolicitacaoServico,
                  element.SequencialSolicitacaoServico,
                  this.props.mobile
                );
              }}
            >
              <ErrorBoundary texto={"no card de agendamentos"}>
                <CardAgendamento
                  data={formatDayMonth(element.DataAgendamento)}
                  hora={element.HoraAgendamentoFormatada}
                  fornecedor={element.NomeFornecedor}
                  endereco={element.EnderecoFornecedor}
                  corcard={this.props.corcard}
                />
              </ErrorBoundary>
            </div>
          );
        })}
      </Panel>
    );
  }
}

export class BotaoNovoAgendamento extends Component {
  render() {
    return (
      <React.Fragment>
        <MediaQuery maxDeviceWidth={1025}>
          <span>
            {this.props.ocultar ||
            this.props.situacao === SituacaoNovoAgendamento.Ocultar ? null : (
              <button
                className="button"
                onClick={this.props.openFormInNewTab}
                style={{
                  textDecoration: "none",
                  backgroundColor: '#01602a',
                  color: "#FFFFFF",
                  margin: "27px 0 0 0",
                }}
              >
                AGENDAR NOVO SERVIÇO
              </button>
            )}
          </span>
        </MediaQuery>
        <MediaQuery minDeviceWidth={1025}>
          <span>
            {this.props.ocultar ||
            this.props.situacao === SituacaoNovoAgendamento.Ocultar ? null : (
              <button
                className="button"
                onClick={this.props.openFormInNewTab}
                style={{
                  textDecoration: "none",
                  color: "#FFFFFF",
                  backgroundColor: "#01602A",
                  cursor: "pointer",
                }}
              >
                AGENDAR NOVO SERVIÇO
              </button>
            )}
          </span>
        </MediaQuery>
      </React.Fragment>
    );
  }
}

export const SituacaoNovoAgendamento = {
  Ocultar: 0,
  Liberar: 1,
};

class Lista extends Component {
  constructor() {
    super();
    this.state = {
      limitTo: 3,
      historico: true,
    };
    this.listarProximos = this.listarProximos.bind(this);
    this.handleRemoveMensagemErro = this.handleRemoveMensagemErro.bind(this);
    this.handleToggleOn = this.handleToggleOn.bind(this);
    this.handleToggleOff = this.handleToggleOff.bind(this);
    this.openFormInNewTab = this.openFormInNewTab.bind(this);
  }

  handleToggleOn() {
    this.setState({
      historico: false,
    });
  }

  openFormInNewTab(e) {
    localStorage.removeItem("link-form");
    this.props.gerarLinkAgendamento();
    setTimeout(() => {
      const linkForm = localStorage.getItem("link-form") ?? "";
      if (linkForm) window.open(linkForm, "_blank", "noreferrer");
    }, 500);
  }

  handleToggleOff() {
    this.setState({
      historico: true,
    });
  }

  componentDidMount() {
    if (localStorage.getItem("placa-atual"))
      this.props.listarAgendamentos(localStorage.getItem("placa-atual"), true);
  }

  handleRemoveMensagemErro() {
    this.props.limparMensageErro();
  }

  listarProximos() {
    this.setState({
      limitTo: this.state.limitTo + 5,
    });
  }

  render() {
    if (this.props.loading) return <Loading />;
    if (!this.props.agendamentos) return null;

    var pnlProximos = null;
    var pnlPassados = null;
    var btnVerMais = null;

    if (
      this.props.agendamentos.ListaAcompanhamentoServico.Proximos.length > 0
    ) {
      pnlProximos = (
        <Painel
          titulo="Próximos"
          placa={localStorage.getItem("placa-atual")}
          itens={this.props.agendamentos.ListaAcompanhamentoServico.Proximos.slice(
            0,
            this.state.limitTo
          )}
          quantidade={
            this.props.agendamentos.ListaAcompanhamentoServico.Proximos.length
          }
          ExibirDetalhes={this.props.exibirDetalheAgendamento}
          corcard=""
          mobile={this.props.mobile}
        />
      );
    }
    if (
      this.props.agendamentos.ListaAcompanhamentoServico.Passados.length > 0
    ) {
      if (this.state.limitTo > 0) {
        pnlPassados = (
          <Painel
            titulo="Realizados"
            placa={localStorage.getItem("placa-atual")}
            itens={this.props.agendamentos.ListaAcompanhamentoServico.Passados.slice(
              0,
              this.state.limitTo
            )}
            quantidade={
              this.props.agendamentos.ListaAcompanhamentoServico.Passados.length
            }
            ExibirDetalhes={this.props.exibirDetalheAgendamento}
            corcard="disabled"
            mobile={this.props.mobile}
          />
        );
      }
    }
    if (
      this.props.agendamentos.ListaAcompanhamentoServico.Proximos.length +
        this.props.agendamentos.ListaAcompanhamentoServico.Passados.length >
      this.state.limitTo
    ) {
      btnVerMais = (
        <div onClick={this.listarProximos}>
          <ButtonLightIcon label="CARREGAR MAIS" />
        </div>
      );
    }

    if (this.props.mobile === true) {
      if (this.props.listar === true) {
        return (
          <section>
            <Container>
              <Abertas
                onClick={this.handleToggleOff}
                className={
                  this.state.historico === true
                    ? "turnOnMobile"
                    : "turnOffMobile"
                }
              >
                <Texto>
                  Realizados
                  {this.props.agendamentos.ListaAcompanhamentoServico.Passados
                    .length === 0
                    ? ""
                    : ` (${this.props.agendamentos.ListaAcompanhamentoServico.Passados.length})`}
                </Texto>
              </Abertas>
              <Historico
                onClick={this.handleToggleOn}
                className={
                  this.state.historico === true
                    ? "turnOffMobile"
                    : "turnOnMobile"
                }
              >
                <Texto>
                  Agendados
                  {this.props.agendamentos.ListaAcompanhamentoServico.Proximos
                    .length === 0
                    ? ""
                    : ` (${this.props.agendamentos.ListaAcompanhamentoServico.Proximos.length})`}
                </Texto>
              </Historico>
            </Container>
            {pnlProximos === null && this.state.historico === false ? (
              <ImgContainer>
                {" "}
                <img src={imgAgendamentos} />
              </ImgContainer>
            ) : (
              ""
            )}
            {this.state.historico === false ? pnlProximos : pnlPassados}
            {this.state.historico === true ? btnVerMais : null}
            {this.state.historico === false &&
            this.props.agendamentos.ListaAcompanhamentoServico.Proximos.length >
              this.state.limitTo
              ? btnVerMais
              : null}
            <ErrorBoundary texto={"nos detalhes do agendamento"}>
              <DetalhesAgendamento />
            </ErrorBoundary>
            {this.props.erro ? (
              <ModalMensagem
                tipoModalMensagem="warning"
                icon="icon-x-circle"
                mensagemPrincipal={this.props.erro.mensagem}
                mensagemSecundaria=""
                OnToggled={this.handleRemoveMensagemErro}
                textoBotao="OK"
              />
            ) : null}
            <BotaoNovoAgendamento
              situacao={this.props.agendamentos.SituacaoNovoAgendamento}
              openFormInNewTab={this.openFormInNewTab}
            />
          </section>
        );
      } else {
        return (
          <section>
            <CabecalhoAgendamento
              placa={localStorage.getItem("placa-atual")}
              cabecalho={
                this.props.agendamentos.Cabecalho
                  ? this.props.agendamentos.Cabecalho
                  : null
              }
              mobile={this.props.mobile}
              situacao={this.props.agendamentos.SituacaoNovoAgendamento}
            />
            <BotaoNovoAgendamento
              situacao={this.props.agendamentos.SituacaoNovoAgendamento}
              openFormInNewTab={this.openFormInNewTab}
            />
            {this.props.erro ? (
              <ModalMensagem
                tipoModalMensagem="warning"
                icon="icon-x-circle"
                mensagemPrincipal={this.props.erro.mensagem}
                OnToggled={this.handleRemoveMensagemErro}
                mensagemSecundaria=""
                textoBotao="OK"
              />
            ) : null}
          </section>
        );
      }
    } else {
      return (
        <div className="item-right">
          <CabecalhoAgendamento
            placa={localStorage.getItem("placa-atual")}
            cabecalho={
              this.props.agendamentos.Cabecalho
                ? this.props.agendamentos.Cabecalho
                : null
            }
            mobile={this.props.mobile}
            situacao={this.props.agendamentos.SituacaoNovoAgendamento}
          />
          <span>
            <BotaoNovoAgendamento
              situacao={this.props.agendamentos.SituacaoNovoAgendamento}
              openFormInNewTab={this.openFormInNewTab}
            />
            <Container>
              <Abertas
                onClick={this.handleToggleOff}
                className={this.state.historico === true ? "turnOn" : "turnOff"}
              >
                <Texto>
                  Realizados
                  {this.props.agendamentos.ListaAcompanhamentoServico.Passados
                    .length === 0
                    ? ""
                    : ` (${this.props.agendamentos.ListaAcompanhamentoServico.Passados.length})`}
                </Texto>
              </Abertas>
              <Historico
                onClick={this.handleToggleOn}
                className={this.state.historico === true ? "turnOff" : "turnOn"}
              >
                <Texto>
                  Agendados
                  {this.props.agendamentos.ListaAcompanhamentoServico.Proximos
                    .length === 0
                    ? ""
                    : ` (${this.props.agendamentos.ListaAcompanhamentoServico.Proximos.length})`}
                </Texto>
              </Historico>
            </Container>
            {pnlProximos === null && this.state.historico === false ? (
              <ImgContainer>
                {" "}
                <img src={imgAgendamentos} />
              </ImgContainer>
            ) : (
              ""
            )}
            {this.state.historico === false ? pnlProximos : pnlPassados}
            {this.state.historico === true ? btnVerMais : null}
            {this.state.historico === false &&
            this.props.agendamentos.ListaAcompanhamentoServico.Proximos.length >
              this.state.limitTo
              ? btnVerMais
              : null}
            <ErrorBoundary texto={"nos detalhes do agendamento"}>
              <DetalhesAgendamento />
            </ErrorBoundary>
            {this.props.erro ? (
              <ModalMensagem
                tipoModalMensagem="warning"
                icon="icon-x-circle"
                mensagemPrincipal={this.props.erro.mensagem}
                mensagemSecundaria=""
                textoBotao="OK"
              />
            ) : null}
          </span>
        </div>
      );
    }
  }
}

const mapStateToProps = (state) => {
  return {
    placa: state.menu.placa,
    agendamentos: state.listaAgendamento.agendamentos,
    erro: state.listaAgendamento.erro,
    detalheAgendamento: state.listaAgendamento.detalheAgendamento,
    loading: state.listaAgendamento.loading,
  };
};

export default connect(mapStateToProps, {
  listarAgendamentos,
  gerarLinkAgendamento,
  exibirDetalheAgendamento,
  limparMensageErro,
})(Lista);
