import React, { Component } from "react";
import "../../styles/css/menu.css";
import "../../styles/css/select-placa.css";
import Cabecalho from "./Cabecalho";
import { connect } from "react-redux";
import { Link, NavLink } from "react-router-dom";
import {
  MenuWebContainer,
  NavContainer,
  SpanContainer,
} from "./styles";
import DriveEtaIcon from "@material-ui/icons/DriveEta";
import ErrorBoundaryRedirect from "../ErrorBoundaryRedirect";

class Menu extends Component {
  render() {
    return (
      <MenuWebContainer>
        <ErrorBoundaryRedirect>
          <Cabecalho />
        </ErrorBoundaryRedirect>
        <NavContainer className="menu">
          <nav className="wrapper-links-menu">
            <ul className="links">
              <li>
                <NavLink
                  data-gtm-inicio="sim"
                  className="link"
                  to="/Home"
                  activeClassName="active"
                >
                  <div className="wrapper  gtm-menu-inicio">
                    <span className="icon icon-home  gtm-menu-inicio"></span>
                    <span className="text gtm-menu-inicio">Início</span>
                  </div>
                </NavLink>
              </li>
              <li>
                <NavLink className="link" to="/Crlv" activeClassName="active">
                  <div className="wrapper">
                    <span className="icon icon-file"></span>
                    <span className="text">Documento CRLV</span>
                  </div>
                </NavLink>
              </li>
              <li>
                <NavLink
                  className="link"
                  to="/GuiaCondutor"
                  activeClassName="active"
                >
                  <div className="wrapper">
                    <span className="icon icon-book"></span>
                    <span className="text">Guia do condutor</span>
                  </div>
                </NavLink>
              </li>
              <li>
                <NavLink
                  className="link"
                  to="/ServicosContratados"
                  activeClassName="active"
                >
                  <div className="wrapper gtm-menu-servicos-contratados">
                    <span className="icon icon-list gtm-menu-servicos-contratados"></span>
                    <span className="text gtm-menu-servicos-contratados">
                      Serviços contratados
                    </span>
                  </div>
                </NavLink>
              </li>
              <li>
                <NavLink
                  className="link"
                  to="/EditarKM"
                  activeClassName="active"
                >
                  <div className="wrapper  gtm-menu-atualizar-km">
                    <span className="icon icon-velocimeter"></span>
                    <span className="text">Atualizar Km</span>
                  </div>
                </NavLink>
              </li>
              <li>
                <NavLink
                  className="link gtm-menu-acidente-batida"
                  to="/Avarias"
                  activeClassName="active"
                >
                  <div className="wrapper gtm-menu-acidente-batida">
                    <span className="icon icon-crash2"></span>
                    <span className="text">Acidente / Batida</span>
                  </div>
                </NavLink>
              </li>
              <li>
                <NavLink className="link" to="/Roubos" activeClassName="active">
                  <div className="wrapper gtm-menu-reportar-roubo">
                    <span className="icon icon-police2"></span>
                    <span className="text">Reportar roubo</span>
                  </div>
                </NavLink>
              </li>
              <li>
                <NavLink
                  className="link"
                  to="/PoliticaFrota"
                  activeClassName="active"
                >
                  <div className="wrapper  gtm-menu-politica-frota">
                    <span className="icon icon-file-text"></span>
                    <span className="text">Política de frota</span>
                  </div>
                </NavLink>
              </li>
              <li className="link">
                <div className="wrapper">
                  <span style={{color: '#DEE9D3', marginRight: '16px'}}>
                    <DriveEtaIcon />
                  </span>
                  <a
                    href="https://localiza.csod.com/"
                    target="_blank"
                    style={{ color: "#FFFFFF" }}
                  >
                    <span className="text">Frotas Experience</span>
                  </a>
                </div>
              </li>
              <li>
                <hr />
                <SpanContainer>
                  <div className="wrapper">
                    <div>
                      <span className="textTitle">Fale com a gente</span>
                    </div>
                    <br />
                    <a
                      href="https://api.whatsapp.com/send?1=pt_BR&phone=5531999101010"
                      target="_blank"
                    >
                      <span className="iconContato icon-whatsapp"></span>
                      <span className="textInfo">WhatsApp: </span>
                      <span className="tel">31 99910-1010</span>
                    </a>
                    <div>
                      <span className="textInfo subtitle">
                      Segunda a Sexta das 07h às 20h
                      </span>
                    </div>
                    <div>
                      <span className="textInfo subtitle">
                      Sábados das 07h às 18h
                      </span>
                    </div>
                    <br />
                    <a href="tel:08009791010">
                      <span className="iconContato icon-phone"></span>
                      <span className="textInfo">Telefone: </span>
                      <span className="tel">0800 979 1010</span>
                    </a>
                    <div>
                      <span className="textInfo subtitle">
                        Todos os dias 24 horas
                      </span>
                    </div>
                  </div>
                </SpanContainer>
                <hr />
              </li>
              <li>
                <Link
                  to="/Login"
                  className="link"
                >
                  <div className="wrapper  gtm-menu-logout">
                    <span className="icon icon-log-out"></span>
                    <span className="text">Sair</span>
                  </div>
                </Link>
              </li>
            </ul>
          </nav>
        </NavContainer>
      </MenuWebContainer>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    /* placa: state.menu.placa, */
  };
};

export default connect(mapStateToProps)(Menu);
