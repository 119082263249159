import moment from 'moment';
import axios from "axios";
import {
    Basic,
} from "../config/consts";

const checkAccessTokenExpired = () => {
    const tokenExpiration = localStorage.getItem('bearerTokenExp');
    return !tokenExpiration || moment(tokenExpiration).diff(moment(), 'minutes') <= 5;
};

export const getApiGeeAccessToken = async () => {
    if (!checkAccessTokenExpired()) {

        return localStorage.getItem('bearerToken') ?? '';
    }

    const config = {
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            Authorization: `Basic ${Basic}`,
        },
    };


    try {

        const response = await axios.post(
            'https://api-gtw-prd.localiza.com/oauth/token/accesstoken?grant_type=client_credentials',
            {},
            config,

        );


        const { access_token = '', expires_in = null } = response?.data || {};


        localStorage.setItem('bearerToken', access_token);
        localStorage.setItem('bearerTokenExp', moment().add(Number(expires_in) / 60, 'minutes').toDate().toString());

        return access_token;
    } catch (e) {
        return '';
    }

};