import
{
    AUTORIZAR_COOKIES,
    LOADING,
    LOADED,
    ERRO
} from './AutorizarCookiesTypes'
import { HttpGet } from '../../functions/default';
import { DESLOGAR } from '../Login/LoginTypes'

export const AutorizarCookies = () => {
    return (dispatch) => {
        dispatch({ type: LOADING });
        HttpGet("/AutorizarCookies?", null, null).then(function (result) {
            dispatch({ type: LOADED });
            dispatch({ type: AUTORIZAR_COOKIES, payload: result.data });
        })
        .catch(function (erro) {
            dispatch({ type: LOADED });
            if(erro.response && erro.response.status === 401) 
                dispatch({ type: DESLOGAR,  payload: {message: 'Usuário não autenticado.'} });
            else {
                dispatch({ type: ERRO, payload: {message: 'Ocorreu um erro ao autorizar o uso de cookies, tente novamente mais tarde.'} }); 
            }
        });
    }
}