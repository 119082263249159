import React, { Component } from "react";
import { connect } from "react-redux";
import { formatDateAbbreviatedShorter } from "../../helpers/formatDate";
import ModalInterposicao from "./ModalInterposicaoRecursos";
import { withRouter } from "react-router-dom";
import { buscarMyFleetUrl } from "./CardMultaAction";
import { ContainerCard } from "./styles";

export class CardMultas extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalOpenedInterposicao: false,
    };
  }

  onShowInterposicao() {
    this.setState({
      modalOpenedInterposicao: true,
    });
  }

  onHideInterposicao() {
    this.setState({
      modalOpenedInterposicao: false,
    });
  }

  onShowIndicacao = () => {
    this.redirectInformacaoMulta();
  };

  redirectInformacaoMulta = () => {
    let { history, ait, usuarioIndicado, orgao } = this.props;

    history.push({
      pathname: `/InformacaoMulta/${ait}/${orgao}`,
      state: {
        ait: ait,
        orgao: orgao,
        usuarioIndicado: usuarioIndicado,
      },
    });
  };

  goToMyFleet = function (temLink) {
    if (temLink) {
      this.onShowIndicacao();
    } else {
      this.onShowInterposicao();
    }
  };

  formataVencimento(data) {
    if (data) return "Vencimento: " + formatDateAbbreviatedShorter(data);
    return "";
  }

  componentDidUpdate(prevProps) {
    if (prevProps.interposicao != this.props.interposicao) {
      if (this.props.interposicao) {
        this.onHideInterposicao();
      }
    }
  }

  corValor = () => {
    if (this.props.situacao === 2) {
      return "multa-valor-vermelho";
    } else if (this.props.situacao === 6) {
      return "multa-valor";
    } else {
      return "multa-valor-amarelo";
    }
  };

  corSituacao = () => {
    if (this.props.situacao === 2) {
      return "multa-situacao-vermelho";
    } else if (this.props.situacao === 6) {
      return "multa-situacao";
    } else {
      return "multa-situacao-amarelo";
    }
  };

  situacaoMultas = () => {
    if (this.props.situacao === 6) {
      return "Prazo expirado";
    } else if (this.props.situacao === 2) {
      return "Pendente de indicação";
    } else {
      return "Aberta";
    }
  };

  render() {
    const infoMultaArray = this.props.multaInfo.split(" ", 5);
    const infoMulta = `${infoMultaArray[3]} ${infoMultaArray[4]} ${infoMultaArray[2]} ${infoMultaArray[0]} ${infoMultaArray[1]}`;
    return (
      <span>
        <section
          key={this.props.key}
          className="wrapper-card cursor-pointer"
          onClick={() => this.goToMyFleet(this.props.temLink)}
        >
          <div className={`card card-list-multas ${this.props.corcard}`}>
            <ContainerCard>
              <div className="multa-info">{infoMulta}</div>
              <div className="multa-vencimento">
                {this.formataVencimento(this.props.vencimento)}
              </div>
            </ContainerCard>
            <div className="multa-descricao">{this.props.descricao}</div>
            <ContainerCard>
              <div className={this.corValor()}>
                {Intl.NumberFormat("pt-br", {
                  style: "currency",
                  currency: "BRL",
                }).format(this.props.valorInfracao)}{" "}
              </div>
              <div className={this.corSituacao()}>
                {" "}
                {this.situacaoMultas()}{" "}
              </div>
            </ContainerCard>
          </div>
        </section>
        <ModalInterposicao
          modalOpened={this.state.modalOpenedInterposicao}
          ait={this.props.ait}
          orgao={this.props.orgao}
          onToggle={() => this.onHideInterposicao()}
        />
      </span>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    interposicao: state.multa.interposicao,
    url: state.multa.url,
    usuario: state.login.usuario,
  };
};

export default connect(mapStateToProps, {
  buscarMyFleetUrl,
})(withRouter(CardMultas));
