import React, { useEffect } from "react";
import ErrorBoundary from "../../components/ErrorBoundary";
import { Redirecionamento } from "../../components/Redirecionamento/Redirecionamento";
import Tracking from "../../hooks/Tracking";

export default function RedirecionamentoPage() {
  return (
    <React.Fragment>
      <ErrorBoundary>
        <Redirecionamento />
      </ErrorBoundary>
    </React.Fragment>
  );
}
