import axios from 'axios'

const instance = axios.create({
    baseURL: 'http://wapps-h.localiza.com/GestaoFrotas24h/AttendanceServices.asmx?op='
  })

export const HttpPost24h = async (urlApi, userJWT, param) => {
    if (userJWT != null){
        instance.defaults.headers.common['Authorization'] = 'Bearer ' + userJWT.accessToken
    }
    return await instance.post(urlApi, param)
}

export const HttpGet24h = async (urlApi, param = null, userJWT = null) => {
    if (userJWT != null){
        instance.defaults.headers.common['Authorization'] = 'Bearer ' + userJWT.accessToken
    }
    return await instance.get(urlApi, param)
}