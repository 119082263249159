import React, { Component } from "react";
import PainelFornecedores from "../../Agendamento/Novo/PainelFornecedores";
import { Link } from "react-router-dom";
import ErrorBoundary from "../../../components/ErrorBoundary";

export default class FornecedoresPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      model: null,
      fornecedores: null,
    };
  }

  componentWillMount() {
    if (this.props.location && this.props.location.state) {
      if (this.props.location.state.model) {
        this.setState({
          model: this.props.location.state.model,
          fornecedores: this.props.location.state.fornecedores,
        });
      }
    }
  }

  render() {
    return (
      <span>
        <header className="hearder-internal">
          <Link
            to={{
              pathname: "NovoAgendamento",
              state: {
                model: this.state.model,
                fornecedores: this.state.fornecedores,
              },
            }}
            className="icon icon-chevron-left"
          ></Link>
          <span className="title">Novo serviço</span>
          <span className="action"></span>
        </header>
        <main>
          <div className="content-primary">
            <ErrorBoundary texto={"no painel de fornecedores"}>
              <PainelFornecedores
                fornecedores={this.state.fornecedores}
                model={this.state.model}
              />
            </ErrorBoundary>
          </div>
        </main>
      </span>
    );
  }
}
