import { api } from "../services/AxiosService";

export const fetchRemoteConfig = async (paramName, defaultValue, type) => {
  const requestData = {
    ParamName: paramName,
    DefaultValue: defaultValue,
    Type: type,
  };

  return await api
    .post("remoteconfig", requestData)
    .then((result) => {
      if (result.data.resultInt != null) {
        return result.data.resultInt;
      } else if (result.data.resultString != null) {
        return result.data.resultString;
      } else if (result.data.resultBool != null) {
        return result.data.resultBool;
      } else {
        return requestData.DefaultValue;
      }
    })
    .catch((error) => {
      console.log(
        "Unable to fetch remote data, default values will be used",
        error
      );
      return requestData.DefaultValue;
    });
};
