import $ from 'jquery'

export default function ConfiguraFileInput(){
    let id = '';

    // Upload BO
    function newFileList(fileList) {
        return Array.from(fileList);
    };

    function htmlListFile(arrayFiles) {
        $(`#${id}-files`).find('.remove-file:not(.remove-file[data-remove="removido"])').closest('.wrapper-file .has-file').remove();
        $.map(arrayFiles, function (val) {
            return $(`#${id}-files`).append(`<div class = "wrapper-file has-file" > <div for="file-pdf" class="wrapper-input-file">
            <div class="label-input-file">
                <i class="icon icon-file-text"></i>
                <span class="file-name">${val.name}</span>
            </div>
            </div> <span class="icon icon icon-x remove-file" data-remove data-file='${val.name}' father='${id}'> </span> </div>`)
        });
    };

    function filesForSave(listFiles) {
        let arrRemovidos = [];    
        let  listRemovedFiles = $(`#${id}-files`).find('.remove-file[data-remove="removido"]');
        $(`#${id}-files`).find('.remove-file[data-remove="removido"]').closest('.wrapper-file .has-file').hide();

        for(var i = 0; i < listRemovedFiles.length ; i++) {    
            arrRemovidos.push(listRemovedFiles[i].dataset.file)
        }

        let removerDuplicados = arrRemovidos.filter((este, i) => {        
            return arrRemovidos.indexOf(este) === i
        })  
    
        var newList = listFiles.filter((file) => {
        return  !removerDuplicados.includes(file.name)
        }) 
    
        htmlListFile( newList );

        //save new list names
        var nomes = [];
        for(var i = 0; i < newList.length ; i++) {    
            nomes.push(newList[i].name)
        }
        $(`#${id}-nomes`).val(JSON.stringify(nomes));
    };


    $('.input-file-multiple').on('change', function() {   
        id = $(this).attr('id');
        let listFiles = newFileList($('#'+id)[0].files);
        $(`#${id}-files`).find('.remove-file[data-remove="removido"]').closest('.wrapper-file .has-file').remove();

        filesForSave(listFiles)
    });

    $('.input-file-multiple').on('click', function() {  
        this.value = null
    });
    
    //set function to remove file
    $('.files').on('click', '.remove-file', function(arquivos) {
        id = $(this).attr('father');
        $(this).attr('data-remove', 'removido'); 
        let listFiles = newFileList($('#'+id)[0].files);
        filesForSave(listFiles);        
    });
}