import {
    AVANCAR, ERRO, REA_CARREGADO, REA_CARREGANDO, VOLTAR, ALTERAR_TIPO_EVENTO,
    ALTERAR_CHAVE_PRINCIPAL, ALTERAR_DOCUMENTOS, RESETAR_ESTADO, ALTERAR_CPF_CLIENTE, SOLICITAR_EMAIL, RETIRA_SOLICITAR_EMAIL,
    ALTERAR_DATA_NASCIMENTO_CLI, ALTERAR_NOME_CONDUTOR, ALTERAR_EMAIL_CONDUTOR, ALTERAR_HABILITADO, ALTERAR_CNH, ALTERAR_CATEGORIA_CNH, ALTERAR_VALIDADE_CNH,
    ALTERAR_DATA_ACIDENTE, ALTERAR_HORA_ACIDENTE, ALTERAR_ENDERECO_ACIDENTE, ALTERAR_BAIRRO_ACIDENTE, ALTERAR_CIDADE_ACIDENTE, ALTERAR_DESCRICAO_ACIDENTE,
    ALTERAR_OCORRENCIA, ALTERAR_NUMERO_OCORRENCIA, ALTERAR_NOME_DECLARANTE, ALTERAR_TERCEIRO_ENVOLVIDO, ALTERAR_OUTRO_ENVOLVIDO, ALTERAR_ENDERECO_TERCEIRO, ALTERAR_BAIRRO_TERCEIRO, ALTERAR_CIDADE_TERCEIRO,
    ALTERAR_CAUSADOR_ACIDENTE, ALTERAR_LAUDO_PERICIA, ALTERAR_NUMERO_LAUDO, ALTERAR_PLACA, ALTERAR_MODELO, ALTERAR_ANO, ALTERAR_PROPRIETARIO, ALTERAR_CPF_CNPJ,
    ALTERAR_CEP, ALTERAR_ENDERECO_PROPRIETARIO, ALTERAR_NUMERO_ENDERECO_PROPRIETARIO, ALTERAR_BAIRRO_PROPRIETARIO, ALTERAR_CIDADE_PROPRIETARIO, ALTERAR_TELEFONE_PROPRIETARIO,
    ALTERAR_CONDUTOR_PROPRIETARIO, ALTERAR_CONDUTOR, ALTERAR_CPF_CONDUTOR, ALTERAR_TELEFONE_CONDUTOR, ALTERAR_EMAIL_CONDUTOR_TERCEIRO, ALTERAR_CNH_CONDUTOR, ALTERAR_CATEGORIA_CNH_CONDUTOR, ALTERAR_VALIDADE_CNH_CONDUTOR, ALTERAR_SEGURO, ALTERAR_SEGURADORA, ALTERAR_NUMERO_SINISTRO,
    IR_PARA_ETAPA, ATUALIZAR_ESTADO_COOKIE, BUSCAR_DADOS_PLACA, BUSCAR_DADOS_CEP, BUSCAR_DADOS_CPF, BUSCAR_DADOS_CPF_DATA_INVALIDA, REA_FINALIZADO, ALTERAR_EMAIL_PROPRIETARIO, ALTERAR_CLIENTE_ISENCAO_PREFIXACAO, 
    ALTERAR_TIPO_EMAIL, ALTERAR_CHAVE_RESERVA, ALTERAR_SS, ALTERAR_SS_SEQ
} from './ReaTypes'

import update from 'react-addons-update';

const INITIAL_STATE = {
    etapa: 1,
    carregando: false,
    erro: null,
    sucesso: null,
    solicitaEmail: false,
    bloqueadoDadosCondutor: true,
    dataNascimentoInvalida: false,
    modelo: {
        tipoEvento: "",
        chaveReserva: "",
        chavePrincipal: "",
        documentos: "",
        cpfCliente: "",
        dataNascimentoCliente: "",
        nomeCondutor: "",
        emailCondutor: "",
        habilitado: "",
        cnh: "",
        categoriaCnh: "",
        validadeCnh: "",
        dataAcidente: "",
        horaAcidente: "",
        enderecoAcidente: "",
        bairroAcidente: "",
        cidadeAcidente: null,
        descricaoAcidente: "",
        ocorrencia: "",
        numeroOcorrencia: "",
        nomeDeclarante: "",
        terceiroEnvolvido: "",
        outroEnvolvido: "",
        enderecoTerceiro: "",
        bairroTerceiro: "",
        cidadeTerceiro: null,
        causadorAcidente: "",
        laudoPericia: "",
        numeroLaudo: "",
        placa: "",
        modelo: "",
        ano: "",
        proprietario: "",
        cpf_cnpj: "",
        cep: "",
        enderecoProprietario: "",
        numeroEnderecoProprietario: "",
        bairroProprietario: "",
        cidadeProprietario: null,
        telefoneProprietario: "",
        condutorProprietario: "",
        emailProprietario: "",
        condutor: "",
        cpfCondutor: "",
        telefoneCondutor: "",
        emailCondutorTerceiro: "",
        cnhCondutor: "",
        categoriaCnhCondutor: "",
        validadeCnhCondutor: "",
        seguro: "",
        seguradora: "",
        numeroSinistro: "",
        placaLocaliza: "",
        clienteLocaliza: "",
        modeloLocaliza: "",
        clienteIsencaoPrefixacao: false,
        tipoEmail: "",
        aceiteDeclaracao: "",
        ss: "",
        ss_seq: ""
    }
}

const _rea = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case ERRO:
            return { ...state, erro: action.payload };
        case REA_FINALIZADO:
            return { ...state, sucesso: action.payload };
        case AVANCAR:
            return { ...state, etapa: state.etapa + 1 };
        case IR_PARA_ETAPA:
            return { ...state, etapa: action.payload };
        case VOLTAR:
            return { ...state, etapa: state.etapa - 1 };
        case REA_CARREGANDO:
            return { ...state, carregando: true };
        case REA_CARREGADO:
            return { ...state, carregando: false };
        case ALTERAR_TIPO_EVENTO:
            return update(state, { modelo: { tipoEvento: { $set: action.payload } } });
        case ALTERAR_CPF_CLIENTE:
            return update(state, { modelo: { cpfCliente: { $set: action.payload } } });
        case ALTERAR_CHAVE_PRINCIPAL:
            return update(state, { modelo: { chavePrincipal: { $set: action.payload } } });
        case ALTERAR_CHAVE_RESERVA:
            return update(state, { modelo: { chaveReserva: { $set: action.payload } } });    
        case ALTERAR_DOCUMENTOS:
            return update(state, { modelo: { documentos: { $set: action.payload } } });
        case ALTERAR_DATA_NASCIMENTO_CLI:
            return update(state, { modelo: { dataNascimentoCliente: { $set: action.payload } } });
        case ALTERAR_NOME_CONDUTOR:
            return update(state, { modelo: { nomeCondutor: { $set: action.payload } } });
        case ALTERAR_EMAIL_CONDUTOR:
            return update(state, { modelo: { emailCondutor: { $set: action.payload } } });
        case ALTERAR_HABILITADO:
            return update(state, { modelo: { habilitado: { $set: action.payload } } });
        case ALTERAR_CNH:
            return update(state, { modelo: { cnh: { $set: action.payload } } });
        case ALTERAR_CATEGORIA_CNH:
            return update(state, { modelo: { categoriaCnh: { $set: action.payload } } });
        case ALTERAR_VALIDADE_CNH:
            return update(state, { modelo: { validadeCnh: { $set: action.payload } } });
        case ALTERAR_DATA_ACIDENTE:
            return update(state, { modelo: { dataAcidente: { $set: action.payload } } });
        case ALTERAR_HORA_ACIDENTE:
            return update(state, { modelo: { horaAcidente: { $set: action.payload } } });
        case ALTERAR_ENDERECO_ACIDENTE:
            return update(state, { modelo: { enderecoAcidente: { $set: action.payload } } });
        case ALTERAR_BAIRRO_ACIDENTE:
            return update(state, { modelo: { bairroAcidente: { $set: action.payload } } });
        case ALTERAR_CIDADE_ACIDENTE:
            return update(state, { modelo: { cidadeAcidente: { $set: action.payload } } });
        case ALTERAR_DESCRICAO_ACIDENTE:
            return update(state, { modelo: { descricaoAcidente: { $set: action.payload } } });
        case ALTERAR_OCORRENCIA:
            return update(state, { modelo: { ocorrencia: { $set: action.payload } } });
        case ALTERAR_NUMERO_OCORRENCIA:
            return update(state, { modelo: { numeroOcorrencia: { $set: action.payload } } });
        case ALTERAR_NOME_DECLARANTE:
            return update(state, { modelo: { nomeDeclarante: { $set: action.payload } } });
        case ALTERAR_TERCEIRO_ENVOLVIDO:
            return update(state, { modelo: { terceiroEnvolvido: { $set: action.payload } } });
        case ALTERAR_OUTRO_ENVOLVIDO:
            return update(state, { modelo: { outroEnvolvido: { $set: action.payload } } });
        case ALTERAR_ENDERECO_TERCEIRO:
            return update(state, { modelo: { enderecoTerceiro: { $set: action.payload } } });
        case ALTERAR_BAIRRO_TERCEIRO:
            return update(state, { modelo: { bairroTerceiro: { $set: action.payload } } });
        case ALTERAR_CIDADE_TERCEIRO:
            return update(state, { modelo: { cidadeTerceiro: { $set: action.payload } } });
        case ALTERAR_CAUSADOR_ACIDENTE:
            return update(state, { modelo: { causadorAcidente: { $set: action.payload } } });
        case ALTERAR_LAUDO_PERICIA:
            return update(state, { modelo: { laudoPericia: { $set: action.payload } } });
        case ALTERAR_NUMERO_LAUDO:
            return update(state, { modelo: { numeroLaudo: { $set: action.payload } } });
        case ALTERAR_PLACA:
            return update(state, { modelo: { placa: { $set: action.payload } } });
        case ALTERAR_MODELO:
            return update(state, { modelo: { modelo: { $set: action.payload } } });
        case ALTERAR_ANO:
            return update(state, { modelo: { ano: { $set: action.payload } } });
        case ALTERAR_PROPRIETARIO:
            return update(state, { modelo: { proprietario: { $set: action.payload } } });
        case ALTERAR_CPF_CNPJ:
            return update(state, { modelo: { cpf_cnpj: { $set: action.payload } } });
        case ALTERAR_CEP:
            return update(state, { modelo: { cep: { $set: action.payload } } });
        case ALTERAR_ENDERECO_PROPRIETARIO:
            return update(state, { modelo: { enderecoProprietario: { $set: action.payload } } });
        case ALTERAR_NUMERO_ENDERECO_PROPRIETARIO:
            return update(state, { modelo: { numeroEnderecoProprietario: { $set: action.payload } } });
        case ALTERAR_BAIRRO_PROPRIETARIO:
            return update(state, { modelo: { bairroProprietario: { $set: action.payload } } });
        case ALTERAR_CIDADE_PROPRIETARIO:
            return update(state, { modelo: { cidadeProprietario: { $set: action.payload } } });
        case ALTERAR_TELEFONE_PROPRIETARIO:
            return update(state, { modelo: { telefoneProprietario: { $set: action.payload } } });
        case ALTERAR_CONDUTOR_PROPRIETARIO:
            return update(state, { modelo: { condutorProprietario: { $set: action.payload } } });
        case ALTERAR_EMAIL_PROPRIETARIO:
            return update(state, { modelo: { emailProprietario: { $set: action.payload } } });
        case ALTERAR_CONDUTOR:
            return update(state, { modelo: { condutor: { $set: action.payload } } });
        case ALTERAR_CPF_CONDUTOR:
            return update(state, { modelo: { cpfCondutor: { $set: action.payload } } });
        case ALTERAR_TELEFONE_CONDUTOR:
            return update(state, { modelo: { telefoneCondutor: { $set: action.payload } } });
        case ALTERAR_EMAIL_CONDUTOR_TERCEIRO:
            return update(state, { modelo: { emailCondutorTerceiro: { $set: action.payload } } });
        case ALTERAR_CNH_CONDUTOR:
            return update(state, { modelo: { cnhCondutor: { $set: action.payload } } });
        case ALTERAR_CATEGORIA_CNH_CONDUTOR:
            return update(state, { modelo: { categoriaCnhCondutor: { $set: action.payload } } });
        case ALTERAR_VALIDADE_CNH_CONDUTOR:
            return update(state, { modelo: { validadeCnhCondutor: { $set: action.payload } } });
        case ALTERAR_SEGURO:
            return update(state, { modelo: { seguro: { $set: action.payload } } });
        case ALTERAR_SEGURADORA:
            return update(state, { modelo: { seguradora: { $set: action.payload } } });
        case ALTERAR_NUMERO_SINISTRO:
            return update(state, { modelo: { numeroSinistro: { $set: action.payload } } });
        case ALTERAR_SS:
            return update(state, { modelo: { ss: { $set: action.payload } } });    
        case ALTERAR_SS_SEQ:
            return update(state, { modelo: { ss_seq: { $set: action.payload } } });    
        case ATUALIZAR_ESTADO_COOKIE:
            return update(state, { modelo: { $set: action.payload.modelo }, etapa: { $set: action.payload.etapa }, bloqueadoDadosCondutor: { $set: action.payload.bloqueadoDadosCondutor } });
        case BUSCAR_DADOS_PLACA:
            return update(state, { modelo: { placaLocaliza: { $set: action.payload.Placa }, clienteLocaliza: { $set: action.payload.NomeEmpresa }, modeloLocaliza: { $set: action.payload.Modelo },
                clienteIsencaoPrefixacao : { $set : action.payload.IsencaoPrefixacao } } });
        case BUSCAR_DADOS_CEP:
            return update(state, {
                modelo: {
                    enderecoProprietario: { $set: action.payload.TipoLogradouro + " " + action.payload.Logradouro },
                    bairroProprietario: { $set: action.payload.Bairro }
                }
            });
        case BUSCAR_DADOS_CPF: {
            let _estado = { ...state };
            let _habilitado = (action.payload.cnh !== "" && action.payload.cnh !== null
            ) || (action.payload.categoriaCnh !== "" && action.payload.categoriaCnh !== null)
                || (action.payload.validadeCnh !== "" && action.payload.validadeCnh !== null)
                ? "sim" : "";
            _estado.modelo.nomeCondutor = action.payload.nomeCondutor !== null ? action.payload.nomeCondutor : "";
            _estado.modelo.habilitado = _habilitado;
            _estado.modelo.cnh = action.payload.cnh !== null ? action.payload.cnh : "";
            _estado.modelo.categoriaCnh = action.payload.categoriaCnh !== null ? action.payload.categoriaCnh : "";
            _estado.modelo.validadeCnh = action.payload.validadeCnh;
            _estado.bloqueadoDadosCondutor = false;
            _estado.dataNascimentoInvalida = false;
            return _estado;
        }
        case BUSCAR_DADOS_CPF_DATA_INVALIDA: {
            let _estado = { ...state };
            _estado.modelo.nomeCondutor = "";
            _estado.modelo.habilitado = "";
            _estado.modelo.cnh = "";
            _estado.modelo.categoriaCnh = "";
            _estado.modelo.validadeCnh = null;
            _estado.bloqueadoDadosCondutor = true;
            _estado.dataNascimentoInvalida = true;
            return _estado;
        }
        case RESETAR_ESTADO:
            return INITIAL_STATE;
        case SOLICITAR_EMAIL:
            return { ...state, solicitaEmail: true };
        case RETIRA_SOLICITAR_EMAIL:
            return { ...state, solicitaEmail: false };
        case ALTERAR_CLIENTE_ISENCAO_PREFIXACAO:
            return update(state, { modelo: { clienteIsencaoPrefixacao: { $set: action.payload } } });
        case ALTERAR_TIPO_EMAIL:
            return update(state, { modelo: { tipoEmail: { $set: action.payload } } });
        default:
            return state;
    }
}

export default _rea;

