import React, { Component } from "react";
import { connect } from "react-redux";
import { sliderToggle } from "./SliderActions";
import BottomNavigation from "../BottomNavigation";
import Loading from "../Loading";

class Slider extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
    };
  }

  componentWillMount() {
    this.setState({
      loading: true,
    });
    setTimeout(() => {
      this.setState({
        loading: false,
      });
    }, 1500);
  }

  onSliderClick = () => {
    this.props.sliderToggle(this.props.sliderActive);
  };

  render() {
    return this.state.loading === true ? (
      <Loading />
    ) : (
      <span>
        <div
          className={`overlay ${this.props.sliderActive ? "open" : "close"}`}
        ></div>
        <div className={`slider ${this.props.sliderActive ? "open" : "close"}`}>
          <div className="slider-header">
            <span>
              <span
                className={`icon icon-desktop ${this.props.iconDesktop}`}
                onClick={this.onSliderClick}
              ></span>
              <span
                className={`icon icon-mobile ${this.props.iconMobile}`}
                onClick={this.onSliderClick}
              ></span>
            </span>
            <span className="title">{this.props.title}</span>
            <span className="action" action={this.props.action}>
              <span className={`icon ${this.props.iconAction}`}></span>
            </span>
          </div>
          <div className="slider-body">{this.props.children}</div>
          <div onClick={this.onSliderClick}>
            <BottomNavigation />
          </div>
        </div>
      </span>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    sliderActive: state.slider.sliderActive,
  };
};

export default connect(mapStateToProps, {
  sliderToggle,
})(Slider);
