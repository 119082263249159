import React, { Component } from 'react';

export default class ItensDevolvidos extends Component {

    constructor() {
        super();
        this.handleVoltar = this.handleVoltar.bind(this);
        this.handleAvancar = this.handleAvancar.bind(this);
        this.handleAlteraDocumento = this.handleAlteraDocumento.bind(this);
        this.handleAlteraChavePrincipal = this.handleAlteraChavePrincipal.bind(this);
        this.handleAlteraChaveReserva = this.handleAlteraChaveReserva.bind(this);
    }


    componentDidMount() {
        window.scrollTo(0, 0);
    }

    handleAvancar() {
        this.props.onIrParaEtapa(3);
    }

    handleVoltar() {
        this.handleLimpaCampos();
        this.props.onIrParaEtapa(1);
    }

    handleAlteraDocumento = (event) => {
        if (event.target.value !== null && event.target.value !== "") {
            this.props.onChangeAlteraDocumento(event.target.value);
        }
    }

    handleAlteraChavePrincipal = (event) => {
        if (event.target.value !== null && event.target.value !== "") {
            this.props.onChangeChavePrincipal(event.target.value);
        }
    }

    handleAlteraChaveReserva = (event) => {
        if (event.target.value !== null && event.target.value !== "") {
            this.props.onChangeChaveReserva(event.target.value);
        }
    }

    handleLimpaCampos = () => {
        this.props.onChangeAlteraDocumento("");
        this.props.onChangeChave("");
    }

    render() {
        return (
            <React.Fragment>
                <div className="row w-xl-66">
                    <div className="form-intro col-12">
                        <p className="text-justify">Os seguintes itens foram devolvidos para a Localiza Gestão de Frotas?</p>
                    </div>

                    <div className="col-12">
                        <h2 className="form-title">Chave principal</h2>
                        <div className="custom-radio">
                            <input type="radio" id="chave_s" name="itens_devolvidos" className="custom-control-input" value="sim"
                                onChange={this.handleAlteraChavePrincipal} checked={this.props.modelo.chavePrincipal=== "sim"} />
                            <label className="custom-control-label" htmlFor="chave_s">Sim</label>
                        </div>
                        <div className="custom-radio">
                            <input type="radio" id="chave_n" name="itens_devolvidos" className="custom-control-input" value="não"
                                onChange={this.handleAlteraChavePrincipal} checked={this.props.modelo.chavePrincipal === "não"} />
                            <label className="custom-control-label" htmlFor="chave_n">Não</label>
                        </div>
                    </div>

                    <div className="col-12">
                        <h2 className="form-title">Chave reserva</h2>
                        <div className="custom-radio">
                            <input type="radio" id="chave_reserva_s" name="chave_reserva_devolvida" className="custom-control-input" value="sim"
                                onChange={this.handleAlteraChaveReserva} checked={this.props.modelo.chaveReserva === "sim"} />
                            <label className="custom-control-label" htmlFor="chave_reserva_s">Sim</label>
                        </div>
                        <div className="custom-radio">
                            <input type="radio" id="chave_reserva_n" name="chave_reserva_devolvida" className="custom-control-input" value="não"
                                onChange={this.handleAlteraChaveReserva} checked={this.props.modelo.chaveReserva === "não"} />
                            <label className="custom-control-label" htmlFor="chave_reserva_n">Não</label>
                        </div>
                    </div>

                    <div className="col-12">
                        <h2 className="form-title">Documentos</h2>
                        <div className="custom-radio">
                            <input type="radio" id="doc_s" name="doc_devolvidos" className="custom-control-input" value="sim"
                                onChange={this.handleAlteraDocumento} checked={this.props.modelo.documentos === "sim"} />
                            <label className="custom-control-label" htmlFor="doc_s">Sim</label>
                        </div>
                        <div className="custom-radio">
                            <input type="radio" id="doc_n" name="doc_devolvidos" className="custom-control-input" value="não"
                                onChange={this.handleAlteraDocumento} checked={this.props.modelo.documentos === "não"} />
                            <label className="custom-control-label" htmlFor="doc_n">Não</label>
                        </div>
                    </div>

                    <div className="w-100"></div>
                    <div className="ml-auto mt-3 col-md-6">
                        <div className="row">
                            <div className="col">
                                <button className="button" onClick={this.handleVoltar}>Voltar</button>
                            </div>
                            <div className="col">
                                <button className="button" onClick={this.handleAvancar} disabled={this.props.modelo.documentos === ""
                                    || this.props.modelo.chavePrincipaleReserva === ""}>Continuar</button>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}