import { SLIDER_ACTIVE } from './SliderTypes'

const INITIAL_STATE = {
    sliderActive: null
}

export default (state = INITIAL_STATE, action) => {

    switch (action.type) {
        case SLIDER_ACTIVE:
            return { ...state, sliderActive: action.payload };
        default:
            return state;
    }
}


