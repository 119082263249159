import { LISTA_CIDADES, BUSCAR_CODIGO, LIMPAR_CODIGO, SELECIONAR_CIDADE_NOME_UF, INSERIR_CIDADE_SELECIONADA } from './InputCidadesTypes';
import { DESLOGAR } from '../../pages/Login/LoginTypes';
import { ERRO_AGENDAMENTO, LIMPAR_FORNECEDORES } from '../../pages/Agendamento/Novo/AgendamentoTypes';
import { HttpGet, HttpPost } from '../../functions/default';

export const listaCidades = (param) => {
  return (dispatch) => {
    if(navigator.onLine){
      HttpGet(`/ListCities?filtro=${param}`, param)
      .then(function (result) {
         if (result.data)                    
              dispatch({ type: LISTA_CIDADES, payload: result.data });
     })
     .catch(function (erro) {
         if(erro.response && erro.response.status === 401) 
             dispatch({ type: DESLOGAR,  payload: {message: 'Usuário não autenticado.'} });
         else
             dispatch({ type: ERRO_AGENDAMENTO, payload: {message: 'Ocorreu um erro ao buscar os dados, tente novamente mais tarde.'} });
     });
    }
  }
}

export const selecionarCidadePorNomeEUf = (_nomeCidade, _uf) => {
  return (dispatch) => {   
    dispatch({type: SELECIONAR_CIDADE_NOME_UF, payload: {nomeCidade:_nomeCidade, uf:_uf}});
  }
}

export const inputarCidadeSelecionada = (cidade) => {
  return (dispatch) => {   
    dispatch({type: INSERIR_CIDADE_SELECIONADA, payload: cidade});
  }
}


export const buscarCodigo = (param, todas) => {
  return (dispatch) => {    
    let dados = param.split(" - ")
    let cidade = todas.filter(x => x.mNomeCidadeField == dados[0])
    cidade = cidade.filter(x => x.mUfField == dados[1])
    dispatch({ type: BUSCAR_CODIGO, payload: cidade });
    dispatch({ type: LIMPAR_FORNECEDORES });
  }
}

export const limparCidadeSelecionada =() => {
  return (dispatch) => { 
     dispatch({ type: LIMPAR_CODIGO }); 
  }
}