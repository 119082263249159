import React, {  } from 'react';
import '../../src/styles/css/tela-agendamento.css';
import Autosuggest from 'react-autosuggest';
import { buscarCodigo } from './InputCidades/InputCidadesAction'
import { connect } from 'react-redux';

let nameToWork = ''
let complement = ''
const getSuggestions = (value, data, name, complemente) => {
    const inputValue = value.trim().toLowerCase();
    const inputLength = inputValue.length;
    nameToWork = name;
    complement = complemente;
    return inputLength === 0 ? [] : data.filter(lang =>
      lang[name].toLowerCase().slice(0, inputLength) === inputValue
    );
  };
  
  function formatInputAutoSuggest(){
    const  divAutoSuggest = document.querySelectorAll('.react-autosuggest__container');

    for (let i = 0; i < divAutoSuggest.length; i++) {
      const input = divAutoSuggest[i].children[0];
      input.setAttribute('required', '');
  
       /* Manage label */
       const label = document.createElement('LABEL');
       const textNode = document.createTextNode('Cidade');
       label.classList.add('label');
       label.appendChild(textNode);
       divAutoSuggest[i].appendChild(label);
    }
  }
 

  const getSuggestionValue = suggestion => (complement !== '') ? suggestion[nameToWork] + ' - ' + suggestion[complement] : suggestion[nameToWork];
  
  const renderSuggestion = suggestion => (
    <div>
      {(complement !== '') ? suggestion[nameToWork] + ' - ' + suggestion[complement] : suggestion[nameToWork]}    
    </div>      
  );
  
  class InputAutoComplete extends React.Component {
    constructor(props) {
      super(props);
      this.textInput = React.createRef();
      this.state = {
        value: this.props.valorInicial != null ? 
         this.props.valorInicial 
         : "",
        suggestions: [],
        data: [],
      };        
    }  

    componentDidMount(){
      formatInputAutoSuggest();
    }

    onChange = (event, { newValue }) => {
      this.setState({
        value: newValue
      });      
    };

    onSuggestionsFetchRequested = ({ value }) => {
      this.setState({
        suggestions: getSuggestions(value, this.props.data, this.props.name, this.props.complement)
      });      
    };

    onBlur = (event) => {
      this.props.buscarCodigo(this.state.value, this.props.data);
    }
  
    onSuggestionsClearRequested = () => {
      this.setState({
        suggestions: []
      });
    };

    componentDidUpdate(){
    }

    getSnapshotBeforeUpdate(prevProps){
       if(this.props.valorInicial !== prevProps.valorInicial && 
        (this.props.valorInicial !== null && this.props.valorInicial !== "") 
         ){
         this.setState({
           value: this.props.valorInicial
         });      
       }
     return null;      
    }
 
    render() {
      const { value, suggestions } = this.state;  
      const inputProps = {        
        value,
        onChange: this.onChange,
        onBlur: this.onBlur
      };
  
     
      return (
        <div className={`wrapper-input ${this.props.erro ? 'has-error' : null} `}>
          <div className="group-input">
            <Autosuggest      
                data={this.props.data}
                suggestions={suggestions}
                onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
                onSuggestionsClearRequested={this.onSuggestionsClearRequested}
                getSuggestionValue={getSuggestionValue}
                renderSuggestion={renderSuggestion}
                inputProps={inputProps}
                name={this.props.name}
                placeholder={this.props.placeholder}
                complement={this.props.complement}
                ref={this.textInput} 
              requeired />            
          </div>   
        </div> 
        
      );
    }
  }

const mapStateToProps = state => {
    return {
        selecao: state.listaCidades.buscarCodigo
    }
};

export default connect(mapStateToProps, {
  buscarCodigo
})(InputAutoComplete)
