import React, { useEffect } from "react";
import LayoutContainer from "../../components/novo-login/Layout/LayoutContainer";
import ErrorBoundary from "../../components/ErrorBoundary";
import { LoginReenviarEmail } from "../../components/novo-login/LoginRenviarEmail/LoginRenviarEmail";
import Tracking from "../../hooks/Tracking";

export default function ReenvioEmailPage() {
  useEffect(() => {
    Tracking.sendPageview("Login Reenvio Email");
  }, []);
  return (
    <React.Fragment>
      <LayoutContainer>
        <ErrorBoundary>
          <LoginReenviarEmail />
        </ErrorBoundary>
      </LayoutContainer>
    </React.Fragment>
  );
}
