import { HttpPost, HttpGet, HttpPostNew  } from '../functions/default';
import qs from 'qs';

export const IndicarCondutorAssinaturaDigitalizada = (dadosIndicacao) => {
    return HttpPostNew(`/IndicarCondutorAssinatura`, dadosIndicacao);
} 

export const IndicarCondutorTermoDeResponsabilidade = (dadosIndicacao) => {
    return HttpPostNew(`/IndicarCondutorTermo`, dadosIndicacao);
}

export const GerarTermoDeResponsabilidade = (dadosIndicacao) => {
    let param = qs.stringify(dadosIndicacao);
    return HttpGet(`/GerarTermoDeResponsabilidade?${param}`, null, null);
}