import { LISTA_SERVICOSCONTRATADOS, ERRO, SERVICOSCONTRATADOS_LOADING, SERVICOSCONTRATADOS_LOADED } from './ListaServicosContratadosTypes'

const INITIAL_STATE = {
    servicosContratados: [],
    erro: null,
    loading: false
}

const listaServicosContratados = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case LISTA_SERVICOSCONTRATADOS:
            return { ...state, servicosContratados: action.payload };
        case ERRO:
            return { ...state, erro: action.payload };
        case SERVICOSCONTRATADOS_LOADING:
            return { ...state, loading: true };
        case SERVICOSCONTRATADOS_LOADED :
            return { ...state, loading: false };
        default:
            return state;
    }
}

export default listaServicosContratados;