import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin: 30px 0 22px 0;

  @media (max-width: 1025px) {
    margin: 14px 0 20px 0;
    padding: 0 6px 0 6px;
  }
`;

export const Historico = styled.div`
  width: 46.5%;
  height: 26px;
  border-radius: 24px;
  border: 1px solid #d8d8d8;
  cursor: pointer;
  @media (max-width: 1025px) {
    width: 48%;
    max-width: 170px;
    height: 25px;
  }
`;

export const Abertas = styled.div`
  width: 46.5%;
  height: 26px;
  border-radius: 24px;
  border: 1px solid #d8d8d8;
  cursor: pointer;
  @media (max-width: 1025px) {
    width: 48%;
    max-width: 170px;
    height: 26px;
  }
`;

export const Texto = styled.h1`
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  text-align: center;
  text-transform: uppercase;
  position: relative;
  bottom: 1px;
  @media (max-width: 1025px) {
  }
`;

export const ImgContainer = styled.div`
  padding: 80px 0 0 0;
  text-align: center;
`;

export const Titulo = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
  color: #3a3d42;
  margin: 15px 0 5px 0;
`;
