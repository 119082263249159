import { 
    LISTA_SERVICOSCONTRATADOS,
    ERRO,
    SERVICOSCONTRATADOS_LOADED,
    SERVICOSCONTRATADOS_LOADING
} from './ListaServicosContratadosTypes'
import { HttpGet } from '../../../functions/default';
import { DESLOGAR } from '../../Login/LoginTypes'

const listaServicosContratados = (param) => {
    return (dispatch) => {
        if(navigator.onLine){
            dispatch({type: SERVICOSCONTRATADOS_LOADING}) 
            HttpGet(`/BuscarServicosContratados?placa=${param}`, param, null).then(function (result) {
             dispatch({ type: LISTA_SERVICOSCONTRATADOS, payload: result.data });
             dispatch({type: SERVICOSCONTRATADOS_LOADED}) 
         })
          .catch(function (erro) {
              if(erro.response && erro.response.status === 401) 
                  dispatch({ type: DESLOGAR,  payload: {message: 'Usuário não autenticado.'} });
              else {
                  dispatch({ type: ERRO, payload: {message: 'Ocorreu um erro ao buscar os serviços contratados, tente novamente mais tarde.'} }); 
              }
              dispatch({type: SERVICOSCONTRATADOS_LOADED}) 
          });
        }
    }
}

export default listaServicosContratados;