import React, { Component } from "react";
import LayoutInternal from "../../../components/LayoutInternal";
import Agendamentos from "../../../components/Agendamentos/ListaAgendamentos";
import Tracking from "../../../hooks/Tracking";
import ErrorBoundary from "../../../components/ErrorBoundary"

export default class ListaAgendamentosPage extends Component {
  componentDidMount() {
    Tracking.sendPageview("Lista Agendamentos");
  }

  render() {
    return (
      <LayoutInternal>
        <h1 style={{fontSize: "16px", fontWeight: 'bold', color: '#3A3D42', padding: "0 0 0 16px"}}>Serviços</h1>
        <main className="agendamentos">
          <ErrorBoundary texto={"na listagem de agendamentos"}>
            <Agendamentos mobile={true} listar={true} />  
          </ErrorBoundary>
        </main>
      </LayoutInternal>
    );
  }
}
