import { getUA, isMobile, isMobileOnly } from "react-device-detect";

const APP_UA = "wv nova-app-meucarro";

export const isMobilePlatform = () => {
  return isMobile || isMobileOnly || getUA === APP_UA;
};

export const checkIsWebView = navigator.userAgent.includes(
  APP_UA
);

export const isNewAppOnly = () => {
  return getUA === APP_UA;
};