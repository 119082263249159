import React, { useState, useEffect } from 'react';
import MaskedInput from 'react-text-mask';
import LayoutInternal from '../../components/LayoutInternal';
import { Typography, Container, makeStyles, Grid } from '@material-ui/core';
import PhotoSizeSelectActualOutlined from '@material-ui/icons/PhotoSizeSelectActualOutlined';
import { somenteNumerosFromString, validarCpf } from '../../functions/manutencaoGeralValidacoes';
import { ObterAssinaturaCondutor, ExcluirAssinaturaCondutor } from '../../services/UsuarioService';
import ExibirImagem from '../../components/IndicarCondutor/ExibirImagem';
import ModalMensagem from '../../components/ModalMensagem/ModalMensagem';
import { NomeCookieUrlForcada } from '../../config/consts';
import * as Cookie from '../../functions/Cookies';
import Loading from '../../components/Loading';

const useStyles = makeStyles({
    title: {
        fontFamily: "Metropolis-semiBold",
        fontSize: "12px",
    },
    subtitle: {
        fontFamily: "Metropolis",
        fontSize: "12px",
        color: '#7B808C',
        marginBottom: 20
    },
    grid:{
        marginTop: 20
    }
});

function AssinaturaPage() {
    const classes = useStyles();
    const [imagemAssinatura, setImagemAssinatura] = useState({
        base64: null,
        identificadorArquivo: null
    });
    const [exibirModal, setExibirModal] = useState(false);
    const [exibirCarregando, setExibirCarregando] = useState(false);
    const [imagemEncontrada, setImagemEncontrada] = useState(null);
    const [mensagemFalhaBusca, setMensagemFalhaBusca] = useState(null);
    const [codigoUsuario, setcodigoUsuario] = useState(0);
    const [cpfClienteValido, setCpfClienteValido] = useState({
        valido: false,
        valor: ''
    });

    useEffect(() => {
        Cookie.get(NomeCookieUrlForcada, false) && setcodigoUsuario(JSON.parse(Cookie.get(NomeCookieUrlForcada, false)).codigoUsuario);
    }, []);

    const handleAlteraCpfCliente = (event) => {
        let value = event.target.value;
        if (value !== null) {
            let cpfSomenteNumeros = somenteNumerosFromString(value);
            let validacaoCpf = validarCpf(value);

            if (cpfSomenteNumeros.length >= 11) {
                if (validacaoCpf) {
                    setCpfClienteValido({
                        valido: true,
                        valor: cpfSomenteNumeros
                    });
                } else {
                    setCpfClienteValido({
                        valido: false,
                        valor: cpfSomenteNumeros
                    });
                }
            }
            else {
                setCpfClienteValido({
                    valido: false,
                    valor: cpfSomenteNumeros
                });
            }
        }
    }

    const handleBuscarAssinatura = () => {
        ObterAssinaturaCondutor(cpfClienteValido.valor, codigoUsuario)
            .then((result) => {
                if (result.data && result.data !== null && result.data !== "") {
                    if(result.data.cpfValido === true){
                        setImagemAssinatura({
                            base64: result.data.Base64ArquivoAssinatura,
                            identificadorArquivo: result.data.IdArquivo
                        });
                        setImagemEncontrada(true);
                    } else{
                        setImagemAssinatura({
                            base64: null,
                            identificadorArquivo: null
                        })
                        setMensagemFalhaBusca("Pesquise apenas com o seu próprio cpf!");
                        setImagemEncontrada(false);
                    }
                } else {
                    setImagemAssinatura({
                        base64: null,
                        identificadorArquivo: null
                    })
                    setMensagemFalhaBusca("Não encontramos sua assinatura em nosso sistema!");
                    setImagemEncontrada(false);
                }
                setExibirCarregando(false);   
            })
            .catch((error) => {
                setExibirCarregando(false);   
            });  
    }

    const handleExcluirAssinatura = (resposta) => {
        setExibirModal(false);
        if(resposta){
            ExcluirAssinaturaCondutor(imagemAssinatura.identificadorArquivo)
                .then(() => {
                    setImagemAssinatura({
                        base64: null,
                        identificadorArquivo: null
                    })
                })
        }    
    }

    return (
        <LayoutInternal title="Multa">
            {
                exibirModal &&
                <div>
                    <ModalMensagem tipoModalMensagem=''
                        icon='icon-alert-circle2'
                        mensagemPrincipal='Está certo disso?'
                        OnToggled={handleExcluirAssinatura}
                        mensagemSecondaria='Ao excluir a sua assinatura você precisará enviar outro arquivo quando precisar indicar multas, tem certeza que deseja continuar?'
                        textoBotao="Excluir"/>
                </div>
            }
            <main>
                <Container maxWidth="lg">

                { exibirCarregando && <Loading /> }

                    <h1>Assinatura</h1>
                    <Typography className={classes.subtitle}>
                        A imagem da sua assinatura será utilizada unicamente no termo de responsabilidade
                        para indicação de condutorinfrator junto ao orgão de trânsito, quando autorizado por você
                    </Typography>
                        <Grid container item xs={12} sm={6} className={`wrapper-input ${(cpfClienteValido.valor && cpfClienteValido.valido === false) ? 'has-error' : ""}`}>
                            <div className="group-input">
                                <MaskedInput type="tel" name="cpf" id="cpf" className="input" maxLength={15}
                                    mask={[/\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '-', /\d/, /\d/]}
                                    onChange={handleAlteraCpfCliente} required />
                                <label htmlFor="cpf" className="label" >CPF</label>
                            </div>
                        </Grid>
                        <Grid container item xs={12} sm={6} className={classes.grid}>
                            <button className="button" onClick={() =>{ setExibirCarregando(true); handleBuscarAssinatura(); }} disabled={!(cpfClienteValido.valido)}>Buscar</button>
                        </Grid>
                        { 
                        (() => {
                            if(imagemAssinatura.base64 !== null){
                                return (
                                <React.Fragment>
                                    <Grid container item xs={12} sm={6}>
                                        <ExibirImagem image={imagemAssinatura.base64} />
                                    </Grid>
                                    <Grid container item xs={12} sm={6}>
                                        <button className="button" onClick={() => { setExibirModal(true) }}>Excluir</button>
                                    </Grid>
                                </React.Fragment>)
                            }else if(imagemEncontrada === false){
                                return (
                                <React.Fragment>
                                    <Grid container direction='column' alignItems="center" xs={12} sm={6} spacing={2} className={classes.grid}>
                                        <Grid item>
                                            <PhotoSizeSelectActualOutlined fontSize='large' />
                                        </Grid>
                                        <Grid item>
                                            <Typography className={classes.subtitle}>
                                                {mensagemFalhaBusca}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </React.Fragment>)
                            }
                        })()
                        }
                </Container>
            </main>
        </LayoutInternal>
    )
}

export default AssinaturaPage;