import React, { Component } from 'react';

const PanelHeader = props => {
    return (
        <div className="panel-header" onClick={props.handleToggle} aria-expanded={props.isExpanded}>
            {props.children}         
        </div>
    )
}

const PanelBody = props => {
    return (
        <div className="panel-body" aria-hidden={props.isExpanded}>
            {props.children}
        </div>
    )
}

export default class Panel extends Component {
    constructor(props) {
        super(props)
        
        this.state = {
            isExpanded: this.props.openDefault
        }
        
        this.handleToggle = this.handleToggle.bind(this) 
    }
    
    handleToggle() {
        this.setState({
            isExpanded: !this.state.isExpanded 
        })
    }
    
    render() {
        return (
            <div className="panel">
                <PanelBody isExpanded={!this.state.isExpanded}>
                    {this.props.children}
                </PanelBody>
            </div>
        )
    }
}