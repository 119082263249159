import { HttpGet, HttpDelete } from '../functions/default';

export const ObterUsuarioPorCPFDataNascimento = (cpf, dataNascimento) => {
    return HttpGet(`/ObterUsuarioPorCPFDataNascimento?cpf=${cpf}&dataNascimento=${dataNascimento}`, null, null);
}

export const ObterAssinaturaCondutor = (cpf, codigoUsuario) => {
    return HttpGet(`/ObterAssinaturaCondutor?cpf=${cpf}&codigoUsuario=${codigoUsuario}`, null, null);
}

export const ExcluirAssinaturaCondutor = (identificadorArquivo) => {
    return HttpDelete(`/ExcluirAssinaturaCondutor?identificadorArquivo=${identificadorArquivo}`, null, null);
}
