import { combineReducers } from 'redux';
import MultaReducer from '../components/Multas/CardMultaReducer';
import BaseReducer from './BaseReducer';
import AgendamentoReducer from '../pages/Agendamento/Novo/AgendamentoReducer';
import ListaAgendamentosReducer from '../components/Agendamentos/CardAgendamentoReducer';
import EditarKMReducer from '../pages/EditarKM/EditarKMReducer';
import ListaServicosContratadosReducer from '../pages/ServicosContratados/ListaServicosContratados/ListaServicosContratadosReducer';
import ListaCidades from '../components/InputCidades/InputCidadesReducer';
import CabecalhoKm from '../pages/EditarKM/Cabecalho/CabecalhoReducer';
import Menu from '../components/Menu/MenuReducer'
import Sinistro from '../pages/Sinistro/SinistroReducer';
import SliderActive from '../components/Slider/SliderReducer';
import ReaReducer from '../pages/Rea/ReaReducer';
import LoginReducer from '../pages/Login/LoginReducer';
import PoliticaFrotaReducer from '../pages/PoliticaFrota/PoliticaFrotaReducer';
import AceitePoliticaFrotaReducer from '../pages/PoliticaFrota/AceitePoliticaFrotaReducer';
import AutorizarCookies from '../pages/AutorizarCookies/AutorizarCookiesReducer';
import TutorialReducer from '../components/Tutorial/TutorialReducer';

export default combineReducers({
    base: BaseReducer,
    login: LoginReducer,
    multa: MultaReducer,   
    Agendamento: AgendamentoReducer,
    listaAgendamento: ListaAgendamentosReducer,
    KM: EditarKMReducer,
    listaServicosContratados: ListaServicosContratadosReducer,
    CabecalhoKm: CabecalhoKm,
    listaCidades: ListaCidades,
    menu: Menu,
    sinistro: Sinistro,
    slider: SliderActive,
    PoliticaFrota: PoliticaFrotaReducer,
    AceitePoliticaFrota: AceitePoliticaFrotaReducer,
    AutorizarCookies : AutorizarCookies,
    Rea: ReaReducer,
    Tutorial: TutorialReducer
});