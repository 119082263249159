import {
    FormContainer,
    ImageRedirectApp,
} from "./styles";
import RedirectAppImg from "../../images/wizard/redirectApp.svg";

export const RedirectAppContainer = () => {


    return (

        <FormContainer>
            <ImageRedirectApp src={RedirectAppImg} />
        </FormContainer>

    );
};
