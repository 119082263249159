import React, { useEffect } from "react";
import Tracking from "../../hooks/Tracking";
import { RedirectAppMobileContainer } from "../../components/RedirectApp/RedirectAppMobile";


export default function RedirectAppPage() {
    useEffect(() => {
        Tracking.sendPageview("RedirectAppMobile");
    }, []);
    return (
        <React.Fragment>
            <RedirectAppMobileContainer />
        </React.Fragment>
    );
}
