import {
    ERRO_AGENDAMENTO,
    BUSCAR_SERVICOS_ATIVOS,
    VALIDAR_KM,
    BUSCAR_ULTIMA_MEDICAO,
    SELECIONAR_SERVICOS,
    BUSCAR_FORNECEDORES,
    LISTAR_FERIADOS,
    LIMPAR_FORNECEDORES,
    SALVAR_AGENDAMENTO,
    MOSTRAR_LOADING,
    ESCONDER_LOADING,
    IS_HOLIDAY,
    DADOS_USUARIO,
    AGENDAMENTO_DATAS_DO_DIA,
    BUSCAR_SS_MAE
} from './AgendamentoTypes'

const INITIAL_STATE = {
    erro: null,
    validacaoKm: null,
    isHoliday: false,
    fornecedores: null,
    datasDia: null,
    ssMae: null
}

export default (state = INITIAL_STATE, action) => {

    switch (action.type) {
        case AGENDAMENTO_DATAS_DO_DIA:
            return { ...state, datasDia: action.payload };
        case BUSCAR_ULTIMA_MEDICAO:
            return { ...state, ultimoKm: action.payload, validacaoKm: null, servicos: null, servicosSelecionados: null, erro: null, ssMae: null };
        case VALIDAR_KM:
            return { ...state, validacaoKm: action.payload, servicos: null, servicosSelecionados: null, erro: null, ssMae: null };
        case BUSCAR_SERVICOS_ATIVOS:
            return { ...state, servicos: action.payload, validacaoKm: null, servicosSelecionados: null, erro: null, ssMae: null };
        case SELECIONAR_SERVICOS:
            return { ...state, servicosSelecionados: action.payload, fornecedores: null, validacaoKm: null, erro: null, ssMae: null };
        case BUSCAR_FORNECEDORES:
            return { ...state, fornecedores: action.payload, validacaoKm: null, erro: null, ssMae: null };
        case LIMPAR_FORNECEDORES:
            return { ...state, fornecedores: null, validacaoKm: null, erro: null, ssMae: null };
        case LISTAR_FERIADOS:
            return { ...state, feriados: action.payload, validacaoKm: null, erro: null };
        case SALVAR_AGENDAMENTO:
            return { ...state, resposta: action.payload, erro: null };
        case MOSTRAR_LOADING:
            return { ...state, loading: true };
        case ESCONDER_LOADING:
            return { ...state, loading: false };
        case IS_HOLIDAY:
            return { ...state, isHoliday: action.payload };
        case DADOS_USUARIO:
            return { ...state, usuario: action.payload, ssMae: null };
        case BUSCAR_SS_MAE:
            return { ...state, ssMae: action.payload };
        case ERRO_AGENDAMENTO:
            return { ...state, erro: action.payload, validacaoKm: null, ssMae: null };
        default:
            return state;
    }
}