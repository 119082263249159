import {
  LOADING_BUSCAR_MULTA,
  BUSCAR_MULTA,
  ERRO_BUSCAR_MULTA,
  LISTA_MULTA,
  ERRO_MULTA,
  LINK_MY_FLEET_LOADING,
  LINK_MY_FLEET_LOADED,
  LINK_MY_FLEET,
  INTERPOSICAO,
  ALTERAR_DATA_NASCIMENTO,
  ALTERAR_NOME_CONDUTOR,
  ALTERAR_EMAIL_CONDUTOR,
  ALTERAR_RG_CONDUTOR,
  ALTERAR_CPF_CLIENTE,
  ALTERAR_ORGAO_EMISSOR,
  ALTERAR_ESTADO_RG,
  ALTERAR_CNH_CONDUTOR,
  ALTERAR_PRIMEIRA_HABILITACAO,
  ALTERAR_ESTADO_CNH,
  ALTERAR_TIPO_DOCUMENTO,
  ALTERAR_DOCUMENTO_ESTRANGEIRO,
  ALTERAR_LICENCA_HABILITACAO,
  ALTERAR_DATA_ENTRADA,
  ALTERAR_CONDUTOR_ESTRANGEIRO,
  CPF_CLIENTE_VALIDO,
  DATA_NASCIMENTO_VALIDO,
  EMAIL_CONDUTOR_VALIDO,
  PRIMEIRA_HABILITACAO_VALIDO,
  DATA_ENTRADA_VALIDA,
  LIMPAR_DADOS_CONDUTOR,
  BUSCAR_DADOS_CONDUTOR,
  LIMPAR_ASSINATURA_CONDUTOR,
} from "./CardMultaTypes";

import { HttpPost, HttpGet, HttpGetFile } from "../../functions/default";
import { DESLOGAR } from "../../pages/Login/LoginTypes";
import qs from "qs";
import { ObterUsuarioPorCPFDataNascimento } from "../../services/UsuarioService";
import Tracking from "../../hooks/Tracking";
import moment from "moment";

export const limparMensageErro = () => {
  return (dispatch) => {
    dispatch({ type: ERRO_MULTA, payload: null });
  };
};

export const listaMulta = (placa, param) => {
  return (dispatch) => {
    if (navigator.onLine) {
      HttpGet(`/BuscarMultas/${placa}?codigoCliente=${param}`, null)
        .then(function (result) {
          Tracking.sendEvent({
            category: "card_multa",
            action: "MULTAS_LISTA_SUCESSO",
            label: "Lista de multas buscada com sucesso",
            nonInteraction: true,
          });
          dispatch({ type: LISTA_MULTA, payload: result.data });
        })
        .catch(function (erro) {
          if (
            (erro.response && erro.response.status === 401) ||
            (erro.response && erro.response.status === 403)
          ) {
            dispatch({
              type: DESLOGAR,
              payload: { message: "Usuário não autenticado." },
            });
          }
          if (erro.response.status !== 401 || erro.response.status !== 403) {
            Tracking.sendEvent({
              category: "card_multa",
              action: "MULTAS_LISTA_ERRO",
              label: "Erro ao buscar a lista de multas",
              nonInteraction: true,
            });
            dispatch({
              type: ERRO_MULTA,
              payload: {
                message:
                  "Ocorreu um erro ao buscar multas, tente novamente mais tarde.",
              },
            });
          }
          dispatch({
            type: ERRO_MULTA,
            payload: {
              message:
                "Ocorreu um erro ao buscar multas, tente novamente mais tarde.",
            },
          });
        });
    }
  };
};

export const buscarMulta = (ait, orgao) => {
  return (dispatch) => {
    dispatch({
      type: LOADING_BUSCAR_MULTA,
      payload: true,
    });

    HttpGet(`/BuscarMulta/${ait}/${orgao}`, null)
      .then((response) => {
        Tracking.sendEvent({
          category: "card_multa",
          action: "MULTAS_DETALHE_MULTA_SUCESSO",
          label: "Multa buscada",
          nonInteraction: true,
        });

        dispatch({ type: BUSCAR_MULTA, payload: response.data });
      })
      .catch((error) => {
        dispatch({
          type: ERRO_BUSCAR_MULTA,
          payload: error,
        });
        if (error.response.status !== 401) {
          Tracking.sendEvent({
            category: "card_multa",
            action: "MULTAS_DETALHE_MULTA_ERRO",
            label: "Lista de multas buscada",
            nonInteraction: true,
          });
        }
        if (error.response.status === 401 || error.response.status === 403) {
          dispatch({
            type: DESLOGAR,
            payload: { message: "Usuário não autenticado." },
          });
        }
      });
  };
};

export const alterarLimite = (limiteTo) => {
  return (dispatch) => {
    dispatch({ type: LISTA_MULTA, payload: limiteTo + 5 });
  };
};

export const buscarMyFleetUrl = (ait, orgao) => {
  return (dispatch) => {
    if (navigator.onLine) {
      dispatch({ type: LINK_MY_FLEET_LOADING });

      var param = qs.stringify({ ait, orgao });
      HttpGet(`/BuscarLinkMultaMyFleet?${param}`, param, null)
        .then(function (result) {
          dispatch({ type: LINK_MY_FLEET, payload: result.data });
          dispatch({ type: LINK_MY_FLEET_LOADED });
        })
        .catch(function (erro) {
          if (erro.response && erro.response.status === 401)
            dispatch({
              type: DESLOGAR,
              payload: { message: "Usuário não autenticado." },
            });
          else
            dispatch({
              type: ERRO_MULTA,
              payload: {
                message:
                  "Ocorreu um erro ao buscar multas, tente novamente mais tarde.",
              },
            });

          dispatch({ type: LINK_MY_FLEET_LOADED });
        });
    } else
      dispatch({
        type: ERRO_MULTA,
        payload: {
          message: "Para prosseguir, conecte seu celular à internet.",
        },
      });
  };
};

export const GerarDocumentoInterposicao = (ait, orgao, nome, rg, placa) => {
  return (dispatch) => {
    dispatch({ type: LINK_MY_FLEET_LOADING });
    var filename = nomearquivointerposicao(ait, placa);

    HttpGetFile(
      `/GerarDocumentoInterposicao?placa=${placa}&ait=${ait}&orgao=${orgao}&nome=${nome}&rg=${rg}`,
      null,
      filename
    )
      .then(function (result) {
        Tracking.sendEvent({
          category: "card_multa",
          action: "MULTAS_DOCUMENTO_INTERPOSICAO_SUCESSO",
          label: "Documento de interposição gerado",
          nonInteraction: true,
        });

        dispatch({ type: LINK_MY_FLEET_LOADED });
        dispatch({ type: INTERPOSICAO, payload: true });
      })
      .catch(function (erro) {
        dispatch({ type: LINK_MY_FLEET_LOADED });
        if (erro.response && erro.response.status === 401)
          dispatch({
            type: DESLOGAR,
            payload: { message: "Usuário não autenticado." },
          });
        else {
          Tracking.sendEvent({
            category: "card_multa",
            action: "MULTAS_DOCUMENTO_INTERPOSICAO_ERRO",
            label: "Erro ao gerar o documento de interposição",
            nonInteraction: true,
          });
          dispatch({
            type: ERRO_MULTA,
            payload: {
              message:
                "Ocorreu um erro ao obter o arquivo, tente novamente mais tarde.",
            },
          });
        }
      });
  };
};

export const BaixarArquivoMulta = (placa, ait, orgao) => {
  return (dispatch) => {
    dispatch({ type: LINK_MY_FLEET_LOADING });

    Tracking.sendEvent({
      category: "informacao_multa",
      action: "MULTAS_IMAGEM_CLICADA",
      label: "Imagem da multa clicada",
    });

    let filename = `infracao_${placa}.pdf`;

    HttpGetFile(`/DownloadArquivoNotificacao/${ait}/${orgao}`, null, filename)
      .then(function (result) {
        dispatch({ type: LINK_MY_FLEET_LOADED });
      })
      .catch(function (err) {
        dispatch({ type: LINK_MY_FLEET_LOADED });
        dispatch({
          type: ERRO_MULTA,
          payload: {
            message:
              "Ocorreu um erro ao obter o arquivo da infração, tente novamente mais tarde.",
          },
        });
      });
  };
};

export const limparErroMulta = () => {
  return (dispatch) => {
    dispatch({ type: ERRO_MULTA, payload: null });
  };
};

export const alterarDataNascimento = (value) => {
  return (dispatch) => {
    dispatch({ type: ALTERAR_DATA_NASCIMENTO, payload: value });
  };
};
export const alterarNomeCondutor = (value) => {
  return (dispatch) => {
    dispatch({ type: ALTERAR_NOME_CONDUTOR, payload: value });
  };
};
export const alterarEmailCondutor = (value) => {
  return (dispatch) => {
    dispatch({ type: ALTERAR_EMAIL_CONDUTOR, payload: value });
  };
};
export const alterarRgCondutor = (value) => {
  return (dispatch) => {
    dispatch({ type: ALTERAR_RG_CONDUTOR, payload: value });
  };
};
export const alterarCpfCliente = (value) => {
  return (dispatch) => {
    dispatch({ type: ALTERAR_CPF_CLIENTE, payload: value });
  };
};
export const alterarOrgaoEmissor = (value) => {
  return (dispatch) => {
    dispatch({ type: ALTERAR_ORGAO_EMISSOR, payload: value });
  };
};
export const alterarEstadoRg = (value) => {
  return (dispatch) => {
    dispatch({ type: ALTERAR_ESTADO_RG, payload: value });
  };
};
export const alterarCnhCondutor = (value) => {
  return (dispatch) => {
    dispatch({ type: ALTERAR_CNH_CONDUTOR, payload: value });
  };
};
export const alterarPrimeiraHabilitacao = (value) => {
  return (dispatch) => {
    dispatch({ type: ALTERAR_PRIMEIRA_HABILITACAO, payload: value });
  };
};
export const alterarEstadoCnh = (value) => {
  return (dispatch) => {
    dispatch({ type: ALTERAR_ESTADO_CNH, payload: value });
  };
};
export const alterarTipoDocumento = (value) => {
  return (dispatch) => {
    dispatch({ type: ALTERAR_TIPO_DOCUMENTO, payload: value });
  };
};
export const alterarDocumentoEstrangeiro = (value) => {
  return (dispatch) => {
    dispatch({ type: ALTERAR_DOCUMENTO_ESTRANGEIRO, payload: value });
  };
};
export const alterarLicencaHabilitacao = (value) => {
  return (dispatch) => {
    dispatch({ type: ALTERAR_LICENCA_HABILITACAO, payload: value });
  };
};
export const alterarDataEntrada = (value) => {
  return (dispatch) => {
    dispatch({ type: ALTERAR_DATA_ENTRADA, payload: value });
  };
};

export const alterarCondutorEstrangeiro = (value) => {
  return (dispatch) => {
    dispatch({ type: ALTERAR_CONDUTOR_ESTRANGEIRO, payload: value });
  };
};

export const limparDadosCondutor = () => {
  return (dispatch) => {
    dispatch({ type: LIMPAR_DADOS_CONDUTOR });
  };
};

export const verificarCpfClienteValido = (value) => {
  return (dispatch) => {
    dispatch({ type: CPF_CLIENTE_VALIDO, payload: value });
  };
};

export const verificarDataNascimentoValido = (value) => {
  return (dispatch) => {
    dispatch({ type: DATA_NASCIMENTO_VALIDO, payload: value });
  };
};

export const verificarEmailCondutorValido = (value) => {
  return (dispatch) => {
    dispatch({ type: EMAIL_CONDUTOR_VALIDO, payload: value });
  };
};

export const verificarPrimeiraHabilitacaoValido = (value) => {
  return (dispatch) => {
    dispatch({ type: PRIMEIRA_HABILITACAO_VALIDO, payload: value });
  };
};

export const verificarDataEntradaValido = (value) => {
  return (dispatch) => {
    dispatch({ type: DATA_ENTRADA_VALIDA, payload: value });
  };
};

export const exibirCarregando = () => {
  return (dispatch) => {
    dispatch({ type: LINK_MY_FLEET_LOADING });
  };
};

export const removerCarregando = () => {
  return (dispatch) => {
    dispatch({ type: LINK_MY_FLEET_LOADED });
  };
};

export const limparAssinaturaCondutor = () => {
  return (dispatch) => {
    dispatch({ type: LIMPAR_ASSINATURA_CONDUTOR });
  };
};

export const buscarDadosCpfDataNascimento = (cpf, dataNascimento) => {
  return (dispatch) => {
    dispatch({ type: LINK_MY_FLEET_LOADING });
    ObterUsuarioPorCPFDataNascimento(cpf, dataNascimento)
      .then(function (result) {
        Tracking.sendEvent({
          category: "card_multa",
          action: "MULTAS_BUSCAR_CPF_SUCESSO",
          label: "Dados do condutor buscados por cpf e data de nascimento",
          nonInteraction: true,
        });
        let _dados = {
          nomeCondutor:
            result.data.Usuario.Nome !== null ? result.data.Usuario.Nome : "",
          cnhCondutor:
            result.data.Usuario.Cnh !== null ? result.data.Usuario.Cnh : "",
          estadoCnh:
            result.data.Usuario.CnhUf !== null ? result.data.Usuario.CnhUf : "",
          rgCondutor:
            result.data.Usuario.Rg !== null ? result.data.Usuario.Rg : "",
          orgaoEmissor:
            result.data.Usuario.RgOrgaoEmissor !== null
              ? result.data.Usuario.RgOrgaoEmissor
              : "",
          estadoRg:
            result.data.Usuario.RgUf !== null ? result.data.Usuario.RgUf : "",
          dataNascimento:
            result.data.Usuario.DataNascimento !== null
              ? moment(result.data.Usuario.DataNascimento)
              : moment(dataNascimento),
          cpfCliente:
            result.data.Usuario.Cpf !== null ? result.data.Usuario.Cpf : cpf,
          emailCondutor:
            result.data.Usuario.Email !== null ? result.data.Usuario.Email : "",
          primeiraHabilitacao:
            result.data.Usuario.DataPrimeiraHabilitacao !== null
              ? moment(result.data.Usuario.DataPrimeiraHabilitacao)
              : "",
          idPessoa:
            result.data.Usuario.IdPessoa !== null
              ? result.data.Usuario.IdPessoa
              : "",
          imagemAssinatura:
            result.data.Base64ArquivoAssinatura !== null
              ? result.data.Base64ArquivoAssinatura
              : "",
          cdUsuario:
            result.data.Usuario.CdUsuario !== null
              ? result.data.Usuario.CdUsuario
              : "",
        };

        dispatch({ type: BUSCAR_DADOS_CONDUTOR, payload: _dados });
        dispatch({ type: LINK_MY_FLEET_LOADED });
      })
      .catch(function (erro) {
        dispatch({
          type: ERRO_MULTA,
          payload: { message: "Erro ao recuperar os dados do condutor" },
        });
        dispatch({ type: LINK_MY_FLEET_LOADED });
        if (erro.response.status !== 401) {
          Tracking.sendEvent({
            category: "card_multa",
            action: "MULTAS_BUSCAR_CPF_ERRO",
            label:
              "Erro ao buscar dados do condutor por cpf e data de nascimento",
            nonInteraction: true,
          });
        }
      });
  };
};

export const RegistrarAcesso = (tipoIndicacaoCondutor) => {
  return HttpPost(
    `/RegistrarAcessoIndicacaoMulta?tipoIndicacaoCondutor=${tipoIndicacaoCondutor}`,
    null,
    null
  );
};

function nomearquivointerposicao(ait, placa) {
  var now = new Date();
  var dia = now.getDate();
  var mes = now.getMonth() + 1;
  var ano = now.getFullYear();
  var hora = now.getHours();
  var minuto = now.getMinutes();

  dia = dia > 9 ? dia : "0" + dia;
  mes = mes > 9 ? mes : "0" + mes;

  return `${placa}_${ait}_${dia}_${mes}_${ano}_${hora}_${minuto}.pdf`;
}
