import { LISTAR, EXCLUIR, VER_MAIS, ERRO_KM, EXCLUIRKM_LOADING, EXCLUIRKM_LOADED } from './EditarKMTypes'

const INITIAL_STATE = {
    kms: [],
    contador: 3, //exibe os 3 primeiros registros
    erro: null,
    resposta: null,
    loading: false
}

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case LISTAR:
            return { ...state, kms: action.kms, erro: null, resposta: null };
        case EXCLUIR:
            return { ...state, resposta: action.resposta, erro: null };
        case VER_MAIS:
            return { ...state, contador: action.contador, erro: null, resposta: null };
        case ERRO_KM:
            return { ...state, erro: action.erro, resposta: null };
        case EXCLUIRKM_LOADING:
            return { ...state, loading: true };
        case EXCLUIRKM_LOADED:
            return { ...state, loading: false };
        default:
            return state;
    }
}