import React, { Component } from 'react';
import insertLabelDatePicker from '../../helpers/formatDatePickers';

class TemSeguro extends Component {
    constructor(props) {
        super(props);
        this.handleVoltar = this.handleVoltar.bind(this);
        this.handleAvancar = this.handleAvancar.bind(this);
    }

    componentDidMount() {
        insertLabelDatePicker();
        window.scrollTo(0, 0);
    }

    handleAvancar() {
        this.props.onIrParaEtapa(9);
    }

    handleVoltar() {
        this.props.onIrParaEtapa(7);
    }

    handleAlteraSeguro = (event) => {
        if (event.target.value !== null && event.target.value !== "") {
            this.props.onChangeSeguro(event.target.value);
        }
    }

        handleAlteraSeguradora = (event) => {
        if (event.target.value !== null) {
            this.props.onChangeSeguradora(event.target.value);
        }
    }

    handleAlteraNumeroSinistro = (event) => {
        if (event.target.value !== null) {
            this.props.onChangeNumeroSinistro(event.target.value);
        }
    }

    render() {
        return (
            <React.Fragment>

                <div className="row w-xl-66">

                    <div className="col-12">
                        <h2 className="form-title">O carro terceiro tem seguro?</h2>
                        <div className="custom-radio">
                            <input type="radio" id="chave_s" name="seguro" className="custom-control-input" value="sim" 
                            onChange={this.handleAlteraSeguro} checked={this.props.modelo.seguro === "sim"}/>
                            <label className="custom-control-label" htmlFor="chave_s">Sim</label>
                        </div>
                        <div className="custom-radio">
                            <input type="radio" id="chave_n" name="seguro" className="custom-control-input" value="não" 
                            onChange={this.handleAlteraSeguro} checked={this.props.modelo.seguro === "não"}/>
                            <label className="custom-control-label" htmlFor="chave_n">Não</label>
                        </div>
                    </div>

                    <div className="col-12">
                        <div className={`wrapper-input`}>
                            <div className="group-input">
                                <input type="text" name="terceiro_end" id="seguradora" className="input" maxLength={40}
                                disabled={this.props.modelo.seguro === "" || this.props.modelo.seguro === "não"}
                                onChange={this.handleAlteraSeguradora} value={this.props.modelo.seguradora} required />
                                <label htmlFor="seguradora" className="label">Seguradora</label>
                            </div>
                        </div>
                        <div className={`wrapper-input`}>
                            <div className="group-input">
                                <input type="text" name="terceiro_end" id="seguradora" className="input" maxLength={40}
                                disabled={this.props.modelo.seguro === "" || this.props.modelo.seguro === "não"}
                                onChange={this.handleAlteraNumeroSinistro} value={this.props.modelo.numeroSinistro} required />
                                <label htmlFor="seguradora" className="label">Nº do aviso do sinistro</label>
                            </div>
                        </div>
                    </div>

                    <div className="w-100"></div>
                    <div className="ml-auto mt-3 col-md-6">
                        <div className="row">
                            <div className="col">
                                <button className="button" onClick={this.handleVoltar}>Voltar</button>
                            </div>
                            <div className="col">
                                <button className="button" onClick={this.handleAvancar}>Continuar</button>
                            </div>
                        </div>
                    </div>

                </div>
            </React.Fragment>

        );
    }

}

export default TemSeguro