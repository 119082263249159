import {
    LOADING_BUSCAR_MULTA,
    BUSCAR_MULTA,
    ERRO_BUSCAR_MULTA,
    LISTA_MULTA,
    ERRO_MULTA,
    LIMITTO,
    LINK_MY_FLEET_LOADING,
    LINK_MY_FLEET_LOADED,
    LINK_MY_FLEET,
    INTERPOSICAO,
    ALTERAR_DATA_NASCIMENTO,
    ALTERAR_NOME_CONDUTOR,
    ALTERAR_EMAIL_CONDUTOR,
    ALTERAR_RG_CONDUTOR,
    ALTERAR_CPF_CLIENTE,
    ALTERAR_ORGAO_EMISSOR,
    ALTERAR_ESTADO_RG,
    ALTERAR_CNH_CONDUTOR,
    ALTERAR_PRIMEIRA_HABILITACAO,
    ALTERAR_ESTADO_CNH,
    ALTERAR_TIPO_DOCUMENTO,
    ALTERAR_DOCUMENTO_ESTRANGEIRO,
    ALTERAR_LICENCA_HABILITACAO,
    ALTERAR_DATA_ENTRADA,
    ALTERAR_CONDUTOR_ESTRANGEIRO,
    CPF_CLIENTE_VALIDO,
    DATA_NASCIMENTO_VALIDO,
    EMAIL_CONDUTOR_VALIDO,
    PRIMEIRA_HABILITACAO_VALIDO,
    DATA_ENTRADA_VALIDA,
    LIMPAR_DADOS_CONDUTOR,
    BUSCAR_DADOS_CONDUTOR,
    LIMPAR_ASSINATURA_CONDUTOR
} from './CardMultaTypes'

import update from 'react-addons-update';

const INITIAL_STATE = {
    loading_buscar_multa: null,
    multa: null,
    erro_buscar_multa: null,
    multas: null,
    erro: null,
    limiteTo: 3,
    url: null,
    loading: false,
    interposicao: false,
    bloqueadoDadosCondutor: false,
    cpfClienteValido: true,
    dataNascimentoValida: true,
    emailCondutorValido: true,
    primeiraHabilitacaoValida: true,
    dataEntradaValida: true,
    condutor: {
        dataNascimento: "",
        nomeCondutor: "",
        emailCondutor: "",
        rgCondutor: "",
        cpfCliente: "",
        orgaoEmissor: "",
        estadoRg: "",
        cnhCondutor: "",
        primeiraHabilitacao: "",
        estadoCnh: "",
        tipoDocumento: "",
        documentoEstrangeiro: "",
        licencaHabilitacao: "",
        dataEntrada: "",
        condutorEhEstrangeiro: 0,
        idPessoa: "",
        cdUsuario: "",
        imagemAssinatura: ""
    }
}

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case LOADING_BUSCAR_MULTA:
            return {
                ...state,
                loading_buscar_multa: action.payload
            }
        case BUSCAR_MULTA:
            return {
                ...state,
                loading_buscar_multa: false,
                multa: action.payload,
                erro_buscar_multa: null
            }
        case ERRO_BUSCAR_MULTA:
            return {
                ...state,
                loading_buscar_multa: false,
                erro_buscar_multa: action.payload
            }
        case LISTA_MULTA:
            return { ...state, multas: action.payload, interposicao: false };
        case ERRO_MULTA:
            return { ...state, erro: action.payload, interposicao: false };
        case LIMITTO:
            return { ...state, limiteTo: action.payload, interposicao: false };
        case LINK_MY_FLEET_LOADING:
            return { ...state, loading: true, interposicao: false };
        case LINK_MY_FLEET_LOADED:
            return { ...state, loading: false, interposicao: false };
        case LINK_MY_FLEET:
            return { ...state, url: action.payload, interposicao: false };
        case INTERPOSICAO:
            return { ...state, interposicao: action.payload };
        case ALTERAR_DATA_NASCIMENTO:
            return update(state, { condutor: { dataNascimento: { $set: action.payload } } });
        case ALTERAR_NOME_CONDUTOR:
            return update(state, { condutor: { nomeCondutor: { $set: action.payload } } });
        case ALTERAR_EMAIL_CONDUTOR:
            return update(state, { condutor: { emailCondutor: { $set: action.payload } } });
        case ALTERAR_RG_CONDUTOR:
            return update(state, { condutor: { rgCondutor: { $set: action.payload } } });
        case ALTERAR_CPF_CLIENTE:
            return update(state, { condutor: { cpfCliente: { $set: action.payload } } });
        case ALTERAR_ORGAO_EMISSOR:
            return update(state, { condutor: { orgaoEmissor: { $set: action.payload } } });
        case ALTERAR_ESTADO_RG:
            return update(state, { condutor: { estadoRg: { $set: action.payload } } });
        case ALTERAR_CNH_CONDUTOR:
            return update(state, { condutor: { cnhCondutor: { $set: action.payload } } });
        case ALTERAR_PRIMEIRA_HABILITACAO:
            return update(state, { condutor: { primeiraHabilitacao: { $set: action.payload } } });
        case ALTERAR_ESTADO_CNH:
            return update(state, { condutor: { estadoCnh: { $set: action.payload } } });
        case ALTERAR_TIPO_DOCUMENTO:
            return update(state, { condutor: { tipoDocumento: { $set: action.payload } } });
        case ALTERAR_DOCUMENTO_ESTRANGEIRO:
            return update(state, { condutor: { documentoEstrangeiro: { $set: action.payload } } });
        case ALTERAR_LICENCA_HABILITACAO:
            return update(state, { condutor: { licencaHabilitacao: { $set: action.payload } } });
        case ALTERAR_DATA_ENTRADA:
            return update(state, { condutor: { dataEntrada: { $set: action.payload } } });
        case ALTERAR_CONDUTOR_ESTRANGEIRO:
            return update(state, { condutor: { condutorEhEstrangeiro: { $set: action.payload } } });
        case CPF_CLIENTE_VALIDO:
            return update(state, { cpfClienteValido: { $set: action.payload } });    
        case DATA_NASCIMENTO_VALIDO:
            return update(state, { dataNascimentoValida: { $set: action.payload } });    
        case EMAIL_CONDUTOR_VALIDO:
            return update(state, { emailCondutorValido: { $set: action.payload } });    
        case PRIMEIRA_HABILITACAO_VALIDO:
            return update(state, { primeiraHabilitacaoValida: { $set: action.payload } });   
        case DATA_ENTRADA_VALIDA:
            return update(state, { dataEntradaValida: { $set: action.payload } });    
        case LIMPAR_DADOS_CONDUTOR:
            return update(state, {
                condutor: {
                    cpfCliente: { $set: "" },
                    dataNascimento: { $set: "" },
                    nomeCondutor: { $set: "" },
                    emailCondutor: { $set: "" },
                    rgCondutor: { $set: "" },
                    orgaoEmissor: { $set: "" },
                    estadoRg: { $set: "" },
                    cnhCondutor: { $set: "" },
                    primeiraHabilitacao: { $set: "" },
                    estadoCnh: { $set: "" },
                    tipoDocumento: { $set: "" },
                    documentoEstrangeiro: { $set: "" },
                    licencaHabilitacao: { $set: "" },
                    dataEntrada: { $set: "" },
                    condutorEhEstrangeiro: { $set: 0 },
                    idPessoa: { $set: "" },
                    imagemAssinatura: { $set: "" },
                    cdUsuario: { $set: "" }
                },
                bloqueadoDadosCondutor: { $set: false }
            });
        case BUSCAR_DADOS_CONDUTOR:
            return update(state, {
                condutor: {
                    dataNascimento: { $set: action.payload.dataNascimento },
                    nomeCondutor: { $set: action.payload.nomeCondutor },
                    emailCondutor: { $set: action.payload.emailCondutor },
                    rgCondutor: { $set: action.payload.rgCondutor },
                    cpfCliente: { $set: action.payload.cpfCliente },
                    orgaoEmissor: { $set: action.payload.orgaoEmissor },
                    estadoRg: { $set: action.payload.estadoRg },
                    cnhCondutor: { $set: action.payload.cnhCondutor },
                    primeiraHabilitacao: { $set: action.payload.primeiraHabilitacao },
                    estadoCnh: { $set: action.payload.estadoCnh },
                    idPessoa: { $set: action.payload.idPessoa },
                    imagemAssinatura: { $set: action.payload.imagemAssinatura },
                    cdUsuario: { $set: action.payload.cdUsuario }
                },
                bloqueadoDadosCondutor: { $set: false },
            });
        case LIMPAR_ASSINATURA_CONDUTOR:
            return update(state, {
                condutor: {
                    imagemAssinatura: { $set: '' }
                }
            });
        default:
            return state;
    }
}