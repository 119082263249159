import React, { useEffect } from "react";
import Tracking from "../../hooks/Tracking";
import { RedirectAppContainer } from "../../components/RedirectApp/RedirectApp";


export default function RedirectAppPage() {
    useEffect(() => {
        Tracking.sendPageview("RedirectApp");
    }, []);
    return (
        <React.Fragment>
            <RedirectAppContainer />
        </React.Fragment>
    );
}
