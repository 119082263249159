import React, { Component } from 'react';
import '../styles/css/info-read.css';

export default class    Input extends Component {
    constructor(props){
        super(props);
        this.textInput = React.createRef();
    }

    render() {
        return (
            <div className={`wrapper-input ${this.props.erro ? 'has-error' : null}`}>
                <div className="group-input">
                    <input className="input" type={this.props.type} ref={this.textInput} required/>
                    <label className="label">{this.props.label}</label>
                </div>
                <span className={`icon ${this.props.icon}`}></span>
            </div>          
        );
    }
}