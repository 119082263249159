import React, { Component } from 'react';
import '../styles/css/bottom-navigation.css';
import { BrowserRouter as Router, NavLink, Redirect, Link, withRouter } from 'react-router-dom';
import * as Cookie from '../functions/Cookies';
import { NomeCookieSessaoRea } from '../config/consts';
import { checkIsWebView } from '../helpers/checkPlatform';

class BottomNavigation extends Component {
    //teste
    state = {
        innerHeight: window.innerHeight,
        availHeight: window.screen.availHeight,
        availWidth: window.screen.availWidth,
        urlARedirecionar: null
    }

    constructor() {
        super()

        this.estaNaHome = this.estaNaHome.bind(this);

        window.onresize = () => {
            this.setState({
                innerHeight: window.innerHeight,
                availHeight: window.screen.availHeight,
                availWidth: window.screen.availWidth
            })
        }
    }

    ehAndroid = () => (navigator.userAgent.toLowerCase().indexOf('android') > -1)
    ehPortrait = () => (this.state.availHeight > this.state.availWidth)
    percentualTelaComprometida = () => (((this.state.availHeight - this.state.innerHeight) / this.state.availHeight) * 100)
    esconderMenu = () => (this.ehAndroid() && this.percentualTelaComprometida() > 23)

    redirecionarHome = () => {
        Cookie.set(NomeCookieSessaoRea, "", -1, true);
        this.setState({ urlARedirecionar: "/home" });
    }

    estaNaHome = () => {
        if (this.props.location && this.props.location.pathname && (
            this.props.location.pathname === "/" || this.props.location.pathname.toLowerCase() === "/home")) {
            return true
        }
        else {
            return false;
        }
    }

    render() {
        if (this.esconderMenu()) {
            return false;
        }

        if (this.state.urlARedirecionar !== null && !this.estaNaHome()) {
            return (<Redirect to={this.state.urlARedirecionar} />);
        }


        if (checkIsWebView) {
            return null
        }
        else {
            return (

                <footer className="wrapper-bottom-navigation">
                    <nav className="bottom-navigation">
                        <div className="item-navigation">
                            <a to="#" onClick={this.redirecionarHome} className={this.estaNaHome() === true ? "active" : ""} >
                                <span className="icon icon-home"></span>
                                <span>Home</span>
                            </a>
                        </div>
                        <div className="item-navigation">
                            <NavLink to="/Multas" activeClassName="active">
                                <span className="icon icon-bill2"></span>
                                <span>Multas</span>
                            </NavLink>
                        </div>
                        <div className="item-navigation">
                            <NavLink to="/Agendamentos" activeClassName="active">
                                <span className="icon icon-tools"></span>
                                <span>Serviços</span>
                            </NavLink>
                        </div>
                    </nav>
                </footer>

            );
        }

    }
}

export default withRouter(BottomNavigation);