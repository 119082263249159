import {
    ACEITE_POLITICA_FROTA,
    LOADING_ACEITE_POLITICA_FROTA,
    CONFIRMACAO_ACEITE_POLITICA_FROTA,
    ERRO_ACEITE_POLITICA_FROTA,
    LIMPAR_STATE_POLITICA_FROTA,
    LOADING_DOWNLOAD_POLITICA_FROTA,
    LOADED_DOWNLOAD_POLITICA_FROTA,
    ERRO_DOWNLOAD_POLITICA_FROTA
} from './AceitePoliticaFrotaTypes';

const INITIAL_STATE = {
    aceitePoliticaFrota: null,
    mensagem: null,
    erro : null,
    loadingAceitePolitica: false
}

export const aceitePoliticaFrota = (state = INITIAL_STATE, action) => {
    switch (action.type){
    case LOADING_ACEITE_POLITICA_FROTA:
        return  {
            ...state,
            loadingAceitePolitica: true
        };
    case CONFIRMACAO_ACEITE_POLITICA_FROTA:
        return  {
            ...state,
            aceitePoliticaFrota: {
                ...state.aceitePoliticaFrota,
                ExibeAceitePolitica: false
            },
            mensagem: action.payload,
            erro: null,
            loadingAceitePolitica: false
        };
    case ACEITE_POLITICA_FROTA:
        return  {
            ...state,
            aceitePoliticaFrota: action.payload,
            mensagem: null,
            erro: null,
            loadingAceitePolitica: false
        };
    case ERRO_ACEITE_POLITICA_FROTA:
    case LIMPAR_STATE_POLITICA_FROTA:
        return  {
            ...state,
            aceitePoliticaFrota: null,
            mensagem: null,
            erro: action.payload,
            loadingAceitePolitica: false
        };
    case LOADING_DOWNLOAD_POLITICA_FROTA:
        return  {
            ...state,
            loadingAceitePolitica: true
        };
    case LOADED_DOWNLOAD_POLITICA_FROTA:
        return  {
            ...state,
            loadingAceitePolitica: false
        };
    case ERRO_DOWNLOAD_POLITICA_FROTA:
        return  {
            ...state,
            loadingAceitePolitica: false,
            erro: action.payload
        };
    default:
        return state;
    }
}

export default aceitePoliticaFrota;