import styled from "styled-components";

export const HeaderWrapper = styled.header`
  background: #01602a;
  color: #fff;
  height: 30%;
`;

export const LogoContainer = styled.div`
  margin: 0 auto;
  padding: 40px 0 0 0;
  max-width: 264px;
  @media (max-width: 1025px) {
    padding: 59px 0 0 0;
    max-width: 133px;
  }
`;

export const Img = styled.img`
  display: table;
  width: 100%;
  height: auto;
`;

export const H1 = styled.h1`
  display: table;
  margin: 0 auto;
`;

export const SectionContainer = styled.section`
  display: flex;
  flex-direction: column;
  line-height: 1.5;
  align-items: center;
  padding-top: 15px;
  padding-bottom: 15px;
  background-color: #fafafa;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 154px;
  border-top-left-radius: 24px;
  border-top-right-radius: 24px;
  box-sizing: border-box;
  @media (max-width: 1025px) {
    top: 131px;
  }
`;

export const FooterContainer = styled.footer`
  position: absolute;
  bottom: 17%;
  left: 0;
  margin: 15px;

  @media (max-height: 570px) {
    position: relative;
    bottom: 0;
    left: 0;
  }

  @media (max-height: 629px) {
    position: relative;
    bottom: 0;
    left: 0;
  }

  @media (max-height: 722px) {
    position: relative;
    bottom: 0;
    left: 0;
  }
`;

export const Logo = styled.img`
  margin: 20px;
  @media (max-width: 1025px) {
    width: 142px;
  }
`;
