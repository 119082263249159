import React, { useState, useEffect } from "react";
import "./styles";
import { Redirect } from "react-router-dom";
import {
  FormContainer,
  LabelWrapper,
  InputContainer,
  TextFieldMaterial,
  ButtonContainer,
  Button,
  ErrorContainer,
  MessageError,
  Ancora,
} from "./styles";
import { useAuth } from "../../../providers/auth";
import Loading from "../../Loading";
import Tracking from "../../../hooks/Tracking";

export const LoginRenavamEmail = () => {
  const [placa, setPlaca] = useState("");
  const [renavam, setRenavam] = useState("");

  const {
    placaRegistration,
    error,
    home,
    loading,
    email,
    resetState,
    setError,
    clearErrors,
  } = useAuth();

  const handleClick = () => {
    if (!placa || !renavam) {
      setError("Campo obrigatório");
    } else if (placa.length < 7 || renavam.length < 4) {
      setError("Placa ou renavam inválidos.");
    } else {
      localStorage.setItem("placa-atual", placa);
      placaRegistration(placa, renavam, localStorage.getItem("token-id"));
    }
  };

  useEffect(() => {
    clearErrors();
  }, []);

  if (!email) {
    return <Redirect to="/Login" />;
  } else {
    return (
      <FormContainer>
        <LabelWrapper>
          Verificamos que você ainda não tem placa vinculada a seu e-mail.{" "}
          <br /> Acesse o sistema com uma Placa e Renavam.
        </LabelWrapper>
        <InputContainer>
          <TextFieldMaterial
            className="textFieldPassword"
            id="Placa"
            label="Placa"
            variant="outlined"
            name="placa"
            type="text"
            onChange={(e) => setPlaca(e.target.value.toUpperCase())}
            value={placa}
            inputProps={{ maxLength: 7 }}
            InputLabelProps={{
              style: { color: "#3A3D42", fontSize: "14px" },
            }}
          />
          <TextFieldMaterial
            className="textFieldPassword"
            id="Renavam"
            label="4 ultimos digitos do renavam"
            variant="outlined"
            name="renavam"
            type="text"
            onChange={(e) => setRenavam(e.target.value)}
            value={renavam}
            inputProps={{ maxLength: 4 }}
            InputLabelProps={{
              style: { color: "#3A3D42", fontSize: "14px" },
            }}
          />
        </InputContainer>
        {error ? (
          <ErrorContainer>
            <MessageError>{error}</MessageError>
          </ErrorContainer>
        ) : (
          ""
        )}
        <ButtonContainer>
          <Button onClick={handleClick}>Prosseguir</Button>
        </ButtonContainer>
        <Ancora onClick={resetState}>Voltar para a tela de login</Ancora>
        {loading === true ? <Loading /> : ""}
        {home === true ? (
          <Redirect
            to={{
              pathname: "/Home",
              state: { id: "Login" },
            }}
          />
        ) : (
          ""
        )}
      </FormContainer>
    );
  }
};
