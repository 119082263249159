import
{
    AUTORIZAR_COOKIES,
    LOADING,
    LOADED,
    ERRO
} from './AutorizarCookiesTypes'

const INITIAL_STATE = {
    erro : null,
    autorizarCookies : false
}

export const autorizarCookies = (state = INITIAL_STATE, action) => {
    switch (action.type)
    {
        case AUTORIZAR_COOKIES:
            return  { ...state, autorizarCookies: action.payload };
        case LOADING:
            return  { ...state, loading: true };
        case LOADED:
            return  { ...state, loading: false };
        case ERRO:
            return  { ...state, erro: action.payload };
        default:
            return state;
    }
}

export default autorizarCookies;