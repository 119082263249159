import React, { Component } from 'react';
import arrow from '../../images/arrow.svg'
import MediaQuery from "react-responsive";
import {
    listaMulta,
  } from "./CardMultaAction";
  import { connect } from "react-redux";
  import { Link } from "react-router-dom";

function Conteudo(props) {
    if(props.Dados.PendenteIndicacao === true) {
        return(
        <div>
            <div className="description" >
                <div className="primary-info">{props.Dados.Titulo}</div>
                <div>{props.Dados.SubTitulo}</div>
            </div>
        </div>);
    }
    else {
        return(
        <div>
            <div className="description" >
                <div className="primary-info">{props.Dados.Titulo}</div>
                <div>{props.Dados.SubTitulo}</div>
            </div>
            <div className="bottom-info">
                <span className="item">
                    Notificações:
                    <span className="value">{props.Dados.Notificoes}</span>
                </span>
                <span className="item">
                    Infrações:
                    <span className="value">{props.Dados.Infracoes}</span>
        </span>
            </div>
        </div>);
    }
}

export class CardMulta extends Component {

    handleClick = () => {
        if(this.props.mobile)
            this.props.history.push("/Multas");
    }

    render() {
        if (this.props.mobile === true) {
            return (
                <section className="wrapper-card">
                    <Link to="/Multas">
                    <div className={this.props.cabecalho.Cor}>
                        <div className="title">
                            <span className='icon icon-bill2'></span>
                            <span className="text">Multas</span>
                            <span className="arrow"><img src={arrow}/></span>
                        </div>
                        <Conteudo Dados={this.props.cabecalho}/>
                    </div>
                    </Link>
              </section>
            );
        } else {
            return (
                <section className="wrapper-card" >
                    <div className={this.props.cabecalho.Cor}>
                        <div className="title">
                            <span className='icon icon-bill2'></span>
                            <span className="text">Multas</span>
                        </div>
                        <Conteudo Dados={this.props.cabecalho}/>
                    </div>
              </section>
            );
        }
    
    }
}

const mapStateToProps = (state) => {
    return {
      multas: state.multa.multas,
      erro: state.multa.erro,
      limitTo: state.multa.limitTo,
      placa: state.menu.placa,
    };
  };

export default connect(mapStateToProps, {
    listaMulta,
  })(CardMulta);
  