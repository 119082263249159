import React, { Component } from "react";
import { connect } from "react-redux";
import DatePicker from "react-datepicker";
import moment from "moment";
import formatDatePickers from "../../../helpers/formatDatePickers";
import { salvarAgendamento } from "./AgendamentoActions";
import Loading from "../../../components/Loading";
import ModalMensagem from "../../../components/ModalMensagem/ModalMensagem";
import Tracking from "../../../hooks/Tracking";

const GACATERGORIAAGEDAMENTO = "agendamento_servico";

class PainelData extends Component {
  constructor(props) {
    super(props);

    this.state = {
      includedTimes: null,
      msgData: false,
      msgHora: false,

      model: {
        kmAtual: null,
        condutor: null,
        contato: null,
        celcontato: null,
        cidade: null,
        servicos: null,
        fornecedor: null,
        shiftList: null,
        date: null,
        time: null,
      },
    };
  }

  setReadOnly(e, selectedTime) {
    this.setScrollTimePicker(selectedTime);
  }

  setScrollTimePicker(selectedTime) {
    setTimeout(function () {
      if (
        document.querySelector(".react-datepicker__time-list") !== null &&
        selectedTime === null
      ) {
        document.querySelector(".react-datepicker__time-list").scrollTo(0, 0);
      }
    }, 100);
  }

  validarDataHora(data, hora, sscomplementar) {
    if (sscomplementar === true) return true;

    var agora = moment(new Date());
    var msgData = false;
    var msgHora = false;

    if (!data || data._d < agora.startOf("day")._d) {
      msgData = true;
    }
    if (!hora || (hora._d === agora._d && hora.hour() < agora.hour())) {
      msgHora = true;
    }

    if (msgData || msgHora) {
      var model = this.state.model;
      model.date = null;
      model.time = null;
      this.setState({ msgData, msgHora, model });
      return false;
    }
    return true;
  }

  OnHandleInserir() {
    let condutor = this.props.model.condutor;
    let contato = this.props.model.contato;
    let celcontato = this.props.model.celcontato;
    let fornecedor = this.props.model.fornecedor;
    let cidade = this.props.model.cidade;
    let servicos = this.props.model.servicos;
    let data = this.props.model.date;
    let hora = this.props.model.time;
    let kmatual = this.props.model.kmAtual;
    let sscomplementar = this.props.model.sscomplementar;

    var dataOk = this.validarDataHora(data, hora, sscomplementar);
    if (!dataOk) return;

    data.set({
      hour: hora.hour(),
      minute: hora.minute(),
      second: 0,
      millisecond: 0,
    });

    let objetoInserir = {
      AttendanceTypeCode: "08",
      Driver: condutor,
      ContactName: contato,
      ContactPhoneLandline: celcontato,
      Email: "",
      ContactCellPhone: celcontato,
      CellMessage: 1,
      AttendanceValidated: true,
      ValidateTowTruckIntegration: false,
      SupplierCGC: fornecedor,
      CityId: cidade && cidade.length > 0 ? cidade[0].mIdCidadeField : null,
      Plate: localStorage.getItem("placa-atual"),
      User: "",
      AttendanceTreeIds: servicos,
      SelectedDate: data.format("DD/MM/YYYY HH:mm:ss"),
      CurrentKm: kmatual,
      Sscomplementar: sscomplementar,
    };

    this.props.salvarAgendamento(objetoInserir);
  }

  componentDidMount() {
    formatDatePickers();
  }

  componentDidUpdate(prevProps) {
    if (this.props.loading !== prevProps.loading) {
      this.setState({ loading: this.props.loading });
    }
    if (this.props.isHoliday !== prevProps.isHoliday) {
      this.setState();
    }
  }

  OnModalToggle() {
    document.location.href = "/";
  }

  render() {
    var iOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
    if (!this.props.fornecedor || !this.props.shiftList) return null;

    return (
      <div>
        {this.state.loading ? <Loading /> : null}

        {this.state.msgData || this.state.msgHora ? (
          <ModalMensagem
            tipoModalMensagem="warning"
            icon="icon-x-circle"
            textoBotao="OK"
            mensagemPrincipal="Favor inserir data e hora do agendamento."
            mensagemSecondaria=""
          />
        ) : null}
        {(this.state.msgData = this.state.msgHora = false)}

        {this.props.erro ? (
          <ModalMensagem
            tipoModalMensagem="warning"
            icon="icon-x-circle"
            mensagemPrincipal={this.props.erro.message}
            mensagemSecundaria=""
            textoBotao="OK"
            OnToggled={this.OnModalToggle}
          />
        ) : null}

        {this.props.model.isHoliday === true ? (
          <ModalMensagem
            tipoModalMensagem="warning"
            icon="icon-x-circle"
            mensagemPrincipal="A data escolhida é um feriado. Selecione outra data."
            mensagemSecundaria=""
            textoBotao="OK"
            OnToggled={this.props.OnModalHolidayToggle}
          />
        ) : null}

        <section className="wrapperfornecedores-data-local">
          <h2>Escolha o melhor dia e horário</h2>
          <h3 className="subtitle">
            As datas e horários disponiveis são relacionados ao fornecedor.
          </h3>
          <div className="container-form-date-time">
            <div className="wrapper-input">
              <div className="group-input">
                <DatePicker
                  selected={this.props.model.date}
                  dateFormat="DD/MM/YYYY"
                  locale="pt-br"
                  onChange={(e) => {
                    this.props.OnDateChange(e);
                    Tracking.sendEvent({
                      category: GACATERGORIAAGEDAMENTO,
                      action: "AGENDAMENTO_INFORMANDO_DATA",
                      label: "Informando Data Agendamento",
                    });
                  }}
                  className="input date"
                  minDate={
                    this.props.model.minDate !== null
                      ? this.props.model.minDate
                      : moment()
                  }
                  maxDate={moment().add(30, "days")}
                  excludeDates={this.props.model.excludedDates}
                  required
                  disabled={this.props.model.sscomplementar === true}
                  id={
                    this.props.model.sscomplementar === true ? "disabled" : null
                  }
                />
              </div>
            </div>
            <div className="wrapper-input">
              <div className="group-input">
                <DatePicker
                  selected={this.props.model.time}
                  onChange={(e) => this.props.OnTimeChange(e)}
                  onFocus={(e) => this.setReadOnly(e, this.props.model.time)}
                  showTimeSelect
                  showTimeSelectOnly
                  includeTimes={this.props.includedTimes}
                  timeFormat="HH:mm"
                  dateFormat="LT"
                  timeCaption="Hora"
                  className="input time"
                  timeIntervals={15}
                  required
                  disabled={
                    !this.props.model.date ||
                    this.props.model.sscomplementar === true
                  }
                  id={
                    !this.props.model.date ||
                    this.props.model.sscomplementar === true
                      ? "disabled"
                      : null
                  }
                />
              </div>
            </div>
          </div>
        </section>

        <span className="wrapper-button-agendamento">
          <button
            disabled={
              this.props.model.date && this.props.model.time ? null : "disabled"
            }
            onClick={() => this.OnHandleInserir()}
            className="button gtm-agendamentos-concluir-agendamento"
          >
            Concluir Agendamento{" "}
          </button>
        </span>
        {formatDatePickers()}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    placa: state.menu.placa,
    loading: state.Agendamento.loading,
    erro: state.Agendamento.erro,
  };
};

export default connect(mapStateToProps, {
  salvarAgendamento,
})(PainelData);
