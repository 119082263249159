import React, { Component } from 'react';
import { Link } from 'react-router-dom';

export default class ReaEtapaX extends Component {

    constructor() {
        super();
        this.handleChangeTipoEvento = this.handleChangeTipoEvento.bind(this);
        this.handleAvancar = this.handleAvancar.bind(this);
    }


    componentDidMount() {
        window.scrollTo(0, 0);
    }

    handleAvancar() {
        if (this.props.modelo.tipoEvento === "furto") {
            this.props.onIrParaEtapa(2);
        } else if(this.props.modelo.tipoEvento === "acidente"){
            this.props.onIrParaEtapa(5);
        } else{
            this.props.onIrParaEtapa(3);
        }
    }

    handleChangeTipoEvento = (event) => {
        if (event.target.value !== null && event.target.value !== "") {
            this.props.onChangeTipoEvento(event.target.value);
        }
    }

    render() {
        return (
            <React.Fragment>
                <div className="row w-xl-66">
                    <div className="form-intro col-12">
                        <p className="text-justify">Informe os dados abaixo para preencher o relatório:</p>
                     </div>

                    <div className="col-12">
                        <h2 className="form-title">Tipo de evento/avaria</h2>
                        <div className="custom-radio">
                            <input type="radio" id="ev1" name="tipoEvento" className="custom-control-input" value="acidente"
                                onChange={this.handleChangeTipoEvento} checked={this.props.modelo.tipoEvento === "acidente"} />
                            <label className="custom-control-label" htmlFor="ev1">Acidente</label>
                        </div>
                        <div className="custom-radio">
                            <input type="radio" id="ev3" name="tipoEvento" className="custom-control-input" value="furto"
                                onChange={this.handleChangeTipoEvento} checked={this.props.modelo.tipoEvento === "furto"} />
                            <label className="custom-control-label" htmlFor="ev3">Furto ou Roubo</label>
                        </div>
                        <div className="custom-radio">
                            <input type="radio" id="ev4" name="tipoEvento" className="custom-control-input" value="incendio"
                                onChange={this.handleChangeTipoEvento} checked={this.props.modelo.tipoEvento === "incendio"} />
                            <label className="custom-control-label" htmlFor="ev4">Incêndio</label>
                        </div>
                    </div>

                    <div className="w-100"></div>
                    <div className="ml-auto mt-3 col-md-6">
                        <div className="row">
                            <div className="col">
                                <Link to={{ pathname: "/Avarias" }} className="text-decoration-none">
                                    <button className="button">Voltar</button>
                                </Link>
                            </div>
                            <div className="col">
                                <button className="button" disabled={this.props.modelo.tipoEvento === ""} onClick={this.handleAvancar}>Continuar</button>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}