import React, { useState, useEffect } from "react";
import LayoutContainer from "../../components/novo-login/Layout/LayoutContainer";
import { LoginEmailContainer } from "../../components/novo-login/LoginEmail/LoginEmail";
import ErrorBoundary from "../../components/ErrorBoundary";
import Tracking from "../../hooks/Tracking";
import { isMobile } from "react-device-detect";
import "../../functions/newCookies";
import { FooterContainer } from "../../components/novo-login/Layout/styles";
import { ManutencaoContainer } from "../../components/Manutencao/Manutencao";
import { fetchRemoteConfig } from "../../remote_config/remote_config_call";
import { defaultConfig } from "../../remote_config/remote_keys";

export default function LoginEmailPage() {
  const [isOnMaintenance, setIsOnMaintenance] = useState(false);

  useEffect(() => {
    const checkMaintenanceMode = async () => {
      try {
        const maintenanceMode = await fetchRemoteConfig(
          "maintenance_mode",
          `${defaultConfig.maintenanceMode}`,
          "bool"
        );
        setIsOnMaintenance(maintenanceMode);
      } catch (error) {
        console.error("Error fetching maintenance mode:", error);
        setIsOnMaintenance(false);
      }
    };

    checkMaintenanceMode();
  }, []);

  useEffect(() => {
    Tracking.sendPageview("Login");
  }, []);

  return (
    <React.Fragment>
      {isOnMaintenance === true ? (
        <ManutencaoContainer />
      ) : (
        <LayoutContainer>
          <ErrorBoundary>
            <LoginEmailContainer />
          </ErrorBoundary>

          {!isMobile && (
            <FooterContainer>
              <span
                style={{ cursor: "pointer" }}
                onClick={() => showConsentPreferencesPopup()}
              >
                Configurações de cookies
              </span>
            </FooterContainer>
          )}
        </LayoutContainer>
      )}
    </React.Fragment>
  );
}
