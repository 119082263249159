import React, { useState } from 'react';
import { Grid, makeStyles } from '@material-ui/core';
import ButtonUploadImage from './ButtonUploadImage';

const useStyles = makeStyles(theme => ({
  titulo: {
    fontFamily: "Metropolis-semiBold",
    fontSize: "12px"
  },
  subtitulo: {
    fontFamily: "Metropolis",
    color: '#b8bac1'
  }
}));

function EnvioTermo(props) {
  const classes = useStyles();
  
  return (

    <React.Fragment>
      {
            <div>
              <Grid container>
                <Grid item sm={12}>
                  <p className="icon icon-info" style={{ color: "#555555", marginTop: 15 }}>
                    <span className={classes.titulo}> ENVIO DO TERMO DE RESPONSABILIDADE</span></p>
                </Grid>
              </Grid>
              <Grid container justify="center" spacing={1}>
                <Grid item className={classes.subtitulo} sm={12} style={{ marginTop: 10 }}>
                  <span>Tire uma foto ou carregue o arquivo do termo. Carregue o termo assinado.</span>
                  
                  <ButtonUploadImage 
                    title='Adicionar Termo' 
                    image={props.imagemTermo} 
                    handleUpload={props.onChangeImagemTermo}
                    handleClearState={props.onLimparImagemTermo} />

                  <Grid item className={classes.subtitulo} sm={12} xs={12}>
                    <span>Arquivos permitidos: PNG, JPG, JPEG, e PDF</span>
                    <br />
                    <span>(Tamanho máximo 3 MB)</span>
                  </Grid>
                </Grid>
              </Grid>
            </div>
      }
    </React.Fragment>
  )
}

export default EnvioTermo;