import React, { Component } from "react";
import { connect } from "react-redux";
import LayoutInternal from "../../components/LayoutInternal";
import Historico from "./Historico";
import CabecalhoKm from "./Cabecalho/CabecalhoComponent";
import "../../styles/css/tela-editarkm.css";
import ModalMensagem from "../../components/ModalMensagem/ModalMensagem";
import { buscarCabecalho } from "./Cabecalho/CabecalhoActions";
import formatDatePickers from "../../helpers/formatDatePickers";
import Loading from "../../components/Loading";
import { listaKM, verMais, excluirKm } from "./EditarKMActions";
import Tracking from "../../hooks/Tracking";
import { HistoricoContainer } from "./styles";
import ErrorBoundary from "../../components/ErrorBoundary";

class ConfirmacaoExcluir extends Component {
  constructor(props) {
    super(props);

    this.handleExcluir = this.handleExcluir.bind(this);
  }

  handleExcluir(resposta) {
    if (resposta === true) {
      this.props.OnExcluirKm();
    }
  }

  render() {
    if (this.props.confirm) {
      return (
        <ModalMensagem
          tipoModalMensagem=""
          icon="icon-check-circle2"
          mensagemPrincipal="Deseja excluir esse registro?"
          mensagemSecundaria=""
          textoBotao="Sim"
          secondButton={true}
          textoSecondButton="Não"
          OnToggled={this.handleExcluir}
        />
      );
    } else {
      return null;
    }
  }
}

class Mensagens extends Component {
  constructor(props) {
    super(props);

    this.handleAtualizar = this.handleAtualizar.bind(this);
  }

  handleAtualizar() {
    this.props.OnListarKm(localStorage.getItem("placa-atual"));
    this.props.OnBuscarCabecalho(localStorage.getItem("placa-atual"));
  }

  render() {
    if (this.props.erro) {
      return (
        <ModalMensagem
          tipoModalMensagem="warning"
          icon="icon-x-circle"
          mensagemPrincipal={this.props.erro.message}
          mensagemSecundaria=""
          textoBotao="OK"
        />
      );
    }

    if (this.props.resposta) {
      if (this.props.resposta.Estado !== 0) {
        return (
          <ModalMensagem
            tipoModalMensagem="warning"
            icon="icon-x-circle"
            mensagemPrincipal={this.props.resposta.Mensagem}
            mensagemSecundaria=""
            textoBotao="OK"
          />
        );
      } else {
        return (
          <ModalMensagem
            tipoModalMensagem=""
            icon="icon-check-circle2"
            mensagemPrincipal="Registro excluído com sucesso"
            mensagemSecundaria=""
            textoBotao="OK"
            OnToggled={this.handleAtualizar}
          />
        );
      }
    }
    return null;
  }
}

class VerMais extends Component {
  render() {
    if (this.props.contador && this.props.total > 0) {
      if (this.props.contador <= this.props.total) {
        return (
          <div
            className="button-light-icon"
            onClick={() => this.props.vermais(this.props.contador)}
          >
            <span>CARREGAR MAIS</span>
          </div>
        );
      }
    }
    return null;
  }
}

class EditarKM extends Component {
  constructor() {
    super();

    this.state = {
      hasMounted: false,
      exclusao: {
        confirm: false,
        numero: null,
        data: null,
        terceiro: null,
      },
    };
  }

  componentDidMount() {
    Tracking.sendPageview("Editar KM");

    this.setState({ hasMounted: true });
    if (localStorage.getItem("placa-atual")) this.props.listaKM(localStorage.getItem("placa-atual"));
  }

  confirmarExclusao = (n, d, t) => {
    this.setState({
      exclusao: {
        confirm: true,
        numero: n,
        data: d,
        terceiro: t,
      },
    });
  };

  render() {
    let confirm = false;
    if (this.state.exclusao.confirm) {
      confirm = this.state.exclusao.confirm;
      this.state.exclusao.confirm = false;
    }

    return (
      <div>
        {this.props.loadingExluirKM ? <Loading /> : null}
          <ConfirmacaoExcluir
            confirm={confirm}
            OnExcluirKm={() =>
              this.props.excluirKm(
                this.state.exclusao.numero,
                this.state.exclusao.data,
                this.state.exclusao.terceiro
              )
            }
          />
          <Mensagens
            erro={this.props.erro}
            resposta={this.props.resposta}
            OnListarKm={(placa) => this.props.listaKM(placa)}
            placa={localStorage.getItem("placa-atual")}
            OnBuscarCabecalho={(placa) => this.props.buscarCabecalho(placa)}
          />

        <LayoutInternal title="Atualizar KM">
          <main className="background-gray">
            <div className="content-primary">
              <section className="edit-km">
                <h1>Atualizar Km</h1>
                <ErrorBoundary texto={"ao buscar informações"}>
                  <CabecalhoKm />
                </ErrorBoundary>
              </section>
            </div>

            <HistoricoContainer>
              <ErrorBoundary texto={"ao buscar informações"}>
                <Historico
                  OnExcluir={(n, d, t) => this.confirmarExclusao(n, d, t)}
                  kms={
                    this.props.kms
                      ? this.props.kms.slice(0, this.props.contador)
                      : {}
                  }
                />
                </ErrorBoundary>
                <VerMais
                  contador={this.props.contador}
                  total={this.props.kms ? this.props.kms.length : 0}
                  vermais={this.props.verMais}
                />
              </HistoricoContainer>
            
          </main>
        </LayoutInternal>
        {this.state.hasMounted ? formatDatePickers() : null}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    kms: state.KM.kms,
    contador: state.KM.contador,
    erro: state.KM.erro,
    resposta: state.KM.resposta,
    loadingExluirKM: state.KM.loading,
    placa: state.menu.placa,
  };
};

export default connect(mapStateToProps, {
  buscarCabecalho,
  listaKM,
  verMais,
  excluirKm,
})(EditarKM);
