import React,{ Component } from 'react';
import * as Cookie from '../../functions/Cookies';
import { NomeCookieSessaoRea} from '../../config/consts';
import { Redirect } from 'react-router-dom';

export default class ReaFinalizado extends Component{
    render() {
        Cookie.set(NomeCookieSessaoRea,'', -1, true);
        return  (
            <Redirect to={"/home"} />    
        );
    }
}