import React, { Component } from 'react';

export default class ModalMensagem extends Component {
    constructor(props) {
        super(props);

        this.state = {
            modalOpened: true,
            destroy: false
        };

        this.modalToggle = this.modalToggle.bind(this);
    }

    modalToggle(resposta) {
        this.setState({
            modalOpened: !this.state.modalOpened,
            destroy: true
        });

        if(this.props.OnToggled)
            this.props.OnToggled(resposta);
    }

    render() {
        return (            
            <div className={`modal-backdrop ${this.state.modalOpened ? 'modal-open' : 'modal-close'}`}>
                <div className={`modal-mensagem ${this.props.tipoModalMensagem}`}>
                    <span className="icon icon icon-x" onClick={() => this.modalToggle(false)}></span>
                    <div className="modal-mensagem-body">
                        <span className={`icon ${this.props.icon}`}></span>
                        <div className="wrapper-mensagem">
                            <div className="mensagem-principal">
                                {this.props.imagem !== null ? 
                                <img src ={this.props.imagem} className="imgBanner"/>
                                : null
                                }
                                {this.props.mensagemPrincipal}
                            </div>
                            <div className="mensagem-secondaria">
                               {this.props.mensagemSecondaria}
                            </div>
                        </div>
                    </div>
                    <div className="modal-bottom">
                        <button className="button" onClick={() => this.modalToggle(true)}>{this.props.textoBotao}</button>
                    </div>
                    {
                        this.props.secondButton ? (<div className="modal-bottom">
                                                      <button className="button" onClick={() => this.modalToggle(false)}>{this.props.textoSecondButton}</button>
                                                   </div>) : null
                    }
                </div>
            </div>
        )
    }
}