import React, { Component } from 'react';
import ModalMensagem from '../../../components/ModalMensagem/ModalMensagem';

export default class ModalSSComplementar extends Component {
    constructor(props) {
        super(props);

        this.state = { index: 0, continue: false }

        this.onToggled = this.onToggled.bind(this);
    }

    onToggled(resposta) {
        if (resposta === false) {
            if (this.props.ssMae.DataEntradaReal) { //possui data de entrada real preenchida
                this.props.onToggle(true);//cria uma ss complementar
            }
            else {//possui data de entrada real nula
                if (this.state.index === 0) {
                    this.props.onToggle(false);//cria uma nova ss
                }
                else {
                    this.props.onToggle(true);//cria uma ss complementar
                }
            };
        }
        else {
            if (this.props.ssMae.DataEntradaReal) { //possui data de entrada real preenchida
                this.props.onToggle(false);//cria uma nova ss
            }
            else {
                if (this.props.ssMae.Textos.length > this.state.index + 1) {
                    let i = this.state.index + 1;
                    this.setState({ index: i, continue: true });
                }
                else {
                    this.props.onToggle(false);//cria uma nova ss
                    return;
                }
            }
        }
    }

    componentDidUpdate() {
        //força update do modal
        if (this.state.continue === true) {
            this.setState({ continue: false });
        }
    }

    render() {
        if (!this.props.ssMae || this.props.ssMae.PossuiMae === false || !this.props.ssMae.Textos)
            return null;

        return (<ModalMensagem tipoModalMensagem='warning' icon='icon-x-circle' mensagemPrincipal={this.props.ssMae.Textos[this.state.index]} mensagemSecundaria=''
                    textoBotao="Sim" secondButton={true} textoSecondButton='Não' OnToggled={this.onToggled} />)
    }
}