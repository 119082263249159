import {
    LISTAR_AVARIAS,
    LISTAR_ROUBOS,
    VER_MAIS,
    BUSCAR_ULTIMO_ROUBO_PENDENTE,
    ENVIAR_DOC,
    CRIAR_MODAL,
    ERRO_SINISTRO,
    ENVIARDOC_LOADING,
    ENVIARDOC_LOADED
} from './SinistroTypes'

const INITIAL_STATE = {
    avarias: [],
    roubos: [],
    ultimoroubo: null,
    contador: 3, //exibe os 3 primeiros registros
    modal: null,
    erro: null,
    resposta: null,
    loading: false
}

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case LISTAR_AVARIAS:
            return { ...state, avarias: action.payload, roubos: null, erro: null, resposta: null, modal: null };
        case LISTAR_ROUBOS:
            return { ...state, roubos: action.payload, avarias: [], erro: null, resposta: null, modal: null };
        case BUSCAR_ULTIMO_ROUBO_PENDENTE:
            return { ...state, ultimoroubo: action.payload, avarias: [], erro: null, resposta: null, modal: null };
        case VER_MAIS:
            return { ...state, contador: action.payload, erro: null, resposta: null, modal: null };
        case ENVIAR_DOC:
            return { ...state, resposta: action.payload, erro: null, modal: null };
        case CRIAR_MODAL:
            return { ...state, modal: action.payload, erro: null, resposta: null };
        case ERRO_SINISTRO:
            return { ...state, erro: action.payload, resposta: null, modal: null };
        case ENVIARDOC_LOADING:
            return { ...state, loading: true };
        case ENVIARDOC_LOADED:
            return { ...state, loading: false };
        default:
            return state;
    }
}