import {
  INSERIR,
  CABECALHO,
  ERRO_CABECALHO,
  INSERIRKM_LOADING,
  INSERIRKM_LOADED,
} from "./CabecalhoTypes";
import { HttpGet, HttpPostNew } from "../../../functions/default";
import { DESLOGAR } from "../../Login/LoginTypes";
import qs from "qs";
import Tracking from "../../../hooks/Tracking";

export const buscarCabecalho = (placa) => {
  return (dispatch) => {
    if (navigator.onLine) {
      HttpGet(`/BuscarCabecalhoKm?placa=${placa}`, null, null)
        .then(function (result) {
          dispatch({ type: CABECALHO, payload: result.data });
          Tracking.sendEvent({
            category: "atualizar_km",
            action: "ATUALIZAR_KM_BUSCA_CABECALHO_SUCESSO",
            label: "Sucesso ao buscar cabecalho de atualização de km.",
          });
        })
        .catch(function (erro) {
          Tracking.sendEvent({
            category: "atualizar_km",
            action: "ATUALIZAR_KM_BUSCA_CABECALHO_ERRO",
            label: "Erro ao buscar cabecalho de atualização de km.",
          });
          if (
            (erro.response && erro.response.status === 401) ||
            (erro.response && erro.response.status === 403)
          )
            dispatch({
              type: DESLOGAR,
              payload: { message: "Usuário não autenticado." },
            });
          else
            dispatch({
              type: ERRO_CABECALHO,
              payload: {
                message:
                  "Ocorreu um erro ao buscar os dados, tente novamente mais tarde.",
              },
            });
        });
    }
  };
};

export const limparMensageErro = () => {
  return (dispatch) => {
    dispatch({ type: ERRO_CABECALHO, payload: null });
  };
};

export const inserirKm = (
  placa,
  medicao,
  data,
  insercaoAcimaConfirmado
) => {
  return (dispatch) => {
    if (navigator.onLine) {
      dispatch({ type: INSERIRKM_LOADING });
      const objeto = {};
      objeto["Km"] = medicao;
      objeto["DataMedicao"] = data;
      objeto["InsercaoAcimaAlertaConfirmada"] = insercaoAcimaConfirmado;

      HttpPostNew(`/InserirKm?placa=${placa}`, objeto)
        .then(function (result) {
          dispatch({ type: INSERIR, payload: result.data });
          dispatch({ type: INSERIRKM_LOADED });
          Tracking.sendEvent({
            category: "atualizar_km",
            action: "ATUALIZAR_KM_INSERIR_KM_SUCESSO",
            label: "Sucesso ao inserir novo km.",
          });
        })
        .catch(function (erro) {
          Tracking.sendEvent({
            category: "atualizar_km",
            action: "ATUALIZAR_KM_INSERIR_KM_ERRO",
            label: "Erro ao inserir novo km.",
          });
          dispatch({ type: INSERIRKM_LOADED });
          if (
            (erro.response && erro.response.status === 401) ||
            (erro.response && erro.response.status === 403)
          ) {
            dispatch({
              type: DESLOGAR,
              payload: { message: "Usuário não autenticado." },
            });
          } else if (
            erro.response.status === 400 &&
            erro.response.data.Message ==
              "Já existe quilometragem cadastrada para esta data."
          ) {
            dispatch({
              type: ERRO_CABECALHO,
              payload: {
                message:
                  "Você já inseriu uma quilometragem para esta data. Por favor, selecione outra data.",
              },
            });
          } else if (
            erro.response.status === 400 &&
            erro.response.data.Message.includes(
              "A quilometragem para esta data deve ser maior que"
            )
          ) {
            dispatch({
              type: ERRO_CABECALHO,
              payload: {
                message:
                  "Você não pode inserir uma quilometragem menor do que a última registrada.",
              },
            });
          } else if (
            erro.response.status === 400 &&
            erro.response.data.Message.includes("A sua última medição foi")
          ) {
            dispatch({
              type: ERRO_CABECALHO,
              payload: {
                message: erro.response.data.Message,
              },
            });
          } else {
            dispatch({
              type: ERRO_CABECALHO,
              payload: {
                message:
                  "Ocorreu um erro ao salvar os dados, tente novamente mais tarde.",
              },
            });
          }
        });
    } else {
      dispatch({
        type: ERRO_CABECALHO,
        payload: {
          message: "Para prosseguir, conecte seu celular à internet.",
        },
      });
    }
  };
};

export const DispatchErro = (mensagem) => {
  return (dispatch) => {
    dispatch({ type: ERRO_CABECALHO, payload: { message: mensagem } });
  };
};
