import { HttpPost, HttpGet } from '../../functions/default';
import {
    SET_MOSTRAR_TUTORIAL,
    SET_TUTORIAL_JA_FOI_EXIBIDO
} from './TutorialTypes';

export const RegistrarAcesso = () => {
    return () => { HttpPost('/RegistrarAcesso', null, null) }
}

export const RegistrarVisualizacao = () => {
    return () => { HttpPost('/RegistrarVisualizacao', null, null) }
}

export const RegistrarRecusa = () => {
    return () => { HttpPost('/RegistrarRecusa', null, null) }
}

export const RegistrarNaoExibeNovamente = () => {
    return () => { HttpPost('/RegistrarNaoExibeNovamente', null, null) }
}

export const TutorialJaFoiExibido = () => {
    return (dispatch) => {
        dispatch({ type: SET_TUTORIAL_JA_FOI_EXIBIDO, payload: true })
    }
}