import React, { Component } from 'react';

export default class Checkbox extends Component {

    onCheckboxClick = (element) => {
        if (this.props.onCheckboxClick) 
            this.props.onCheckboxClick(this.props.label, element.target, this.props.id, this.props.data);
    }

    render() {
        return (
            <label className="label--checkbox">
                <input type="checkbox" className="checkbox" id={this.props.id} onClick={this.onCheckboxClick} />{this.props.label}
            </label>
        );
    }
}