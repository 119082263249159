import React from "react";
import { isAndroid, isIOS } from "react-device-detect";

import anuncio from "./images/anuncio.png"; // with import
import localiza_logo from "./images/localiza_logo.png"; // with import
import { Title, Text, Logo, Avatar, VStack, Container, Button } from "./styles";

function ModalForcarAtualizacao() {
  function handleClick() {
    if (isAndroid) {
      window.location.href =
        "https://play.google.com/store/apps/details?id=com.meucarroapp";
      return null;
    }
    if (isIOS) {
      window.location.href =
        "https://apps.apple.com/br/app/meucarro/id1571136021";
      return null;
    }
    return null;
  }
  return (
    <Container>
      <Logo src={localiza_logo} />
      <VStack>
        <Avatar src={anuncio} />
        <Title>Quer uma novidade?</Title>
        <Text>
          Lançamos o novo app do <strong>#meucarro.</strong> Agora ele é mais
          rápido, mais eficiente e vai receber atualizações constantes para
          tornar a sua experiência cada vez melhor.
        </Text>
      </VStack>
      <Button onClick={handleClick}>baixar novo app</Button>
    </Container>
  );
}

export default ModalForcarAtualizacao;
