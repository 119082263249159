import React, { Component } from 'react';
import MaskedInput from 'react-text-mask';
import insertLabelDatePicker from '../../helpers/formatDatePickers';
import moment from 'moment';
import { validaEmail, somenteNumerosFromString, dataValida, validarCpf } from '../../functions/manutencaoGeralValidacoes';
import * as Cookie from '../../functions/Cookies';
import { NomeCookiePlaca } from '../../config/consts';

export default class IdentificacaoCondutorCarro extends Component {

    constructor(props) {
        super(props);
        this.handleVoltar = this.handleVoltar.bind(this);
        this.handleAvancar = this.handleAvancar.bind(this);
        this.handleValidacao = this.handleValidacao.bind(this);
        var placa = localStorage.getItem('placa-atual')
        this.props.buscarDadosPlaca(placa);
        this.state = {
            erroValidacoes: {
                cnh: false,
                categoria: false,
                validade: false,
                nomeCondutor: false,
                emailCondutor: false,
                dataNascimento: false,
                cpf: false,
            }
        };
    }

    handleValidacao() {
        return (
            this.state.erroValidacoes.cnh === false &&
            this.state.erroValidacoes.categoria === false &&
            this.state.erroValidacoes.validade === false &&
            this.state.erroValidacoes.nomeCondutor === false &&
            this.state.erroValidacoes.emailCondutor === false &&
            this.state.erroValidacoes.dataNascimento === false &&
            this.state.erroValidacoes.cpf === false
        );
    }

    handleAvancar() {
   
            if (this.props.modelo.nomeCondutor === "") {
                let newState = { ...this.state }
                newState.erroValidacoes.nomeCondutor = true;
                this.setState(newState);
            } else {
                let newState = { ...this.state }
                newState.erroValidacoes.nomeCondutor = false;
                this.setState(newState);
            }

            if (this.props.modelo.emailCondutor === "") {
                let newState = { ...this.state }
                newState.erroValidacoes.emailCondutor = true;
                this.setState(newState);
            }

         if (this.props.modelo.terceiroEnvolvido === "sim") {
            
            if (!validarCpf(this.props.modelo.cpfCliente)) {
                let newState = { ...this.state }
                newState.erroValidacoes.cpf = true;
                this.setState(newState);
            } else {
                let newState = { ...this.state }
                newState.erroValidacoes.cpf = false;
                this.setState(newState);
            }
    
            if (this.props.modelo.dataNascimentoCliente === "" || !moment.isMoment(this.props.modelo.dataNascimentoCliente) ) {
                let newState = { ...this.state }
                newState.erroValidacoes.dataNascimento = true;
                this.setState(newState);
            } else if( !this.props.modelo.dataNascimentoCliente.isValid() || this.props.modelo.dataNascimentoCliente > moment() ){
                let newState = { ...this.state }
                newState.erroValidacoes.dataNascimento = true;
                this.setState(newState);
            }
            else
            {
                let newState = { ...this.state }
                newState.erroValidacoes.dataNascimento = false;
                this.setState(newState);
            }
            
            if (this.props.modelo.cnh === "") {
                let newState = { ...this.state }
                newState.erroValidacoes.cnh = true;
                this.setState(newState);
            } else {
                let newState = { ...this.state }
                newState.erroValidacoes.cnh = false;
                this.setState(newState);
            }

            if (this.props.modelo.categoriaCnh === "") {
                let newState = { ...this.state }
                newState.erroValidacoes.categoria = true;
                this.setState(newState);
            } else {
                let newState = { ...this.state }
                newState.erroValidacoes.categoria = false;
                this.setState(newState);
            }

            if (this.props.modelo.validadeCnh === "" || !moment.isMoment(this.props.modelo.validadeCnh) ) {
                let newState = { ...this.state }
                newState.erroValidacoes.validade = true;
                this.setState(newState);
            } else if( !this.props.modelo.validadeCnh.isValid() ){
                let newState = { ...this.state }
                newState.erroValidacoes.validade = true;
                this.setState(newState);
            }
            else
            {
                let newState = { ...this.state }
                newState.erroValidacoes.validade = false;
                this.setState(newState);
            }
        } 

        if (this.handleValidacao()) {
            this.props.onIrParaEtapa(4);
        }
    }

    handleVoltar() {
        if (this.props.modelo.tipoEvento === "furto") {
            this.handleLimpaCampos();
            this.props.onIrParaEtapa(2);
        }else if (this.props.modelo.tipoEvento === "acidente") {
            this.handleLimpaCampos();
            this.props.onIrParaEtapa(5);
        }else{
            this.handleLimpaCampos();
            this.props.onIrParaEtapa(1);    
        }
    }

    componentDidMount() {
        insertLabelDatePicker();
        window.scrollTo(0, 0);
    }

    handleAlteraCpfCliente = (event) => {
        if (event.target.value !== null) {
            this.props.onChangeCpfCliente(event.target.value);

            let cpfSomenteNumeros = somenteNumerosFromString(event.target.value);
            let validacaoCpf = validarCpf(event.target.value);

            if (cpfSomenteNumeros.length >= 11) {
                if (validacaoCpf) {
                    let newState = { ...this.state }
                    newState.erroValidacoes.cpf = false;
                    this.setState(newState);
                } else {
                    let newState = { ...this.state }
                    newState.erroValidacoes.cpf = true;
                    this.setState(newState);
                }
            }
        }
    }

    handleAlteraDataNascimento = (event) => {
        this.props.onChangeDataNascimento(event.target.value);

        if (somenteNumerosFromString(event.target.value).length === 8) {
            if(dataValida(event.target.value)){
                let data = moment(event.target.value, ["DD/MM/YYYY"], 'pt-br');
                this.props.onChangeDataNascimento(data);

                if (data > moment()) {
                    let newState = { ...this.state }
                    newState.erroValidacoes.dataNascimento = true;
                    this.setState(newState);
                } else {
                    let newState = { ...this.state }
                    newState.erroValidacoes.dataNascimento = false;
                    this.setState(newState);
                }
            } else {
                let newState = { ...this.state }
                newState.erroValidacoes.dataNascimento = true;
                this.setState(newState);
            }
        }
    }

    handleAlteraNomeCondutor = (event) => {
        if (event.target.value !== null) {
            this.props.onChangeNomeCondutor(event.target.value);
            let newState = { ...this.state }
            newState.erroValidacoes.nomeCondutor = false;
            this.setState(newState);
        }
    }

    handleAlteraEmailCondutor = (event) => {
        if (event.target.value !== null) {
            this.props.onChangeEmailCondutor(event.target.value);

            let validacaoEmail = validaEmail(event.target.value);
            if (validacaoEmail) {                
                    let newState = { ...this.state }
                    newState.erroValidacoes.emailCondutor = false;
                    this.setState(newState);
            }else {
                let newState = { ...this.state }
                newState.erroValidacoes.emailCondutor = true;
                this.setState(newState);
            }
        }
    }

    handleAlteraCnh = (event) => {
        if (event.target.value !== null) {
            this.props.onChangeCnh(event.target.value);
            let newState = { ...this.state }
            newState.erroValidacoes.cnh = false;
            this.setState(newState);
        }
    }

    handleAlteraCategoriaCnh = (event) => {
        if (event.target.value !== null) {
            this.props.onChangeCategoriaCnh(event.target.value);
            let newState = { ...this.state }
            newState.erroValidacoes.categoria = false;
            this.setState(newState);
        }
    }

    handleAlteraValidadeCnh = (event) => {
        if ( somenteNumerosFromString(event.target.value).length === 8 && dataValida(event.target.value) ) {
            let data = moment(event.target.value, ["DD/MM/YYYY"], 'pt-br');
            this.props.onChangeValidadeCnh(data);
            let newState = { ...this.state }
            newState.erroValidacoes.validade = false;
            this.setState(newState);
        } else {
            this.props.onChangeValidadeCnh(event.target.value);
        }
    }

    handleLimpaCampos = () => {
        this.props.onChangeCnh("");
        this.props.onChangeCategoriaCnh("");
        this.props.onChangeValidadeCnh("");
        this.props.onChangeNomeCondutor("");
        this.props.onChangeEmailCondutor("");
        this.props.onChangeCpfCliente("");
        this.props.onChangeDataNascimento("");
    }

    render() {
        let exibicaoDataNascimento = "";
        let exibicaoValidadeCnh = "";

        if (this.props.modelo.dataNascimentoCliente === "" || this.props.modelo.dataNascimentoCliente === null) {
            exibicaoDataNascimento = this.props.modelo.dataNascimentoCliente;
        } else if(moment.isMoment(this.props.modelo.dataNascimentoCliente) || this.props.modelo.dataNascimentoCliente.length > 10){
            exibicaoDataNascimento = moment(this.props.modelo.dataNascimentoCliente).format("DD/MM/YYYY");
        } else {
            exibicaoDataNascimento = this.props.modelo.dataNascimentoCliente;
        }

        if (this.props.modelo.validadeCnh === "" || this.props.modelo.validadeCnh === null) {
            exibicaoValidadeCnh = this.props.modelo.validadeCnh;
        } else if(moment.isMoment(this.props.modelo.validadeCnh) || this.props.modelo.validadeCnh.length > 10){
            exibicaoValidadeCnh = moment(this.props.modelo.validadeCnh).format("DD/MM/YYYY");
        } else {
            exibicaoValidadeCnh = this.props.modelo.validadeCnh;
        }

        let ehAcidente = this.props.modelo.terceiroEnvolvido === "sim" ?
        <React.Fragment>
            <div className="col-md-6 col-lg-6">
                <div className={`wrapper-input ${(this.state.erroValidacoes.cpf === true) ? 'has-error' : ""}`}>
                    <div className="group-input">
                        <MaskedInput type="tel" name="cpf" id="cpf" className="input" maxLength={40}
                            mask={[/\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '-', /\d/, /\d/]}
                            onChange={this.handleAlteraCpfCliente} value={this.props.modelo.cpfCliente} required />
                        <label htmlFor="cpf" className="label" >CPF</label>
                    </div>
                </div>
            </div>

            <div className="col-md-6 col-lg-6">
                <div className={`wrapper-input ${(this.state.erroValidacoes.dataNascimento === true) ? 'has-error' : ""}`}>
                    <div className="group-input">
                        <MaskedInput type="tel" name="dataNasc" id="dataNasc" className='input' inputMode="numeric"
                            mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
                            onChange={this.handleAlteraDataNascimento} value={ exibicaoDataNascimento } required />
                        <label htmlFor="dataNasc" className="label" >Data de Nascimento</label>
                    </div>
                </div>
            </div>

            <div className="col-md-4">
                <div className={`wrapper-input ${(this.state.erroValidacoes.cnh === true) ? 'has-error' : ""}`}>
                    <div className="group-input">
                        <input type="tel" name="cnh" id="cnh" className="input" inputMode="numeric"
                            onChange={this.handleAlteraCnh} value={this.props.modelo.cnh} required maxLength={23}/>
                        <label htmlFor="cnh" className="label">Nº CNH</label>
                    </div>
                </div>
            </div>

            <div className="col-6 col-md-4">
                <div className={`wrapper-input ${(this.state.erroValidacoes.categoria === true) ? 'has-error' : ""}`}>
                    <div className="group-input">
                        <input type="text" name="categoria" id="categoria" className="input"
                            onChange={this.handleAlteraCategoriaCnh} value={this.props.modelo.categoriaCnh} required maxLength={3}/>
                        <label htmlFor="categoria" className="label">Categoria</label>
                    </div>
                </div>
            </div>

            <div className="col-6 col-md-4">
                <div className={`wrapper-input ${(this.state.erroValidacoes.validade === true) ? 'has-error' : ""}`}>
                    <div className="group-input">
                        <MaskedInput type='tel' name="validade" id="validade" className='input'
                            onChange={this.handleAlteraValidadeCnh} value={ exibicaoValidadeCnh } required
                            mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}/>
                        <label htmlFor="validade" className="label" >Validade</label>
                    </div>
                </div>
            </div>                        
        </React.Fragment> : "";

        return (
            <React.Fragment>
                <div className="row w-xl-66">
                    <div className="form-intro col-12">
                        <p className="text-justify">Identificação do carro/condutor no momento do acidente</p>
                    </div>

                    <div className="col-md-4">
                        <div className="wrapper-input">
                            <div className="group-input">
                                <input type="text" name="placa" id="placa" className="input" readOnly={true} required value={this.props.modelo.placaLocaliza} />
                                <label htmlFor="placa" className="label text-left">Placa</label>
                            </div>
                        </div>
                    </div>

                    <div className="col-md-8">
                        <div className="wrapper-input">
                            <div className="group-input">
                                <input type="text" name="modelo" id="modelo" className="input" readOnly={true} required value={this.props.modelo.modeloLocaliza} />
                                <label htmlFor="modelo" className="label">Modelo</label>
                            </div>
                        </div>
                    </div>

                    <div className="col-xl-6">
                        <div className="wrapper-input">
                            <div className="group-input">
                                <input type="text" name="cliente" id="cliente" className="input" readOnly={true} required value={this.props.modelo.clienteLocaliza} />
                                <label htmlFor="cliente" className="label">Cliente</label>
                            </div>
                        </div>
                    </div>

                    <div className="col-xl-6">
                        <div className={`wrapper-input ${(this.state.erroValidacoes.nomeCondutor === true) ? 'has-error' : ""}`}>
                            <div className="group-input">
                                <input type="text" name="condutor" id="condutor" className="input" maxLength={40}
                                    onChange={this.handleAlteraNomeCondutor} value={this.props.modelo.nomeCondutor} required
                                     />
                                <label htmlFor="condutor" className="label">Condutor</label>
                            </div>
                        </div>
                    </div>

                    <div className="col-xl-6">
                        <div className={`wrapper-input ${(this.state.erroValidacoes.emailCondutor === true) ? 'has-error' : ""}`}>
                            <div className="group-input">
                                <input type="text" name="email" id="email" className="input" maxLength={40}
                                    onChange={this.handleAlteraEmailCondutor} value={this.props.modelo.emailCondutor} required
                                     />
                                <label htmlFor="email" className="label">E-mail</label>
                            </div>
                        </div>
                    </div>

                    {ehAcidente}

                    <div className="w-100"></div>
                    <div className="ml-auto mt-3 col-md-6">
                        <div className="row">
                            <div className="col">
                                <button className="button" onClick={this.handleVoltar} >Voltar</button>
                            </div>
                            <div className="col">
                                <button className="button" onClick={this.handleAvancar}>Continuar</button>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}