import {
    ACEITE_POLITICA_FROTA,
    LOADING_ACEITE_POLITICA_FROTA,
    CONFIRMACAO_ACEITE_POLITICA_FROTA,
    ERRO_ACEITE_POLITICA_FROTA,
    LIMPAR_STATE_POLITICA_FROTA,
    LOADING_DOWNLOAD_POLITICA_FROTA,
    LOADED_DOWNLOAD_POLITICA_FROTA,
    ERRO_DOWNLOAD_POLITICA_FROTA
} from './AceitePoliticaFrotaTypes';

import { HttpGet, HttpGetFile } from '../../functions/default';
import qs from 'qs';
import { isNewAppOnly } from "../../helpers/checkPlatform";

export const limparMensagemAceitePoliticaFrota = () => {
    return (dispatch) => {
        dispatch({ type: CONFIRMACAO_ACEITE_POLITICA_FROTA, payload: null }); 
    }
}

export const limparMensagemErroAceitePoliticaFrota = () => {
    return (dispatch) => {
        dispatch({ type: ERRO_ACEITE_POLITICA_FROTA, payload: null }); 
    }
}

export const VerificarSePlacaPossuiPoliticaFrotaAtiva = (placa) => {
    return (dispatch) => {
        if(navigator.onLine){            
            dispatch({ type: LOADING_ACEITE_POLITICA_FROTA });
            HttpGet(`/VerificarSePlacaPossuiPoliticaFrotaAtiva?placa=${placa}`, null).then(function (result) {
                dispatch({ type: ACEITE_POLITICA_FROTA, payload: result.data });
            })
            .catch(function (erro) {
                dispatch({ type: ERRO_ACEITE_POLITICA_FROTA, payload: {message: 'Ocorreu um erro ao buscar dados do aceite de política de frotas, tente novamente mais tarde. '} });
            });
        }
    }
}

export const ConfirmarAceitePoliticaFrotaPorCpf = (requestObj) => {
    return (dispatch) => {
        if(navigator.onLine){
            dispatch({ type: LOADING_ACEITE_POLITICA_FROTA });
            var param = qs.stringify(requestObj);
            HttpGet(`/ConfirmarAceitePoliticaFrotaPorCpf?${param}`, null).then(function (result) {
                if(result.data){
                    if(result.data.sucesso){
                        dispatch({ type: CONFIRMACAO_ACEITE_POLITICA_FROTA, payload: result.data.sucesso });
                    }else{
                        dispatch({ type: ERRO_ACEITE_POLITICA_FROTA, payload: result.data.erro });
                    }                    
                }
            })
            .catch(function (erro) {
                dispatch({ type: ERRO_ACEITE_POLITICA_FROTA, payload: 'Ocorreu um erro ao confirmar o aceite na política de frotas, tente novamente mais tarde.'});
            });
        }
    }
}

export const ConfirmarAceitePoliticaFrota = (requestObj) => {
    return (dispatch) => {
        if(navigator.onLine){
            dispatch({ type: LOADING_ACEITE_POLITICA_FROTA });
            var param = qs.stringify(requestObj);
            HttpGet(`/ConfirmarAceitePoliticaFrota?${param}`, null).then(function (result) {
                if(result.data){
                    if(result.data.sucesso){
                        dispatch({ type: CONFIRMACAO_ACEITE_POLITICA_FROTA, payload: result.data.sucesso });
                    }else{
                        dispatch({ type: ERRO_ACEITE_POLITICA_FROTA, payload: result.data.erro });
                    }                    
                }
            })
            .catch(function (erro) {
                dispatch({ type: ERRO_ACEITE_POLITICA_FROTA, payload: 'Ocorreu um erro ao confirmar o aceite na política de frotas, tente novamente mais tarde. '});
            });
        }
    }
}

export const LimparDadosDePoliticaFrotaNoState = () => {
    return (dispatch) => {        
        dispatch({ type: LIMPAR_STATE_POLITICA_FROTA });
    }
}

export const BaixarArquivoAceitePoliticaFrota = (idDocumento) => {
    return (dispatch) => {
        dispatch({ type: LOADING_DOWNLOAD_POLITICA_FROTA });
        var filename = "PoliticaDeFrota.pdf"
        
        HttpGetFile(
            `/DownloadArquivo?id=${idDocumento}`,
            null,
            filename
            )
        .then(function(result) {
            dispatch({ type: LOADED_DOWNLOAD_POLITICA_FROTA });
        })
        .catch(function(err) {
            if(isNewAppOnly) {
                dispatch({ type: LOADED_DOWNLOAD_POLITICA_FROTA });
            } else {
                dispatch({ type: ERRO_DOWNLOAD_POLITICA_FROTA, payload: 'Ocorreu um erro ao fazer download do arquivo de política de frotas, tente novamente mais tarde.' });
            }
        });
    };
};