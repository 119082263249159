import {
    avancarEtapa, voltarEtapa, alterarTipoEvento,
    alterarChavePrincipal, alterarDocumentos, resetarEstado, alterarCpfCliente, confirmarEmail, retiraConfirmacaoEmail,
    alterarDataNascimentoCliente, alterarNomeCondutor, alterarEmailCondutor, alterarHabilitado, alterarCnh, alterarCategoriaCnh, alterarValidadeCnh,
    alterarDataAcidente, alterarHoraAcidente, alterarEnderecoAcidente, alterarBairroAcidente, alterarCidadeAcidente, alterarDescricaoAcidente,
    alterarOcorrencia, alterarNumeroOcorrencia, alterarNomeDeclarante, alterarTerceiroEnvolvido, alterarOutroEnvolvido, alterarEnderecoTerceiro, alterarBairroTerceiro, alterarCidadeTerceiro,
    alterarCausadorAcidente, alterarLaudoPericia, alterarNumeroLaudo, alterarPlaca, alterarModelo, alterarAno, alterarProprietario, alterarCpfCnpj,
    alterarCep, alterarEnderecoProprietario, alterarNumeroEnderecoProprietario, alterarBairroProprietario, alterarCidadeProprietario, alterarTelefoneProprietario,
    alterarCondutorProprietario, alterarCondutor, alterarCpfCondutor, alterarTelefoneCondutor, alterarCnhCondutor, alterarCategoriaCnhCondutor, alterarValidadeCnhCondutor, alterarSeguro, alterarSeguradora, alterarNumeroSinistro,
    downloadFormularioRea, irParaEtapaSelecionada, alterarEstadoPeloCookie, buscarDadosPlaca, buscarDadosCep, buscarDadosCpf, enviarEmailFormularioRea, limparMensageErro, alterarEmailCondutorTerceiro, 
    alterarEmailProprietario, alterarTipoEmail, alterarClienteIsencaoPrefixacao, alterarChaveReserva, alterarSs
} from './ReaActions';

import React, { Component } from 'react';
import LayoutInternal from '../../components/LayoutInternal';
import { connect } from 'react-redux';
import ModalMensagem from '../../components/ModalMensagem/ModalMensagem';
import ModalConfirmaEmail from '../../components/ModalConfirmarEmail/ModalConfirmarEmail';
import TipoDeEvento from '../../components/Rea/TipoDeEvento';
import ItensDevolvidos from '../../components/Rea/ItensDevolvidos';
import IdentificacaoCondutorCarro from '../../components/Rea/IdentificacaoCondutorCarro';
import DadosTerceiroCondutor from '../../components/Rea/DadosTerceiroCondutor';
import DadosDoEvento from '../../components/Rea/DadosDoEvento';
import Declaracao from '../../components/Rea/Declaracao';
import TemSeguro from '../../components/Rea/TemSeguro';
import ReaFinalizado from '../../components/Rea/ReaFinalizado';
import { NomeCookieSessaoRea } from '../../config/consts';
import HackManterSessaoMobile from '../../components/Rea/HackManterSessaoMobile';
import Loading from '../../components/Loading';
import * as Cookie from '../../functions/Cookies';
import MediaQuery from 'react-responsive';
import moment from 'moment';
import TerceiroEnvolvido from '../../components/Rea/TerceiroEnvolvido';
import DadosTerceiroProprietario from '../../components/Rea/DadosTerceiroProprietario';
import Tracking from '../../hooks/Tracking';

class ReaPage extends Component {

    constructor(props) {
        super(props);
        let objSs = {
            ss: this.props.modelo.ss,
            ss_seq : this.props.modelo.ss_seq}
        this.props.resetarEstado();
        if(objSs.ss && objSs.ss_seq){
            this.props.alterarSs(objSs); 
        }
        this.handleRepostaEmail = this.handleRepostaEmail.bind(this);
        this.handleRemoveMensagemErro = this.handleRemoveMensagemErro.bind(this);
        let dadosCookie = Cookie.get(NomeCookieSessaoRea, true);
        if (dadosCookie !== "" && dadosCookie !== "null" && dadosCookie !== null && this.isIOSAndPWA()) {
            this.props.irParaEtapaSelecionada(1);

            let _novaSessao = JSON.parse(dadosCookie);
            _novaSessao.modelo.dataAcidente = _novaSessao.modelo.dataAcidente !== null && _novaSessao.modelo.dataAcidente.length > 10 ? moment(_novaSessao.modelo.dataAcidente) : _novaSessao.modelo.dataAcidente;
            _novaSessao.modelo.dataNascimentoCliente = _novaSessao.modelo.dataNascimentoCliente !== null && _novaSessao.modelo.dataNascimentoCliente.length > 10 ? moment(_novaSessao.modelo.dataNascimentoCliente) : _novaSessao.modelo.dataNascimentoCliente;
            _novaSessao.modelo.validadeCnhCondutor = _novaSessao.modelo.validadeCnhCondutor !== null && _novaSessao.modelo.validadeCnhCondutor.length > 10 ? moment(_novaSessao.modelo.validadeCnhCondutor) : _novaSessao.modelo.validadeCnhCondutor;
            _novaSessao.modelo.validadeCnh = _novaSessao.modelo.validadeCnh !== null && _novaSessao.modelo.validadeCnh.length > 10 ? moment(_novaSessao.modelo.validadeCnh) : _novaSessao.modelo.validadeCnh;

            this.props.alterarEstadoPeloCookie(_novaSessao);
        }
    }

    isIOSAndPWA() {
        var iOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
        var isPWA = (window.matchMedia('(display-mode: standalone)').matches) || (window.navigator.standalone);
        isPWA = isPWA === true ? true : false
        return iOS && isPWA;
    }

    componentDidMount() {
        Tracking.sendPageview('REA');
    }

    handleRepostaEmail(reposta) {

        let objeto = {
        Tipo: this.props.modelo.tipoEvento,
        ChaveReservaDevolvida: this.props.modelo.chaveReserva,
        ChavePrincipalDevolvida: this.props.modelo.chavePrincipal,
        DocumentosDevolvidos: this.props.modelo.documentos,
        PlacaCarroGF: this.props.modelo.placaLocaliza,
        ModeloCarroGF: this.props.modelo.modeloLocaliza,
        ClienteGF: this.props.modelo.clienteLocaliza,
        NomeCondutorCarroGF: this.props.modelo.nomeCondutor,
        DataNascimentoCondutorCarroGF: moment(this.props.modelo.dataNascimentoCliente),
        CPFCondutorCarroGF: this.props.modelo.cpfCliente,
        CondutorCarroGFHabilitado: this.props.modelo.habilitado,
        CNHCondutorCarroGF: this.props.modelo.cnh,
        CategoriaCNHCondutorCarroGF: this.props.modelo.categoriaCnh,
        ValidadeCNHCondutorCarroGF: this.props.modelo.validadeCnh,
        DataEvento: moment(this.props.modelo.dataAcidente),
        HoraEvento: this.props.modelo.horaAcidente,
        EventoPossuiOcorrenciaPolicial: this.props.modelo.ocorrencia,
        NumeroOcorrenciaPolicialEvento: this.props.modelo.numeroOcorrencia,
        EnderecoQueOCarroEstavaEvento: this.props.modelo.enderecoAcidente,
        BairroEvento: this.props.modelo.bairroAcidente,
        CidadeEvento: this.props.modelo.cidadeAcidente.mNomeCidadeField,
        EstadoEvento: this.props.modelo.cidadeAcidente.mUfField,
        DescricaoDoOcorrido: this.props.modelo.descricaoAcidente,
        NomeDeclarante: this.props.modelo.nomeDeclarante,
        DataOcorrido: this.props.modelo.dataAcidente,
        TerceiroEnvolvidoNoOcorrido: this.props.modelo.terceiroEnvolvido,
        CausadorAcidente: this.props.modelo.causadorAcidente,
        OcorridoPossuiLaudoPericial: this.props.modelo.laudoPericia,
        NumeroLaudoPericialOcorrido: this.props.modelo.numeroLaudo,
        EnderecoQueOCarroTerceiroEstava: this.props.modelo.enderecoTerceiro + (this.props.modelo.cidadeTerceiro != null ? " " + this.props.modelo.cidadeTerceiro.mNomeCidadeField + " " + this.props.modelo.cidadeTerceiro.mUfField : ""),
        PlacaCarroTerceiro: this.props.modelo.placa,
        ModeloCarroTerceiro: this.props.modelo.modelo,
        AnoCarroTerceiro: this.props.modelo.ano,
        ProprietarioCarroTerceiro: this.props.modelo.proprietario,
        CPFCNPJTerceiro: this.props.modelo.cpf_cnpj,
        EnderecoProprietarioCarroTerceiro: this.props.modelo.enderecoProprietario + (this.props.modelo.cidadeProprietario != null ? " " + this.props.modelo.cidadeProprietario.mNomeCidadeField + " " + this.props.modelo.cidadeProprietario.mUfField : ""),
        CEPTerceiro: this.props.modelo.cep,
        TelefoneTerceiro: this.props.modelo.telefoneProprietario,
        EmailProprietario: this.props.modelo.emailProprietario,
        CarroTerceiroPossuiSeguro: this.props.modelo.seguro,
        SeguradoraCarroTerceiro: this.props.modelo.seguradora,
        NumeroAvisoSinistroCarroTerceiro: this.props.modelo.numeroSinistro,
        CondutorCarroTerceiro: this.props.modelo.condutor,
        CPFCondutorCarroTerceiro: this.props.modelo.cpfCondutor,
        TelefoneCondutorCarroTerceiro: this.props.modelo.telefoneCondutor,
        EmailCondutorTerceiro: this.props.modelo.emailCondutorTerceiro,
        CNHCondutorCarroTerceiro: this.props.modelo.cnhCondutor,
        CategoriaCNHCondutorCarroTerceiro: this.props.modelo.categoriaCnhCondutor,
        ValidadeCNHCondutor: this.props.modelo.validadeCnhCondutor !== null ? moment(this.props.modelo.validadeCnhCondutor) : null,
        Emails: [this.props.modelo.emailCondutor],
        TipoEmail: this.props.modelo.tipoEmail,
        Ss: this.props.modelo.ss, 
        SsSeq: this.props.modelo.ss_seq
        };

        this.props.enviarEmailFormularioRea(objeto);
    }

    handleRemoveMensagemErro() {
        this.props.limparMensageErro();
    }

    render() {

        let modalMensagem;
        let etapa;

        if (this.props.erro) {
            modalMensagem = <ModalMensagem tipoModalMensagem='warning' icon='icon-x-circle'
                mensagemPrincipal={this.props.erro.message}
                OnToggled={this.handleRemoveMensagemErro}
                textoBotao="OK" />;
        }
        else if (this.props.sucesso)  {
            modalMensagem = <ModalMensagem tipoModalMensagem=''
                icon='icon-check-circle2'
                mensagemPrincipal={this.props.sucesso.message}
                mensagemSecondaria={this.props.sucesso.mensagemSecondaria}
                OnToggled={() => this.props.irParaEtapaSelecionada(10)}
                textoBotao="OK" />
        }

        switch (this.props.etapa) {
            case 1:
                etapa =
                    <TipoDeEvento
                        modelo={this.props.modelo}
                        onChangeTipoEvento={this.props.alterarTipoEvento}
                        onIrParaEtapa={this.props.irParaEtapaSelecionada}
                    />;
                break;

            case 2:
                etapa =
                    <ItensDevolvidos
                        onIrParaEtapa={this.props.irParaEtapaSelecionada}
                        modelo={this.props.modelo}
                        onChangeAlteraDocumento={this.props.alterarDocumentos}
                        onChangeChavePrincipal={this.props.alterarChavePrincipal}
                        onChangeChaveReserva={this.props.alterarChaveReserva}
                    />;
                break;

            case 3:
                etapa =
                    <IdentificacaoCondutorCarro
                        modelo={this.props.modelo}
                        bloqueadoDadosCondutor={this.props.bloqueadoDadosCondutor}
                        onChangeCpfCliente={this.props.alterarCpfCliente}
                        onChangeDataNascimento={this.props.alterarDataNascimentoCliente}
                        onChangeNomeCondutor={this.props.alterarNomeCondutor}
                        onChangeEmailCondutor={this.props.alterarEmailCondutor}
                        onChangeHabilitado={this.props.alterarHabilitado}
                        onChangeCnh={this.props.alterarCnh}
                        onChangeCategoriaCnh={this.props.alterarCategoriaCnh}
                        onChangeValidadeCnh={this.props.alterarValidadeCnh}
                        onIrParaEtapa={this.props.irParaEtapaSelecionada}
                        buscarDadosPlaca={this.props.buscarDadosPlaca}
                        buscarDadosCpf={this.props.buscarDadosCpf}
                        dataNascimentoInvalida={this.props.dataNascimentoInvalida}
                    />;
                break;

            case 4:
                etapa = 
                    <DadosDoEvento
                        onIrParaEtapa={this.props.irParaEtapaSelecionada}
                        modelo={this.props.modelo}
                        onChangeDataAcidente={this.props.alterarDataAcidente}
                        onChangeHoraAcidente={this.props.alterarHoraAcidente}
                        onChangeEnderecoAcidente={this.props.alterarEnderecoAcidente}
                        onChangeBairroAcidente={this.props.alterarBairroAcidente}
                        onChangeCidadeAcidente={this.props.alterarCidadeAcidente}
                        onChangeDescricaoAcidente={this.props.alterarDescricaoAcidente}
                />;
                break;

            case 5:
                etapa = 
                    <TerceiroEnvolvido
                        onIrParaEtapa={this.props.irParaEtapaSelecionada}
                        modelo={this.props.modelo}
                        onChangeTerceiroEnvolvido={this.props.alterarTerceiroEnvolvido}
                        onChangeCausadorAcidente={this.props.alterarCausadorAcidente}            
                        onChangeOutroEnvolvido={this.props.alterarOutroEnvolvido} 
                />;
                break;

            case 6:
                etapa = 
                    <DadosTerceiroProprietario
                        modelo={this.props.modelo}
                        onIrParaEtapa={this.props.irParaEtapaSelecionada}
                        onChangePlaca={this.props.alterarPlaca}
                        onChangeModelo={this.props.alterarModelo}
                        onChangeAno={this.props.alterarAno}
                        onChangeProprietario={this.props.alterarProprietario}
                        onChangeCep={this.props.alterarCep}
                        onChangeEnderecoProprietario={this.props.alterarEnderecoProprietario}
                        onChangeBairroProprietario={this.props.alterarBairroProprietario}
                        onChangeCidadeProprietario={this.props.alterarCidadeProprietario}
                        onChangeTelefoneProprietario={this.props.alterarTelefoneProprietario}
                        onChangeEmailProprietario={this.props.alterarEmailProprietario}
                        buscarDadosCep = {this.props.buscarDadosCep}
                />;
                break;

            case 7:
                etapa = 
                    <DadosTerceiroCondutor
                        onIrParaEtapa={this.props.irParaEtapaSelecionada}
                        modelo={this.props.modelo}
                        onChangeCondutorProprietario={this.props.alterarCondutorProprietario}
                        onChangeCondutor={this.props.alterarCondutor}
                        onChangeCpfCondutor={this.props.alterarCpfCondutor}
                        onChangeTelefoneCondutor={this.props.alterarTelefoneCondutor}
                        onChangeEmailCondutorTerceiro={this.props.alterarEmailCondutorTerceiro}
                />;
                break;

            case 8:
                etapa = <TemSeguro 
                    onIrParaEtapa={this.props.irParaEtapaSelecionada}
                    modelo={this.props.modelo}
                    onChangeSeguro={this.props.alterarSeguro}
                    onChangeSeguradora={this.props.alterarSeguradora}
                    onChangeNumeroSinistro={this.props.alterarNumeroSinistro}
                />                
                break;

            case 9:
                etapa = <Declaracao
                    modelo={this.props.modelo}
                    condutor={this.props.modelo.nomeCondutor}
                    onIrParaEtapa={this.props.irParaEtapaSelecionada}
                    onAlterarTipoEmail={this.props.alterarTipoEmail}
                    onEnviarEmailFormulario={this.handleRepostaEmail}
                />
                break;

            case 10:
                this.props.resetarEstado();
                return (<ReaFinalizado />);
            default:
                etapa = <h1>Etapa não encontrada</h1>
                break;
        }

        return (
            <React.Fragment>
                {this.isIOSAndPWA() == true ?
                    <React.Fragment maxDeviceWidth={850}>
                        <HackManterSessaoMobile sessaoAtual={{ etapa: this.props.etapa, bloqueadoDadosCondutor: this.props.bloqueadoDadosCondutor, modelo: this.props.modelo }} />
                    </React.Fragment>
                    : ""}

                <LayoutInternal title={'Preenchimento do REA'}>
                    {
                        this.props.carregando === true ? <Loading /> : null
                    }
                    {modalMensagem}
                    <main>
                        <div className="content-primary">
                            {etapa}
                        </div>
                    </main>
                </LayoutInternal>
            </React.Fragment>
        )
    }
}

const mapStateToProps = state => {
    return {
        model: state.Rea.model,
        erro: state.Rea.erro,
        sucesso: state.Rea.sucesso,
        carregando: state.Rea.carregando,
        etapa: state.Rea.etapa,
        bloqueadoDadosCondutor: state.Rea.bloqueadoDadosCondutor,
        modelo: state.Rea.modelo,
        solicitaEmail: state.Rea.solicitaEmail,
        dataNascimentoInvalida: state.Rea.dataNascimentoInvalida
    }
};

export default connect(mapStateToProps, {
    avancarEtapa, voltarEtapa, alterarTipoEvento, alterarChavePrincipal, alterarDocumentos, resetarEstado,
    alterarCpfCliente, confirmarEmail, retiraConfirmacaoEmail, alterarDataNascimentoCliente,
    alterarNomeCondutor, alterarEmailCondutor, alterarHabilitado, alterarCnh, alterarCategoriaCnh, alterarValidadeCnh,
    alterarDataAcidente, alterarHoraAcidente, alterarEnderecoAcidente, alterarBairroAcidente, alterarCidadeAcidente, alterarDescricaoAcidente,
    alterarOcorrencia, alterarNumeroOcorrencia, alterarNomeDeclarante, alterarTerceiroEnvolvido, alterarOutroEnvolvido, alterarEnderecoTerceiro, alterarBairroTerceiro, alterarCidadeTerceiro,
    alterarLaudoPericia, alterarCausadorAcidente, alterarNumeroLaudo, alterarPlaca, alterarModelo, alterarAno, alterarProprietario, alterarCpfCnpj,
    alterarCep, alterarEnderecoProprietario, alterarNumeroEnderecoProprietario, alterarBairroProprietario, alterarCidadeProprietario, alterarTelefoneProprietario,
    alterarCondutorProprietario, alterarCondutor, alterarCpfCondutor, alterarTelefoneCondutor, alterarCnhCondutor, alterarCategoriaCnhCondutor, alterarValidadeCnhCondutor, alterarSeguro, alterarSeguradora, alterarNumeroSinistro,
    downloadFormularioRea, irParaEtapaSelecionada, alterarEstadoPeloCookie, buscarDadosPlaca, buscarDadosCep, buscarDadosCpf, enviarEmailFormularioRea, limparMensageErro, alterarEmailCondutorTerceiro, 
    alterarEmailProprietario, alterarClienteIsencaoPrefixacao, alterarTipoEmail, alterarChaveReserva, alterarSs
})(ReaPage)