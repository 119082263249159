import React, { Component } from "react";
import Menu from "./Menu/Menu";
import HeaderInternal from "./HeaderInternal";
import BottomNavigation from "./BottomNavigation";
import MediaQuery from "react-responsive";
import MenuMobile from "./Menu/MenuMobile";
import styled, { css } from "styled-components";

export const MobileContainer = styled.section`
  width: 100%;
  height: 150%;
  background: #fafafa;
  display: flex;
  flex-direction: column;
  padding: 10px 10px 10px 10px;
  position: absolute;
  top: 100px;
  border-radius: 24px 24px 0px 0px;

  ${({ isWithoutPadding }) =>
    isWithoutPadding === true &&
    css`
      padding: 0px;
      top: 45px;
    `}
`;
export default class LayoutInternal extends Component {
  render() {
    const mobilePadding = this.props.mobileWithoutPadding ?? false;
    return (
      <React.Fragment>
        <MediaQuery minDeviceWidth={1025}>
          <div className="App">
            <HeaderInternal title={this.props.title} />
            <Menu />
            {this.props.children}
          </div>
        </MediaQuery>
        <MediaQuery maxDeviceWidth={1025}>
          <div className="App">
            <MenuMobile />
            {mobilePadding ? (
              <MobileContainer isWithoutPadding>
                {this.props.children}
              </MobileContainer>
            ) : (
              <MobileContainer>{this.props.children}</MobileContainer>
            )}

            <BottomNavigation />
          </div>
        </MediaQuery>
      </React.Fragment>
    );
  }
}
