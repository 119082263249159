import React, { Component } from 'react';
import { connect } from 'react-redux';
import Cabecalho from './SinistroCabecalho';
import { listaAvarias, verMais } from './SinistroActions'
import LayoutInternal from '../../components/LayoutInternal';
import Modal from './Modal';
import Historico from './Historico'
import ModalMensagem from '../../components/ModalMensagem/ModalMensagem';
import DetalhesAgendamento from '../../components/Agendamentos/DetalhesAgendamento';
import { exibirDetalheAgendamento } from '../../components/Agendamentos/CardAgendamentoAction';
import Loading from '../../components/Loading';
import Tracking from '../../hooks/Tracking';
import {HistoricoContainer} from './styles';
import ErrorBoundary from '../../components/ErrorBoundary'

class Mensagens extends Component {
    constructor(props) {
        super(props);

        this.handleAtualizar = this.handleAtualizar.bind(this);
    }

    handleAtualizar() {
        this.props.OnListarAvarias(localStorage.getItem("placa-atual"));
    }

    render() {
        if (this.props.erro) {
            return (<ModalMensagem tipoModalMensagem='warning' icon='icon-x-circle'
                mensagemPrincipal={this.props.erro.message}
                mensagemSecundaria=''
                textoBotao="OK" />);
        }

        if (this.props.resposta) {
            return (<ModalMensagem tipoModalMensagem='' icon='icon-check-circle2'
                mensagemPrincipal={this.props.resposta.message}
                mensagemSecundaria=''
                textoBotao="OK"
                OnToggled={this.handleAtualizar} />);

        }
        return null;
    }
}

class VerMais extends Component {
    render() {
        if (this.props.contador && this.props.total > 0) {
            if (this.props.contador <= this.props.total) {
                return (
                    <div className="button-light-icon" onClick={() => this.props.vermais(this.props.contador)}>                
                        <span>CARREGAR MAIS</span>
                    </div>
                );
            }
        }
        return null;
    }
}

class RenderModal extends Component {
    render() {
        if (this.props.modal) {
            return <Modal placa={this.props.modal.placa}
                data={this.props.modal.data}
                numero={this.props.modal.numero}
                sequencia={this.props.modal.sequencia}
                documentos={this.props.modal.documentos}
                OnToggled={this.props.handleAtualizar} />
        }
        else {
            return null;
        }
    }
}

class Avarias extends Component {
    componentDidMount() {
        Tracking.sendPageview('Avaria');
        if (localStorage.getItem("placa-atual"))
            this.props.listaAvarias(localStorage.getItem("placa-atual"));
    }

    handleAtualizar(p) {
        this.props.listaAvarias(p);
    }

    render() {
        return (
            <div>
                {
                    this.props.loadingEnviarDoc ? <Loading /> : null
                }
                <Mensagens
                    erro={this.props.erro}
                    resposta={this.props.resposta}
                    OnListarAvarias={(placa) => this.props.listaAvarias(placa)}
                    placa={localStorage.getItem("placa-atual")} />
                <LayoutInternal title="Evento/Avaria">
                    <main className="background-gray">
                        <RenderModal modal={this.props.modal} handleAtualizar={(p) => this.handleAtualizar(p)} />
                        <div className="content-primary">
                            <Cabecalho roubo={false} />
                        </div>
                        <HistoricoContainer>
                            <Historico
                                sinistros={this.props.avarias ? this.props.avarias.slice(0, this.props.contador) : {}}
                                placa={localStorage.getItem("placa-atual")}
                                OnExibirAgendamento={(p, n, s) => this.props.exibirDetalheAgendamento(p, n, s)} />
                            <VerMais contador={this.props.contador} total={this.props.avarias ? this.props.avarias.length : 0} vermais={this.props.verMais} />
                        </HistoricoContainer>
                    </main>
                </LayoutInternal>
                <ErrorBoundary texto={"nos detalhes do agendamento"}>
                     <DetalhesAgendamento />
                </ErrorBoundary>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        modal: state.sinistro.modal,
        avarias: state.sinistro.avarias,
        contador: state.sinistro.contador,
        erro: state.sinistro.erro,
        resposta: state.sinistro.resposta,
        loadingEnviarDoc: state.sinistro.loading,
        placa: state.menu.placa,
    }
};

export default connect(mapStateToProps, {
    listaAvarias, verMais, exibirDetalheAgendamento
})(Avarias)