import React, { Component } from "react";
import { Provider } from "react-redux";
import { CookiesProvider } from "react-cookie";
import Router from "./Router";
import { urlAdrum } from "../src/config/consts";
import store from "./store";
import { createMuiTheme, createTheme } from "@material-ui/core";
import { ThemeProvider } from "@material-ui/styles";
import ReactGA from "react-ga4";
import { getUA, isMobile, isDesktop } from "react-device-detect";
import ModalForcarAtualizacao from "./components/ModalForcarAtualizacao/index";
import { AuthProvider } from "./providers/auth";
import packageJson from "../package.json";
import withClearCache from "./ClearCache";
import { getBuildDate } from "./GetBuildDate";

const ClearCacheComponent = withClearCache(MainApp);

const theme = createTheme({
  palette: {
    primary: {
      main: "#01602A",
    },
  },
  overrides: {
    MuiStepIcon: {
      root: {
        "&$active": {
          color: "#01602A",
        },
        "&$completed": {
          color: "#01602A",
        },
      },
    },
    MuiStepper: {
      root: {
        padding: "20px 5px",
      },
    },
    MuiFormControlLabel: {
      label: {
        fontFamily: "Metropolis",
        fontSize: "12px",
        color: "#555555",
      },
    },
  },
});
class App extends Component {
  InitializeReactGA() {
    const url = window.location.href;
    const account = url.includes("meucarro.localiza.com")
      ? "G-3RPH3BW7GX"
      : "G-5J12ZTF1C3";
    ReactGA.initialize(account);
  }

  isNotUserAgentValidNewApp() {
    const USER_AGENT_NOVA_APP_MOBILE = "wv nova-app-meucarro";
    const USER_AGENT_NOVA_WEB_VIEW = "wv";
    const isWebView = navigator.userAgent.includes(USER_AGENT_NOVA_WEB_VIEW);
    return (
      !isDesktop &&
      isMobile &&
      isWebView &&
      getUA !== USER_AGENT_NOVA_APP_MOBILE
    );
  }

  isSafari() {
    var ua = navigator.userAgent.toLowerCase();
    if (ua.indexOf("safari") != -1) {
      if (ua.indexOf("chrome") > -1) {
        return 1;
      } else {
        return 2;
      }
    }
  }

  render() {
    this.InitializeReactGA();

    if (this.isNotUserAgentValidNewApp()) {
      return (
        <React.Fragment>
          <ModalForcarAtualizacao />
        </React.Fragment>
      );
    }

    if (
      this.isSafari() !== 2 &&
      navigator.userAgent.includes("iPhone") === true
    ) {
      return (
        <React.Fragment>
          <ModalForcarAtualizacao />
        </React.Fragment>
      );
    }

    return (
      <React.Fragment>
        <script type="text/javascript" src={urlAdrum}></script>
        <ThemeProvider theme={theme}>
          <Provider store={store}>
            <CookiesProvider>
              <AuthProvider>
                <ClearCacheComponent />
                <Router />
              </AuthProvider>
            </CookiesProvider>
          </Provider>
        </ThemeProvider>
      </React.Fragment>
    );
  }
}

function MainApp(props) {
  return (
    <footer style={{ display: "none" }}>
      Build date: {getBuildDate(packageJson.buildDate)}
    </footer>
  );
}

export default App;
