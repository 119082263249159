import React, { Component } from 'react';
import LayoutInternal from '../../../components/LayoutInternal';
import { connect } from 'react-redux';
import listaServicosContratados from './ListaServicosContratadosActions'
import ModalMensagem from '../../../components/ModalMensagem/ModalMensagem';
import Loading from '../../../components/Loading'
import pneu from '../../../styles/img/servicos-contratados/pneu.png';
import reparo from '../../../styles/img/servicos-contratados/reparo.png';
import sinistro from '../../../styles/img/servicos-contratados/sinistro.png';
import substituto from '../../../styles/img/servicos-contratados/substituto.png';
import Tracking from '../../../hooks/Tracking';

const iconesServicosContratados = { pneu, reparo, sinistro, substituto }

class ErrorMessage extends Component {
    render() {
        return <ModalMensagem tipoModalMensagem='warning' icon='icon-x-circle'
            mensagemPrincipal={this.props.erro.message}
            textoBotao="OK" />;
    }
}

class ListaServicosContratadosPage extends Component {

    componentDidMount() {
        Tracking.sendPageview('Listagem de Servicos Contratados');

        if (localStorage.getItem("placa-atual"))
            this.props.listaServicosContratados(localStorage.getItem("placa-atual"));
    }

    render() {
        let retorno;
        if (this.props.erro) {
            retorno = <ModalMensagem tipoModalMensagem='warning' icon='icon-x-circle'
                mensagemPrincipal={this.props.erro.message}
                textoBotao="OK" />;
        }
        else if (this.props.servicosContratados.length) {
            var i = 0;
            let itens = this.props.servicosContratados.map(m => {
                return <li key={i++}><img className="icon" src={iconesServicosContratados[m.ServicoImagem]} /><span>{m.ServicoDescricao}</span></li>
            });

            retorno = <span className="servicos-contratados">
                <h1>Serviços Contratados</h1>
                <div className="wrapper-list-two-texts">
                    <ul className="list-icon">{itens}</ul>
                </div>
            </span>
        }
        else {
            retorno = <p>Não foram encontrados serviços contratados.</p>;
        }

        return (
            <LayoutInternal title={'Serviços Contratados'}>
                
                {
                    this.props.loadingServicosContratados && <Loading />
                }
                <main>
                    <div className="content-primary">
                        {this.props.loadingServicosContratados ? <p>Carregando...</p> : retorno}
                    </div>
                </main>
            </LayoutInternal>
        )
    }
}

const mapStateToProps = state => {
    return {
        servicosContratados: state.listaServicosContratados.servicosContratados,
        erro: state.listaServicosContratados.erro,
        placa: state.menu.placa,
        loadingServicosContratados: state.listaServicosContratados.loading
    }
};

export default connect(mapStateToProps, {
    listaServicosContratados
})(ListaServicosContratadosPage)