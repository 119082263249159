

export const PG_HOME = { url: '/', valor: 0 };
export const PG_LOGIN_CELULAR = { url: '/Login', valor: 1 };
export const PG_LOGIN_SMS = { url: '/LoginSMS', valor: 2 };
export const PG_LOGIN_MENSAGEM = { url: '/LoginMensagens', valor: 3 };
export const PG_LOGIN_PLACA_RENAVAM = { url: '/LoginPlacaRenavam', valor: 4 };
export const PG_MULTAS_MY_FLEET = { valor: 5 };
export const PF_MULTAS = { url: '/Multas', valor: 6};
export const PG_AUTORIZAR_COOKIES = { url: '/AutorizarCookies', valor: 7};
export const NENHUMA = { valor: 999 };