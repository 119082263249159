export const AVANCAR                            = 'avancar_etapa'
export const VOLTAR                             = 'voltar_etapa'
export const ERRO                               = 'erro'
export const REA_CARREGANDO                     = 'rea_loading'
export const REA_CARREGADO                      = 'rea_loaded'
export const ALTERAR_TIPO_EVENTO                = 'etp1_alterar_tipo_evento'
export const ALTERAR_CHAVE_PRINCIPAL            = 'etp2_alterar_chave_pri'
export const ALTERAR_CHAVE_RESERVA              = 'alterar_chave_reserva'
export const ALTERAR_DOCUMENTOS                 = 'etp2_alterar_doc'
export const RESETAR_ESTADO                     = 'reseta_estado_rea'
export const ALTERAR_CPF_CLIENTE                = 'reseta_cpf_cliente'
export const SOLICITAR_EMAIL                    = 'solicitar_confimacao_email_rea';
export const RETIRA_SOLICITAR_EMAIL             = 'retira_solicitar_confimacao_email_rea';
export const ALTERAR_DATA_NASCIMENTO_CLI        = 'alterar_data_nascimento_cliente';
export const ARQUIVO                            = 'donwload_arquivo';
export const ALTERAR_NOME_CONDUTOR              = 'alterar_nome_condutor';
export const ALTERAR_EMAIL_CONDUTOR             = 'alterar_email_condutor';
export const ALTERAR_HABILITADO                 = 'alterar_habilitado';
export const ALTERAR_CNH                        = 'alterar_cnh';
export const ALTERAR_CATEGORIA_CNH              = 'alterar_categoria_cnh';
export const ALTERAR_VALIDADE_CNH               = 'alterar_validade_cnh';
export const ALTERAR_DATA_ACIDENTE              = 'alterar_data_acidente';
export const ALTERAR_HORA_ACIDENTE              = 'alterar_hora_acidente';
export const ALTERAR_ENDERECO_ACIDENTE          = 'alterar_endereco_acidente';
export const ALTERAR_BAIRRO_ACIDENTE            = 'alterar_bairro_acidente';
export const ALTERAR_CIDADE_ACIDENTE            = 'alterar_cidade_acidente';
export const ALTERAR_DESCRICAO_ACIDENTE         = 'alterar_descricao_acidente';
export const ALTERAR_OCORRENCIA                 = 'alterar_ocorrencia';
export const ALTERAR_NUMERO_OCORRENCIA          = 'alterar_numero_ocorrencia';
export const ALTERAR_NOME_DECLARANTE            = 'alterar_nome_declarante';
export const ALTERAR_TERCEIRO_ENVOLVIDO         = 'alterar_terceiro_envolvido';
export const ALTERAR_OUTRO_ENVOLVIDO            = 'alterar_outro_envolvido';
export const ALTERAR_ENDERECO_TERCEIRO          = 'alterar_endereco_terceiro';
export const ALTERAR_BAIRRO_TERCEIRO            = 'alterar_bairro_terceiro';
export const ALTERAR_CIDADE_TERCEIRO            = 'alterar_cidade_terceiro';
export const ALTERAR_CAUSADOR_ACIDENTE          = 'alterar_causador_acidente';
export const ALTERAR_LAUDO_PERICIA              = 'alterar_laudo_pericia';
export const ALTERAR_NUMERO_LAUDO               = 'alterar_numero_laudo';
export const ALTERAR_PLACA                      = 'alterar_placa';
export const ALTERAR_MODELO                     = 'alterar_modelo';
export const ALTERAR_ANO                        = 'alterar_ano';
export const ALTERAR_PROPRIETARIO               = 'alterar_proprietario';
export const ALTERAR_CPF_CNPJ                   = 'alterar_cpf_cnpj';
export const ALTERAR_CEP                        = 'alterar_cep';
export const ALTERAR_ENDERECO_PROPRIETARIO      = 'alterar_endereco_proprietario';
export const ALTERAR_NUMERO_ENDERECO_PROPRIETARIO = 'alterar_numero_endereco_proprietario';
export const ALTERAR_BAIRRO_PROPRIETARIO        = 'alterar_bairro_proprietario';
export const ALTERAR_CIDADE_PROPRIETARIO        = 'alterar_cidade_proprietario';
export const ALTERAR_TELEFONE_PROPRIETARIO      = 'alterar_telefone_proprietario';
export const ALTERAR_CONDUTOR_PROPRIETARIO      = 'alterar_condutor_proprietario';
export const ALTERAR_CONDUTOR                   = 'alterar_condutor';
export const ALTERAR_CPF_CONDUTOR               = 'alterar_cpf_condutor';
export const ALTERAR_TELEFONE_CONDUTOR          = 'alterar_telefone_condutor';
export const ALTERAR_EMAIL_CONDUTOR_TERCEIRO    = 'alterar_email_condutor_terceiro';
export const ALTERAR_CNH_CONDUTOR               = 'alterar_cnh_condutor';
export const ALTERAR_CATEGORIA_CNH_CONDUTOR     = 'alterar_categoria_cnh_condutor';
export const ALTERAR_VALIDADE_CNH_CONDUTOR      = 'alterar_validade_cnh_condutor';
export const ALTERAR_SEGURO                     = 'alterar_seguro';
export const ALTERAR_SEGURADORA                 = 'alterar_seguradora';
export const ALTERAR_NUMERO_SINISTRO            = 'alterar_numero_sinistro';
export const IR_PARA_ETAPA                      = 'ir_para_etapa_selecionada';
export const ATUALIZAR_ESTADO_COOKIE            = 'atualizar_estado_pelo_cookie';
export const BUSCAR_DADOS_PLACA                 = 'buscar_dados_placa';
export const BUSCAR_DADOS_CEP                   = 'buscar_dados_cep';
export const BUSCAR_DADOS_CPF                   = 'buscar_dados_cpf';
export const BUSCAR_DADOS_CPF_DATA_INVALIDA     = 'buscar_dados_cpf_data_invalida';
export const REA_FINALIZADO                     = 'rea_finalizado_com_sucesso';
export const ALTERAR_EMAIL_PROPRIETARIO         = 'alterar_email_proprietario';
export const ALTERAR_CLIENTE_ISENCAO_PREFIXACAO = 'alterar_cliente_isencao_prefixacao';
export const ALTERAR_TIPO_EMAIL                 = 'alterar_tipo_email';
export const ALTERAR_SS                         = 'alterar_ss';
export const ALTERAR_SS_SEQ                     = 'alterar_ss_seq';