import 'core-js/es6/map';
import 'core-js/es6/set';
import 'raf/polyfill';

import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import {datadogApplicationId, datadogClientToken, datadogSite, datadogService} from './config/consts'
import registerServiceWorker from './registerServiceWorker';
import { datadogRum } from '@datadog/browser-rum';
import './styles/css/normalize.css';
import './styles/fonts/icon/style.css';
import './styles/css/style.css';
import './styles/css/media-query.css';

 datadogRum.init({
    applicationId: datadogApplicationId,
    clientToken: datadogClientToken,
    site: datadogSite,
    service: datadogService,
    sampleRate: 100,
    trackInteractions: true
 });

ReactDOM.render(<App />, document.getElementById('root'));

registerServiceWorker();