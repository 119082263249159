import React, { Component } from 'react';
import ConfiguraFileInput from './InputJQuery';
import $ from 'jquery'
import { Link } from 'react-router-dom';
import { enviarArquivos, dispatchErro } from './SinistroActions'
import { connect } from 'react-redux';
import { alterarSs } from '../Rea/ReaActions';

 class Modal extends Component {
    constructor(props) {
        super(props);

        this.state = { modalOpened: true, destroy: false };
        this.modalToggle = this.modalToggle.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
    }

    componentDidMount(){  
        ConfiguraFileInput();
        this.props.alterarSs({
            ss: this.props.numero, 
            ss_seq: this.props.sequencia}); 
    }

    modalToggle(resposta) {
        this.setState({ modalOpened: !this.state.modalOpened })
        this.state.destroy = true;

        this.configuraScroll();

        //função a ser executada depois do Toggle
        if(this.props.OnToggled)
            this.props.OnToggled(resposta);
    }

    onSubmit() {
        this.configuraScroll();

        var data = new FormData();
        var inputs = $( "input:file" );
        var tam = 0;
        inputs.each(function(i){
            var input = inputs[i];
            var id = input.getAttribute('name');

            tam += validarTamanhoArquivos(input.files)
            $(input.files).each(function (j) { data.append(id, input.files[j]) });
        });
        
        if(tam > 25) {
            this.props.dispatchErro("Tamanho máximo de arquivos excedido (25MB)");
            return;
        }

        var texts = $('#modalUpload').find('input:hidden').not( "script" );
        texts.each(function (j) { 
            var input = texts[j];
            var id = input.getAttribute('name');
            data.append(id, input.value); 
        });
        
        this.props.enviarArquivos(data);
    }

    configuraScroll() { 
        const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

        // evitar scroll na tela quando a modal estiver aberta
        if(isSafari){
            if(this.state.modalOpened) {
                document.querySelector('body').style.position = 'relative'
            } else {
                document.querySelector('body').style.position = 'fixed' 
            }
        }
    }

    render(){
        /* configura flag para que o modal apareça da próxima vez que for chamado */
        if(this.state.destroy)
        {
            this.state.destroy = false;
            this.state.modalOpened = true;
            return null;
        }

        return (
            <div className="modal-backdrop modal-open modal-upload" id="modalUpload">
                <div className="modal">
                    <div className="modal-header">
                        <span className="icon icon-x" onClick={this.modalToggle}></span>
                    </div>
                    <div>
                        <input name="assunto" type="hidden" value={this.props.sequencia > 0 ? (localStorage.getItem("placa-atual")+' '+this.props.numero+'/'+this.props.sequencia) : (localStorage.getItem("placa-atual")+' '+this.props.numero)} />
                        <input name="descricao" type="hidden" value={this.props.sequencia > 0 ? 'sinistro' : 'roubo'} />
                        <input name="numero" type="hidden" value={this.props.sequencia > 0 ? this.props.numero : ''} />         
                        <input name="sequencia" type="hidden" value={this.props.sequencia} />
                        <input name="atendimento" type="hidden" value={this.props.sequencia > 0 ? '' : this.props.numero} />         
                        <input name="placa" type="hidden" value={localStorage.getItem("placa-atual")} />           
                        <input name="cod-tipo-sin" type="hidden" value={this.props.documentos && this.props.documentos.length > 0 ? this.props.documentos[0].TipoSinistro : null} />
                    </div>
                    <div className="modal-body">
                        <div className="codigo-roubo">
                            <div className="codigo">{this.props.sequencia > 0 ? (this.props.numero+'/'+this.props.sequencia):(this.props.numero)}</div>
                            <div className="data">{this.props.data.toUpperCase()}</div>
                        </div>                            
                        {
                            this.props.documentos.map(dados => 
                                <React.Fragment>
                                    {
                                       dados.CodTipoDocumento === "PRO" ?
                                            (
                                                <div key={dados.CodTipoDocumento} className="wrapper-file">
                                                    <label htmlFor={dados.CodTipoDocumento} className="wrapper-input-file">
                                                        <div className="label-input-file">
                                                            <i className="icon icon-plus"></i>
                                                            <Link className="link-download-rea" to={"/PreencherRea"}>
                                                                <span>{dados.TipoDocumento}</span>
                                                            </Link>
                                                        </div>
                                                    </label>
                                                </div>
                                            ) :
                                            (
                                                <div key={dados.CodTipoDocumento} className="wrapper-file">
                                                    <input type="file" data-title={dados.TipoDocumento} name={dados.CodTipoDocumento} 
                                                            id={dados.CodTipoDocumento} className="input-file-multiple" accept="image/jpeg,application/pdf" 
                                                            data-required="" multiple />        
                                                    <label htmlFor={dados.CodTipoDocumento} className="wrapper-input-file">
                                                        <div className="label-input-file">
                                                            <i className="icon icon-plus"></i>
                                                            <span className="file-name">{dados.TipoDocumento}</span>
                                                        </div>
                                                    </label>
                                                    <input name={dados.CodTipoDocumento+'-nomes'} id={dados.CodTipoDocumento+'-nomes'} type="hidden"/>
                                                    <div className={'files '+dados.CodTipoDocumento+'-files'} id={dados.CodTipoDocumento+'-files'}></div>
                                                </div>
                                            )
                                    }
                                </React.Fragment>
                            )                              
                        }                                                       
                    </div>
                    <div className="modal-footer">
                        <button className="button" onClick={() => this.onSubmit()}>Enviar</button>
                    </div>
                </div>   
            </div>
         );
    }
}

function validarTamanhoArquivos(files){
    var total = 0;
    $(files).each(function(i) { total += files[i].size });
    return total/1024/1024;
}

export default connect(null, { 
    enviarArquivos, dispatchErro, alterarSs
})(Modal)