import styled from "styled-components";

//MENU GERAL

export const MenuWebContainer = styled.header`
  background: #01602a;
  width: 268px;
  height: 100%;
  box-sizing: border-box;
  position: fixed;
  top: 0;
  left: 0;
  border-radius: 0px 24px 0px 0px;
  font-family: Inter;

  @media (max-width: 1025px) {
    display: none;
  }
`;

export const MenuMobileContainer = styled.header`
  @media (max-width: 1025px) {
    background: #01602a;
    width: 100%;
    height: 130px;
    font-family: Inter;
  }
`;

export const TopMobileContainer = styled.div`
  @media (max-width: 1025px) {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
`;

export const LogoMobileContainer = styled.div`
  margin: 13px 0px 0px 13px;
`;

export const MenuIconContainer = styled.div`
  @media (max-width: 1025px) {
    color: #DEE9D3;
    font-size: 24px;
    margin: 11px 13px 0px 0px;
  }
`;

export const WrapperImg = styled.img`
  position: fixed;
  top: 76px;
  right: 0;
`;

export const NavContainer = styled.div`
  position: absolute;
  left: 0;
  width: 100%;
  height: 75%;
  top: 200px;

  @media (max-height: 760px) {
    overflow-x: auto;
    height: 70%;
    menu::-webkit-scrollbar-track {
      background-color: #01602a;
    }
    menu:-webkit-scrollbar-thumb {
      border-radius: 10px;
      background: rgba(0, 0, 0, 0.3);
    }
  }

  @media (max-height: 680px) {
    overflow-x: auto;
    height: 62%;
    menu::-webkit-scrollbar-track {
      background-color: #01602a;
    }
    menu:-webkit-scrollbar-thumb {
      border-radius: 10px;
      background: rgba(0, 0, 0, 0.3);
    }
  }
`;

export const NavContainerMobile = styled.section`
  width: 100%;
  position: absolute;
  top: 131px;
  border-radius: 16px;
  background: #ffffff;
  padding: 20px 0px 0px 0px;
`;

export const SpanContainer = styled.div`
  padding: 0 0 0 35px;
`;
export const WrapperSpan = styled.span``;

//CABEÇALHO MENU

export const TopMenuWeb = styled.header`
  padding: 20px 0 0 15px;
`;

export const TopMenuMobile = styled.header`
  background-color: #01602a;
  height: 400px;
  position: absolute;
  top: 0;
  width: 100%;
`;

export const ContainerUsuarioMobile = styled.div`
  height: 70px;
`;
export const ContainerUsuario = styled.div`
  position: absolute;
  top: 32px;
  left: 26px;
`;

export const ContainerEmpresa = styled.div`
  position: absolute;
  top: 60px;
  left: 26px;
  width: 60%;
  word-wrap: break-all;
`;

export const NomeUsuario = styled.p`
  color: #ffffff;
  font-family: Inter;
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
  text-transform: capitalize;
`;

export const NomeEmpresa = styled.p`
  color: #ffffff;
  font-family: Inter;
  font-size: 10px;
  font-weight: 400;
  line-height: 12px;
`;

//NOVAS PLACAS

export const WrapperPlaca = styled.div`
  background: #dee9d3;
  color: #01602a;
  width: 269px;
  height: 84px;
  position: absolute;
  left: 24px;
  top: 100px;
  box-shadow: 0px 10px 30px rgba(6, 54, 73, 0.3);
  border-radius: 24px;
`;

export const WrapperPlacaMobile = styled.div`
  background: #dee9d3;
  color: #01602a;
  position: absolute;
  width: 236px;
  height: 82px;
  left: 50%;
  transform: translateX(-50%);
  top: 57px;
  box-shadow: 0px 10px 30px rgba(6, 54, 73, 0.3);
  border-radius: 24px;
  z-index: 1;
`;

export const Placa = styled.h1`
  position: relative;
  bottom: 20px;
  padding: 5px 5px 0px 10px;
  font-weight: bold;
  font-size: 32px;
  line-height: 39px;
  font-family: Inter;
`;
export const Modelo = styled.h5`
  position: relative;
  bottom: 40px;
  padding: 0px 5px 5px 15px;
  font-weight: normal;
  font-size: 12px;
  line-height: 15px;
  font-family: Inter;
`;

export const ListaPlacas = styled.div`
  position: absolute;
  left: 0;
  top: 85px;
  width: 269px;
  background-color: #dee9d3;
  color: #01602a;
  font-family: Inter;
  border-radius: 24px;
  justify-content: center;
  cursor: pointer;
  box-shadow: 0px 10px 30px rgba(6, 54, 73, 0.3);
  font-weight: bold;
  font-size: 32px;
  line-height: 39px;
  z-index: 10;

  @media (max-width: 1025px) {
    width: 236px;
  }
`;
