export const LOADING_BUSCAR_MULTA = 'LOADING_BUSCAR_MULTA';
export const BUSCAR_MULTA = 'BUSCAR_MULTA';
export const ERRO_BUSCAR_MULTA = 'ERRO_BUSCAR_MULTA';
export const CPF_CLIENTE_VALIDO = 'CPF_CLIENTE_VALIDO';
export const DATA_NASCIMENTO_VALIDO = 'DATA_NASCIMENTO_VALIDO';
export const EMAIL_CONDUTOR_VALIDO = 'EMAIL_CONDUTOR_VALIDO';
export const PRIMEIRA_HABILITACAO_VALIDO = 'PRIMEIRA_HABILITACAO_VALIDO';
export const DATA_ENTRADA_VALIDA = 'DATA_ENTRADA_VALIDA';
export const LISTA_MULTA = 'LISTA_MULTA';
export const ERRO_MULTA = 'ERRO_MULTA';
export const LIMITTO = 'LIMITTO';
export const LINK_MY_FLEET_LOADING = 'LINK_MY_FLEET_LOADING';
export const LINK_MY_FLEET_LOADED = 'LINK_MY_FLEET_LOADED';
export const LINK_MY_FLEET = 'LINK_MY_FLEET';
export const INTERPOSICAO = 'INTERPOSICAO';
export const ALTERAR_DATA_NASCIMENTO = 'ALTERAR_DATA_NASCIMENTO';
export const ALTERAR_NOME_CONDUTOR = 'ALTERAR_NOME_CONDUTOR';
export const ALTERAR_EMAIL_CONDUTOR = 'ALTERAR_EMAIL_CONDUTOR';
export const ALTERAR_RG_CONDUTOR = 'ALTERAR_RG_CONDUTOR';
export const ALTERAR_CPF_CLIENTE = 'ALTERAR_CPF_CLIENTE';
export const ALTERAR_ORGAO_EMISSOR = 'ALTERAR_ORGAO_EMISSOR';
export const ALTERAR_ESTADO_RG = 'ALTERAR_ESTADO_RG';
export const ALTERAR_CNH_CONDUTOR = 'ALTERAR_CNH_CONDUTOR';
export const ALTERAR_PRIMEIRA_HABILITACAO = 'ALTERAR_PRIMEIRA_HABILITACAO';
export const ALTERAR_ESTADO_CNH = 'ALTERAR_ESTADO_CNH';
export const ALTERAR_TIPO_DOCUMENTO = 'ALTERAR_TIPO_DOCUMENTO';
export const ALTERAR_DOCUMENTO_ESTRANGEIRO = 'ALTERAR_DOCUMENTO_ESTRANGEIRO';
export const ALTERAR_LICENCA_HABILITACAO = 'ALTERAR_LICENCA_HABILITACAO';
export const ALTERAR_DATA_ENTRADA = 'ALTERAR_DATA_ENTRADA';
export const ALTERAR_CONDUTOR_ESTRANGEIRO = 'ALTERAR_CONDUTOR_ESTRANGEIRO';
export const LIMPAR_DADOS_CONDUTOR = 'LIMPAR_DADOS_CONDUTOR';
export const BUSCAR_DADOS_CONDUTOR = 'BUSCAR_DADOS_CONDUTOR';
export const LIMPAR_ASSINATURA_CONDUTOR = 'LIMPAR_ASSINATURA_CONDUTOR';