import React, { Component } from 'react';
import Lista from '../../components/Multas/Lista';
import LayoutInternal from '../../components/LayoutInternal';
import Tracking from '../../hooks/Tracking';
import {ListaMultasContainer} from './styles'
import ErrorBoundary from '../../components/ErrorBoundary'

export default class GridMulta extends Component {
    render() {
        return (
            <LayoutInternal>       
                <ListaMultasContainer>
                <h1 style={{fontSize: "16px", fontWeight: 'bold', color: '#3A3D42', padding: "0 0 0 16px"}}>Multas</h1>
                <ErrorBoundary texto={"na listagem de multas"}>
                        <Lista listar={true} mobile={true}/>
                </ErrorBoundary>
                </ListaMultasContainer>
            </LayoutInternal>
        )
    }
    componentDidMount() {
        Tracking.sendPageview('Listagem de Multas')
    }
}