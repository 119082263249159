import React, { useEffect } from "react";
import LayoutContainer from "../../components/novo-login/Layout/LayoutContainer";
import { LoginCelular } from "../../components/novo-login/LoginCelular/LoginCelular";
import ErrorBoundary from "../../components/ErrorBoundary";
import Tracking from "../../hooks/Tracking";

export default function LoginCelularPage() {
  useEffect(() => {
    Tracking.sendPageview("Login Celular");
  }, []);
  return (
    <React.Fragment>
      <LayoutContainer>
        <ErrorBoundary>
          <LoginCelular />
        </ErrorBoundary>
      </LayoutContainer>
    </React.Fragment>
  );
}
