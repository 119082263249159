import styled, { css } from "styled-components";

export const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media (max-width: 1025px) {
    width: 100%;
    height: 100%;
    position: relative;
  }

  {
  background: #018444;
}
`;

export const ImageRedirectApp = styled.img`
  width: 100%;
  height: 100%;
`;


