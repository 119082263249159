import React, { useState, useEffect } from "react";
import { Link, Redirect } from "react-router-dom";
import {
  FormContainer,
  LabelWrapper,
  LinkContainer,
  Ancora,
  Text,
  TextContainer,
  Button,
  ButtonContainer,
  ErrorContainer,
  MessageError,
  Aviso,
  AvisoContainer,
  WrapperAviso,
  OkLink,
} from "./styles";
import { useAuth } from "../../../providers/auth";
import fire from "../../fire";
import checkAviso from "../../../images/checkAviso.svg";
import Tracking from "../../../hooks/Tracking";

export const LoginReenviarEmail = () => {
  const {
    resetState,
    email,
    password,
    error,
    setError,
    clearErrors,
    sendEmailVerificationFb,
  } = useAuth();

  const [notification, setNotification] = useState(false);

  useEffect(() => {
    clearErrors();
  }, []);

  const reesendEmail = () => {
    Tracking.sendEvent({
      category: "login_reenviar_email",
      action: "LOGIN_REENVIAR_EMAIL_CLICK",
      label: "Clicou no botão para reenviar o e-mail de verificação.",
    });
    clearErrors();
    sendEmailVerificationFb(localStorage.getItem("token-id"))
      .then(() => {
        Tracking.sendEvent({
          category: "login_reenviar_email",
          action: "LOGIN_REENVIAR_EMAIL_SUCESSO",
          label: "E-mail de verificação enviado com sucesso.",
        });
        setNotification(true);
      })
      .catch((error) => {
        if (error.code == "auth/too-many-requests") {
          setError(
            "Já enviamos um e-mail de verificação. Se não recebeu, aguarde um minuto e tente novamente."
          );
        } else {
          Tracking.sendEvent({
            category: "login_reenviar_email",
            action: "LOGIN_REENVIAR_EMAIL_ERRO",
            label: "E-mail de verificação não enviado.",
          });
          setError("Falha ao reenviar o e-mail de verificação.");
        }
      });
  };

  const closeNotification = () => {
    setNotification(false);
  };

  if (!email) {
    return <Redirect to="/Login" />;
  } else {
    return (
      <React.Fragment>
        <FormContainer>
          <LabelWrapper>Verificação de e-mail</LabelWrapper>
          <TextContainer>
            <Text>
              Identificamos que seu e-mail <b>{email}</b> ainda não foi
              verificado. Enviamos um e-mail para que seja feita essa
              verificação.{" "}
            </Text>
            <Text>
              {" "}
              Por favor, confira sua caixa de entrada, spam e lixo eletrônico.
              Caso não tenha recebido, clique no botão <b>REENVIAR</b> abaixo.
            </Text>
          </TextContainer>
          {error ? (
            <ErrorContainer>
              <MessageError>{error}</MessageError>
            </ErrorContainer>
          ) : (
            ""
          )}
          <ButtonContainer>
            <Button onClick={reesendEmail}>Reenviar</Button>
          </ButtonContainer>
          <LinkContainer>
            <Link to="/Login">
              <Ancora onClick={resetState}>Voltar para a tela de login</Ancora>
            </Link>
          </LinkContainer>
        </FormContainer>
        {notification === true ? (
          <React.Fragment>
            <AvisoContainer>
              <Aviso>
                <img src={checkAviso} alt="" />
                <WrapperAviso>
                  Reenviamos o e-mail de verificação. Por favor, confira sua
                  caixa de e-mail.
                </WrapperAviso>
                <OkLink onClick={closeNotification}>OK</OkLink>
              </Aviso>
            </AvisoContainer>
          </React.Fragment>
        ) : null}
      </React.Fragment>
    );
  }
};
