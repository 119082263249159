import {
  LISTAR,
  EXCLUIR,
  VER_MAIS,
  ERRO_KM,
  EXCLUIRKM_LOADING,
  EXCLUIRKM_LOADED,
} from "./EditarKMTypes";
import { HttpGet, HttpPost, HttpPostNew } from "../../functions/default";
import { DESLOGAR } from "../../pages/Login/LoginTypes";
import qs from "qs";
import Tracking from "../../hooks/Tracking";

export const listaKM = (placa) => {
  return (dispatch) => {
    if (navigator.onLine) {
      HttpGet(`/ListarKms?placa=${placa}`, null, null)
        .then(function (result) {
          dispatch({ type: LISTAR, kms: result.data });
          Tracking.sendEvent({
            category: "atualizar_km",
            action: "ATUALIZAR_KM_LISTAR_KM_SUCESSO",
            label: "Sucesso ao listar km.",
          });
        })
        .catch(function (erro) {
          Tracking.sendEvent({
            category: "atualizar_km",
            action: "ATUALIZAR_KM_LISTAR_KM_ERRO",
            label: "Erro ao listar km.",
          });
          if (erro.response && erro.response.status === 401)
            dispatch({
              type: DESLOGAR,
              payload: { message: "Usuário não autenticado." },
            });
          else
            dispatch({
              type: ERRO_KM,
              erro: {
                message:
                  "Ocorreu um erro ao listar a quilometragem, tente novamente mais tarde.",
              },
            });
        });
    }
  };
};

export const verMais = (contador) => {
  return (dispatch) => {
    dispatch({ type: VER_MAIS, contador: contador + 5 });
  };
};

export const excluirKm = (numero_veiculo, data, terceiro) => {
  return (dispatch) => {
    dispatch({ type: EXCLUIRKM_LOADING });
    const objeto = {};
    objeto["NumeroVeiculo"] = numero_veiculo;
    objeto["DataMedicao"] = data;
    objeto["Terceiro"] = terceiro;
    const placa = localStorage.getItem("placa-atual");
    HttpPostNew(`/ExcluirKm?placa=${placa}`, objeto)
      .then(function (result) {
        dispatch({ type: EXCLUIR, resposta: result.data });
        dispatch({ type: EXCLUIRKM_LOADED });
        Tracking.sendEvent({
          category: "atualizar_km",
          action: "ATUALIZAR_KM_EXCLUIR_KM_SUCESSO",
          label: "Sucesso ao excluir km.",
        });
      })
      .catch(function (erro) {
        Tracking.sendEvent({
          category: "atualizar_km",
          action: "ATUALIZAR_KM_EXCLUIR_KM_ERRO",
          label: "Erro ao excluir km.",
        });
        if (erro.response && erro.response.status === 401)
          dispatch({
            type: DESLOGAR,
            payload: { message: "Usuário não autenticado." },
          });
        else
          dispatch({
            type: ERRO_KM,
            erro: {
              message:
                "Ocorreu um erro ao excluir registro, tente novamente mais tarde.",
            },
          });

        dispatch({ type: EXCLUIRKM_LOADED });
      });
  };
};
