import React from "react";
import "./styles";
import logo from "../../../images/image-localiza.png";

import { HeaderWrapper, LogoContainer, Img, SectionContainer, Logo} from "./styles";

const LayoutContainer = (props) => {
  return (
    <React.Fragment>
      <HeaderWrapper>
        <LogoContainer>
          <Img src={logo} alt="Localiza Gestão de Frotas" />
        </LogoContainer>
      </HeaderWrapper>
      <SectionContainer>
        {props.children}
      </SectionContainer>
    </React.Fragment>
  );
};

export default LayoutContainer;
