import React, { Component } from 'react';
import moment from 'moment';
import Slider from '../Slider/Slider';
import ListTwoText from '../ListTwoText';
import { connect } from 'react-redux';
import { formatWeekNameDateHours } from '../../helpers/formatDate'
import Loading from "../Loading";

class DetalhesAgendamento extends Component {
    
    render() {
        if(!this.props.detalheAgendamento)
            return null;
        
        var agendamento = moment(this.props.detalheAgendamento.DataAgendamento);
        var agora = moment(new Date());
        var futuro = agendamento > agora;

        let detalhesServicos = [];

        if (this.props.detalheAgendamento.DescricaoServicos) {
            let i = 1;
            this.props.detalheAgendamento.DescricaoServicos.map((item) => {

                detalhesServicos.push(
                    { 'textOne': item, 'id': i++ }
                );
            })
        }
        return (
            <Slider title='Serviços' iconDesktop='icon-x' iconMobile='icon-chevron-left'  detalheAgendamento={this.props.detalheAgendamento}>
                {
                    this.props.detalheAgendamento !== '' ?
                        <React.Fragment>
                            <h2>{this.props.detalheAgendamento.NomeFornecedor}</h2> 
                            <ul className="list-icon">
                                <li key='0'>
                                    <span className='icon icon-clock'></span>
                                    <span>{formatWeekNameDateHours(this.props.detalheAgendamento.DataAgendamento)}</span>
                                </li> 
                                {
                                    this.props.detalheAgendamento.EnderecoFornecedor!==null ?                                   
                                    futuro === true ? 
                                        <li key='1'>
                                         
                                                <span className='icon icon-navigation'></span>
                                                <span>{this.props.detalheAgendamento.EnderecoFornecedor}</span>
                                        </li>  : 
                                        <li key='1'>
                                            <span className='icon icon-navigation'></span>
                                            <span>{this.props.detalheAgendamento.EnderecoFornecedor}</span>
                                        </li> : ""
                                }
                                {
                                futuro === true ? 
                                this.props.detalheAgendamento.TelefoneFornecedor!==null ?
                                        <li key='2'>
                                            <a className='no-hyperlink'>
                                                <span className='icon icon-phone'></span>
                                                <span>{this.props.detalheAgendamento.TelefoneFornecedor}</span>
                                            </a>
                                        </li> : "" : 
                                 this.props.detalheAgendamento.TelefoneFornecedor!==null ?
                                        <React.Fragment>
                                        <li key='2'>
                                            <span className='icon icon-phone'></span>
                                            <span>{this.props.detalheAgendamento.TelefoneFornecedor}</span>
                                        </li>                                                                           
                                        </React.Fragment> : ""
                                }                                           
                            </ul>
                            <div className="subheader">Detalhamento</div>
                            <ListTwoText itens={detalhesServicos} />   
                            {
                                  futuro === true ?                                 
                                    <div className="mensagem-modal-aviso-cancelamento">Caso queira alterar ou cancelar o agendamento, entre em contato com o Atendimento 24h através do número  
                                    <a href='tel:08009791010' className='no-hyperlink'> 0800 979 1010</a></div>                                  
                                    : null                             
                             }                       
                        </React.Fragment>
                        : null
                }
            </Slider>
        );
    }
}

const mapStateToProps = state => {
    return {
        detalheAgendamento: state.listaAgendamento.detalheAgendamento
    }
};

export default connect(mapStateToProps, {

})(DetalhesAgendamento)