import React from 'react';
import { Grid, makeStyles } from '@material-ui/core';
import ButtonUploadImage from './ButtonUploadImage';

const useStyles = makeStyles(theme => ({
  titulo: {
    fontFamily: "Metropolis-semiBold",
    fontSize: "12px"
  },
  subtitulo: {
    fontFamily: "Metropolis",
    color: '#b8bac1'
  }
}));

function EnvioCNH({ imagemCNH, loadCNH, limparImagemCNH }) {
  const classes = useStyles();

  return (
    <React.Fragment>
      <Grid container>
        <Grid item sm={12}>
          <p className="icon icon-info" style={{ color: "#555555", marginTop: 5 }}>
            <span className={classes.titulo}> ENVIO DA CNH</span></p>
        </Grid>
      </Grid>
      <Grid container justify="center" spacing={1}>
        <Grid item className={classes.subtitulo} sm={12} style={{ marginTop: 10 }}>
          <span>Tire uma foto ou carregue o arquivo da CNH. Fique atento a qualidade da foto para que os dados estejam legíveis.</span>
          <ButtonUploadImage title='Adicionar CNH'
            image={imagemCNH}
            handleUpload={loadCNH}
            handleClearState={limparImagemCNH}
          />
          <Grid item className={classes.subtitulo} sm={12} xs={12}>
            <span>Arquivos permitidos: PNG, JPG, JPEG, e PDF</span>
            <br />
            <span>(Tamanho máximo 3 MB)</span>
          </Grid>
        </Grid>
      </Grid>
    </React.Fragment>
  )
}

export default EnvioCNH;