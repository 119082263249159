import {
    SET_MOSTRAR_TUTORIAL,
    SET_TUTORIAL_JA_FOI_EXIBIDO
} from './TutorialTypes';

const INITIAL_STATE = {
    mostrarTutorial: null,
    tutorialJaFoiExibido: false
}

const TutorialReducer = (state = INITIAL_STATE, action) => {
    switch(action.type){
        case SET_MOSTRAR_TUTORIAL:
            return {...state, mostrarTutorial: action.payload}
        case SET_TUTORIAL_JA_FOI_EXIBIDO:
            return {...state, tutorialJaFoiExibido: action.payload}
        default:
            return state; 
    }
}

export default TutorialReducer;