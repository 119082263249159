import React, { Component } from "react";
import REA from "../../arquivos/ReaPdf.pdf";
import { Link } from "react-router-dom";
import * as Cookie from "../../functions/Cookies";
import { NomeCookieSessaoRea, DominioApi } from "../../config/consts";
import {
  registrarLogReaEmBranco,
  limparMensageErro,
} from "../../pages/Rea/ReaActions";
import ModalMensagem from "../../components/ModalMensagem/ModalMensagem";
import { connect } from "react-redux";
import Tracking from "../../hooks/Tracking";

class Cabecalho extends Component {
  constructor(props) {
    super(props);
    Cookie.set(NomeCookieSessaoRea, "", -1, true);
    this.downloadRea = this.downloadRea.bind(this);
    this.handleRemoveMensagemErro = this.handleRemoveMensagemErro.bind(this);
  }

  downloadRea() {
    this.props.registrarLogReaEmBranco();

    //TODO: adicionar IF para só mostrar esse botão no caso de parâmetro ISENCAO_PREFIXACAO
    if (navigator.onLine) {
      const link = document.createElement("a");
      link.href = DominioApi + "/Arquivos/ReaPdf.pdf";
      link.target = "_blank";
      document.body.appendChild(link);
      setTimeout(function () {
        link.click();
      }, 1000);
    }
  }

  handleRemoveMensagemErro() {
    this.props.limparMensageErro();
  }

  tagTelefone() {
    Tracking.sendEvent({
      category: "roubo",
      action: "ROUBO_CLICK_LINK_TELEFONE",
      label: "Selecionada opção por link do telefone",
    });
  }

  tagPolicia() {
    Tracking.sendEvent({
      category: "roubo",
      action: "ROUBO_CLICK_LINK_POLICIA",
      label: "Selecionada opção por link da policia",
    });
  }

  tagEmail() {
    Tracking.sendEvent({
      category: "roubo",
      action: "ROUBO_CLICK_LINK_EMAIL",
      label: "Selecionada opção por link do email",
    });
  }

  tagBotao() {
    Tracking.sendEvent({
      category: "roubo",
      action: "ROUBO_CLICK_BOTAO_ATENDIMENTO",
      label: "Selecionada opção pelo botão",
    });
  }

  render() {
    let modalMensagem;

    if (this.props.erro) {
      modalMensagem = (
        <ModalMensagem
          tipoModalMensagem="warning"
          icon="icon-x-circle"
          mensagemPrincipal={this.props.erro.message}
          OnToggled={this.handleRemoveMensagemErro}
          textoBotao="OK"
        />
      );
    }

    if (this.props.roubo) {
      return (
        <section className="evento-avaria">
          <h1>Roubo</h1>
          <section className="wrapper-list-decimal">
            <ul className="list-decimal">
              <li>
                Ligue <b>imediatamente</b> para nossa Central de Atendimento
                para reportar o roubo pelo número:{" "}
                <a
                  href="tel:08009791010"
                  style={{ color: "#01602A", fontWeight: "bold" }}
                  onClick={this.tagTelefone}
                >
                  0800 979 1010
                </a>
              </li>
              <li>
                Logo após, ligue para a polícia militar para apoiar na busca
                imediata do carro pelo número:{" "}
                <a
                  href="tel:190"
                  style={{ color: "#01602A", fontWeight: "bold" }}
                  onClick={this.tagPolicia}
                >
                  190
                </a>
              </li>
              <li>
                Registre um Boletim de Ocorrência (BO) com a autoridade policial
                e encaminhe para o e-mail:{" "}
                <a
                  href="mailto:furtoroubo@localiza.com"
                  style={{ color: "#01602A", fontWeight: "bold" }}
                  onClick={this.tagEmail}
                >
                  furtoroubo@localiza.com
                </a>
              </li>
            </ul>
          </section>

          <div
            className="wrapper-number-contact"
            style={{
              width: "215px",
              height: "50px",
              background: "#01602A",
              borderRadius: "16px",
            }}
          >
            <a
              href="tel:08009791010"
              style={{ textDecoration: "none" }}
              onClick={this.tagBotao}
            >
              <div className="number-contact">
                <div
                  className="left"
                  style={{ paddingTop: "15px", paddingLeft: "10px" }}
                >
                  <span
                    className="icon icon-phone"
                    style={{ color: "#DEE9D3" }}
                  ></span>
                </div>

                <div style={{ paddingTop: "5px" }}>
                  <div className="title" style={{ fontSize: "16px", color: '#DEE9D3' }}>
                    Atendimento 24h
                  </div>
                  <div className="tel" style={{ fontSize: "18px", color: "#DEE9D3" }}>
                    0800 979 1010
                  </div>
                </div>
              </div>
            </a>
          </div>
        </section>
      );
    } else {
      return (
        <section className="evento-avaria">
          {modalMensagem}
          <h1>Acidente / Batida</h1>
          <section className="wrapper-list-decimal">
            <ul className="list-decimal">
              <li>
                Precisamos que entre em contato com o Atendimento 24h através do
                número abaixo para registrar o evento.*
              </li>
              <li>
                Clique no histórico abaixo e encaminhe os documentos
                necessários.
                <a
                  href="#"
                  onClick={this.downloadRea}
                  className="link-download gtm-rea-download"
                >
                  <span className="icon icon-file"></span>
                  <span>
                    Fazer o download do Relatório de Eventos e Avarias (REA)
                  </span>
                </a>
                <Link
                  className="link-download gtm-rea-preencher-online"
                  to={"/PreencherRea"}
                >
                  <span className="icon icon-edit"></span>
                  <span>Preencher o REA online</span>
                </Link>
              </li>
            </ul>
            <span>
              *Caso sua empresa não possua a contratação de “Reparos em caso de
              acidente” (verificar na seção Serviços Contratados no menu lateral
              direito), entre em contato com o responsável pelo seu contrato.
            </span>
          </section>
          <section className="wrapper-number-contact">
            <div className="number-contact">
              <div className="left">
                <span className="icon icon-phone"></span>
              </div>
              <a href="tel:08009791010" className="right">
                <div>
                  <div className="title">Atendimento 24h</div>
                  <div className="tel">0800 979 1010</div>
                </div>
              </a>
            </div>
            <div className="number-contact">
              <div className="left">
                <span className="icon icon-phone"></span>
              </div>
              <a href="tel:03132479310" className="right">
                <div>
                  <div className="title">Assistência a terceiros</div>
                  <div className="tel">(31) 3247 – 9310</div>
                </div>
              </a>
            </div>
          </section>
        </section>
      );
    }
  }
}

const mapStateToProps = (state) => {
  return {
    erro: state.Rea.erro,
  };
};

export default connect(mapStateToProps, {
  registrarLogReaEmBranco,
  limparMensageErro,
})(Cabecalho);
