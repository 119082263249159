import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { buscarUltimoRouboPendente } from '../../pages/Sinistro/SinistroActions';
import ModalMensagem from '../ModalMensagem/ModalMensagem';
import { connect } from 'react-redux';

class CardRoubo extends Component {
    componentDidMount() {
        if(localStorage.getItem("placa-atual"))
            this.props.buscarUltimoRouboPendente(localStorage.getItem("placa-atual"), true);
    }

    render() {
        if(this.props.ultimoroubo) {
            return (
                <section key='0' className="wrapper-card">
                    {
                        this.props.erro ?
                            <ModalMensagem tipoModalMensagem='warning' icon='icon-x-circle'
                                mensagemPrincipal={this.props.erro.mensagem}
                                mensagemSecundaria=""
                                textoBotao="OK" />
                            : null
                    }
                    <Link to="/Roubos">
                        <div className='card amarelo'>
                            <div className="title">
                                <span className="icon icon-police2"></span>
                                <span className="text">Roubo</span>
                            </div>
                            <div className="description">
                                <div className="primary-info">{this.props.ultimoroubo.DataFormatada}</div>
                                <div>Aguardando envio de documentos</div>
                            </div>
                        </div>
                    </Link>
            </section>
            );
        }
        return null;
    }
}

const mapStateToProps = state => {
    return {
        ultimoroubo: state.sinistro.ultimoroubo,
        erro: state.sinistro.erro,
        placa: state.menu.placa
    }
};

export default connect(mapStateToProps, {
    buscarUltimoRouboPendente
})(CardRoubo)