import { INSERIR, CABECALHO, ERRO_CABECALHO, INSERIRKM_LOADING, INSERIRKM_LOADED } from './CabecalhoTypes'

const INITIAL_STATE = {
    erro: null,
    resposta: null,
    cabecalho: null,
    loading: false
}

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case INSERIR:
            return { ...state, resposta: action.payload, erro: null };
        case CABECALHO:
            return { ...state, cabecalho: action.payload, erro: null, resposta: null };
        case ERRO_CABECALHO:
            return { ...state, erro: action.payload, resposta: null };
        case INSERIRKM_LOADING:
            return { ...state, loading: true };
        case INSERIRKM_LOADED:
            return { ...state, loading: false };
        default:
            return state;
    }
}