export const base64ToArrayBuffer = (base64) => {
    let binaryString = window.atob(base64);
    let binaryLen = binaryString.length;
    let bytes = new Uint8Array(binaryLen);
    for (let i = 0; i < binaryLen; i++) {
        let ascii = binaryString.charCodeAt(i);
        bytes[i] = ascii;
    }
    return bytes;
}

export const getBlob = (bytes) => {
    var byteArray = base64ToArrayBuffer(bytes);
    let blob = new Blob([byteArray], { type: 'application/pdf' });
    return blob;
}