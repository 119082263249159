import ReactGA from "react-ga4";

const PAGE_LOCATION = window.location.href;
const PAGE_PATH = window.location.pathname 

function sendEvent(payload) {
  ReactGA.event(payload);
}

function sendPageview(path) {
  if(!path){
    path = PAGE_PATH;
  }
  ReactGA._gaCommandSendPageview(path, PAGE_LOCATION, PAGE_PATH);
}

export default {
  sendEvent,
  sendPageview,
};