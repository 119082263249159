import styled, { css } from "styled-components";

export const ButtonContainer = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  background: #01602a;
  border-radius: 16px;
  border: transparent;

  color: #FFFFFF;

  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 15px;
  font-family: "Inter", sans-serif;

  text-align: center;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  padding: 16px;
  width: 100%;
  height: 52px;
  margin-bottom: 16px;

  ${({ isOutlined }) =>
    isOutlined === true &&
    css`
      background: #fff;
      border: 1px solid #01602A;
      color: #01602a;
    `}/* 

  &:not(:disabled):hover {
    filter: brightness(0.9);
  }

  &:disabled {
    opacity: 0.6;
    cursor: not-allowed;
  } */
`;
