import React, { useState } from "react";
import Tracking from "../../hooks/Tracking";
import MaskedInput from "react-text-mask";
import { Tabs, Tab, Box, Grid } from "@material-ui/core";
import {
  validaEmail,
  somenteNumerosFromString,
  dataValida,
  validarCpf,
} from "../../functions/manutencaoGeralValidacoes";
import moment from "moment";

function TabPanel({ children, value, index }) {
  return (
    <React.Fragment>{value === index && <Box>{children}</Box>}</React.Fragment>
  );
}

function DadosCondutor({
  condutor,
  cpfClienteValido,
  dataNascimentoValida,
  emailCondutorValido,
  primeiraHabilitacaoValida,
  dataEntradaValida,
  bloqueadoDadosCondutor,
  onChangeCondutorEstrangeiro,
  onChangeCpfCliente,
  onChangesDadosCpfDataNascimento,
  onChangeDataNascimento,
  onChangeNomeCondutor,
  onChangeEmailCondutor,
  onChangeRgCondutor,
  onChangeOrgaoEmissor,
  onChangeEstadoRg,
  onChangeCnhCondutor,
  onChangePrimeiraHabilitacao,
  onChangeEstadoCnh,
  onChangeTipoDocumento,
  onChangeDocumentoEstrangeiro,
  onChangeLicencaHabilitacao,
  onChangeDataEntrada,
  verificarCpfClienteValido,
  verificarDataNascimentoValido,
  verificarEmailCondutorValido,
  verificarPrimeiraHabilitacaoValido,
  verificarDataEntradaValido,
}) {
  const [value, setValue] = useState(0);

  const gaCategory = "indicacao_de_condutor_por_assinatura";

  const handleChange = (event, newValue) => {
    setValue(newValue);
    onChangeCondutorEstrangeiro(newValue);
  };

  const handleAlteraCpfCliente = (event) => {
    if (event.target.value !== null) {
      let cpfSomenteNumeros = somenteNumerosFromString(event.target.value);
      let validacaoCpf = validarCpf(event.target.value);

      if (cpfSomenteNumeros.length >= 11) {
        if (validacaoCpf) {
          onChangeCpfCliente(event.target.value);
          verificarCpfClienteValido(true);
        } else {
          verificarCpfClienteValido(false);
        }
      }

      if (moment.isMoment(condutor.dataNascimento)) {
        if (condutor.dataNascimento.isValid() && validacaoCpf) {
          onChangesDadosCpfDataNascimento(
            cpfSomenteNumeros,
            moment(condutor.dataNascimento).format("YYYY-MM-DD")
          );
        }
      }
    }
  };

  const handleAlteraDataNascimento = (event) => {
    onChangeDataNascimento(event.target.value);

    if (somenteNumerosFromString(event.target.value).length === 8) {
      if (dataValida(event.target.value)) {
        let data = moment(event.target.value, ["DD/MM/YYYY"], "pt-br");
        onChangeDataNascimento(data);

        if (data > moment()) {
          verificarDataNascimentoValido(false);
        } else {
          verificarDataNascimentoValido(true);
          if (validarCpf(condutor.cpfCliente)) {
            onChangesDadosCpfDataNascimento(
              somenteNumerosFromString(condutor.cpfCliente),
              moment(data).format("YYYY-MM-DD")
            );
          }
        }
      } else {
        verificarDataNascimentoValido(false);
      }
    }
  };

  const handleAlteraNomeCondutor = (event) => {
    if (event.target.value !== null) {
      onChangeNomeCondutor(event.target.value);
    }
  };

  const handleAlteraEmailCondutor = (event) => {
    if (event.target.value !== null) {
      onChangeEmailCondutor(event.target.value);

      let validacaoEmail = validaEmail(event.target.value);
      if (validacaoEmail) {
        verificarEmailCondutorValido(true);
      } else {
        verificarEmailCondutorValido(false);
      }
    }
  };

  const handleAlteraRgCondutor = (event) => {
    if (event.target.value !== null) {
      onChangeRgCondutor(event.target.value);
    }
  };

  const handleAlteraOrgaoEmissor = (event) => {
    if (event.target.value !== null) {
      onChangeOrgaoEmissor(event.target.value);
    }
  };

  const handleAlteraEstadoRg = (event) => {
    if (event.target.value !== null) {
      onChangeEstadoRg(event.target.value);
    }
  };

  const handleAlteraCnhCondutor = (event) => {
    if (event.target.value !== null) {
      onChangeCnhCondutor(event.target.value);
    }
  };

  const handleAlteraPrimeiraHabilitacao = (event) => {
    onChangePrimeiraHabilitacao(event.target.value);

    if (somenteNumerosFromString(event.target.value).length === 8) {
      if (dataValida(event.target.value)) {
        let data = moment(event.target.value, ["DD/MM/YYYY"], "pt-br");
        onChangePrimeiraHabilitacao(data);

        if (data > moment()) {
          verificarPrimeiraHabilitacaoValido(false);
        } else {
          verificarPrimeiraHabilitacaoValido(true);
        }
      } else {
        verificarPrimeiraHabilitacaoValido(false);
      }
    }
  };

  const handleAlteraEstadoCnh = (event) => {
    if (event.target.value !== null) {
      onChangeEstadoCnh(event.target.value);
    }
  };

  const handleAlteraTipoDocumento = (event) => {
    if (event.target.value !== null) {
      onChangeTipoDocumento(event.target.value);
    }
  };

  const handleAlteraDocumentoEstrangeiro = (event) => {
    if (event.target.value !== null) {
      onChangeDocumentoEstrangeiro(event.target.value);
    }
  };

  const handleAlteraLicencaHabilitacao = (event) => {
    if (event.target.value !== null) {
      onChangeLicencaHabilitacao(event.target.value);
    }
  };

  const handleAlteraDataEntrada = (event) => {
    onChangeDataEntrada(event.target.value);

    if (somenteNumerosFromString(event.target.value).length === 8) {
      if (dataValida(event.target.value)) {
        let data = moment(event.target.value, ["DD/MM/YYYY"], "pt-br");
        onChangeDataEntrada(data);

        if (data > moment()) {
          verificarDataEntradaValido(false);
        } else {
          verificarDataEntradaValido(true);
        }
      } else {
        verificarDataEntradaValido(false);
      }
    }
  };

  let exibicaoDataNascimento = "";
  let exibicaoPrimeiraHabilitacao = "";
  let exibicaoDataEntrada = "";

  if (condutor.dataNascimento === "" || condutor.dataNascimento === null) {
    exibicaoDataNascimento = condutor.dataNascimento;
  } else if (moment.isMoment(condutor.dataNascimento)) {
    exibicaoDataNascimento = moment(condutor.dataNascimento).format(
      "DD/MM/YYYY"
    );
  } else {
    exibicaoDataNascimento = condutor.dataNascimento;
  }

  if (
    condutor.primeiraHabilitacao === "" ||
    condutor.primeiraHabilitacao === null
  ) {
    exibicaoPrimeiraHabilitacao = condutor.primeiraHabilitacao;
  } else if (
    moment.isMoment(condutor.primeiraHabilitacao) ||
    condutor.primeiraHabilitacao.length > 10
  ) {
    exibicaoPrimeiraHabilitacao = moment(condutor.primeiraHabilitacao).format(
      "DD/MM/YYYY"
    );
  } else {
    exibicaoPrimeiraHabilitacao = condutor.primeiraHabilitacao;
  }

  if (condutor.dataEntrada === "" || condutor.dataEntrada === null) {
    exibicaoDataEntrada = condutor.dataEntrada;
  } else if (
    moment.isMoment(condutor.dataEntrada) ||
    condutor.dataEntrada.length > 10
  ) {
    exibicaoDataEntrada = moment(condutor.dataEntrada).format("DD/MM/YYYY");
  } else {
    exibicaoDataEntrada = condutor.dataEntrada;
  }

  return (
    <Grid container>
      <Grid item sm={12}>
        <div className="row col-md-12">
          <div className="form-intro col-12">
            <p
              className="icon icon-info text-justify"
              style={{ color: "#555555", margin: "5px 0px" }}
            >
              <span
                style={{
                  fontFamily: "Metropolis-semiBold",
                  fontSize: "12px",
                  color: "#555555",
                }}
              >
                {" "}
                DADOS DO CONDUTOR INFRATOR
              </span>
            </p>
          </div>
          <div>
            <span
              style={{
                fontFamily: "Metropolis",
                fontSize: "10px",
                textAlign: "left",
                color: "#b8bac1",
              }}
            >
              Todos os campos abaixo são obrigatórios.
            </span>
          </div>
        </div>
        <Tabs
          value={value}
          indicatorColor="primary"
          textColor="primary"
          onChange={handleChange}
          variant="fullWidth"
        >
          <Tab label="Condutor Brasileiro" />
          <Tab label="Condutor Estrangeiro" />
        </Tabs>
        <TabPanel value={value} index={0}>
          <div className="row col-md-12" style={{ marginTop: 16 }}>
            <div className="col-md-6 col-lg-6">
              <div
                className={`wrapper-input ${
                  cpfClienteValido === false ? "has-error" : ""
                }`}
              >
                <div className="group-input">
                  <MaskedInput
                    type="tel"
                    name="cpf"
                    id="cpf"
                    className="input"
                    maxLength={40}
                    mask={[
                      /\d/,
                      /\d/,
                      /\d/,
                      ".",
                      /\d/,
                      /\d/,
                      /\d/,
                      ".",
                      /\d/,
                      /\d/,
                      /\d/,
                      "-",
                      /\d/,
                      /\d/,
                    ]}
                    onChange={handleAlteraCpfCliente}
                    value={condutor.cpfCliente}
                    required
                  />
                  <label htmlFor="cpf" className="label">
                    CPF
                  </label>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-6">
              <div
                className={`wrapper-input ${
                  dataNascimentoValida === false ? "has-error" : ""
                }`}
              >
                <div className="group-input">
                  <MaskedInput
                    type="tel"
                    name="dataNasc"
                    id="dataNasc"
                    className="input"
                    inputMode="numeric"
                    mask={[
                      /\d/,
                      /\d/,
                      "/",
                      /\d/,
                      /\d/,
                      "/",
                      /\d/,
                      /\d/,
                      /\d/,
                      /\d/,
                    ]}
                    onChange={handleAlteraDataNascimento}
                    value={exibicaoDataNascimento}
                    required
                  />
                  <label htmlFor="dataNasc" className="label">
                    Data de Nascimento
                  </label>
                </div>
              </div>
            </div>

            <div className="col-md-6 col-lg-6">
              <div className={"wrapper-input"}>
                <div className="group-input">
                  <input
                    type="text"
                    name="condutor"
                    id="condutor"
                    className="input"
                    maxLength={40}
                    onChange={handleAlteraNomeCondutor}
                    value={condutor.nomeCondutor}
                    disabled={bloqueadoDadosCondutor}
                    required
                  />
                  <label htmlFor="condutor" className="label">
                    Nome Completo
                  </label>
                </div>
              </div>
            </div>

            <div className="col-md-6 col-lg-6">
              <div
                className={`wrapper-input ${
                  emailCondutorValido === false ? "has-error" : ""
                }`}
              >
                <div className="group-input">
                  <input
                    type="text"
                    name="email"
                    id="email"
                    className="input"
                    maxLength={40}
                    onChange={handleAlteraEmailCondutor}
                    value={condutor.emailCondutor}
                    disabled={bloqueadoDadosCondutor}
                    required
                  />
                  <label htmlFor="email" className="label">
                    E-mail
                  </label>
                </div>
              </div>
            </div>

            <div className="col-md-6 col-lg-6">
              <div className={"wrapper-input"}>
                <div className="group-input">
                  <input
                    type="text"
                    name="rg"
                    id="rg"
                    className="input"
                    maxLength={15}
                    onChange={handleAlteraRgCondutor}
                    value={condutor.rgCondutor}
                    disabled={bloqueadoDadosCondutor}
                    required
                  />
                  <label htmlFor="rg" className="label">
                    RG
                  </label>
                </div>
              </div>
            </div>

            <div className="col-md-6 col-lg-6">
              <div className={"wrapper-input"}>
                <div className="group-input">
                  <input
                    type="text"
                    name="orgaoEmissor"
                    id="orgaoEmissor"
                    className="input"
                    maxLength={15}
                    onChange={handleAlteraOrgaoEmissor}
                    value={condutor.orgaoEmissor}
                    disabled={bloqueadoDadosCondutor}
                    required
                  />
                  <label htmlFor="orgaoEmissor" className="label">
                    Orgão Emissor
                  </label>
                </div>
              </div>
            </div>

            <div className="col-md-6 col-lg-6">
              <div className={"wrapper-input"}>
                <div className="group-input">
                  <input
                    type="text"
                    name="estadoRg"
                    id="estadoRg"
                    className="input"
                    maxLength={2}
                    onChange={handleAlteraEstadoRg}
                    value={condutor.estadoRg}
                    disabled={bloqueadoDadosCondutor}
                    required
                  />
                  <label htmlFor="estadoRg" className="label">
                    Estado (RG)
                  </label>
                </div>
              </div>
            </div>

            <div className="col-md-6 col-lg-6">
              <div className={"wrapper-input"}>
                <div className="group-input">
                  <input
                    type="tel"
                    name="cnh"
                    id="cnh"
                    className="input"
                    inputMode="numeric"
                    maxLength={23}
                    onChange={handleAlteraCnhCondutor}
                    value={condutor.cnhCondutor}
                    disabled={bloqueadoDadosCondutor}
                    required
                  />
                  <label htmlFor="cnh" className="label">
                    Nº CNH
                  </label>
                </div>
              </div>
            </div>

            <div className="col-md-6 col-lg-6">
              <div
                className={`wrapper-input ${
                  primeiraHabilitacaoValida === false ? "has-error" : ""
                }`}
              >
                <div className="group-input">
                  <MaskedInput
                    type="tel"
                    name="validade"
                    id="validade"
                    className="input"
                    mask={[
                      /\d/,
                      /\d/,
                      "/",
                      /\d/,
                      /\d/,
                      "/",
                      /\d/,
                      /\d/,
                      /\d/,
                      /\d/,
                    ]}
                    onChange={handleAlteraPrimeiraHabilitacao}
                    value={exibicaoPrimeiraHabilitacao}
                    disabled={bloqueadoDadosCondutor}
                    required
                  />
                  <label htmlFor="validade" className="label">
                    Data 1ª habilitação
                  </label>
                </div>
              </div>
            </div>

            <div className="col-md-6 col-lg-6">
              <div className={"wrapper-input"}>
                <div className="group-input">
                  <input
                    type="text"
                    name="estadoCng"
                    id="estadoCnh"
                    className="input"
                    maxLength={2}
                    onChange={handleAlteraEstadoCnh}
                    value={condutor.estadoCnh}
                    disabled={bloqueadoDadosCondutor}
                    required
                  />
                  <label htmlFor="estadoCnh" className="label">
                    Estado (CNH)
                  </label>
                </div>
              </div>
            </div>
          </div>
        </TabPanel>
        <TabPanel value={value} index={1}>
          <div className="row col-md-12" style={{ marginTop: 16 }}>
            <div className="col-md-6 col-lg-6">
              <div className={"wrapper-input"}>
                <div className="group-input">
                  <input
                    type="text"
                    name="condutorEstrangeiro"
                    id="condutorEstrangeiro"
                    className="input"
                    maxLength={40}
                    onChange={handleAlteraNomeCondutor}
                    value={condutor.nomeCondutor}
                    required
                  />
                  <label htmlFor="condutorEstrangeiro" className="label">
                    Nome Completo
                  </label>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-6">
              <div className={"wrapper-input"}>
                <div className="group-input">
                  <input
                    type="text"
                    name="tipoDocumento"
                    id="tipoDocumento"
                    className="input"
                    maxLength={20}
                    onChange={handleAlteraTipoDocumento}
                    value={condutor.tipoDocumento}
                    required
                  />
                  <label htmlFor="tipoDocumento" className="label">
                    Tipo Documento Identificação
                  </label>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-6">
              <div className={"wrapper-input"}>
                <div className="group-input">
                  <input
                    type="text"
                    name="numeroDocumento"
                    id="numeroDocumento"
                    className="input"
                    maxLength={40}
                    onChange={handleAlteraDocumentoEstrangeiro}
                    value={condutor.documentoEstrangeiro}
                    required
                  />
                  <label htmlFor="numeroDocumento" className="label">
                    N° Documento Identificação
                  </label>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-6">
              <div className={"wrapper-input"}>
                <div className="group-input">
                  <input
                    type="text"
                    name="numeroHabilitacao"
                    id="numeroHabilitacao"
                    className="input"
                    maxLength={40}
                    onChange={handleAlteraLicencaHabilitacao}
                    value={condutor.licencaHabilitacao}
                    required
                  />
                  <label htmlFor="numeroHabilitacao" className="label">
                    N° Registro Licença de Habilitação
                  </label>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-6">
              <div
                className={`wrapper-input ${
                  dataEntradaValida === false ? "has-error" : ""
                }`}
              >
                <div className="group-input">
                  <MaskedInput
                    type="tel"
                    name="dataEntrada"
                    id="dataEntrada"
                    className="input"
                    mask={[
                      /\d/,
                      /\d/,
                      "/",
                      /\d/,
                      /\d/,
                      "/",
                      /\d/,
                      /\d/,
                      /\d/,
                      /\d/,
                    ]}
                    onChange={handleAlteraDataEntrada}
                    value={exibicaoDataEntrada}
                    required
                  />
                  <label htmlFor="dataEntrada" className="label">
                    Data Entrada no Brasil
                  </label>
                </div>
              </div>
            </div>
          </div>
        </TabPanel>
      </Grid>
    </Grid>
  );
}

export default DadosCondutor;
