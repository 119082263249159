export default {
    MULTA: 2,

    properties: {
        2: {
            acesso: 0,
            indicacaoCondutorAssinatura: 1,
            indicacaoCondutorTermo: 2,
            downloadNotificacao: 3
        }
    }
}