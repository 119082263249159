import React, { Component } from "react";
import ButtonLightIcon from "../../../components/ButtonLightIcon";
import PainelConcluir from "./PainelConcluir";
import ErrorBoundary from "../../../components/ErrorBoundary";
import Tracking from "../../../hooks/Tracking";

const GACATERGORIAAGEDAMENTO = "agendamento_servico";

export default class PainelFornecedores extends Component {
  constructor(props) {
    super(props);

    this.state = {
      limite: 3,

      model: {
        kmAtual: null,
        condutor: null,
        contato: null,
        celcontato: null,
        cidade: null,
        servicos: null,
        fornecedor: null,
        shiftList: null,
        availability: null,
      },
    };
  }

  OnVerMais() {
    var anterior = this.state.limite;
    this.setState({ limite: anterior + 5 });
  }

  OnFornecedorChange = function (c, s, a) {
    var model = this.state.model;
    model.fornecedor = c;
    model.shiftList = s;
    model.availability = a;

    this.setState({ model: model });
    Tracking.sendEvent({
      category: GACATERGORIAAGEDAMENTO,
      action: "AGENDAMENTO_SELECIONANDO_FORNECEDOR",
      label: "Selecionando Forecedor ",
    });
  };

  componentDidUpdate(prevProps) {
    if (this.props.model != prevProps.model) {
      this.setState({ model: this.props.model });
    }
  }

  componentDidMount() {
    this.setState({ model: this.props.model });
  }

  render() {
    if (!this.props.fornecedores) return null;

    if (this.props.fornecedores.length === 0) {
      return (
        <section className="wrapper-fornecedores">
          <h2 className="sem-fornecedores">
            Não identificamos nenhum fornecedor disponível no local escolhido.
            Nesse caso, precisamos que entre em contato com o Atendimento 24h
            através do número{" "}
            <a className="no-hyperlink" href="tel:08009791010">
              {" "}
              0800 979 1010.
            </a>
          </h2>
        </section>
      );
    }

    let i = 0;
    return (
      <div>
        <section className="wrapper-fornecedores">
          <h2>Selecione abaixo o fornecedor desejado</h2>
          <div className="lista-fornecedore">
            {this.props.fornecedores.length > 0
              ? this.props.fornecedores
                  .slice(0, this.state.limite)
                  .map((item) => {
                    return (
                      <div key={i++} className="item-lista-fornecedores">
                        <label className="label--radio">
                          <input
                            type="radio"
                            name="ListaFornecedores"
                            className="radio"
                            checked={
                              this.state.model.fornecedor ===
                              item.mSupplierCGCField
                            }
                            onChange={(c, s) =>
                              this.OnFornecedorChange(
                                item.mSupplierCGCField,
                                item.mShiftListField,
                                item.mAvailableDateForAttendanceField
                              )
                            }
                          />{" "}
                          {item.mNameField}
                        </label>
                        <span className="endereco">
                          {item.mAddressField} | {item.mNeighborhoodField},{" "}
                          {item.mCityNameField}
                        </span>
                      </div>
                    );
                  })
              : null}
          </div>
          {this.props.fornecedores.length > this.state.limite ? (
            <div onClick={() => this.OnVerMais()}>
              <ButtonLightIcon label={"VER MAIS FORNECEDORES"} />
            </div>
          ) : null}
        </section>

        {this.state.model.fornecedor ? (
          <ErrorBoundary texto={"neste painel"}>
            <PainelConcluir
              model={this.state.model}
              fornecedor={this.state.model.fornecedor}
              shiftList={this.state.model.shiftList}
              availability={this.state.model.availability}
            />
          </ErrorBoundary>
        ) : null}
      </div>
    );
  }
}
