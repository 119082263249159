import React, { useEffect, useState } from "react";
import { Link, Redirect, useHistory } from "react-router-dom";
import { CaptchaSiteKey } from "../../../config/consts";
import ReCAPTCHA from "react-google-recaptcha";
import {
  FormContainer,
  LabelWrapper,
  InputContainer,
  TextFieldMaterial,
  LinkContainer,
  Ancora,
  ButtonContainer,
  Button,
  TextContainer,
  Text,
  SMSTextContainer,
  SMSText,
  MessageError,
  ErrorContainer,
  Aviso,
  AvisoContainer,
  WrapperAviso,
  OkLink,
  WrapperAvisoTitle,
} from "./styles";
import { useAuth } from "../../../providers/auth";
import Loading from "../../Loading";
import checkAviso from "../../../images/checkAviso.svg";
import Warning from "../../../images/Warning.png";
import Tracking from "../../../hooks/Tracking";

export const LoginCelular = () => {
  const {
    email,
    resetState,
    verifyPhone,
    redoCaptcha,
    reCaptchaRef,
    getTokenLocalStorage,
    error,
    setError,
    clearErrors,
    clickCaptcha,
    number,
    setNumber,
    loading,
    setClickCaptcha,
    notification200,
    notification404,
    notification409,
    setNotification200,
    setNotification404,
    setNotification409,
    phoneReturnEmail,
  } = useAuth();

  const [click, setClick] = useState(false);
  const history = useHistory();

  const handleClick = () => {
    setClick(true);
    if (!number) {
      setError("Campo de celular obrigatório");
    } else if (number.replace(/[^\d]+/g, "").length !== 11) {
      setError("Número de celular inválido");
    } else if (clickCaptcha === false) {
      setError("Por favor, clique em não sou um robô");
    } else {
      clearErrors();
      verifyPhone(
        number.replace(/[^\d]+/g, ""),
        localStorage.getItem("recaptcha-token")
      );
    }
  };

  const maskPhone = (value) => {
    return value
      .replace(/\D/g, "")
      .replace(/(\d{2})(\d)/, "($1) $2")
      .replace(/(\d{5})(\d{4})(\d)/, "$1-$2");
  };

  useEffect(() => {
    clearErrors();
    setClickCaptcha(false);
    setNotification200(false);
    setNotification404(false);
    setNotification409(false);
  }, []);

  const redirectToLogin = () => {
    history.push("/Login");
  };

  const redirectToPlaca = () => {
    history.push("/LoginRenavamMain");
  };

  const emailMask = (email) => {
    var maskedEmail = email.replace(/([^@])/g, "*").split("");
    var previous = "";
    for (let i = 0; i < maskedEmail.length; i++) {
      if (maskedEmail.length > 20) {
        if (i <= 2 || i >= 10) {
          maskedEmail[i] = email[i];
        }
      }
      if (maskedEmail.length <= 20) {
        if (i <= 1 || i >= 7) {
          maskedEmail[i] = email[i];
        }
      }

      previous = email[i];
    }
    return maskedEmail.join("");
  };

  return (
    <React.Fragment>
      <FormContainer>
        <LabelWrapper>Informe seu número de celular</LabelWrapper>
        <TextContainer>
          <Text>Vamos verificar se há um e-mail vinculado ao seu número.</Text>
        </TextContainer>
        <InputContainer>
          <TextFieldMaterial
            id="Celular"
            name="celular"
            label="Número do celular"
            variant="outlined"
            onChange={(e) => setNumber(maskPhone(e.target.value))}
            value={number}
            InputLabelProps={{
              style: { color: "#3A3D42", fontSize: "14px" },
            }}
          />
        </InputContainer>
        {error ? (
          <ErrorContainer>
            <MessageError>{error}</MessageError>
          </ErrorContainer>
        ) : (
          ""
        )}
        <ReCAPTCHA
          onChange={getTokenLocalStorage}
          ref={reCaptchaRef}
          sitekey={CaptchaSiteKey}
          onExpired={redoCaptcha}
        />
        <ButtonContainer>
          <Button onClick={handleClick}>Prosseguir</Button>
        </ButtonContainer>
        <LinkContainer>
          <Link to="/Login">
            <Ancora onClick={resetState}>Voltar para a tela de login</Ancora>
          </Link>
        </LinkContainer>
      </FormContainer>
      {loading === true ? <Loading /> : ""}
      {clickCaptcha === true && click === true && notification200 === true ? (
        <React.Fragment>
          <AvisoContainer>
            <Aviso>
              <img src={checkAviso} alt="" />
              <WrapperAvisoTitle>
                Encontramos o seu e-mail cadastrado
              </WrapperAvisoTitle>
              <WrapperAviso>
                O e-mail <b>{emailMask(phoneReturnEmail)}</b> está vinculado ao
                número informado. Por favor, utilize esse e-mail para fazer
                login.
              </WrapperAviso>
              <OkLink onClick={redirectToLogin}>OK</OkLink>
            </Aviso>
          </AvisoContainer>
        </React.Fragment>
      ) : (
        ""
      )}
      {clickCaptcha === true && click === true && notification404 === true ? (
        <React.Fragment>
          <AvisoContainer>
            <Aviso>
              <img src={Warning} alt="" style={{ width: 34, height: 34 }} />
              <WrapperAvisoTitle>Não encontramos seu e-mail</WrapperAvisoTitle>
              <WrapperAviso>
                Não encontramos um e-mail vinculado ao número informado. Por
                favor, faça login pela opção de placa e renavam.
              </WrapperAviso>
              <OkLink onClick={redirectToPlaca}>OK</OkLink>
            </Aviso>
          </AvisoContainer>
        </React.Fragment>
      ) : (
        ""
      )}
      {clickCaptcha === true && click === true && notification409 === true ? (
        <React.Fragment>
          <AvisoContainer>
            <Aviso>
              <img src={Warning} alt="" style={{ width: 34, height: 34 }} />
              <WrapperAvisoTitle>
                Encontramos mais de um e-mail vinculado
              </WrapperAvisoTitle>
              <WrapperAviso>
                Mais de um e-mail está vinculado ao número informado. Por favor,
                faça login pela opção de placa e renavam.
              </WrapperAviso>
              <OkLink onClick={redirectToPlaca}>OK</OkLink>
            </Aviso>
          </AvisoContainer>
        </React.Fragment>
      ) : (
        ""
      )}
    </React.Fragment>
  );
};
