import React from 'react';
import { Grid, makeStyles, Paper, Typography } from '@material-ui/core';
import { CloseRounded } from '@material-ui/icons';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        marginTop: 20,
        marginBottom: 20,
        textAlign: 'center'
    },
    paper: {
        padding: theme.spacing(2),
        margin: 'auto',
        maxWidth: 500,
    },
    img: {
        margin: 'auto',
        display: 'block',
        maxWidth: '100%',
        maxHeight: '100%',
        width: 256,
        height: 256,
    }
}));

function ExibirImagem({ image, handleClearState }) {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <Paper className={classes.paper}>
                <Grid container direction={'row'} spacing={2}>
                    <Grid item>
                        {
                            (() => {
                                if (image.type !== undefined) {
                                    const file = image && URL.createObjectURL(image);

                                    if (image.type === 'application/pdf') {
                                        return <Document file={file}>
                                            <Page width={192} height={192} pageNumber={1} />
                                        </Document>
                                    }
                                    return <img className={classes.img} src={file} />
                                }

                                return <img className={classes.img} src={`data:image/png;base64,${image}`} />
                            })()
                        }
                    </Grid>
                    <Grid item xs={12} sm container>
                        <Grid item xs container alignItems='center' direction="row" spacing={2}>
                            <Grid item xs>
                                <Typography gutterBottom style={{ fontFamily: 'Metropolis', fontSize: 12 }}>
                                    {image.name}
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid item>
                            { handleClearState && <CloseRounded onClick={handleClearState} color='error' cursor='pointer'></CloseRounded>}
                        </Grid>
                    </Grid>
                </Grid>
            </Paper>
        </div >
    )
}

export default ExibirImagem;