import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import LayoutInternal from "../../components/LayoutInternal";
import { Grid, makeStyles, Typography, Container } from "@material-ui/core";
import { formatDate, formatDateAndHour } from "../../helpers/formatDate";
import { urlAPI } from "../../config/consts";
import ModalInterposicao from "../../components/Multas/ModalInterposicaoRecursos";
import {
  buscarMulta,
  BaixarArquivoMulta,
  limparErroMulta,
} from "../../components/Multas/CardMultaAction";
import Loading from "../../components/Loading";
import SelecaoProcessoMulta from "../../components/IndicarCondutor/SelecaoProcessoMulta";
import IndicarCondutorAssinatura from "../../components/IndicarCondutor/IndicarCondutorAssinatura";
import IndicarCondutorTermoResponsabilidade from "../../components/IndicarCondutor/IndicarCondutorTermoResponsabilidade";
import { NomeCookieTipoLogin } from "../../config/consts";
import * as Cookie from "../../functions/Cookies";
import Tracking from "../../hooks/Tracking";
import ModalMensagem from "../../components/ModalMensagem/ModalMensagem";

const useStyles = makeStyles({
  grid: {
    marginBottom: 15,
  },
  title: {
    fontFamily: "Metropolis-semiBold",
    fontSize: "12px",
  },
  subtitle: {
    fontFamily: "Metropolis",
    fontSize: "12px",
    color: "#7B808C",
  },
  subtitlePrimary: {
    fontFamily: "Metropolis",
    fontSize: "12px",
    color: "#01602A",
  },
});

function InformacaoMultaPage({
  history,
  match,
  buscarMulta,
  BaixarArquivoMulta,
  limparErroMulta,
  multa,
  loading_buscar_multa,
  loading,
  erro,
  situacao,
}) {
  const classes = useStyles();
  const [modalOpenedInterposicao, setModalOpenedInterposicao] = useState(false);
  const [termoStep, setTermoStep] = useState(0);
  const [situacaoLogin, setSituacaoLogin] = useState(null);
  const [processo, setProcesso] = useState({
    indicarCondutor: false,
    assinatura: false,
    termo: false,
  });

  const gaCategory = "informacao_multa";

  useEffect(() => {
    Tracking.sendPageview("Detalhes Multa");
    const { id, orgao } = match.params;
    buscarMulta(id, orgao);
    setSituacaoLogin(parseInt(Cookie.get(NomeCookieTipoLogin, true)));
  }, [match.params, buscarMulta]);

  const handleSetarProcesso = (tipo, step) => {
    if (tipo === "termo") setTermoStep(step);

    setProcesso({
      indicarCondutor: tipo === "indicar",
      assinatura: tipo === "assinatura",
      termo: tipo === "termo",
    });
  };

  const handleVoltar = () => {
    setProcesso({
      indicarCondutor: false,
      assinatura: false,
      termo: false,
    });
  };

  const handleIndicar = () => {
    if (situacao === 100) {
      handleSetarProcesso("termo", 0);
    } else {
      handleSetarProcesso("indicar");
    }
  };

  const handleFinalizarProcesso = () => {
    history.push({
      pathname: "/Home",
    });
  };

  const handleCloseInterposicao = () => {
    setModalOpenedInterposicao(false);
  };

  const handleOpenInterposicao = () => {
    setModalOpenedInterposicao(true);
  };

  const handleAssinatura = () => {
    history.push({
      pathname: "/Assinatura",
    });
  };

  const corCardInformacao = (corCard) => {
    switch (corCard) {
      case "card verde":
        return "#00984a";
      case "card vermelho":
        return "#f85843";
      default:
        return "#FDC100";
    }
  };

  const downloadArquivo = () => {
    BaixarArquivoMulta(
      localStorage.getItem("placa-atual"),
      multa.AIT,
      multa.CodigoOrgao
    );
  };

  const limparStateErroMulta = (toggled) => {
    limparErroMulta();
  };

  if (loading_buscar_multa === null || loading_buscar_multa === true)
    return <Loading />;

  if (processo.indicarCondutor)
    return <SelecaoProcessoMulta handleSetarProcesso={handleSetarProcesso} />;

  if (processo.assinatura)
    return (
      <IndicarCondutorAssinatura
        multa={multa}
        situacaoLogin={situacaoLogin}
        handleSetarProcesso={handleIndicar}
        handleFinalizarProcesso={handleFinalizarProcesso}
      />
    );

  if (processo.termo)
    return (
      <IndicarCondutorTermoResponsabilidade
        multa={multa}
        situacao={situacao}
        termoStep={termoStep}
        handleIndicar={handleIndicar}
        handleVoltar={handleVoltar}
        handleFinalizarProcesso={handleFinalizarProcesso}
      />
    );

  return (
    <LayoutInternal title="Multa">
      <main>
        {loading ? <Loading /> : null}
        {erro ? (
          <ModalMensagem
            tipoModalMensagem="warning"
            icon="icon-x-circle"
            mensagemPrincipal={erro.message}
            mensagemSecundaria=""
            textoBotao="OK"
            OnToggled={limparStateErroMulta}
          />
        ) : null}

        <Container maxWidth="lg">
          {multa.UsuarioIndicado &&
          multa.DescSituacaoMulta === "Pendente Indicação" ? (
            <div
              style={{
                border: "1px solid #eeeeee",
                borderRadius: 5,
                paddingTop: 10,
                margin: "10px 0px",
                textAlign: "center",
                backgroundColor: "#DEE9D3",
              }}
            >
              <Grid container alignItems="center" className={classes.grid}>
                <Grid item sm={12} xs={12}>
                  <Typography color={"primary"}>
                    <span className={classes.title}> CONTINUE A INDICAÇÃO</span>
                  </Typography>
                </Grid>
                <Grid item sm={12} xs={12}>
                  <Typography>
                    <span className={classes.subtitlePrimary}>
                      Carregar termo de responsabilidade assinado e CNH do
                      condutor infrator.
                    </span>
                  </Typography>
                </Grid>
              </Grid>
            </div>
          ) : (
            <div
              style={{
                border: "1px solid #eeeeee",
                borderRadius: 5,
                margin: "10px 0px",
                paddingTop: 10,
                paddingBottom: 10,
                borderLeft: `4px solid ${corCardInformacao(multa.Cor)}`,
              }}
            >
              <Grid container>
                <Grid item style={{ padding: 8 }}>
                  <Typography className={classes.subtitle}>
                    <span> Situação</span>
                  </Typography>
                  <Typography className={classes.title}>
                    <span> {multa.DescSituacaoMulta}</span>
                  </Typography>
                  {multa.DescSituacaoMulta === "Pendente Indicação" && (
                    <Typography className={classes.subtitle}>
                      <span> Seu prazo para indicar é até </span>
                      <span style={{ color: "red" }}>
                        {formatDate(multa.DataLimiteMulta)}
                      </span>
                    </Typography>
                  )}
                </Grid>
              </Grid>
            </div>
          )}

          <div
            style={{
              border: "1px solid #eeeeee",
              borderRadius: 5,
              padding: 10,
            }}
          >
            <Grid container alignItems="center" className={classes.grid}>
              <Grid item>
                <Typography>
                  <i
                    className="icon icon-info text-justify"
                    style={{ color: "#555555", marginTop: 5 }}
                  >
                    <span className={classes.title}> INFORMAÇÕES DA MULTA</span>
                  </i>
                </Typography>
              </Grid>
            </Grid>

            <Grid
              container
              alignItems="center"
              spacing={2}
              className={classes.grid}
            >
              <Grid item sm={6} xs={12}>
                <Typography className={classes.title}>
                  <span>Infração</span>
                </Typography>
                <Typography className={classes.subtitle}>
                  <span>{multa.DescricaoInfracao} </span>
                </Typography>
              </Grid>
              <Grid item sm={6} xs={12}>
                <Typography className={classes.title}>
                  <span>Data e Hora</span>
                </Typography>
                <Typography className={classes.subtitle}>
                  <span>{formatDateAndHour(multa.DataInfracao)} </span>
                </Typography>
              </Grid>
              <Grid item sm={6} xs={12}>
                <Typography className={classes.title}>
                  <span>Local</span>
                </Typography>
                <Typography className={classes.subtitle}>
                  <span>{multa.DescricaoEndereco} </span>
                </Typography>
              </Grid>
              <Grid item sm={6} xs={12}>
                <Typography className={classes.title}>
                  <span>Valor</span>
                </Typography>
                <Typography className={classes.subtitle}>
                  <span>
                    {Intl.NumberFormat("pt-br", {
                      style: "currency",
                      currency: "BRL",
                    }).format(multa.ValorInfracao)}{" "}
                  </span>
                </Typography>
              </Grid>
            </Grid>
            <Grid
              container
              justify="center"
              spacing={1}
              className={classes.grid}
            >
              {multa.DescSituacaoMulta === "Pendente Indicação" ? (
                multa.UsuarioIndicado ? (
                  <React.Fragment>
                    <Grid item sm={3} xs={12}>
                      <button
                        className="button"
                        onClick={() => {
                          handleSetarProcesso("termo", 2);
                        }}
                      >
                        Continuar Indicação
                      </button>
                    </Grid>
                    <Grid item sm={3} xs={12}>
                      <button
                        className="button"
                        onClick={handleIndicar}
                      >
                        Recomeçar Indicação
                      </button>
                    </Grid>
                  </React.Fragment>
                ) : (
                  <Grid item sm={3} xs={12}>
                    <button className="button" onClick={handleIndicar}>
                      Indicar Condutor
                    </button>
                  </Grid>
                )
              ) : (
                ""
              )}
              <Grid item sm={3} xs={12}>
                <button
                  className="button"
                  onClick={downloadArquivo}
                >
                  Imagem da Multa
                </button>
              </Grid>
              <Grid item sm={3} xs={12}>
                <button
                  className="button"
                  onClick={handleOpenInterposicao}
                >
                  Autorização para Recurso
                </button>
              </Grid>
              {situacaoLogin === 110 && (
                <Grid item sm={3} xs={12}>
                  <button
                    className="button"
                    onClick={handleAssinatura}
                  >
                    Assinatura
                  </button>
                </Grid>
              )}
            </Grid>
          </div>
        </Container>
      </main>
      <ModalInterposicao
        modalOpened={modalOpenedInterposicao}
        ait={multa.AIT}
        orgao={multa.CodigoOrgao}
        onToggle={handleCloseInterposicao}
      />
    </LayoutInternal>
  );
}

const mapStateToProps = (state) => {
  return {
    multa: state.multa.multa,
    loading_buscar_multa: state.multa.loading_buscar_multa,
    loading: state.multa.loading,
    erro_buscar_multa: state.multa.erro_buscar_multa,
    erro: state.multa.erro,
    /* situacao: state.login.usuario.Situacao, */
  };
};

export default connect(mapStateToProps, {
  buscarMulta,
  BaixarArquivoMulta,
  limparErroMulta,
})(InformacaoMultaPage);
