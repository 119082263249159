import { POLITICAFROTA, 
         ARQUIVO, 
         LOADING_POLITICA,
         LOADED_POLITICA,
         ERRO_POLITICA } from './PoliticaFrotaTypes'

const INITIAL_STATE = {
    politicaFrota : null,
    arquivo : null,
    erro : null
}

export const politicaFrota = (state = INITIAL_STATE, action) => {
    switch (action.type){
        case POLITICAFROTA:
            return  { ...state, politicaFrota: action.payload, erro: null };
        case ARQUIVO:
            return  { ...state, arquivo: action.payload, erro:null };
        case LOADING_POLITICA:
            return  { ...state, loading: true, erro:null };
        case LOADED_POLITICA:
            return  { ...state, loading: false, erro:null };
        case ERRO_POLITICA:
            return  { ...state, erro: action.payload };
        default:
            return state;
    }
}

export default politicaFrota;