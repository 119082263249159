import React, { Component } from 'react';
import { connect } from 'react-redux';
import MaskedInput from 'react-text-mask';
import { withRouter } from 'react-router-dom';

import '../../styles/css/tela-politicafrota.css';

import LayoutInternal from '../../components/LayoutInternal';
import ModalMensagem from '../../components/ModalMensagem/ModalMensagem';
import Loading from '../../components/Loading';

import { validarCpf } from '../../functions/manutencaoGeralValidacoes';
import { urlAPI } from '../../config/consts';
import Tracking from '../../hooks/Tracking';

import {
    PoliticaFrota,
    RegistrarDownloadArquivo,
    limparMensageErro
} from './PoliticaFrotaActions';

import {
    ConfirmarAceitePoliticaFrotaPorCpf,
    ConfirmarAceitePoliticaFrota,
    limparMensagemErroAceitePoliticaFrota,
    limparMensagemAceitePoliticaFrota,
    VerificarSePlacaPossuiPoliticaFrotaAtiva,
    LimparDadosDePoliticaFrotaNoState,
    BaixarArquivoAceitePoliticaFrota
} from './AceitePoliticaFrotaActions';




class PoliticaFrotaPage extends Component {
    constructor() {
        super();

        this.state = {
            checkboxMarcado: false,
            checkboxDesabilitado: false,
            mostrarBotaoConfirmar: false,
            exibirNomeCpf: false,
            dado: {
                nome: '',
                cpf: ''
            },
            erroValidacao: {
                nome: false,
                cpf: false
            }
        }
    }

    componentDidMount() {
        Tracking.sendPageview('Politica de Frota');
        localStorage.removeItem("politica-frota-id");

        this.props.VerificarSePlacaPossuiPoliticaFrotaAtiva(localStorage.getItem("placa-atual"));

        this.verificaState();
    }

    componentWillUnmount() {
        this.props.LimparDadosDePoliticaFrotaNoState();
        localStorage.removeItem("politica-frota-id");
    }

    verificaState() {
        if (this.props.aceitePoliticaFrota == null) {
            setTimeout(() => {
                this.verificaState();
            }, 50);
        }
        else {
            this.props.PoliticaFrota(localStorage.getItem("placa-atual"));
            if (this.props.aceitePoliticaFrota.ExibeAceitePolitica) {
                this.setState({
                    checkboxMarcado: false,
                    checkboxDesabilitado: false
                });
            } else {
                this.setState({
                    checkboxMarcado: true,
                    checkboxDesabilitado: true
                });
            }
        }
    }

    downloadArquivo = () => {
        var downloadArquivoPoliticaRequestObj = {
            cnpjCliente: localStorage.getItem("cnpj-atual"),
            nomeCliente: localStorage.getItem("nome-empresa"),
            codigoCliente: localStorage.getItem("codigo-identificador-atual"),
            placa: localStorage.getItem("placa-atual"),
            email: localStorage.getItem("user-email")
        }
        this.props.RegistrarDownloadArquivo(downloadArquivoPoliticaRequestObj);

        this.props.BaixarArquivoAceitePoliticaFrota(localStorage.getItem('politica-frota-id'));

        // this.setState({
        //     checkboxDesabilitado: !this.props.aceitePoliticaFrota.ExibeAceitePolitica && this.props.situacao == 110
        // });
    }

    handleRemoveMensagemErro = () => {
        this.props.limparMensageErro();
    }

    handleRemoveMensagemErroPoliticaFrota = () => {
        this.setState({
            dado: {
                ...this.state.dado,
                nome: '',
                cpf: ''
            }
        });

        this.props.limparMensagemErroAceitePoliticaFrota();
    }

    handleRemoveMensagemPoliticaFrota = () => {
        this.props.limparMensagemAceitePoliticaFrota();
        this.props.history.push("/Home");
    }

    handleChangeCheckbox = () => {
        if (!this.state.checkboxDesabilitado) {
            if (this.props.aceitePoliticaFrota.ExibeAceitePolitica) {
                //Cenário 2
                if (this.props.situacao == 110) {
                    this.setState({
                        checkboxMarcado: !this.state.checkboxMarcado,
                        mostrarBotaoConfirmar: true
                    });
                } else {
                    this.setState({
                        checkboxMarcado: !this.state.checkboxMarcado,
                        exibirNomeCpf: !this.state.checkboxMarcado,
                        mostrarBotaoConfirmar: !this.state.checkboxMarcado
                    });
                }
            } else {
                if (this.props.situacao == 110) {
                    this.setState({
                        checkboxMarcado: !this.state.checkboxMarcado
                    });
                } else {
                    this.setState({
                        checkboxMarcado: !this.state.checkboxMarcado,
                        exibirNomeCpf: !this.state.checkboxMarcado,
                        mostrarBotaoConfirmar: !this.state.checkboxMarcado
                    });
                }
            }
        }
    }

    handleConfirmar = () => {

        var aceitePoliticaRequestObj = {
            cnpjCliente: localStorage.getItem("cnpj-atual"),
            nomeCliente: localStorage.getItem("nome-empresa"),
            codigoCliente: localStorage.getItem("codigo-identificador-atual"),
            placa: localStorage.getItem("placa-atual"),
            email: localStorage.getItem("user-email"),
            nomeUsuario: "",
            cpfUsuario: "",
            dataEnvioPolitica: this.props.aceitePoliticaFrota.DataEnvio
        }

        if (this.props.situacao == 110) {
            //Login=Celular

            aceitePoliticaRequestObj.nomeUsuario = localStorage.getItem("user-name");
            // aceitePoliticaRequestObj.cpfUsuario = é preenchido no back 

            if (this.props.aceitePoliticaFrota.ExibeAceitePolitica) {
                this.props.ConfirmarAceitePoliticaFrota(aceitePoliticaRequestObj);
            }
        } else {
            //Login=PlacaRenavam ou Email
            var erroValidacao = {
                nome: false,
                cpf: false
            }

            if (this.state.dado.nome.trim() == "") {
                erroValidacao.nome = true;
            }

            if (this.state.dado.cpf.trim() == "" || !validarCpf(this.state.dado.cpf)) {
                erroValidacao.cpf = true;
            }

            if (!erroValidacao.nome && !erroValidacao.cpf) {
                aceitePoliticaRequestObj.nomeUsuario = this.state.dado.nome;
                aceitePoliticaRequestObj.cpfUsuario = this.state.dado.cpf;

                this.props.ConfirmarAceitePoliticaFrotaPorCpf(aceitePoliticaRequestObj);
            }
            else {
                this.setState({
                    erroValidacao
                });
            }
        }
    }

    handleChange = (e) => {
        const { name, value } = e.target;

        this.setState({
            dado: {
                ...this.state.dado,
                [name]: value
            }
        });
    }

    render() {
        let retorno;

        if (this.props.erro) {
            retorno = <ModalMensagem tipoModalMensagem='warning' icon='icon-x-circle'
                mensagemPrincipal={this.props.erro.message}
                OnToggled={this.handleRemoveMensagemErro}
                textoBotao="OK" />;
        }
        else if (this.props.politicaFrota != null) {
            retorno = <div>
                {
                    this.props.loadingAceitePolitica ? <Loading /> : null
                }
                {
                    this.props.mensagemAceitePoliticaFrota ?
                        <ModalMensagem tipoModalMensagem='' icon='icon-check-circle2'
                            mensagemPrincipal={this.props.mensagemAceitePoliticaFrota}
                            OnToggled={this.handleRemoveMensagemPoliticaFrota}
                            textoBotao="OK" /> : null
                }
                {
                    this.props.erroAceitePolitica ?
                        <ModalMensagem tipoModalMensagem='warning' icon='icon-x-circle'
                            mensagemPrincipal={this.props.erroAceitePolitica}
                            OnToggled={this.handleRemoveMensagemErroPoliticaFrota}
                            textoBotao="OK" /> : null
                }
                <h1>Política de Frota</h1>
                <div className="label-input-file download-file download-only">
                    <a href="#" onClick={this.downloadArquivo} className="link-download gtm-politica-frota-download">
                        <i className="icon icon-file-text"></i>
                        <span className="file-name">Política de Frota</span>
                    </a>
                </div>
                <p>Esse documento é de responsabilidade da sua empresa. Caso tenha alguma dúvida, entre em contato com o responsável pelo seu contrato.</p>
                <br />
                <p>
                    <input className="gtm-politica-frota-aceite-termo" type="checkbox"
                        checked={this.state.checkboxMarcado}
                        disabled={this.state.checkboxDesabilitado}
                        onChange={this.handleChangeCheckbox} />
                    <span style={{ color: this.state.checkboxDesabilitado ? '#ddd' : '#000000' }}> Declaro que li e estou de acordo com todas as informações e regras contidas na Política de Frotas disponibilizada acima.</span>
                </p>
                {
                    this.state.exibirNomeCpf ?
                        <div className="col-md-6">
                            <div className={`wrapper-input ${(this.state.erroValidacao.nome == true) ? 'has-error' : ""}`}>
                                <div className="group-input">
                                    <input type="text" id="nome" name="nome" maxLength={60} className="input" onChange={this.handleChange} required value={this.state.dado.nome}></input>
                                    <label htmlFor="nome" className="label" >Nome</label>
                                </div>
                            </div>
                            <div className={`wrapper-input ${(this.state.erroValidacao.cpf == true) ? 'has-error' : ""}`}>
                                <div className="group-input">
                                    <MaskedInput type="text" id="cpf" name="cpf" mask={[/\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '-', /\d/, /\d/]} className="input" onChange={this.handleChange} required value={this.state.dado.cpf} />
                                    <label htmlFor="cpf" className="label" >CPF</label>
                                </div>
                            </div>
                        </div> : null
                }
                {
                    this.state.mostrarBotaoConfirmar ?
                        <div className="col-md-3">
                            <button className="button gtm-politica-frota-confirmar" onClick={this.handleConfirmar}>Confirmar</button>
                        </div> : null
                }
            </div>
        }
        else {
            retorno = <div>
                <h1>Política de Frota</h1>
                <p>A política de frotas da sua empresa não foi cadastrada pelo responsável do seu contrato. Procure-o caso tenha alguma dúvida.</p>
            </div>
        }

        return (
            <LayoutInternal title={'Política de Frota'}>
                {
                    this.props.loading ? <Loading /> : null
                }
                <main>
                    <div className="content-primary">
                        {retorno}
                    </div>
                </main>
            </LayoutInternal>
        )
    }
}

const mapStateToProps = state => {
    return {
        politicaFrota: state.PoliticaFrota.politicaFrota,
        arquivo: state.PoliticaFrota.arquivo,
        loading: state.PoliticaFrota.loading,
        loadingAceitePolitica: state.AceitePoliticaFrota.loadingAceitePolitica,
        erro: state.PoliticaFrota.erro,
        erroAceitePolitica: state.AceitePoliticaFrota.erro,
        mensagemAceitePoliticaFrota: state.AceitePoliticaFrota.mensagem,
        placa: state.menu.placa,
        /* situacao: state.login.usuario.Situacao, */
        aceitePoliticaFrota: state.AceitePoliticaFrota.aceitePoliticaFrota
    }
};

export default connect(mapStateToProps, {
    PoliticaFrota,
    ConfirmarAceitePoliticaFrotaPorCpf,
    ConfirmarAceitePoliticaFrota,
    RegistrarDownloadArquivo,
    limparMensageErro,
    limparMensagemErroAceitePoliticaFrota,
    limparMensagemAceitePoliticaFrota,
    VerificarSePlacaPossuiPoliticaFrotaAtiva,
    LimparDadosDePoliticaFrotaNoState,
    BaixarArquivoAceitePoliticaFrota
})(withRouter(PoliticaFrotaPage))