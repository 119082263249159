
import React, { Component } from 'react';
import { connect } from 'react-redux';
import Modal from '../../components/Modal'
import Input from '../../components/Input';
import { GerarDocumentoInterposicao } from './CardMultaAction';
import Loading from '../Loading';
import ModalMensagem from '../ModalMensagem/ModalMensagem';

class ErrorMessage extends Component {
    render() {
        if (this.props.erro) {
            return (<ModalMensagem tipoModalMensagem='warning' icon='icon-x-circle'
                mensagemPrincipal={this.props.erro.message}
                mensagemSecundaria=''
                textoBotao="OK" />);
        }
        return null
    }
}

export class ModalInterposicao extends Component {
    constructor(props) {
        super(props);
        this.state = {
            modalOpened: false
        }
    }

    onToggleModal() {
        this.setState({ msgNome: false, msgRg: false })
        this.props.onToggle();
    }

    onGerarDocumento() {
        var msgNome = false;
        var msgRg = false;
        if (this.Nome.textInput.current.value === '') {
            msgNome = true;
        }
        if (this.Rg.textInput.current.value === '') {
            msgRg = true;
        }

        if (msgNome || msgRg) {
            this.setState({ msgNome, msgRg });
            return;
        }

        this.props.GerarDocumentoInterposicao(this.props.ait, this.props.orgao, this.Nome.textInput.current.value, this.Rg.textInput.current.value, localStorage.getItem("placa-atual"));
    }

    componentDidUpdate(prevProps) {
        if (prevProps.modalOpened != this.props.modalOpened) {
            this.setState({
                modalOpened: this.props.modalOpened
            });
        }
    }

    render() {
        if (!this.state.modalOpened)
            return null;

        return (
            <div>                
                <ErrorMessage erro={this.props.erro}></ErrorMessage>
                {this.props.loading ? <Loading /> : null}

                <div className="wapper-modal-interposicao-recursos">
                    <Modal onClickCloseModal={() => this.onToggleModal()}>
                        <div className="form-interposicao-recursos">
                            <h2>Insira abaixo os dados do responsável para interposição de recursos</h2>
                            <Input label="Nome" ref={(input) => this.Nome = input} erro={this.state.msgNome === true} />
                            <Input label="RG" ref={(input) => this.Rg = input} erro={this.state.msgRg === true} />
                            {this.state.msgData = this.state.msgHora = false}

                            {/*<a href={`${urlAPI}/GerarDocumentoInterposicao?Ait=${this.props.ait}&Orgao=${this.props.orgao}&Rg=${this.Rg}&Nome=${this.Nome}`}>ENVIAR </a>*/}
                            <button className="button" onClick={() => this.onGerarDocumento()}>ENVIAR</button>
                        </div>
                    </Modal>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        erro: state.multa.erro,
        loading: state.multa.loading,
        placa: state.menu.placa,
    }
};

export default connect(mapStateToProps, {
    GerarDocumentoInterposicao
})(ModalInterposicao)