import React, { Component } from 'react';
import MaskedInput from 'react-text-mask';
import { validaEmail, somenteNumerosFromString } from '../../functions/manutencaoGeralValidacoes';
import InputCidades from '../../components/InputCidades/InputCidades';

class DadosTerceiroProprietario extends Component{

    constructor(props){
        super(props);
        this.state = {
            erroValidacoes: {
                emailProprietario: false
            }
        };
    }

    componentDidMount() {
        window.scrollTo(0, 0);
    }

    handleAvancar = () => {
        if(this.props.modelo.terceiroEnvolvido == "sim" && this.props.modelo.outroEnvolvido == "não"){
            this.props.onIrParaEtapa(9);
        }
        else{
            this.props.onIrParaEtapa(7)};
    }

    handleVoltar = () => {
        this.handleLipaCampos();
        this.props.onIrParaEtapa(4);
    }

    handleAlteraPlaca = (event) => {
        if (event.target.value !== null) {
            this.props.onChangePlaca(event.target.value);
        }
    }

    handleAlteraModelo = (event) => {
        if (event.target.value !== null) {
            this.props.onChangeModelo(event.target.value);
        }
    }

    handleAlteraAno = (event) => {
        if (event.target.value !== null) {
            this.props.onChangeAno(event.target.value);
        }
    }

    handleAlteraProprietario = (event) => {
        if (event.target.value !== null) {
            this.props.onChangeProprietario(event.target.value);
        }
    }

    handleAlteraEmail = (event) => {
        if (event.target.value !== null) {
            this.props.onChangeEmailProprietario(event.target.value);

            let validacaoEmail = validaEmail(event.target.value);
            if (validacaoEmail) {                
                    let newState = { ...this.state }
                    newState.erroValidacoes.emailProprietario = false;
                    this.setState(newState);
            }else {
                let newState = { ...this.state }
                newState.erroValidacoes.emailProprietario = true;
                this.setState(newState);
            }
        }
    }

    handleAlteraCep = (event) => {
        if (event.target.value !== null) {
            this.props.onChangeCep(event.target.value);

            let cep = somenteNumerosFromString(event.target.value);
            if (cep.length === 8) {
                this.props.buscarDadosCep(cep);
            }
        }
    }

    handleAlteraEnderecoProprietario = (event) => {
        if (event.target.value !== null) {
            this.props.onChangeEnderecoProprietario(event.target.value);
        }
    }

    handleAlteraBairroProprietario = (event) => {
        if (event.target.value !== null) {
            this.props.onChangeBairroProprietario(event.target.value);
        }
    }

    handleAlteraTelefoneProprietario = (event) => {
        if (event.target.value !== null) {
            this.props.onChangeTelefoneProprietario(event.target.value);
        }
    }

    handleLipaCampos = () => {
        this.props.onChangePlaca("");
        this.props.onChangeAno("");
        this.props.onChangeModelo("");
        this.props.onChangeProprietario("");
        this.props.onChangeEmailProprietario("");
        this.props.onChangeTelefoneProprietario("");
        this.props.onChangeCep("");
        this.props.onChangeEnderecoProprietario("");
        this.props.onChangeBairroProprietario("");
    }

    render () {
        return(
        <React.Fragment>
        <div className="row w-xl-66">
            <div className="form-intro col-12">
                <p>Dados do proprietário do carro terceiro</p>
            </div>

            <div className="row">
                <div className="col-md-3">
                    <div className="wrapper-input">
                        <div className="group-input">
                            <input type="text" name="placa" id="placa" className="input toUpperCase" maxLength={7}
                                onChange={this.handleAlteraPlaca} value={this.props.modelo.placa} required />
                            <label htmlFor="placa" className="label">Placa</label>
                        </div>
                    </div>
                </div>
                <div className="col-md-3">
                    <div className="wrapper-input">
                        <div className="group-input">
                            <MaskedInput type="tel" mask={[/[1-2]/, /\d/, /\d/, /\d/]} name="ano" className="input"
                                size="4" placeholder="" value={this.props.modelo.ano} onChange={this.handleAlteraAno} required />
                            <label htmlFor="ano" className="label">Ano</label>
                        </div>
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="wrapper-input">
                        <div className="group-input">
                            <input type="text" name="modelo" id="modelo" className="input" maxLength={47}
                                onChange={this.handleAlteraModelo} value={this.props.modelo.modelo} required />
                            <label htmlFor="modelo" className="label">Modelo</label>
                        </div>
                    </div>
                </div> 
            </div>

            <div className="row">
                <div className="col-md-12">
                    <div className="wrapper-input">
                        <div className="group-input">
                            <input type="text" name="proprietario" id="proprietario" className="input" maxLength={40}
                                onChange={this.handleAlteraProprietario} value={this.props.modelo.proprietario} required />
                            <label htmlFor="proprietario" className="label">Proprietario do carro</label>
                        </div>
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col-md-6">
                    <div className={`wrapper-input ${(this.state.erroValidacoes.emailProprietario === true) ? 'has-error' : ""}`}>
                        <div className="group-input">
                            <input type="text" name="emailProprietario" id="emailProprietario" className="input" maxLength={50}
                                onChange={this.handleAlteraEmail} value={this.props.modelo.emailProprietario} required />
                            <label htmlFor="email" className="label">E-mail</label>
                        </div>
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="wrapper-input">
                        <div className="group-input">
                            <MaskedInput type="tel" name="telefone" id="telefone" className="input" maxLength={15}
                                mask={['(', /\d/, /\d/, ')', /\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
                                onChange={this.handleAlteraTelefoneProprietario} value={this.props.modelo.telefoneProprietario} required />
                            <label htmlFor="telefone" className="label">Telefone</label>
                        </div>
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col-md-4">
                    <div className="wrapper-input">
                        <div className="group-input">
                            <MaskedInput type="tel" name="cep" id="cep" className="input" maxLength={10}
                                mask={[/\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/]}
                                onChange={this.handleAlteraCep} value={this.props.modelo.cep} required />
                            <label htmlFor="cep" className="label">CEP</label>
                        </div>
                    </div>
                </div>
                <div className="col-8">
                    <div className="wrapper-input">
                        <div className="group-input">
                            <input type="text" name="endereco" id="endereco" className="input" maxLength={70}
                                onChange={this.handleAlteraEnderecoProprietario} value={this.props.modelo.enderecoProprietario} required />
                            <label htmlFor="endereco" className="label">Endereço</label>
                        </div>
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col-md-6">
                    <div className="wrapper-input">
                        <div className="group-input">
                            <input type="text" name="bairro" id="bairro" className="input" maxLength={40}
                                onChange={this.handleAlteraBairroProprietario} value={this.props.modelo.bairroProprietario} required />
                            <label htmlFor="bairro" className="label">Bairro</label>
                        </div>
                    </div>
                </div>                                       
                <div className="col-md-6">
                    <InputCidades name='mNomeCidadeField' placeHolder='Cidade' complement='mUfField'
                        valorInicial={this.props.modelo.cidadeProprietario && this.props.modelo.cidadeProprietario.mNomeCidadeField ? this.props.modelo.cidadeProprietario.mNomeCidadeField + " - " + this.props.modelo.cidadeProprietario.mUfField : null} />
                </div>
            </div>

            <div className="ml-auto mt-3 col-md-6">
                <div className="row">
                    <div className="col">
                    <button className="button" onClick={this.handleVoltar}>Voltar</button>
                        </div>
                    <div className="col">
                        <button className="button" onClick={this.handleAvancar}>Continuar</button>
                    </div>
                </div>
            </div>
        </div>
        </React.Fragment>
        );
    }
}

export default DadosTerceiroProprietario;