import React, { useState } from "react";
import "./styles";
import { Link, Redirect, useHistory } from "react-router-dom";
import {
  FormContainer,
  LabelWrapper,
  InputContainer,
  TextFieldMaterial,
  LinkContainer,
  Ancora,
  ButtonContainer,
  Button,
  ContainerMessages,
  ErrorContainer,
  Message,
  MessageError,
  AvisoContainer,
  Aviso,
  WrapperAviso,
  OkLink,
} from "./styles";
import fire from "../../fire";
import { useAuth } from "../../../providers/auth";
import "../../../styles/css/login-cadastro.css";
import CheckImg from "../../../images/CheckImg.svg";
import XImg from "../../../images/XImg.svg";
import checkAviso from "../../../images/checkAviso.svg";
import InputAdornment from "@material-ui/core/InputAdornment";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import IconButton from "@material-ui/core/IconButton";
import Tracking from "../../../hooks/Tracking";
import Loading from "../../Loading";

export const LoginSenhaCadastroContainer = () => {
  const {
    email,
    password,
    setPassword,
    confirmPassword,
    setConfirmPassword,
    passwordError,
    setPasswordError,
    clearErrors,
    resetState,
    loading,
    setLoading,
    createUserWithEmailAndPasswordFb,
    sendEmailVerificationFb,
  } = useAuth();

  const [notification, setNotification] = useState(false);
  const [statusPassword, setStatuswPassword] = useState(false);
  const history = useHistory();

  const showPassword = () => setStatuswPassword(true);

  const hidePassword = () => setStatuswPassword(false);

  const handleType = () => (statusPassword === false ? "password" : "text");

  const uppercase = /^(?=(?:.*?[A-Z]){1})/;
  const lowercase = /^(?=(?:.*?[a-z]){1})/;
  const numbers = /^(?=(?:.*?[0-9]){1})/;
  const special =
    /^(?=(?:.*?[!@#$%*()_+^&}{:;?.]){1})(?!.*\s)[0-9a-zA-Z!@#;$%*(){}_+^&]/;

  const checkUppercase = () => {
    if (!password) {
      return "initial";
    } else if (!uppercase.exec(password)) {
      return "redOn";
    } else if (uppercase.exec(password)) {
      return "greenOn";
    }
  };

  const checkUppercaseImage = () => {
    if (!password) {
      return "";
    } else if (!uppercase.exec(password)) {
      return XImg;
    } else if (uppercase.exec(password)) {
      return CheckImg;
    }
  };

  const checkLowercase = () => {
    if (!password) {
      return "initial";
    } else if (!lowercase.exec(password)) {
      return "redOn";
    } else if (lowercase.exec(password)) {
      return "greenOn";
    }
  };

  const checkLowercaseImage = () => {
    if (!password) {
      return "";
    } else if (!lowercase.exec(password)) {
      return XImg;
    } else if (lowercase.exec(password)) {
      return CheckImg;
    }
  };

  const checkNumbers = () => {
    if (!password) {
      return "initial";
    } else if (!numbers.exec(password)) {
      return "redOn";
    } else if (numbers.exec(password)) {
      return "greenOn";
    }
  };

  const checkNumbersImage = () => {
    if (!password) {
      return "";
    } else if (!numbers.exec(password)) {
      return XImg;
    } else if (numbers.exec(password)) {
      return CheckImg;
    }
  };

  const checkSpecial = () => {
    if (!password) {
      return "initial";
    } else if (!special.exec(password)) {
      return "redOn";
    } else if (special.exec(password)) {
      return "greenOn";
    }
  };

  const checkSpecialImage = () => {
    if (!password) {
      return "";
    } else if (!special.exec(password)) {
      return XImg;
    } else if (special.exec(password)) {
      return CheckImg;
    }
  };

  const handleSignup = () => {
    clearErrors();
    if (password === confirmPassword) {
      if (password.length < 8) {
        setPasswordError("A senha deve ter pelo menos 8 caracteres.");
      } else if (
        !uppercase.exec(password) ||
        !lowercase.exec(password) ||
        !numbers.exec(password) ||
        !special.exec(password)
      ) {
        setPasswordError("Sua senha não atende aos requisitos");
      } else {
        Tracking.sendEvent({
          category: "login_cadastro_senha",
          action: "LOGIN_CADASTRO_SENHA_CLICK",
          label: "Clicou no botão para cadastrar senha no firebase.",
        });
        setLoading(true);
        createUserWithEmailAndPasswordFb(email, password)
          .then((result) => {
            //localStorage.setItem(
            //  "token-id",
            //  result.data.Authenticated.FirebaseToken
            //);
            setLoading(false);
            sendEmailVerificationFb(result.data.Authenticated.FirebaseToken);
            setLoading(false);
            setNotification(true);
            Tracking.sendEvent({
              category: "login_cadastro_senha",
              action: "LOGIN_CADASTRO_SENHA_SUCESSO",
              label: "Senha cadastrada com sucesso.",
            });
          })
          .catch((err) => {
            setLoading(false);
            setPasswordError("Serviço indisponível.");
            Tracking.sendEvent({
              category: "login_cadastro_senha",
              action: "LOGIN_CADASTRO_SENHA_ERRO",
              label: "Senha não cadastrada.",
            });
          });
      }
    } else {
      setPasswordError("As senhas devem ser iguais.");
    }
  };

  const redirectToLogin = () => {
    history.push("/Login");
  };

  const removeAccents = (str) => {
    var from = "ãàáäâèéëêìíïîòõóöôùúüûñçÃÀÁÂÈÉÊÌÍÎÒÓÔÕÙÚÛÑÇ";
    var to = "aaaaaeeeeiiiiooooouuuuncAAAAEEEIIIOOOOUUUNC";
    for (var i = 0, l = from.length; i < l; i++) {
      str = str.replace(new RegExp(from.charAt(i), "g"), to.charAt(i));
    }
    return str;
  };

  if (!email) {
    return <Redirect to="/Login" />;
  } else {
    return (
      <React.Fragment>
        <FormContainer>
          <LabelWrapper>
            Defina uma senha para <br /> acessar sua conta
          </LabelWrapper>
          <InputContainer>
            <TextFieldMaterial
              className="textFieldPassword"
              id="Senha"
              label="Senha"
              variant="outlined"
              name="senha"
              type={handleType()}
              onChange={(e) =>
                setPassword(removeAccents(e.target.value.trim()))
              }
              value={password}
              InputLabelProps={{
                style: { color: "#3A3D42", fontSize: "14px" },
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="start">
                    {statusPassword === false ? (
                      <IconButton edge="end" onClick={showPassword}>
                        <VisibilityOff />
                      </IconButton>
                    ) : (
                      <IconButton edge="end" onClick={hidePassword}>
                        <Visibility />
                      </IconButton>
                    )}
                  </InputAdornment>
                ),
              }}
            />
            <TextFieldMaterial
              className="textFieldPassword"
              id="ConfirmarSenha"
              label="Repetir senha"
              variant="outlined"
              name="Senha"
              type={handleType()}
              onChange={(e) =>
                setConfirmPassword(removeAccents(e.target.value.trim()))
              }
              value={confirmPassword}
              InputLabelProps={{
                style: { color: "#3A3D42", fontSize: "14px" },
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="start">
                    {statusPassword === false ? (
                      <IconButton edge="end" onClick={showPassword}>
                        <VisibilityOff />
                      </IconButton>
                    ) : (
                      <IconButton edge="end" onClick={hidePassword}>
                        <Visibility />
                      </IconButton>
                    )}
                  </InputAdornment>
                ),
              }}
            />
          </InputContainer>
          {passwordError ? (
            <ErrorContainer>
              <MessageError>{passwordError}</MessageError>
            </ErrorContainer>
          ) : (
            ""
          )}
          <ContainerMessages>
            <Message>
              Sua senha deve ter pelo menos 8 caracteres, havendo entre eles:
            </Message>
            <p className={checkUppercase()}>
              Uma letra maiúscula <img alt="" src={checkUppercaseImage()} />
            </p>
            <p className={checkLowercase()}>
              Uma letra minúscula <img alt="" src={checkLowercaseImage()} />
            </p>
            <p className={checkNumbers()}>
              Um número <img alt="" src={checkNumbersImage()} />
            </p>
            <p className={checkSpecial()}>
              Um símbolo <img alt="" src={checkSpecialImage()} />
            </p>
          </ContainerMessages>

          <ButtonContainer>
            <Button onClick={handleSignup}>Prosseguir</Button>
          </ButtonContainer>
          <LinkContainer>
            <Link to="/Login">
              <Ancora onClick={resetState}>Voltar para a tela de login</Ancora>
            </Link>
          </LinkContainer>
        </FormContainer>
        {loading === true ? <Loading /> : ""}
        {notification === true ? (
          <React.Fragment>
            <AvisoContainer>
              <Aviso>
                <img src={checkAviso} alt="" />
                <WrapperAviso>
                  Senha validada com sucesso! Enviamos um e-mail de verificação,
                  confira sua caixa de e-mail.
                </WrapperAviso>
                <OkLink onClick={redirectToLogin}>OK</OkLink>
              </Aviso>
            </AvisoContainer>
          </React.Fragment>
        ) : null}
      </React.Fragment>
    );
  }
};
