import {
  FormContainer,
  LabelBold,
  Subtitle,
  HelpTitle,
  HelpDescription,
  HelpNumber,
  ImagemMaintenance,
} from "./styles";
import MaintenanceImg from "../../images/wizard/maintenance.svg";
import LayoutContainer from "../novo-login/Layout/LayoutContainer";
import { fetchRemoteConfig } from "../../remote_config/remote_config_call";
import { useState, useEffect } from "react";
import { defaultConfig } from "../../remote_config/remote_keys";

export const ManutencaoContainer = () => {
  const [maintenanceSubtitle, setMaintenanceSubtitle] = useState("");
  const [maintenanceHelpNumber, setmaintenanceHelpNumber] = useState("");

  useEffect(() => {
    const fetchSubtitle = async () => {
      try {
        const subtitle = await fetchRemoteConfig(
          "maintenance_subtitle",
          `${defaultConfig.maintenanceSubtitle}`,
          "string"
        );
        setMaintenanceSubtitle(subtitle);
      } catch (error) {
        return setMaintenanceSubtitle(defaultConfig.maintenanceSubtitle);
      }
    };

    const fetchNumber = async () => {
      try {
        const helpNumber = await fetchRemoteConfig(
          "maintenance_help_number",
          `${defaultConfig.maintenanceHelpNumber}`,
          "string"
        );
        setmaintenanceHelpNumber(helpNumber);
      } catch (error) {
        return setmaintenanceHelpNumber(defaultConfig.maintenanceHelpNumber);
      }
    };

    fetchSubtitle();
    fetchNumber();
  }, []);

  return (
    <LayoutContainer>
      <FormContainer>
        <ImagemMaintenance src={MaintenanceImg} />
        <LabelBold>Voltamos logo!</LabelBold>
        <Subtitle>{maintenanceSubtitle}</Subtitle>
        <HelpTitle>Se precisar, fale com a gente:</HelpTitle>
        <HelpNumber>{maintenanceHelpNumber}</HelpNumber>
        <HelpDescription>Todos os dias 24 horas</HelpDescription>
      </FormContainer>
    </LayoutContainer>
  );
};
