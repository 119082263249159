import React, { useEffect } from "react";
import LayoutContainer from "../../components/novo-login/Layout/LayoutContainer";
import { LoginSenhaRecuperacao } from "../../components/novo-login/LoginSenhaRecuperacao/LoginSenhaRecuperacao";
import ErrorBoundary from "../../components/ErrorBoundary";
import Tracking from "../../hooks/Tracking";

export default function EsqueciSenhaPage() {
  useEffect(() => {
    Tracking.sendPageview("Login Esqueci Senha");
  }, []);
  return (
    <React.Fragment>
      <LayoutContainer>
        <ErrorBoundary>
          <LoginSenhaRecuperacao />
        </ErrorBoundary>
      </LayoutContainer>
    </React.Fragment>
  );
}
