import styled, { css } from "styled-components";
import { TextField, withStyles } from "@material-ui/core";

export const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 330px;
  padding: 0 14px 0 14px;
  margin: 10px 0 0 0;
  @media (max-width: 1025px) {
    width: 25%;
    height: 65%;
    position: relative;
  }
`;

export const LabelBold = styled.label`
  font-family: Inter;
  font-style: normal;
  font-weight: bold;
  font-size: 32px;
  line-height: 150%;
  text-align: center;
  color: #3a3d42;
  padding: 0 10px 10px 10px;  
  white-space: nowrap;
`;

export const Subtitle = styled.p`
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 130%;
  text-align: center;
  margin: 10% 10px 60% 10px;   
  min-width: 300px;
  color: #3a3d42;
   @media (min-width: 570px) {   
     min-width: 360px;
    position: relative;
  }
`;

export const HelpTitle = styled.label`
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 150%;
  text-align: center;
  color: #3a3d42;
  padding: 0 10px 10px 10px;  
  white-space: nowrap;
`;

export const HelpNumber = styled.label`
font-family: Inter;
font-style: normal;
font-weight: normal;
font-size: 16px;
line-height: 130%;
text-align: center;
color: #3a3d42;
padding: 0 10px 5px 10px;  
white-space: nowrap;
`;

export const HelpDescription = styled.label`
font-family: Inter;
font-style: normal;
font-weight: normal;
font-size: 14px;
line-height: 130%;
text-align: center;
color: #3a3d42;
padding: 0 10px 5px 10px;  
white-space: nowrap;
`;

export const ImagemMaintenance = styled.img`
  width: 107px;
  height: 116px;
  margin: 30px 0 30px 0;
`;


