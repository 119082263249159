import React, { useState, useEffect } from "react";
import LayoutInternal from "../../components/LayoutInternal";
import { Document, Page } from "react-pdf/dist/esm/entry.webpack";
import { Container, Grid, useMediaQuery } from "@material-ui/core";
import { urlAPI, NomeCookiePlaca } from "../../config/consts";
import axios from "axios";
import { base64ToArrayBuffer } from "../../helpers/downloadFile";
import Loading from "../../components/Loading";
import Tracking from "../../hooks/Tracking";
import * as Cookie from "../../functions/Cookies";
import ModalMensagem from "../../components/ModalMensagem/ModalMensagem";
import { getUA } from "react-device-detect";
import { HttpGet } from "../../functions/HttpRequest";

const GACATEGORIADOWNLOADCRLV = "download_crlv";
const USER_AGENT_NOVA_APP_MOBILE = "wv nova-app-meucarro";

function CRLVPage({ history }) {
  Tracking.sendPageview("CRLV Veículo");

  var placa = localStorage.getItem("placa-atual");

  useEffect(() => {
    HttpGet("/veiculos/" + placa + "/crlv", null)
      .then((result) => {
        if (result.data && result.data !== null && result.data !== "") {
          const byteArray = base64ToArrayBuffer(result.data.Arquivo.Conteudo);
          Tracking.sendEvent({
            category: GACATEGORIADOWNLOADCRLV,
            action: "CRLV_BUSCAR_SUCESSO",
            label: "Arquivo CRLV buscado",
          });
          setUrlCRLV(
            window.URL.createObjectURL(
              new Blob([byteArray], { type: "application/pdf" })
            )
          );
          setUrlCRLVMobile(result.data.Arquivo.Conteudo);
        }
      })
      .catch((error) => {
        setErrorModal({
          exibeModal: true,
          tipoModalMensagem: "warning",
          icon: "icon-x-circle",
          mensagemPrincipal:
            "Houve um erro ao buscar o documento de CRLV. Por favor tente novamente mais tarde. Se o erro persistir contate o suporte.",
          mensagemSecundaria: "",
        });
        if (error.response.status !== 401) {
          Tracking.sendEvent({
            category: GACATEGORIADOWNLOADCRLV,
            action: "CRLV_BUSCAR_ERRO",
            label: "Erro ao buscar arquivo CRLV",
          });
        }
      });
  }, []);

  const [urlCRLV, setUrlCRLV] = useState(null);
  const [urlCRLVMobile, setUrlCRLVMobile] = useState(null);
  const mobile = useMediaQuery((theme) => theme.breakpoints.down("xs"));
  const [errorModal, setErrorModal] = useState({
    exibeModal: false,
    tipoModalMensagem: "",
    icon: null,
    mensagemPrincipal: "",
    mensagemSecundaria: "",
  });

  const downloadArquivo = () => {
    try {
      if (getUA !== USER_AGENT_NOVA_APP_MOBILE) {
        const link = document.createElement("a");
        var placa = localStorage.getItem("placa-atual");
        link.href = urlCRLV;
        link.setAttribute("download", "CRLV_" + placa + ".pdf");
        link.target = "_blank";
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
      } else {
        downloadArquivoMobile();
      }
      Tracking.sendEvent({
        category: GACATEGORIADOWNLOADCRLV,
        action: "CRLV_DOWNLOAD_SUCESSO",
        label: "Download do arquivo CRLV efetuado com sucesso",
      });
    } catch (e) {
      if (e.response.status !== 401) {
        Tracking.sendEvent({
          category: GACATEGORIADOWNLOADCRLV,
          action: "CRLV_DOWNLOAD_ERRO",
          label: "Erro no Download do arquivo CRLV",
        });
      }
    }
  };

  const downloadArquivoMobile = () => {
    var placa = localStorage.getItem("placa-atual");
    try {
      window.ReactNativeWebView.postMessage(
        JSON.stringify({
          type: "DOWNLOAD_BASE64",
          payload: { fileName: `CRLV_${placa}`, data: urlCRLVMobile },
        })
      );
      Tracking.sendEvent({
        category: GACATEGORIADOWNLOADCRLV,
        action: "CRLV_DOWNLOAD_SUCESSO",
        label: "Download do arquivo CRLV efetuado com sucesso",
      });
    } catch (e) {
      if (e.response.status !== 401) {
        Tracking.sendEvent({
          category: GACATEGORIADOWNLOADCRLV,
          action: "CRLV_DOWNLOAD_ERRO",
          label: "Erro no Download do arquivo CRLV",
        });
      }
    }
  };

  const handleFecharModal = () => {
    setErrorModal((prevState) => ({
      exibeModal: !prevState.exibeModal,
    }));

    history.push({
      pathname: "/Home",
    });
  };

  return (
    <LayoutInternal title="CRLV Digital">
      {errorModal.exibeModal && (
        <ModalMensagem
          tipoModalMensagem={errorModal.tipoModalMensagem}
          icon={errorModal.icon}
          mensagemPrincipal={errorModal.mensagemPrincipal}
          mensagemSecundaria={errorModal.mensagemSecundaria}
          OnToggled={handleFecharModal}
          textoBotao="OK"
        />
      )}
      <main>
        <Container maxWidth="lg">
          {urlCRLV === null && <Loading />}
          <h1>CRLV do Veículo</h1>

          <Grid container spacing={1}>
            {urlCRLV !== null && (
              <React.Fragment>
                <Grid item xs={12}>
                  <Document file={urlCRLV}>
                    <Page scale={mobile ? 0.45 : 1.2} pageNumber={1} />
                  </Document>
                </Grid>
                <Grid item xs={12} sm={3}>
                  <button className="button" onClick={downloadArquivo}>
                    Download
                  </button>
                </Grid>
              </React.Fragment>
            )}
          </Grid>
        </Container>
      </main>
    </LayoutInternal>
  );
}

export default CRLVPage;
