import { CABECALHO_MENU,
         ERRO_CABECALHO_MENU,
         CARREGAR_CABECALHO,
         TROCAR_PLACA,
         LIMPAR_ESTADO, 
         LOADING_MENU_PRINCIPAL, SETAR_PLACA, SETAR_CODIGO } from './MenuTypes';

const INITIAL_STATE = {
    resposta: null,
    carregar: true,
    reload: false,
    erro: null,
    loading:false,
    placa: null,
    codigoIdentificador: null,
}

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case CABECALHO_MENU:
            return { ...state, resposta: action.payload, carregar: false, reload: false, erro: null };
        case LOADING_MENU_PRINCIPAL:
            return  { ...state, loading: action.payload};
        case CARREGAR_CABECALHO:
            return  { ...state, resposta: null, carregar: false, reload: false , erro: null};
        case TROCAR_PLACA:
            return  { ...state, resposta: null, carregar: false, reload: true, erro: null };
        case ERRO_CABECALHO_MENU:
            return  { ...state, resposta: null, carregar: false, reload: false, erro: action.payload };
        case LIMPAR_ESTADO:
            return  { ...state, resposta: null, carregar: true, reload: false, erro: null };
        case SETAR_PLACA:
            return  { ...state, resposta: null, carregar: true, reload: false, erro: null, placa: action.payload };
        case SETAR_CODIGO:
            return  { ...state, resposta: null, carregar: true, reload: false, erro: null, codigoIdentificador: action.payload };
        default:
            return state;
    }
}