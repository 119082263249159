import styled from "styled-components";

export const VStack = styled.div`
  flex: 1;
  display: flex;

  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

export const Logo = styled.img`
  width: 133px;
  height: 33px;
`;

export const Avatar = styled.img`
  margin: 40px 20px;
`;

export const Container = styled.div`
  flex: 1;
  display: flex;
  background-color: #f2f2f2;
  box-sizing: border-box;
  height: 100%;

  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  padding: 60px 23px;
`;

export const Title = styled.h1`
  color: #3a3d42;
  margin: 0 1em;
  padding: 0.25em 1em;

  font-family: Inter;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 150%;
  /* or 24px */

  text-align: center;
`;

export const Text = styled.span`
  color: #3a3d42;
  margin: 0 1em;
  padding: 0.25em 1em;

  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 1rem;
  line-height: 150%;
  /* or 24px */

  text-align: center;
`;

export const Button = styled.button`
  background: #01602a;
  border-radius: 16px;
  border: transparent;
  margin: 0 1em;
  padding: 0.25em 1em;
  color: #ffffff;

  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 15px;
  font-family: "Inter", sans-serif;

  text-align: center;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  padding: 16px;
  width: 100%;
  height: 48px;

  &:hover {
    filter: brightness(0.9);
  }
`;
