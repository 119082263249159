import React, { Component } from "react";
// Remover o jQuery depois
import $ from "jquery";
import { logout } from "./MenuAction";
import { connect } from "react-redux";
import { Link, NavLink } from "react-router-dom";
import LogoMobile from "../../images/LogoMobile.png";
import {
  TopMobileContainer,
  MenuMobileContainer,
  MenuIconContainer,
  LogoMobileContainer,
  WrapperImg,
  NavContainerMobile,
} from "./styles";
import CabecalhoMenuMobile from "./CabecalhoMenuMobile";
import IconeUsuario from "../../images/IconeUsuario.svg";
import DriveEtaIcon from "@material-ui/icons/DriveEta";
import ErrorBoundary from "../../components/ErrorBoundary";
import ErrorBoundaryRedirect from "../ErrorBoundaryRedirect";
import { checkIsWebView } from "../../helpers/checkPlatform";

class MenuMobile extends Component {
  componentDidMount() {
    $("#actionMenuMobile").on("click", function () {
      $("#menuMobile").removeClass("close").addClass("open");
      $("#overlayMenuMobile").show();
    });

    $("#overlayMenuMobile").on("click", function () {
      $(this).hide();
      $("#menuMobile").removeClass("open").addClass("close");
    });
  }

  handleHome = () => {
    $(this).hide();
    $("#menuMobile").removeClass("open").addClass("close");
    $("#overlayMenuMobile").hide();
  };

  render() {
    if (checkIsWebView) {
      return (
        <React.Fragment>
          <MenuMobileContainer>
            <TopMobileContainer>
            </TopMobileContainer>
            {this.props.children}
            <div className="overlay" id="overlayMenuMobile"></div>
            <div className="menu-mobile" id="menuMobile">
              <ErrorBoundaryRedirect>
                <CabecalhoMenuMobile />
              </ErrorBoundaryRedirect>
            </div>
          </MenuMobileContainer>
        </React.Fragment>
      );
    }
    return (
      <React.Fragment>
        <MenuMobileContainer>
          <TopMobileContainer>
            <Link to="/">
              <LogoMobileContainer>
                <img src={LogoMobile} style={{ width: "90px" }} alt="" />
              </LogoMobileContainer>
            </Link>
            <MenuIconContainer id="actionMenuMobile">
              <span className="icon icon-menu"></span>
            </MenuIconContainer>
          </TopMobileContainer>
          {this.props.children}
          <div className="overlay" id="overlayMenuMobile"></div>
          <div className="menu-mobile" id="menuMobile">
            <ErrorBoundaryRedirect>
              <CabecalhoMenuMobile />
            </ErrorBoundaryRedirect>
            <NavContainerMobile>
              <WrapperImg src={IconeUsuario} />
              <div className="menu-mobile-links">
                <nav className="wrapper-links-menu">
                  <ul className="links">
                    <li>
                      <NavLink
                        data-gtm-inicio="sim"
                        className="link"
                        to="/Home"
                        activeClassName="active"
                        onClick={this.handleHome}
                      >
                        <div className="wrapper gtm-menu-inicio">
                          <span className="icon icon-home gtm-menu-inicio"></span>
                          <span className="text gtm-menu-inicio">Início</span>
                        </div>
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        className="link"
                        to="/Crlv"
                        activeClassName="active"
                      >
                        <div className="wrapper">
                          <span className="icon icon-file"></span>
                          <span className="text">Documento CRLV</span>
                        </div>
                      </NavLink>
                    </li>
                    {/*<li>
                      <NavLink
                        className="link"
                        to="/GuiaCondutor"
                        activeClassName="active"
                      >
                        <div className="wrapper">
                          <span className="icon icon-book"></span>
                          <span className="text">Guia do Condutor</span>
                        </div>
                      </NavLink>
                    </li>
                    */}
                    <li>
                      <NavLink
                        className="link"
                        to="/ServicosContratados"
                        activeClassName="active"
                      >
                        <div className="wrapper gtm-menu-servicos-contratados">
                          <span className="icon icon-list gtm-menu-servicos-contratados"></span>
                          <span className="text gtm-menu-servicos-contratados">
                            Serviços contratados
                          </span>
                        </div>
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        className="link"
                        to="/EditarKM"
                        activeClassName="active"
                      >
                        <div className="wrapper gtm-menu-atualizar-km">
                          <span className="icon icon-velocimeter gtm-menu-atualizar-km"></span>
                          <span className="text gtm-menu-atualizar-km">
                            Atualizar Km
                          </span>
                        </div>
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        className="link"
                        to="/Avarias"
                        activeClassName="active"
                      >
                        <div className="wrapper gtm-menu-acidente-batida">
                          <span className="icon icon-crash2 gtm-menu-acidente-batida"></span>
                          <span className="text gtm-menu-acidente-batida">
                            Acidente / Batida
                          </span>
                        </div>
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        className="link"
                        to="/Roubos"
                        activeClassName="active"
                      >
                        <div className="wrapper gtm-menu-reportar-roubo">
                          <span className="icon icon-police2 gtm-menu-reportar-roubo"></span>
                          <span className="text gtm-menu-reportar-roubo">
                            Reportar roubo
                          </span>
                        </div>
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        className="link"
                        to="/PoliticaFrota"
                        activeClassName="active"
                      >
                        <div className="wrapper gtm-menu-politica-frota">
                          <span className="icon icon-file-text gtm-menu-politica-frota"></span>
                          <span className="text gtm-menu-politica-frota">
                            Política de frota
                          </span>
                        </div>
                      </NavLink>
                    </li>
                    {/**
                    <li className="link">
                      <div className="wrapper">
                        <span style={{ color: "#CBCCD2", marginRight: "16px" }}>
                          <DriveEtaIcon />
                        </span>
                        <a
                          href="https://localiza.csod.com/"
                          target="_blank"
                          style={{ color: "#7B808C" }}
                        >
                          <span className="text" style={{}}>
                            Frotas experience
                          </span>
                        </a>
                      </div>
                    </li>
                  */}
                    <li>
                      <hr />
                      <div>
                        <div className="wrapper">
                          <div>
                            <span className="textTitle">Fale com a gente</span>
                          </div>
                          <br />
                          <a
                            href="https://api.whatsapp.com/send?1=pt_BR&phone=5531999101010"
                            target="_blank"
                          >
                            <span className="iconContato icon-whatsapp"></span>
                            <span className="textInfo">WhatsApp: </span>
                            <span className="tel">31 99910-1010</span>
                          </a>
                          <div>
                            <div>
                              <span className="textInfo subtitle">
                                Segunda a Sexta das 07h às 20h
                              </span>
                            </div>
                            <div>
                              <span className="textInfo subtitle">
                                Sábados das 07h às 18h
                              </span>
                            </div>
                          </div>
                          <br />
                          <a href="tel:08009791010">
                            <span className="iconContato icon-phone"></span>
                            <span className="textInfo">Telefone: </span>
                            <span className="tel">0800 979 1010</span>
                          </a>
                          <div>
                            <span className="textInfo subtitle">
                              Todos os dias 24 horas
                            </span>
                          </div>
                        </div>
                      </div>
                      <hr />
                    </li>
                    <li>
                      <Link to="/Login" className="link">
                        <div className="wrapper gtm-menu-logout">
                          <span className="icon icon-log-out gtm-menu-logout"></span>
                          <span className="text gtm-menu-logout">Sair</span>
                        </div>
                      </Link>
                    </li>
                  </ul>
                </nav>
              </div>
            </NavContainerMobile>
          </div>
        </MenuMobileContainer>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    /* placa: state.menu.placa, */
  };
};

export default connect(mapStateToProps, {})(MenuMobile);