import React, { Component } from 'react';
import '../styles/css/header-internal.css';
import {withRouter, Redirect} from 'react-router-dom'
import * as Cookie from '../functions/Cookies';
import { NomeCookieSessaoRea} from '../config/consts';

 class HeaderInternal extends Component {
    constructor(props){
        super(props);
        this.goBack = this.goBack.bind(this);
     }

    goBack(){         
        Cookie.set(NomeCookieSessaoRea,'', -1, true);
        this.props.history.push("/home");
    }

    render() {
        return (
            <header className="hearder-internal">
                <span className="icon icon-chevron-left"  onClick={this.goBack}></span>
                <span className="title">{this.props.title}</span>
                <span className="action"></span>
            </header>
        )
    }
}

export default withRouter(HeaderInternal)
