import React, { Component } from 'react';
import spiner from '../styles/img/loading_spinner.gif';

export default class Loading extends Component { 
    render(){
        return(
           <div className="backdrop">
               <img src={spiner} className="spinner" />
           </div>
        )

    }
}
