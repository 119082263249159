import React, { Component } from 'react';
import insertLabelDatePicker from '../../helpers/formatDatePickers';
import Checkbox from '../Checkbox';

class Declaracao extends Component {
    constructor(props) {
        super(props);
        this.handleVoltar = this.handleVoltar.bind(this);
        this.handleConcluir = this.handleConcluir.bind(this);
        this.handleCheckbox = this.handleCheckbox.bind(this);
        this.state = {
            disabledButton : true
        };
    }

    componentDidMount() {
        insertLabelDatePicker();
        window.scrollTo(0, 0);
    }

    componentWillMount(){
        if (this.props.modelo.terceiroEnvolvido === 'sim' && this.props.modelo.clienteIsencaoPrefixacao === true)
            this.props.onAlterarTipoEmail("email-1");
        else if (this.props.modelo.terceiroEnvolvido === 'não')
            this.props.onAlterarTipoEmail("email-2");
        else{
            this.props.onAlterarTipoEmail("email-3");
        }
    }

    handleConcluir() {
        this.props.onEnviarEmailFormulario();
    }

    handleVoltar() {
        if(this.props.modelo.terceiroEnvolvido === "sim" && this.props.modelo.outroEnvolvido === "não"){
            this.props.onIrParaEtapa(6);
        } else if (this.props.modelo.terceiroEnvolvido === "sim" && this.props.modelo.causadorAcidente === "condutor") {
            this.props.onIrParaEtapa(7);
        } else if (this.props.modelo.terceiroEnvolvido === "sim" && this.props.modelo.causadorAcidente === "terceiro") {
            this.props.onIrParaEtapa(8);
        } else {
            this.props.onIrParaEtapa(4);
        }
    }

    handleCheckbox() {
        this.setState(prevState => ({
            disabledButton: !prevState.disabledButton
        }));
    }

    render() {

        var now = new Date();
        var dia = now.getDate();
        var mes = now.getMonth() + 1;
        var ano = now.getFullYear();

        const { condutor } = this.props;

        return (
            <React.Fragment>
                <div className="row w-xl-66">
                    <div className="col-3">
                        <Checkbox label={`Eu, ${condutor}, declaro, sob as penas da Lei, que são verdadeiras e completas as informações prestadas neste documento, bem como informei a terceiros a existência deste. ${dia.toString()}/${mes.toString()}/${ano.toString()}`}
                        onCheckboxClick={this.handleCheckbox}/>
                    </div>
                    <div className="ml-auto mt-3 col-md-6">
                        <div className="row">
                            <div className="col">
                                <button className="button" onClick={ this.handleVoltar } >Voltar</button>
                            </div>
                            <div className="col">
                                <button className="button gtm-rea-concluir" disabled={this.state.disabledButton} onClick={ this.handleConcluir }>Concluir</button>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}
export default Declaracao