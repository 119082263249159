import React, { Component } from 'react';
import { connect } from 'react-redux';
import { AutorizarCookies } from './AutorizarCookiesActions'
import ModalMensagem from '../../components/ModalMensagem/ModalMensagem';
import Loading from '../../components/Loading';
//import { urlPoliticaCookies } from '../../config/consts';

class AutorizarCookiesPage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            formInvalido: false,
            autorizaCookies: false
        }
    }

    Continuar() {
        let concorda = this.ConcordaTermos.checked;
        if (concorda) {
            let autorizaCookies = this.props.AutorizarCookies();
            this.setState({ loading: true });
        }
        else {
            this.setState({ formInvalido: true });
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.autorizarCookies != this.props.autorizarCookies && this.props.autorizarCookies)
            window.location.href = "/";
        if (this.props.loading != prevProps.loading) {
            this.setState({ loading: this.props.loading });
        }
    }

    render() {

        let retorno;
        if (this.props.erro) {
            retorno = <ModalMensagem tipoModalMensagem='warning' icon='icon-x-circle'
                mensagemPrincipal={this.props.erro.message}
                textoBotao="OK" />;
        }
        else if (!this.props.autorizarCookies) {

            let urlPoliticaCookies = "https://frotas.localiza.com/politica-de-privacidade";
            retorno =
                <div className="autorizar-cookie-wrapper">
                    <div className="autorizar-cookie">
                        <div>
                            <p>
                                A Localiza respeita a sua privacidade! Para sua melhor experiência, as informações pessoais fornecidas serão utilizadas apenas nos limites estabelecidos em nossa <a href={urlPoliticaCookies} target="_blank">Política de Privacidade</a>, confira!
                                </p>
                            <div className={this.state.formInvalido ? "wrapper-input has-error" : "wrapper-input"}>
                               
                            <label className="label"> <input type="checkbox" className="input" ref={(input) => this.ConcordaTermos = input} />
                                  Li e concordo com os termos estabelecidos.</label>
                             
                            </div>
                            <button className="button" onClick={() => this.Continuar()}>Continuar</button>
                        </div>
                    </div>
                </div>

        }

        return (
            <div>
                {this.props.loading ? <Loading /> : null}
                <main>
                    <div className="content-primary">
                        {retorno}
                    </div>
                </main>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        formValido: state.formValido,
        autorizarCookies: state.AutorizarCookies.autorizarCookies,
        loading: state.AutorizarCookies.loading,
        erro: state.AutorizarCookies.erro,
        placa: state.menu.placa
    }
};

export default connect(mapStateToProps, {
    AutorizarCookies
})(AutorizarCookiesPage)