import styled, { css } from "styled-components";

export const MsgContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 320;
  padding: 0px 10px 0px 10px;
`;

export const ErrorMessage = styled.h3`
  text-align: center;
  font-size: 18px;
  padding: 20px 0px 20px 0px;
  color: white;
`;

export const Message = styled.h3`
  text-align: center;
  font-size: 18px;
  padding: 20px 0px 20px 0px;
  color: #01602a;

  ${({ loading }) =>
    loading === true &&
    css`
      padding: 70px 0px 0px 0px;
    `}
`;

export const Button = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  background: #01602a;
  border-radius: 16px;
  border: transparent;
  color: #FFFFFF;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 15px;
  font-family: "Inter", sans-serif;
  text-align: center;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  width: 100%;
  height: 48px;
`;

export const SectionContainerRedirectApp = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #DEE9D3;
  height: 100%;
  width: 100%;
`;

export const LogoContainerRedirectApp = styled.div`
  width: 210px;
`;

export const Img = styled.img`
  display: table;
  width: 100%;
  height: auto;
  padding: 0;
`;
