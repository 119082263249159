import {
    CABECALHO_MENU,
    ERRO_CABECALHO_MENU,
    CARREGAR_CABECALHO,
    TROCAR_PLACA,
    LIMPAR_ESTADO,
    LOADING_MENU_PRINCIPAL,
    SETAR_PLACA,
    SETAR_CODIGO
} from './MenuTypes'
import { HttpGet } from '../../functions/default';
import { DESLOGAR } from '../../pages/Login/LoginTypes';
import * as Cookie from '../../functions/Cookies';
import { NomeCookie, NomeCookiePlaca, NomeCookieUrlForcada, NomeCookieTipoLogin } from '../../config/consts';

export const setPlacaAndCode = (placa, codigo) => {
    return (dispatch) => {
        dispatch({ type: SETAR_PLACA, payload: placa });
        dispatch({ type: SETAR_CODIGO, payload: codigo });
    }
}

export const mudarFlagCarregamento = () => {
    return {
        type: CARREGAR_CABECALHO
    }
}

export const carregarCabecalho = (placa) => {
    return (dispatch) => {
        if (navigator.onLine) {
            HttpGet(`/BuscaDadosCabecalho?placa=${placa}`, null, null)
                .then(function (result) {
                    localStorage.setItem("sigla-contrato", result.data.SiglaContrato)
                    localStorage.setItem("nome-empresa", result.data.NomeEmpresa)
                    dispatch({ type: CABECALHO_MENU, payload: result.data });
                })
                .catch(function (erro) {
                    if (erro.response && erro.response.status === 401 || erro.response && erro.response.status === 403)
                        dispatch({ type: DESLOGAR, payload: { message: 'Usuário não autenticado.' } });
                    else
                        dispatch({ type: ERRO_CABECALHO_MENU, payload: { message: 'Ocorreu um erro ao buscar os dados da placa, tente novamente mais tarde.' } });

                });
        }
    }
}

export const trocarPlaca = (placa) => {
    return (dispatch) => {
        dispatch({ type: LOADING_MENU_PRINCIPAL, payload: true });
        HttpGet(`/TrocarPlaca?placa=${placa}`, null, null)
            .then(function (result) {
                salvarAutenticacao(result.data);
                dispatch({ type: TROCAR_PLACA, payload: true });
                dispatch({ type: LOADING_MENU_PRINCIPAL, payload: false });
            })
            .catch(function (erro) {
                if (erro.response && erro.response.status === 401 || erro.response && erro.response.status === 403) {
                    dispatch({ type: DESLOGAR, payload: { message: 'Usuário não autenticado.' } });
                }
                else {
                    dispatch({ type: ERRO_CABECALHO_MENU, payload: { message: 'Ocorreu um erro ao trocar a placa selecionada, tente novamente mais tarde. ' } });
                }
                dispatch({ type: LOADING_MENU_PRINCIPAL, payload: false });
            });
    }
}

export const logout = () => {
    return (dispatch) => {
        Cookie.set(NomeCookie, '', -1);
        Cookie.set(NomeCookiePlaca, '', -1);
        Cookie.set(NomeCookieUrlForcada, '', -1);
        localStorage.removeItem('codigoUsuario');
        localStorage.removeItem(NomeCookieTipoLogin);
        dispatch({ type: DESLOGAR });
    }
}

export const clearState = () => {
    return { type: LIMPAR_ESTADO }
}

function salvarAutenticacao(data) {
    if (data) {
        Cookie.set(NomeCookie, data.Token, data.TempoExpiracao);

        if (data.Placa) {
            Cookie.set(NomeCookiePlaca, data.Placa, data.TempoExpiracao);
        }
    }
}