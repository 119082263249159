import {
    POLITICAFROTA,
    LOADING_POLITICA,
    LOADED_POLITICA,
    ERRO_POLITICA
} from './PoliticaFrotaTypes'

import { HttpGet } from '../../functions/default';
import { DESLOGAR } from '../Login/LoginTypes';

import qs from 'qs';

export const limparMensageErro = () => {
    return (dispatch) => {
        dispatch({ type: ERRO_POLITICA, payload: null });
    }
}

export const PoliticaFrota = (param) => {
    return (dispatch) => {
        if (navigator.onLine) {
            dispatch({ type: LOADING_POLITICA });
            HttpGet(`/ObterPoliticaFrota?placa=${param}`).then(function (result) {
                localStorage.setItem("politica-frota-id", result.data.IdDocumento);
                dispatch({ type: LOADED_POLITICA });
                dispatch({ type: POLITICAFROTA, payload: result.data });
            })
                .catch(function (erro) {
                    dispatch({ type: LOADED_POLITICA });
                    if (erro.response && erro.response.status === 401)
                        dispatch({ type: DESLOGAR, payload: { message: 'Usuário não autenticado.' } });
                    else if (erro.response) {
                        if (erro.response.data) {
                            dispatch({ type: ERRO_POLITICA, payload: { message: 'Ocorreu um erro ao buscar politica frotas, tente novamente mais tarde.' } });
                        }
                        else {
                            dispatch({ type: ERRO_POLITICA, payload: { message: 'Ocorreu um erro ao buscar politica frotas, tente novamente mais tarde.' } });
                        }
                    }
                    else {
                        dispatch({ type: ERRO_POLITICA, payload: { message: 'Ocorreu um erro ao buscar politica frotas, tente novamente mais tarde.' } });
                    }
                });
        }
    }
}

export const setErro = (mensagem) => {
    return (dispatch) => {
        dispatch({ type: ERRO_POLITICA, payload: { message: mensagem } });
    }
}

export const showLoading = (show) => {
    return (dispatch) => {
        if (show) {
            dispatch({ type: LOADING_POLITICA });
        }
        else {
            dispatch({ type: LOADED_POLITICA });
        }
    }
}

export const RegistrarDownloadArquivo = (requestObj) => {
    return (dispatch) => {
        if (navigator.onLine) {
            var param = qs.stringify(requestObj);
            HttpGet(`/RegistraDownloadArquivoPoliticaFrota?${param}`, null).then(function (res) { });
        }
        else
            dispatch({ type: ERRO_POLITICA, payload: { message: 'Para prosseguir, conecte seu celular à internet.' } });
    }
}