import {
    LISTAR_AVARIAS,
    LISTAR_ROUBOS,
    VER_MAIS,
    BUSCAR_ULTIMO_ROUBO_PENDENTE,
    CRIAR_MODAL,
    ERRO_SINISTRO,
    ENVIAR_DOC,
    ENVIARDOC_LOADING,
    ENVIARDOC_LOADED
} from './SinistroTypes';
import { HttpGet, HttpPost } from '../../functions/default';
import qs from 'qs';
import { DominioApi } from '../../config/consts';
import { DESLOGAR } from '../../pages/Login/LoginTypes';

export const limparMensageErro = () => {
    return (dispatch) => {
        dispatch({ type: ERRO_SINISTRO, payload: null });
    }
}

export const listaAvarias = (placa) => {
    return (dispatch) => {
        if(navigator.onLine){
            HttpGet(`/ListarAvarias?placa=${placa}`, null, null)
            .then(function (result) {
                dispatch({ type: LISTAR_AVARIAS, payload: result.data });
            })
            .catch(function (erro) {
                if (erro.response && erro.response.status === 401 || erro.response && erro.response.status === 403)
                    dispatch({ type: DESLOGAR, payload: { message: 'Usuário não autenticado.' } });
                else
                    dispatch({ type: ERRO_SINISTRO, payload: { message: 'Ocorreu um erro ao buscar a lista de avarias, tente novamente mais tarde.'} });
            });
        }
    }
}

export const listaRoubos = (placa) => {
    return (dispatch) => {
        if(navigator.onLine){
            HttpGet(`/ListarRoubos?placa=${placa}`, null, null)
            .then(function (result) {
                dispatch({ type: LISTAR_ROUBOS, payload: result.data });
            })
            .catch(function (erro) {
                if (erro.response && erro.response.status === 401 || erro.response && erro.response.status === 403)
                    dispatch({ type: DESLOGAR, payload: { message: 'Usuário não autenticado.' } });
                else
                    dispatch({ type: ERRO_SINISTRO, payload: { message: 'Ocorreu um erro ao buscar a lista de roubos, tente novamente mais tarde.'} });
            });
        }
    }
}

export const buscarUltimoRouboPendente = (placa, mobile) => {
    return (dispatch) => {
        if(!mobile)
            mobile = false;
        
        if(navigator.onLine){
            var param = qs.stringify({placa, mobile});
            HttpGet(`/BuscarUltimoRouboPendente?${param}`, null, null)
                .then(function (result) {
                    dispatch({ type: BUSCAR_ULTIMO_ROUBO_PENDENTE, payload: result.data });
                })
                .catch(function (erro) {
                    if (erro.response && erro.response.status === 401 || erro.response && erro.response.status === 403)
                        dispatch({ type: DESLOGAR, payload: { message: 'Usuário não autenticado.' } });
                    else
                        dispatch({ type: ERRO_SINISTRO, payload: { message: 'Ocorreu um erro ao buscar ultimo roubo pendente, tente novamente mais tarde.'} });
                });
        }
    }
}

export const verMais = (contador) => {
    return (dispatch) => {
        dispatch({ type: VER_MAIS, contador: contador + 5 });
    }
}

export const criarModal = (modal) => {
    return (dispatch) => {
        if(navigator.onLine){
            var requisicao = {};
            requisicao['placa'] = localStorage.getItem("placa-atual");
            requisicao['numero'] = modal.numero;
            requisicao['sequencia'] = modal.sequencia;
            requisicao['atendimento'] = modal.sequencia ? '' : modal.numero;
    
            HttpGet(`/DocumentosPendentes?${qs.stringify(requisicao)}`, null, null)
                .then(function (result) {
                    if (result.data.Sucesso) {
                        if (!modal.documentos)
                            modal['documentos'] = result.data.Documentos;
    
                        dispatch({ type: CRIAR_MODAL, payload: modal });
                    }
                    else {
                        dispatch({ type: ERRO_SINISTRO, payload: { message: result.data.Mensagem } });
                    }
                })
                .catch(function (erro) {
                    if (erro.response && erro.response.status === 401 || erro.response && erro.response.status === 403)
                        dispatch({ type: DESLOGAR, payload: { message: 'Usuário não autenticado.' } });
                    else
                        dispatch({ type: ERRO_SINISTRO, payload: { message: 'Ocorreu um erro ao buscar os dados, tente novamente mais tarde.'} });
                });
        }else dispatch({ type: ERRO_SINISTRO, payload: { message: 'Para prosseguir, conecte seu celular à internet.' } });
    }
}

export const enviarArquivos = (formData) => {
    return (dispatch) => {
        dispatch({ type: ENVIARDOC_LOADING });
        HttpPost(DominioApi + '/Sinistro', null, formData)
            .then(function (resposta) {
                if (resposta.data.sucesso) {
                    dispatch({ type: ENVIAR_DOC, payload: { message: resposta.data.mensagem } });
                }
                else {
                    dispatch({ type: ERRO_SINISTRO, payload: { message: resposta.data.mensagem } });
                }

                dispatch({ type: ENVIARDOC_LOADED });
            })
            .catch(function (erro) {
                if (erro.response && erro.response.status === 401 || erro.response && erro.response.status === 403)
                    dispatch({ type: DESLOGAR, payload: { message: 'Usuário não autenticado.' } });
                else
                    dispatch({ type: ERRO_SINISTRO, payload: { message: 'Ocorreu um ao enviar arquivos, tente novamente mais tarde.'} });

                dispatch({ type: ENVIARDOC_LOADED });
            });
    }
}

export const dispatchErro = (mensagem) => {
    return (dispatch) => {
        dispatch({ type: ERRO_SINISTRO, payload: { message: mensagem } });
    }
}