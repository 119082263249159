import React, { Component } from "react";
import { connect } from "react-redux";
import CardMultas from "../../components/Multas/CardMultas";
import Panel from "../../components/Panel";
import {
  listaMulta,
  limparMensageErro,
  buscarMyFleetUrl,
} from "./CardMultaAction";
import { formatDayMonth, formatDateAndHour } from "../../helpers/formatDate";
import ButtonLightIcon from "../ButtonLightIcon";
import ModalMensagem from "../ModalMensagem/ModalMensagem";
import Cabecalho from "./Cabecalho";
import {
  Container,
  Historico,
  Abertas,
  Texto,
  MultasMobile,
  ImgContainer,
  Titulo,
  SubTitulo,
  StatusContainer,
  MarcadorRed,
  MarcadorYellow,
  Status,
  QuantidadePendentes,
  QuantidadeAbertas,
  Arrow,
} from "./styles";
import "../../styles/css/container-multas.css";
import imgMultas from "../../images/imgMultas.svg";
import { formatDateAbbreviatedShorter } from "../../helpers/formatDate";
import { Link } from "react-router-dom";
import arrow from "../../images/arrow.svg";
class Grupo extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { grupo } = this.props;
    return (
      <React.Fragment>
        <Panel
          titulo={grupo.DescSituacao}
          quantidade={grupo.Total}
          openDefault={true}
        >
          {grupo.Multas.slice(0, grupo.Multas.length).map((m) => (
            <CardMultas
              key={m.AIT}
              descricao={m.DescricaoInfracao}
              descSituacao={m.DescSituacaoMulta}
              multaInfo={
                m.NumeroPontosInfracao +
                " pontos | " +
                formatDayMonth(m.DataInfracao)
              }
              dataInfracao={formatDateAndHour(m.DataInfracao)}
              valorInfracao={m.ValorInfracao}
              descEndereco={m.DescricaoEndereco}
              vencimento={m.DataLimiteMulta}
              ait={m.AIT}
              urlMyFleet={m.UrlMyFleet}
              orgao={m.CodigoOrgao}
              descOrgao={m.DescOrgao}
              corcard={this.props.grupo.Cor}
              situacao={m.SituacaoMulta}
              temLink={m.SituacaoMulta != null}
              usuarioIndicado={m.UsuarioIndicado}
            />
          ))}
        </Panel>
      </React.Fragment>
    );
  }
}
class Lista extends Component {
  constructor(props) {
    super(props);

    this.state = {
      historico: true,
    };
    this.handleToggleOn = this.handleToggleOn.bind(this);
    this.handleToggleOff = this.handleToggleOff.bind(this);
  }

  handleToggleOn() {
    this.setState({
      historico: false,
    });
  }

  handleToggleOff() {
    this.setState({
      historico: true,
    });
  }
  handleRemoveMensagemErro = () => {
    this.props.limparMensageErro();
  };

  componentDidMount() {
    this.props.listaMulta(
      localStorage.getItem("placa-atual"),
      localStorage.getItem("codigo-identificador-atual")
    );
  }

  componentDidUpdate(prevProps) {}

  formataVencimento(data) {
    if (data) return "Prazo máximo " + formatDateAbbreviatedShorter(data);
    return "";
  }

  render() {
    if (!this.props.multas) return null;
    const historico = this.props.multas.Grupos.filter(
      (e) => e.Situacao === 6
    ).map((x, index) => <Grupo key={index} grupo={x} />);

    const abertas = this.props.multas.Grupos.filter(
      (e) => e.Situacao !== 6
    ).map((x, index) => <Grupo key={index} grupo={x} />);

    const abertasTotal = this.props.multas.Grupos.filter(
      (e) => e.Situacao !== 6
    ).reduce((sum, gm) => sum + gm.Multas.length, 0);

    const historicoTotal = this.props.multas.Grupos.filter(
      (e) => e.Situacao === 6
    ).reduce((sum, gm) => sum + gm.Multas.length, 0);

    const pendentesTotal = this.props.multas.Grupos.filter(
      (e) => e.Situacao === 2
    ).map((x) => x.Total);
    {
      /* MOBILE */
    }
    if (this.props.mobile === true) {
      if (this.props.listar === true) {
        return (
          <section>
            {this.props.erro ? (
              <ModalMensagem
                tipoModalMensagem="warning"
                icon="icon-x-circle"
                mensagemPrincipal={this.props.erro.message}
                OnToggled={this.handleRemoveMensagemErro}
                textoBotao="OK"
              />
            ) : null}

            <Container>
              <Abertas
                onClick={this.handleToggleOff}
                className={
                  this.state.historico === true
                    ? "turnOnMobile"
                    : "turnOffMobile"
                }
              >
                <Texto style={{ padding: "1px 0 0 0" }}>
                  {abertasTotal === 0 ? `Abertas` : `Abertas (${abertasTotal})`}
                </Texto>
              </Abertas>
              <Historico
                onClick={this.handleToggleOn}
                className={
                  this.state.historico === true
                    ? "turnOffMobile"
                    : "turnOnMobile"
                }
              >
                <Texto>
                  {historicoTotal === 0
                    ? `Expiradas`
                    : `Expiradas (${historicoTotal})`}
                </Texto>
              </Historico>
            </Container>
            {this.state.historico === false ? (
              <span>{historico}</span>
            ) : (
              <span>{abertas}</span>
            )}
            {this.state.historico === true && abertas.length === 0 ? (
              <ImgContainer>
                <img src={imgMultas} />
              </ImgContainer>
            ) : (
              ""
            )}
            {this.state.historico === false && historico.length === 0 ? (
              <ImgContainer>
                <img src={imgMultas} />
              </ImgContainer>
            ) : (
              ""
            )}
          </section>
        );
      } else {
        return (
          <MultasMobile>
            {this.props.erro ? (
              <ModalMensagem
                tipoModalMensagem="warning"
                icon="icon-x-circle"
                mensagemPrincipal={this.props.erro.message}
                OnToggled={this.handleRemoveMensagemErro}
                textoBotao="OK"
              />
            ) : null}
            <Link to="/Multas" style={{ textDecoration: "none" }}>
              <div style={{ margin: "15px 0 0 15px" }}>
                <div>
                  <span
                    className="icon icon-bill2"
                    style={{ color: "#7B808C" }}
                  ></span>
                  <span
                    style={{
                      padding: "0 0 0 5px",
                      fontSize: "10px",
                      color: "#7B808C",
                    }}
                  >
                    MULTAS
                  </span>
                  <Arrow>
                    <img src={arrow} />
                  </Arrow>
                </div>
                <div>
                  <Titulo>
                    Histórico de multas
                    <SubTitulo>
                      {this.props.multas.Grupos.length === 0
                        ? ""
                        : this.formataVencimento(
                            this.props.multas.Grupos[0].Multas[0]
                              .DataLimiteMulta
                          )}
                    </SubTitulo>
                  </Titulo>
                  <StatusContainer>
                    <MarcadorRed />
                    <Status>Pendentes </Status>
                    <QuantidadePendentes>
                      {pendentesTotal.length === 0 ? "0" : pendentesTotal}
                    </QuantidadePendentes>
                    <MarcadorYellow />
                    <Status> Abertas </Status>
                    <QuantidadeAbertas>
                      {abertasTotal === 0 ? "0" : abertasTotal}
                    </QuantidadeAbertas>
                  </StatusContainer>
                </div>
              </div>
            </Link>
          </MultasMobile>
        );
      }
    } else {
      {
        /* WEB */
      }
      return (
        <div className="item-left">
          {this.props.erro ? (
            <ModalMensagem
              tipoModalMensagem="warning"
              icon="icon-x-circle"
              mensagemPrincipal={this.props.erro.message}
              OnToggled={this.handleRemoveMensagemErro}
              textoBotao="OK"
            />
          ) : null}

          <div style={{ margin: "10px 0 0 15px" }}>
            <div style={{ color: "#7B808C" }}>
              <span className="icon icon-bill2"></span>
              <span style={{ padding: "0 0 0 5px", fontSize: "10px" }}>
                MULTAS
              </span>
            </div>
            {this.state.historico === true ? (
              <div>
                <Titulo>Histórico de multas</Titulo>
                <SubTitulo>
                  {this.props.multas.Grupos.length === 0
                    ? ""
                    : this.formataVencimento(
                        this.props.multas.Grupos[0].Multas[0].DataLimiteMulta
                      )}
                </SubTitulo>
              </div>
            ) : (
              <Titulo>Histórico de multas</Titulo>
            )}
          </div>

          <Container>
            <Abertas
              onClick={this.handleToggleOff}
              className={this.state.historico === true ? "turnOn" : "turnOff"}
            >
              <Texto>
                {abertasTotal === 0 ? `Abertas` : `Abertas (${abertasTotal})`}
              </Texto>
            </Abertas>
            <Historico
              onClick={this.handleToggleOn}
              className={this.state.historico === true ? "turnOff" : "turnOn"}
            >
              <Texto>
                {historicoTotal === 0
                  ? `Expiradas`
                  : `Expiradas (${historicoTotal})`}
              </Texto>
            </Historico>
          </Container>
          {this.state.historico === false ? (
            <span>{historico}</span>
          ) : (
            <span>{abertas}</span>
          )}
          {this.state.historico === true && abertas.length === 0 ? (
            <ImgContainer>
              <img src={imgMultas} />
            </ImgContainer>
          ) : (
            ""
          )}
          {this.state.historico === false && historico.length === 0 ? (
            <ImgContainer>
              <img src={imgMultas} />
            </ImgContainer>
          ) : (
            ""
          )}
        </div>
      );
    }
  }
}

const mapStateToProps = (state) => {
  return {
    multas: state.multa.multas,
    erro: state.multa.erro,
    limitTo: state.multa.limitTo,
    placa: state.menu.placa,
    codigoIdentificador: state.menu.codigoIdentificador,
  };
};

export default connect(mapStateToProps, {
  listaMulta,
  limparMensageErro,
})(Lista);
