import React from 'react';
import { Grid, makeStyles, Checkbox, FormControlLabel } from '@material-ui/core';
import ButtonUploadImage from './ButtonUploadImage';
import celular from '../../styles/img/celular.png';
import DialogMensagem from '../DialogMensagem/DialogMensagem';
import { formatDateAndHour } from '../../helpers/formatDate';

const useStyles = makeStyles(theme => ({
  titulo: {
    fontFamily: "Metropolis-semiBold",
    fontSize: "12px",
  },
  subtitulo: {
    fontFamily: "Metropolis",
    color: '#b8bac1'
  },
  informacao: {
    fontFamily: "Metropolis",
    fontSize: "8px",
    textAlign: "right",
    cursor: "pointer"
  },
}));

function EnviarAssinatura({
  imagemAssinatura,
  loadAssinatura,
  limparAssinatura,
  aceitarAssinatura,
  aceitarTermos,
  aceitarIndicacaoTerceiros,
  dadosDaMulta,
  situacaoLogin,
  condutor,
  codigoUsuario }) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [openDialog, setOpenDialog] = React.useState(false);
  const [openInfoDialog, setOpenInfoDialog] = React.useState(false);
  const [scroll, setScroll] = React.useState('paper');

  const handleOpen = scrollType => () => {
    setOpen(true);
    setScroll(scrollType);
  };

  const handleOpenDialog = scrollType => () => {
    setOpenDialog(true);
    setScroll(scrollType);
  };

  const handleOpenInfoDialog = scrollType => () => {
    setOpenInfoDialog(true);
    setScroll(scrollType);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleCloseInfoDialog = () => {
    setOpenInfoDialog(false);
  };

  return (
    <React.Fragment>
      <Grid container>
        <Grid item sm={10} xs={9}>
          <p className="icon icon-info" style={{ color: "#555555", marginTop: 5 }}>
            <span className={classes.titulo}> ENVIO DA ASSINATURA</span></p>
        </Grid>
        <Grid item sm={1} xs={3}>
          <span onClick={handleOpenInfoDialog('paper')} className={classes.informacao}>Porque pedimos assinatura?</span>
          <DialogMensagem
            titulo="Porque pedimos assinatura?"
            conteudo="A assinatura será  utilizada unicamente para a indicação do condutor infrator junto ao órgão de trânsito."
            scroll={scroll}
            open={openInfoDialog}
            handleClose={handleCloseInfoDialog}
          />
        </Grid>
      </Grid>
      <Grid container justify="center" spacing={1}>
        <Grid item className={classes.subtitulo} sm={12} style={{ marginTop: 10 }}>
          <span>Tire uma foto da assinatura do condutor em uma folha em branco sem pauta.
                  Ligue o flash do seu celular e enquadre a assinatura no visor com o celular na horizontal. </span>
        </Grid>
        <Grid item style={{ marginTop: 10 }}>
          {
            (() => {
              if (imagemAssinatura || condutor.imagemAssinatura) {
                return "";
              } else {
                return <img src={celular} width="100%" />;
              }
            })()
          }
        </Grid>
        <Grid item sm={12} xs={12}>
          <ButtonUploadImage title='Adicionar Assinatura'
            image={condutor.imagemAssinatura ? condutor.imagemAssinatura : imagemAssinatura}
            handleUpload={loadAssinatura}
            handleClearState={limparAssinatura}
          />
        </Grid>
        <Grid item>
          {
            imagemAssinatura || condutor.imagemAssinatura ? (
              <React.Fragment>
                <Grid item sm={12} xs={12}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        color="primary"
                        onChange={aceitarTermos}
                      />
                    }
                    label={
                      <Grid item>
                        <span onClick={handleOpen('paper')} style={{ textDecoration: "underline" }}>Li e aceito os termos de responsabilidade</span>
                        <DialogMensagem
                          titulo="Termos de responsabilidade"
                          conteudo={<span>
                            O Usuário / condutor identificado acima declara que estava em posse do carro placa {localStorage.getItem("placa-atual")} no dia {formatDateAndHour(dadosDaMulta.DataInfracao)} horas, momento do cometimento da infração nº AIT {dadosDaMulta.AIT}, emitida pelo(a) {dadosDaMulta.DescOrgao}.
                            <br /><br />
                            Considerando a ciência do Usuário quanto ao Contrato de Aluguel e Gestão de Frota nº {localStorage.getItem("sigla-contrato")} firmado entre LOCALIZA FLEET S/A, CNPJ 02.286.479/0001-08, com sede em Belo Horizonte/MG, e o Cliente {localStorage.getItem("nome-empresa")}, o Usuário/Condutor declara que:
                            <br /><br />
                            1) Assume a responsabilidade pela infração supracitada cometida na condução do Carro alugado e pela pontuação decorrente desta infração, nos termos do artigo 5º e seus parágrafos, da Resolução 619/16 do CONTRAN, e da cláusula 1.7.1 do Contrato.
                            <br /><br />
                            2) Autoriza a Localiza Fleet a ter acesso a tais informações pessoais, bem como assinar o termo de apresentação do condutor / infrator das multas de trânsito que envolvam o Carro alugado, nos termos do artigo 257, parágrafos 1º, 3º, 7º e 8º, do Código Brasileiro de Trânsito, e da cláusula 1.7.1 do Contrato.
                            <br /><br />
                            3) É preposto autorizado pelo Cliente a conduzir o Carro alugado nos termos do Contrato.
                            <br /><br />
                            4) Não havendo identificação do infrator e sendo o veículo de propriedade de pessoa jurídica, será lavrada nova multa ao proprietário do veículo, mantida a originada pela infração, cujo valor é o da multa multiplicada pelo número de infrações iguais cometidas no período de doze meses.
                            <br /><br />
                            5) O preposto responsabiliza-se nas esferas cível, administrativa e penal, pela veracidade das informações e dos documentos fornecidos.
                            <br /><br />
                            6) A identificação do condutor infrator só surtirá efeito se estiver corretamente preenchida, assinada e acompanhada de cópia legível do documento de habilitação, além de documento que comprove a assinatura do condutor infrator, quando esta não constar do referido documento.
                            <br /><br />
                            7) O cliente concorda que a imagem da sua assinatura aposta neste termo será utilizada unicamente no termo de responsabilidade para indicação de condutor infrator junto ao órgão de trânsito.
                            </span>}
                          scroll={scroll}
                          open={open}
                          handleClose={handleClose}
                        />
                      </Grid>
                    }
                  />
                </Grid>
                {
                  (situacaoLogin === 110 && condutor.cdUsuario === codigoUsuario) &&
                  <Grid item sm={12} xs={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          color="primary"
                          onChange={aceitarAssinatura}
                        />
                      }
                      label={
                        <Grid item>
                          <span onClick={handleOpenDialog('paper')} style={{ textDecoration: "underline" }}>Li e aceito os termos para assinatura digitalizada</span>
                          <DialogMensagem
                            titulo="Termos para assinatura digitalizada"
                            conteudo={<span>
                              Deseja gravar sua assinatura para facilitar o processo de indicação tornando-o mais ágil?
                              <br />
                              <br />
                              1) O cliente concorda que sua assinatura após validada pela Gestão de Frotas pode ser gravada em banco de dados para que em uma próxima indicação de multas a mesma imagem seja utilizada, com sua autorização, tornando o processo mais ágil e facilitado.
                              <br />
                              <br />
                              2) A qualquer momento, o cliente pode fazer a exclusão da imagem de sua assinatura no banco de dados da Localiza Gestão de Frotas, através do botão "Assinatura Digitalizada" nas informações de multas pelo #meucarro.
                              <br />
                              <br />
                              Li e concordo com os termos acima.
                              </span>}
                            scroll={scroll}
                            open={openDialog}
                            handleClose={handleCloseDialog}
                          />
                        </Grid>
                      }
                    />
                  </Grid>
                }
                {
                  (condutor.cdUsuario !== codigoUsuario) &&
                  <Grid item sm={12} xs={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          color="primary"
                          onChange={aceitarIndicacaoTerceiros}
                        />
                      }
                      label={
                        <span>Me declaro responsável pela veracidade dos dados informados no formulário e asseguro que todos os passos serão comunicados ao terceiro.</span>
                      } />
                  </Grid>
                }
              </React.Fragment>
            ) : ("")
          }
        </Grid>
        <Grid item className={classes.subtitulo} sm={12} xs={12}>
          <span>Arquivos permitidos: PNG, JPG, JPEG, e PDF</span>
          <br />
          <span>(Tamanho máximo 3 MB)</span>
        </Grid>
      </Grid>
    </React.Fragment>
  )
}

export default EnviarAssinatura;