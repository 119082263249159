import React, { Component } from "react";
import Tracking from "../../hooks/Tracking";
import { connect } from "react-redux";
import LayoutInternal from "../LayoutInternal";
import EnviarAssinatura from "./EnviarAssinatura";
import EnvioCNH from "./EnvioCNH";
import DadosCondutor from "./DadosCondutor";
import ModalMensagem from "../ModalMensagem/ModalMensagem";
import Loading from "../Loading";
import {
  Stepper,
  Step,
  StepLabel,
  Grid,
  Typography,
  Container,
} from "@material-ui/core";
import {
  alterarDataNascimento,
  alterarNomeCondutor,
  alterarEmailCondutor,
  alterarRgCondutor,
  alterarCpfCliente,
  alterarOrgaoEmissor,
  alterarEstadoRg,
  alterarCnhCondutor,
  alterarPrimeiraHabilitacao,
  alterarEstadoCnh,
  alterarTipoDocumento,
  alterarDocumentoEstrangeiro,
  alterarLicencaHabilitacao,
  alterarDataEntrada,
  alterarCondutorEstrangeiro,
  limparDadosCondutor,
  buscarDadosCpfDataNascimento,
  verificarCpfClienteValido,
  verificarDataNascimentoValido,
  verificarEmailCondutorValido,
  verificarPrimeiraHabilitacaoValido,
  verificarDataEntradaValido,
  exibirCarregando,
  removerCarregando,
  limparAssinaturaCondutor,
} from "../Multas/CardMultaAction";
import { IndicarCondutorAssinaturaDigitalizada } from "../../services/IndicarCondutorService";

class IndicarCondutorAssinatura extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeStep: 0,
      imagemCNH: null,
      imagemCnhBase64: "",
      imagemAssinatura: null,
      imagemAssinaturaBase64: "",
      aceiteTermosDeResponsabilidade: false,
      aceiteIndicacaoTerceiros: false,
      aceiteAssinaturaDigitalizada: false,
      exibeModal: false,
      tipoModalMensagem: null,
      icon: null,
      mensagemPrincipal: null,
      mensagemSecondaria: null,
      disabled: true,
    };

    this.gaCategory = "indicacao_condutor_assinatura";
  }

  handleImagemCNH = (event) => {
    Tracking.sendEvent({
      category: this.gaCategory,
      action: "IND_ASSINATURA_IMAGEM_CNH_CLICADA",
      label: "Imagem da CNH selecionada",
    });

    this.handleFileSelect(event.target.files[0], (base64String) => {
      this.setState({
        imagemCnhBase64: base64String,
      });
    });
    this.setState({
      imagemCNH: event.target.files[0],
    });
  };

  handleLimparImagemCNH = () => {
    Tracking.sendEvent({
      category: this.gaCategory,
      action: "IND_ASSINATURA_IMAGEM_CNH_LIMPA",
      label: "Imagem da CNH limpa",
    });

    this.setState({
      imagemCNH: null,
      imagemCnhBase64: null,
    });
  };

  handleImagemAssinatura = (event) => {
    Tracking.sendEvent({
      category: this.gaCategory,
      action: "IND_ASSINATURA_IMAGEM_ASSINATURA_CLICADA",
      label: "Imagem da assinatura selecionada",
    });

    this.handleFileSelect(event.target.files[0], (base64String) => {
      this.setState({
        imagemAssinaturaBase64: base64String,
      });
    });
    this.setState({
      imagemAssinatura: event.target.files[0],
    });
  };

  handleLimparImagemAssinatura = () => {
    Tracking.sendEvent({
      category: this.gaCategory,
      action: "IND_ASSINATURA_IMAGEM_ASSINATURA_LIMPA",
      label: "Imagem da assinatura limpa",
    });

    this.setState({
      imagemAssinatura: null,
      imagemAssinaturaBase64: null,
      aceiteTermosDeResponsabilidade: false,
      aceiteIndicacaoTerceiros: false,
      aceiteAssinaturaDigitalizada: false,
    });
    this.props.limparAssinaturaCondutor();
  };

  handleCheckboxTermosDeResponsabilidade = () => {
    Tracking.sendEvent({
      category: this.gaCategory,
      action: "IND_ASSINATURA_TERMO_RESPONSAB._MARCADO",
      label: "Termo de responsabilidade marcado",
    });

    this.setState((prevState) => ({
      aceiteTermosDeResponsabilidade: !prevState.aceiteTermosDeResponsabilidade,
    }));
  };

  handleCheckboxIndicacaoTerceiros = () => {
    Tracking.sendEvent({
      category: this.gaCategory,
      action: "IND_ASSINATURA_IND_TERCEIROS_MARCADO",
      label: "Indicação de terceiros marcado",
    });

    this.setState((prevState) => ({
      aceiteIndicacaoTerceiros: !prevState.aceiteIndicacaoTerceiros,
    }));
  };

  handleCheckboxAssinaturaDigitalizada = () => {
    Tracking.sendEvent({
      category: this.gaCategory,
      action: "IND_ASSINATURA_ASSINATURA_DIG._MARCADA",
      label: "Assinatura digitalizada marcada",
    });

    this.setState((prevState) => ({
      aceiteAssinaturaDigitalizada: !prevState.aceiteAssinaturaDigitalizada,
    }));
  };

  getSteps() {
    return ["Dados do Condutor", "Enviar CNH", "Enviar Assinatura"];
  }

  handleFinalizar = () => {
    const {
      limparDadosCondutor,
      condutor,
      multa,
      exibirCarregando,
      removerCarregando,
    } = this.props;
    exibirCarregando();
    let dadosIndicacao = "";

    if (condutor.condutorEhEstrangeiro === 1) {
      dadosIndicacao = {
        Nome: condutor.nomeCondutor,
        DataEntrada: condutor.dataEntrada.toDate(),
        DocumentoEstrangeiro: condutor.documentoEstrangeiro,
        LicencaHabilitacao: condutor.licencaHabilitacao,
        TipoDocumento: condutor.tipoDocumento,
        Ait: multa.AIT,
        CodigoOrgao: multa.CodigoOrgao,
        Cnpj: localStorage.getItem("cnpj-atual"),
        CondutorEstrangeiro: true,
        Placa: localStorage.getItem("placa-atual"),
        ArquivoCnhBase64: this.state.imagemCnhBase64,
        ExtensaoCNH: this.state.imagemCNH.type,
        ArquivoAssinaturaBase64: this.state.imagemAssinaturaBase64,
        ExtensaoAssinatura: this.state.imagemAssinatura.type,
        AceiteArmazenamentoAssinatura: this.state.aceiteAssinaturaDigitalizada,
      };
    } else {
      dadosIndicacao = {
        Nome: condutor.nomeCondutor,
        DataNascimento: condutor.dataNascimento.toDate(),
        Cpf: condutor.cpfCliente,
        Cnh: condutor.cnhCondutor,
        CnhUf: condutor.estadoCnh,
        DataPrimeiraHabilitacao: condutor.primeiraHabilitacao.toDate(),
        Email: condutor.emailCondutor,
        Rg: condutor.rgCondutor,
        RgOrgaoEmissor: condutor.orgaoEmissor,
        RgUf: condutor.estadoRg,
        Ait: multa.AIT,
        CodigoOrgao: multa.CodigoOrgao,
        Cnpj: localStorage.getItem("cnpj-atual"),
        CondutorEstrangeiro: false,
        IdPessoa: condutor.idPessoa,
        Placa: localStorage.getItem("placa-atual"),
        ArquivoCnhBase64: this.state.imagemCnhBase64,
        ExtensaoCNH: this.state.imagemCNH.type,
        ArquivoAssinaturaBase64: this.state.imagemAssinaturaBase64
          ? this.state.imagemAssinaturaBase64
          : condutor.imagemAssinatura,
        ExtensaoAssinatura: this.state.imagemAssinatura
          ? this.state.imagemAssinatura.type
          : "image/png",
        AceiteArmazenamentoAssinatura: this.state.aceiteAssinaturaDigitalizada,
      };
    }
    IndicarCondutorAssinaturaDigitalizada(dadosIndicacao)
      .then((result) => {
        if (result.data.Mensagem === "") {
          removerCarregando();
          this.handleModalSucesso();
        } else {
          removerCarregando();
          this.handleModalErro(result.data.Mensagem);
        }
      })
      .catch((error) => {
        removerCarregando();
        this.handleModalErro();
      });
    limparDadosCondutor();
  };

  handleModalSucesso = () => {
    Tracking.sendEvent({
      category: this.gaCategory,
      action: "IND_ASSINATURA_SUCESSO",
      label: "Modal de sucesso exibido",
      nonInteraction: true,
    });

    this.setState({
      exibeModal: true,
      tipoModalMensagem: "",
      icon: "icon-check-circle2",
      mensagemPrincipal: "Enviado!",
      mensagemSecondaria:
        "Sua indicação foi enviada com sucesso! Você receberá por e-mail um parecer após a análise da Localiza Gestão de Frotas.",
    });
  };

  handleModalErro = (mensagem) => {
    Tracking.sendEvent({
      category: this.gaCategory,
      action: "IND_ASSINATURA_ERRO",
      label: "Erro ao indicar o condutor por assinatura",
      nonInteraction: true,
    });

    let mensagemErro =
      "Houve um erro ao indicar o condutor. Favor tentar novamente.";

    if (mensagem) mensagemErro = mensagem;

    this.setState({
      exibeModal: true,
      tipoModalMensagem: "warning",
      icon: "icon-x-circle",
      mensagemSecondaria: mensagemErro,
    });
  };

  handleFileSelect(file, callbackFunc) {
    var reader = new FileReader();

    reader.onload = function (e) {
      var binaryData = e.target.result;
      var base64String = window.btoa(binaryData);
      callbackFunc(base64String);
    };

    reader.readAsBinaryString(file);
  }

  handleNext = () => {
    Tracking.sendEvent({
      category: this.gaCategory,
      action: "IND_ASSINATURA_BOTAO_PROXIMO_CLICADO",
      label: "O botão de próximo foi clicado",
    });

    this.state.activeStep === 2
      ? this.handleFinalizar()
      : this.setState((prevState) => ({
          activeStep: prevState.activeStep + 1,
          disabled: !prevState.disabled,
        }));
  };

  handleBack = () => {
    Tracking.sendEvent({
      category: this.gaCategory,
      action: "IND_ASSINATURA_BOTAO_VOLTAR_CLICADO",
      label: "O botão de voltar foi clicado",
    });

    const { limparDadosCondutor, handleSetarProcesso } = this.props;

    if (this.state.activeStep === 0) {
      limparDadosCondutor();
      handleSetarProcesso();
    } else {
      this.setState((prevState) => ({
        activeStep: prevState.activeStep - 1,
        aceiteTermosDeResponsabilidade: false,
        aceiteAssinaturaDigitalizada: false,
      }));
    }
  };

  handleModalInformacao = (modalInformacao) => {
    this.setState({
      exibeModal: modalInformacao.exibeModal,
      icon: modalInformacao.icon,
      mensagemPrincipal: modalInformacao.mensagemPrincipal,
      mensagemSecondaria: modalInformacao.mensagemSecondaria,
    });
  };

  handleFecharModal = () => {
    Tracking.sendEvent({
      category: this.gaCategory,
      action: "IND_ASSINATURA_BOTAO_FECHAR_CLICADO",
      label: "O botão de fechar foi clicado",
    });

    this.setState((prevState) => ({
      exibeModal: !prevState.exibeModal,
    }));
    this.props.handleFinalizarProcesso();
  };

  isFormValid = () => {
    const {
      condutor,
      cpfClienteValido,
      dataNascimentoValida,
      emailCondutorValido,
      primeiraHabilitacaoValida,
      dataEntradaValida,
    } = this.props;

    switch (this.state.activeStep) {
      case 0:
        return condutor.condutorEhEstrangeiro === 0
          ? condutor.dataNascimento &&
              condutor.nomeCondutor &&
              condutor.emailCondutor &&
              condutor.rgCondutor &&
              condutor.cpfCliente &&
              condutor.orgaoEmissor &&
              condutor.estadoRg &&
              condutor.cnhCondutor &&
              condutor.primeiraHabilitacao &&
              condutor.estadoCnh &&
              cpfClienteValido &&
              dataNascimentoValida &&
              emailCondutorValido &&
              primeiraHabilitacaoValida
          : condutor.nomeCondutor &&
              condutor.tipoDocumento &&
              condutor.documentoEstrangeiro &&
              condutor.licencaHabilitacao &&
              condutor.dataEntrada &&
              dataEntradaValida;
      case 1:
        return this.state.imagemCNH;
      default:
        return (
          (this.state.imagemAssinatura || condutor.imagemAssinatura) &&
          this.state.aceiteTermosDeResponsabilidade &&
          ((condutor.cdUsuario !== this.props.codigoUsuario &&
            this.state.aceiteIndicacaoTerceiros) ||
            condutor.cdUsuario === this.props.codigoUsuario)
        );
    }
  };

  getStepContent = (stepIndex) => {
    switch (stepIndex) {
      case 0:
        return (
          <DadosCondutor
            condutor={this.props.condutor}
            bloqueadoDadosCondutor={this.props.bloqueadoDadosCondutor}
            verificarCpfClienteValido={this.props.verificarCpfClienteValido}
            verificarDataNascimentoValido={
              this.props.verificarDataNascimentoValido
            }
            verificarEmailCondutorValido={
              this.props.verificarEmailCondutorValido
            }
            verificarPrimeiraHabilitacaoValido={
              this.props.verificarPrimeiraHabilitacaoValido
            }
            verificarDataEntradaValido={this.props.verificarDataEntradaValido}
            cpfClienteValido={this.props.cpfClienteValido}
            dataNascimentoValida={this.props.dataNascimentoValida}
            emailCondutorValido={this.props.emailCondutorValido}
            primeiraHabilitacaoValida={this.props.primeiraHabilitacaoValida}
            dataEntradaValida={this.props.dataEntradaValida}
            limparDadosCondutor={this.props.limparDadosCondutor}
            onChangeDataNascimento={this.props.alterarDataNascimento}
            onChangeNomeCondutor={this.props.alterarNomeCondutor}
            onChangeEmailCondutor={this.props.alterarEmailCondutor}
            onChangeRgCondutor={this.props.alterarRgCondutor}
            onChangeCpfCliente={this.props.alterarCpfCliente}
            onChangeOrgaoEmissor={this.props.alterarOrgaoEmissor}
            onChangeEstadoRg={this.props.alterarEstadoRg}
            onChangeCnhCondutor={this.props.alterarCnhCondutor}
            onChangePrimeiraHabilitacao={this.props.alterarPrimeiraHabilitacao}
            onChangeEstadoCnh={this.props.alterarEstadoCnh}
            onChangeTipoDocumento={this.props.alterarTipoDocumento}
            onChangeDocumentoEstrangeiro={
              this.props.alterarDocumentoEstrangeiro
            }
            onChangeLicencaHabilitacao={this.props.alterarLicencaHabilitacao}
            onChangeDataEntrada={this.props.alterarDataEntrada}
            onChangeCondutorEstrangeiro={this.props.alterarCondutorEstrangeiro}
            onChangesDadosCpfDataNascimento={
              this.props.buscarDadosCpfDataNascimento
            }
          />
        );
      case 1:
        return (
          <EnvioCNH
            imagemCNH={this.state.imagemCNH}
            loadCNH={this.handleImagemCNH}
            limparImagemCNH={this.handleLimparImagemCNH}
          />
        );
      default:
        return (
          <EnviarAssinatura
            situacaoLogin={this.props.situacaoLogin}
            imagemAssinatura={this.state.imagemAssinatura}
            loadAssinatura={this.handleImagemAssinatura}
            limparAssinatura={this.handleLimparImagemAssinatura}
            abrirModalInformacao={this.handleModalInformacao}
            aceitarAssinatura={this.handleCheckboxAssinaturaDigitalizada}
            aceitarTermos={this.handleCheckboxTermosDeResponsabilidade}
            aceitarIndicacaoTerceiros={this.handleCheckboxIndicacaoTerceiros}
            dadosDaMulta={this.props.multa}
            condutor={this.props.condutor}
            codigoUsuario={this.props.codigoUsuario}
          />
        );
    }
  };

  render() {
    const steps = this.getSteps();
    const {
      activeStep,
      exibeModal,
      icon,
      mensagemPrincipal,
      mensagemSecondaria,
      tipoModalMensagem,
    } = this.state;

    return (
      <LayoutInternal title="Multa">
        {exibeModal && (
          <ModalMensagem
            tipoModalMensagem={tipoModalMensagem}
            icon={icon}
            mensagemPrincipal={mensagemPrincipal}
            mensagemSecondaria={mensagemSecondaria}
            OnToggled={this.handleFecharModal}
            textoBotao="OK"
          />
        )}
        <main>
          <Container maxWidth="lg">
            <Grid container>
              <Grid item sm={12}>
                <Stepper activeStep={activeStep} alternativeLabel>
                  {steps.map((label) => (
                    <Step key={label}>
                      <StepLabel>
                        <Typography
                          variant="caption"
                          style={{ fontFamily: "Metropolis", fontSize: 12 }}
                        >
                          {label}
                        </Typography>
                      </StepLabel>
                    </Step>
                  ))}
                </Stepper>
              </Grid>
            </Grid>
            {this.props.loading && <Loading />}
            <div
              style={{
                border: "1px solid #eeeeee",
                borderRadius: 5,
                padding: 10,
              }}
            >
              {this.getStepContent(activeStep)}
              <div className="ml-auto mt-3 col-md-6">
                <div className="row">
                  <div className="col">
                    <button
                      className="button"
                      onClick={this.handleBack}
                    >
                      Voltar
                    </button>
                  </div>
                  <div className="col">
                    <button
                      className="button"
                      disabled={!this.isFormValid()}
                      onClick={this.handleNext}
                    >
                      {activeStep === steps.length - 1 ? "Enviar" : "Próximo"}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Container>
        </main>
      </LayoutInternal>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    condutor: state.multa.condutor,
    bloqueadoDadosCondutor: state.multa.bloqueadoDadosCondutor,
    cpfClienteValido: state.multa.cpfClienteValido,
    dataNascimentoValida: state.multa.dataNascimentoValida,
    emailCondutorValido: state.multa.emailCondutorValido,
    primeiraHabilitacaoValida: state.multa.primeiraHabilitacaoValida,
    dataEntradaValida: state.multa.dataEntradaValida,
    loading: state.multa.loading,
    codigoUsuario: state.login.codigoUsuario,
  };
};

export default connect(mapStateToProps, {
  alterarDataNascimento,
  alterarNomeCondutor,
  alterarEmailCondutor,
  alterarRgCondutor,
  alterarCpfCliente,
  alterarOrgaoEmissor,
  alterarEstadoRg,
  alterarCnhCondutor,
  alterarPrimeiraHabilitacao,
  alterarEstadoCnh,
  alterarTipoDocumento,
  alterarDocumentoEstrangeiro,
  alterarLicencaHabilitacao,
  alterarDataEntrada,
  alterarCondutorEstrangeiro,
  limparDadosCondutor,
  buscarDadosCpfDataNascimento,
  verificarCpfClienteValido,
  verificarDataNascimentoValido,
  verificarEmailCondutorValido,
  verificarPrimeiraHabilitacaoValido,
  verificarDataEntradaValido,
  exibirCarregando,
  removerCarregando,
  limparAssinaturaCondutor,
})(IndicarCondutorAssinatura);
