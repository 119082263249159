export default function formatDatePickers(){
    const divsDatePicker = document.getElementsByClassName('react-datepicker__input-container');
        for (let i = 0; i < divsDatePicker.length; i++) {
            const input = divsDatePicker[i].children[0];

            let text = 'Data';
            let icon = 'icon-calendar';
            if (input.classList.contains('time')) {
                text = 'Hora';
                icon = 'icon-chevron-down';
            }
            else {
                divsDatePicker[i].parentNode.parentNode.style['margin-right'] = '8px';
            }
            divsDatePicker[i].parentNode.parentNode.style['width'] = '100%';

            /* Manage input */
            input.setAttribute('required', '');
            let textoLabel = input.getAttribute("placeholder");

            if(textoLabel !== null && textoLabel!==""){
                text = textoLabel;
                input.setAttribute('placeholder', '');
            }

            /* Manage label */
            const label = document.createElement('LABEL');
            const textNode = document.createTextNode(text);
            label.classList.add('label');
            label.appendChild(textNode);
            divsDatePicker[i].appendChild(label);

            /* Manage span */
            const span = document.createElement('SPAN');
            span.classList.add('icon');
            span.classList.add("ico-datepiker-component");
            span.classList.add(icon);
            divsDatePicker[i].parentNode.appendChild(span);
        }
}