import styled , { css }from "styled-components";

export const FlexContainer = styled.div`
  width: 100%;
  flex: 1;
  justify-content: space-around;
  align-items: center;
`;

export const SliderCard = styled.div``;

export const FormContainer = styled.div`
  flex: 1;
  display: flex;
  background: #fafafa;
  padding: 60px 23px;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  border-top-left-radius: 24px;
  border-top-right-radius: 24px;
  width: 100%;
`;

export const VStack = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 100%;
`;

export const HStack = styled.div`
  display: flex;
  flex-direction: row;
  padding: 0px 10px 0px 10px;
  gap: 10px;
  width: 100%;

  @media(min-width:1025px) {
    width: 350px;
    position: absolute;
    bottom: 200px;
  }

  @media(min-width:1025px) and (max-height:760px) {
    width: 350px;
    position: absolute;
    bottom: 140px;
  }

  @media(min-width:1025px) and (max-height:680px) {
    width: 350px;
    position: absolute;
    bottom: 65px;
  }
`;

export const Imagem = styled.img``;

export const ImagemCelular = styled.img`
  width: 124;
  height: 116px;
  margin-bottom: 10px;
`;

export const ImagemMultas = styled.img`
  width: 107px;
  height: 116px;
  margin-bottom: 10px;
`;

export const ImagemCarro = styled.img`
  width: 107px;
  height: 116px;
  margin-bottom: 10px;
`;

export const ImagemAgendamento = styled.img`
  width: 107px;
  height: 116px;
  margin-bottom: 10px;
`;

export const Logo = styled.img`
  margin: 45px 0 20px 0;
  @media (min-width: 1025px){
    margin-left: auto;
    margin-right: auto;
    width: 228px;
    position: absolute;
    top: 65px;
  }
  @media (min-width: 1025px) and (max-height:730px) {
    width: 135px;
    position: absolute;
    margin-left: auto;
    margin-right: auto;
    top: 0px;
  }
  @media (min-width: 1025px) and (max-height:540px) {
    display: none;
  }
`;

export const Hashtag = styled.img`
  margin: 25px 0 0 0;
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 142px;
`;

export const Container = styled.div`
  flex: 1;
  display: flex;
  background-color: #fafafa;
  box-sizing: border-box;
  height: 100%;

  align-items: center;
  justify-content: space-between;
  flex-direction: column;
`;

export const Title = styled.h1`
  font-family: Inter;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 150%;
  margin: 0 0 10px 0;
  text-align: center;
  color: #3a3d42;

  @media (min-width: 1025px) {
    margin-bottom: 0px;
  }
`;

export const Subtitle = styled.p`
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 150%;
  /* or 24px */
  text-align: center;
  color: #3a3d42;
  padding: 0 10px 15px 10px;
`;

export const Message = styled.p`
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 150%;

  text-align: center;

  color: #3a3d42;

  text-align: center;
`;

export const ButtonContainer = styled.div `
width: 70px;
display: flex;
flex-direction: row;
width: 100%;
align-items: center;
justify-content: center;
position: absolute;
bottom: 100px;
`
