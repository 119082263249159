import React, { Component } from 'react';
import MaskedInput from 'react-text-mask';
import { validarCpf, somenteNumerosFromString, validaEmail } from '../../functions/manutencaoGeralValidacoes';

export default class DadosTerceiroCondutor extends Component {

    constructor(props) {
        super(props);
        this.handleVoltar = this.handleVoltar.bind(this);
        this.handleAvancar = this.handleAvancar.bind(this);
        this.state = {
            erroValidacoes: {
                cpfCondutor: false,
                emailCondutor: false
            }
        };
    }

    handleAvancar() {
        if(this.props.modelo.causadorAcidente == "condutor"){
            this.props.onIrParaEtapa(9);
        }
        else{
            this.props.onIrParaEtapa(8);
        }
    }

    handleVoltar() {
        this.handleLimpaCampos();
        this.props.onIrParaEtapa(6);
    }

    componentDidMount() {
        window.scrollTo(0, 0);
    }

    funcaoLimparCampos = () => {
        this.props.onChangeCondutor("");
        this.props.onChangeCpfCondutor("");
        this.props.onChangeEmailCondutorTerceiro("");
        this.props.onChangeTelefoneCondutor("");
    }

    handleAlteraCondutorProprietario = (event) => {
        if (event.target.value !== null && event.target.value !== "") {
            this.props.onChangeCondutorProprietario(event.target.value);
        }

        if (event.target.value === "proprietario") {
            this.props.onChangeCondutor(this.props.modelo.proprietario);
            this.props.onChangeCpfCondutor(this.props.modelo.cpf_cnpj);
            this.props.onChangeTelefoneCondutor(this.props.modelo.telefoneProprietario);
            this.props.onChangeEmailCondutorTerceiro(this.props.modelo.emailProprietario);
        } 
    }

    handleAlteraCondutor = (event) => {
        if (event.target.value !== null) {
            this.props.onChangeCondutor(event.target.value);
        }
    }

    handleAlteraCpfCondutor = (event) => {
        if (event.target.value !== null) {
            this.props.onChangeCpfCondutor(event.target.value);

            let cpfSomenteNumeros = somenteNumerosFromString(event.target.value);
            let validacaoCpf = validarCpf(event.target.value);

            if (cpfSomenteNumeros.length >= 11) {
                if (validacaoCpf) {
                    let newState = { ...this.state }
                    newState.erroValidacoes.cpfCondutor = false;
                    this.setState(newState);
                } else {
                    let newState = { ...this.state }
                    newState.erroValidacoes.cpfCondutor = true;
                    this.setState(newState);
                }
            }

        }
    }

    handleAlteraTelefoneCondutor = (event) => {
        if (event.target.value !== null) {
            this.props.onChangeTelefoneCondutor(event.target.value);
        }
    }

    handleAlteraEmailCondutorTerceiro = (event) => {
        if (event.target.value !== null) {
            this.props.onChangeEmailCondutorTerceiro(event.target.value);

            let validacaoEmail = validaEmail(event.target.value);
            if (validacaoEmail) {                
                    let newState = { ...this.state }
                    newState.erroValidacoes.emailCondutor = false;
                    this.setState(newState);
            }else {
                let newState = { ...this.state }
                newState.erroValidacoes.emailCondutor = true;
                this.setState(newState);
            }
        }   
    }

    handleLimpaCampos = () => {
        this.props.onChangeCondutorProprietario("");
        this.props.onChangeCondutor("");
        this.props.onChangeCpfCondutor("");
        this.props.onChangeEmailCondutorTerceiro("");
        this.props.onChangeTelefoneCondutor("");
    }

    render() {

        return (
            <React.Fragment>
                <div className="row w-xl-66">
                    <div className="form-intro col-12">
                        <p className="text-justify">Dados do condutor do carro terceiro</p>
                    </div>

                    <div className="col-12">
                        <div className="custom-radio">
                            <input type="radio" id="ev1" name="condutorProprietario" className="custom-control-input" value="proprietario"
                                onChange={this.handleAlteraCondutorProprietario} checked={this.props.modelo.condutorProprietario === "proprietario"} />
                            <label className="custom-control-label" htmlFor="ev1">O mesmo do proprietário</label>
                        </div>
                    </div>

                    <div className="col-md-12">
                        <div className="wrapper-input">
                            <div className="group-input">
                                <input type="text" name="condutor" id="condutor" className="input" maxLength={40}
                                    onChange={this.handleAlteraCondutor} value={this.props.modelo.condutor} required />
                                <label htmlFor="condutor" className="label">Nome</label>
                            </div>
                        </div>
                    </div>

                    <div className="col-md-12">
                        <div className="wrapper-input">
                            <div className="group-input">
                                <MaskedInput type="tel" name="condutorCpf" id="condutorCpf" className="input"
                                    mask={[/\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '-', /\d/, /\d/]}
                                    onChange={this.handleAlteraCpfCondutor} value={this.props.modelo.cpfCondutor} required />
                                <label htmlFor="condutorCpf" className="label">CPF</label>
                            </div>
                        </div>
                    </div>

                    <div className="col-md-6">
                        <div className={`wrapper-input ${(this.state.erroValidacoes.emailCondutor === true) ? 'has-error' : ""}`}>
                            <div className="group-input">
                                <input type="text" name="email" id="email" className="input" maxLength={40}
                                    onChange={this.handleAlteraEmailCondutorTerceiro} value={this.props.modelo.emailCondutorTerceiro} required/>
                                <label htmlFor="email" className="label">E-mail</label>
                            </div>
                        </div>
                    </div>

                    <div className="col-md-6">
                        <div className="wrapper-input">
                            <div className="group-input">
                                <MaskedInput type="tel" name="condutorTelefone" id="condutorTelefone" className="input" maxLength={15}
                                    mask={['(', /\d/, /\d/, ')', /\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
                                    onChange={this.handleAlteraTelefoneCondutor} value={this.props.modelo.telefoneCondutor} required />
                                <label htmlFor="condutorTelefone" className="label">Telefone</label>
                            </div>
                        </div>    
                    </div>

                    <div className="w-100"></div>
                    <div className="ml-auto mt-3 col-md-6">
                        <div className="row">
                            <div className="col">
                                <button className="button" onClick={this.handleVoltar} >Voltar</button>
                            </div>
                            <div className="col">
                                <button className="button" onClick={this.handleAvancar}>Continuar</button>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}