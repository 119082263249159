import { LISTA_CIDADES, BUSCAR_CODIGO, LIMPAR_CODIGO, SELECIONAR_CIDADE_NOME_UF, INSERIR_CIDADE_SELECIONADA } from './InputCidadesTypes';

const INITIAL_STATE = {
    cidades: [],
    codigo: {}
}

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case LISTA_CIDADES:
            return { ...state, cidades: action.payload };
        case BUSCAR_CODIGO:
            return { ...state, codigo: action.payload };
        case LIMPAR_CODIGO:
            return { ...state, codigo: [] };
        case INSERIR_CIDADE_SELECIONADA:
             let _codigo = []
             _codigo.push(action.payload);
             return { ...state, codigo: _codigo };
        case SELECIONAR_CIDADE_NOME_UF:
            let _state = {...state};
            _state.codigo = _state.cidades.filter(x => x.mNomeCidadeField === action.payload.nomeCidade && x.mUfField  === action.payload.uf );
            return _state;
        default:
            return state;
    }
}