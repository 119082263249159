import {
    FormContainer,
    ImageRedirectApp,
} from "./stylesMobile";
import RedirectMobileImg from "../../images/wizard/redirectMobile.svg";
import { fetchRemoteConfig } from "../../remote_config/remote_config_call";
import { useState, useEffect } from "react";
import { defaultConfig } from "../../remote_config/remote_keys";



export const RedirectAppMobileContainer = () => {
    useEffect(() => {



        const fetchSubtitle = async () => {
            try {
                const subtitle = await fetchRemoteConfig(
                    "maintenance_subtitle",
                    `${defaultConfig.maintenanceSubtitle}`,
                    "string"
                );
                setMaintenanceSubtitle(subtitle);
            } catch (error) {
                return setMaintenanceSubtitle(defaultConfig.maintenanceSubtitle);
            }
        };

        const fetchNumber = async () => {
            try {
                const helpNumber = await fetchRemoteConfig(
                    "maintenance_help_number",
                    `${defaultConfig.maintenanceHelpNumber}`,
                    "string"
                );
                setmaintenanceHelpNumber(helpNumber);
            } catch (error) {
                return setmaintenanceHelpNumber(defaultConfig.maintenanceHelpNumber);
            }
        };

        fetchSubtitle();
        fetchNumber();
    }, []);

    return (
        <FormContainer>
            <div>
                <a
                    href="https://hh6w.adj.st?adj_t=1fia65ce&adj_campaign=DesativacaoSiteMeuCarro"
                    target="https://hh6w.adj.st?adj_t=1fia65ce&adj_campaign=DesativacaoSiteMeuCarro"
                >
                    <ImageRedirectApp src={RedirectMobileImg} />
                </a>
            </div>
        </FormContainer>
    );
};

