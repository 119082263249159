import { checkIsWebView } from '../helpers/checkPlatform';

if (!checkIsWebView) {
  (function () {
    var s = document.createElement("script");
    s.src = "https://cdn-prod.securiti.ai/consent/cookie-consent-sdk.js";
    s.setAttribute(
      "data-tenant-uuid",
      "458553dc-e754-49c7-98c7-f7b040007182"
    );
    s.setAttribute(
      "data-domain-uuid",
      "591109f3-6d6a-4b9d-afa4-c49dcda4581c"
    );
    s.setAttribute("data-backend-url", "https://app.securiti.ai");
    s.defer = true;
    var parent_node = document.head || document.body;
    parent_node.appendChild(s);
    s.addEventListener("load", function () {
      window.initCmp();
    });
  })();
}