import React, { Component } from 'react';
import { connect } from 'react-redux';
import InfoRead from '../../../components/InfoRead';
import '../../../styles/css/tela-editarkm.css';
import { listaKM } from '../EditarKMActions'
import ModalMensagem from '../../../components/ModalMensagem/ModalMensagem';
import ModalMensagemAdaptada from '../../../components/ModalMensagem/ModalMensagemAdaptado';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';
import 'moment/locale/pt-br';
import formatDatePickers from '../../../helpers/formatDatePickers';
import MaskedInput from 'react-text-mask';
import Loading from '../../../components/Loading';

import {
    buscarCabecalho,
    inserirKm,
    DispatchErro,
    limparMensageErro
} from './CabecalhoActions'

class Mensagens extends Component {
    constructor(props) {
        super(props);

        this.handleInserir = this.handleInserir.bind(this);
        this.handleModalConfimacao = this.handleModalConfimacao.bind(this);
        this.handleAtualizar = this.handleAtualizar.bind(this);
    }

    handleModalConfimacao(resposta) {
        if (resposta === true)
            this.handleInserir(this.props.medicao.textMaskInputElement.state.previousConformedValue.replace(/_/g, ''), this.props.data, true);
        
        this.handleAtualizar();
    }

    handleInserir(medicao, data) {
        this.props.OnInserirKm(medicao, data, true);
    }

    handleAtualizar() {
        this.props.OnListarKm(localStorage.getItem("placa-atual"));
        this.props.OnBuscarCabecalho(localStorage.getItem("placa-atual"));
        this.setState({ data: '' });
    }

    render() {

        if (this.props.erro) {
                return (<ModalMensagem tipoModalMensagem='warning' icon='icon-x-circle'
                                       mensagemPrincipal={this.props.erro.message}
                                       OnToggled={this.props.limparErro}
                                       mensagemSecundaria=''
                                       textoBotao="OK" />);
        }

        if (this.props.resposta) {
            if (this.props.resposta.Estado != 0) {
                if (this.props.resposta.ExigeConfirmacaoLimiteKmMensal == true) {
                    return (<ModalMensagem tipoModalMensagem='' icon='icon-check-circle2'
                        mensagemPrincipal={this.props.resposta.MotivoNaoInsercao}
                        mensagemSecundaria=''
                        textoBotao="Sim"
                        secondButton={true}
                        textoSecondButton='Não'
                        OnToggled={this.handleModalConfimacao}
                    />);
                }
                else {
                    return (<ModalMensagem tipoModalMensagem='warning' icon='icon-x-circle'
                        mensagemPrincipal={this.props.resposta.MotivoNaoInsercao}
                        mensagemSecundaria=''
                        textoBotao="OK" />);
                }
            }
            else {
                return (<ModalMensagem tipoModalMensagem='' icon='icon-check-circle2'
                    mensagemPrincipal='Km atualizado com sucesso'
                    mensagemSecundaria=''
                    textoBotao="OK"
                    OnToggled={this.handleAtualizar}
                    />);
            }
        }
        return null;
    }
}

class CabecalhoKm extends Component {
    constructor() {
        super();
        this.handleInserir = this.handleInserir.bind(this);
        this.handleRemoveMensagemErro = this.handleRemoveMensagemErro.bind(this);
        this.state = { data: null, erro: null, resposta: null, km: null, errosValidacoes:{km:false}, msgErroKmExcedente: {km: false, medicao: ''}};
    }

    componentDidMount() {
        if (localStorage.getItem("placa-atual"))
            this.props.buscarCabecalho(localStorage.getItem("placa-atual"));
    }

    handleRemoveMensagemErro(){
        this.props.limparMensageErro();
    }

    componentDidUpdate(prevProps) {
        if (this.props.erro != prevProps.erro) {
            this.setState({ erro: this.props.erro });
        }
        else if (this.props.resposta != prevProps.resposta) {
            if (this.props.resposta && this.props.resposta.ExigeConfirmacaoLimiteKmMensal) {
                this.setState({ resposta: this.props.resposta });
            }        
            else {
                this.setState({ resposta: this.props.resposta, data: null});
            }
        }
        formatDatePickers();
    }

    onDateChange = (data) => {
        if (data)
            this.setState({ data: data, erro: null, resposta: null });
    }

    handleInserir(medicao, data, confirmadoAcima) {
        if(data === null){data = moment();}
        let medicaoVal = medicao;
        let dataVal = data;
        let _continue = true;
        
        if(medicao===null || medicao.trim() === ""){
            _continue = false;
            this.setState({
            errosValidacoes:{km:true}
            });
      }else{
        this.setState({
            errosValidacoes:{km:false}
        });
      }

        if (medicaoVal && dataVal && _continue) {
            let dataFormatada = '';
            try {
                dataFormatada = TratarData(dataVal);
            }
            catch (erro) {
                this.props.DispatchErro('Favor informar uma data no formato dd/mm/aaaa.');
                return;
            }
            this.datePicker.clear()

            var medicaoEstipulada = Number(this.props.cabecalho.UltimaMedicao) + 10000

            if (medicaoEstipulada <= medicao && !this.state.msgErroKmExcedente.km) {
                this.setState({
                    msgErroKmExcedente: {
                        km: true,
                        medicao: medicao
                    }
                });
            } else {
                this.props.inserirKm(localStorage.getItem("placa-atual"), medicaoVal, dataFormatada, true);
                this.setState({
                    msgErroKmExcedente: {
                        km: false,
                        medicao: ''
                    }
                });
            }
        }
    }

    ListarKm(placa) {
        this.props.listaKM(placa);
        this.medicao.textMaskInputElement.state.previousConformedValue = "";
        this.setState({ km: null, erro: null, resposta: null });
    }

    SalvarEstado(medicao) {
        this.setState({ km: medicao.textMaskInputElement.state.previousConformedValue, erro: null, resposta: null });
    }

    render() {
        if (this.props.cabecalho) {
            let medicao;
            let data;
            return (        
                <div>
                    {
                        this.props.loadingInserirKM ? <Loading /> : null
                    }
                     {
                        this.state.msgErroKmExcedente.km ? <ModalMensagemAdaptada tipoModalMensagem='' icon='icon-check-circle2'
                        mensagemPrincipal={`A quilometragem digitada é de ${this.state.msgErroKmExcedente.medicao}. Deseja continuar?`}
                        textoBotao='Sim'
                        textoSecondButton='Não'
                        OnToggled={() => this.handleInserir(this.state.msgErroKmExcedente.medicao, this.state.data, true)}
                    />: null
                    }
                    <Mensagens
                        erro={this.state.erro}
                        resposta={this.state.resposta}
                        OnInserirKm={(m, d, c) => this.handleInserir(m, d, c)}
                        medicao={this.medicao}
                        data={this.state.data}
                        limparErro={this.handleRemoveMensagemErro}
                        OnListarKm={(placa) => this.ListarKm(placa)}
                        OnBuscarCabecalho={(placa) => this.props.buscarCabecalho(placa)}
                        placa={localStorage.getItem("placa-atual")} />

                    <div className="content-form-edit-km">
                        <InfoRead title={'Placa'} info={this.props.cabecalho.Placa} />
                        <InfoRead title={'Modelo'} info={this.props.cabecalho.Modelo} />
                        <InfoRead title={'Último valor informado'} info={this.props.cabecalho.UltimaMedicao + ' KM'}
                            secondary={this.props.cabecalho.DataUltimaMedicaoFormatado} />
                        <div>
                            <div className="input-km-date">
                                <div className={`wrapper-input ${(this.props.resposta && this.props.resposta.KmValidado === false) || (this.state.errosValidacoes.km==true) ? 'has-error' : ""}`}>
                                    <div className="group-input">
                                        <MaskedInput type="tel" mask={[/\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/]} name="cardInput" className="input" guide={false}
                                            size="20" placeholder="" ref={node => this.medicao = node} required value={this.state.km} onBlur={() => this.SalvarEstado(this.medicao)} />
                                        <label className="label">Km atual</label>
                                    </div>
                                    <span className="icon icon-velocimeter"></span>                        
                                </div> 
                                <div className="wrapper-input">
                                <div className="group-input">
                                <DatePicker
                                    selected={this.state.data}
                                    dateFormat="DD/MM/YYYY"
                                    locale="pt-br"
                                    onChange={this.onDateChange}
                                    maxDate={moment()}
                                    className='input date'
                                    type='date'
                                    ref={datePicker => { this.datePicker = datePicker }}
                                    customInput={<MaskedInput mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]} />}
                                />
                                </div>
                                </div>
                            </div>
                            <button
                                onClick={() => this.handleInserir(this.medicao.textMaskInputElement.state.previousConformedValue.replace(/_/g, ''), this.state.data, true)}
                                className="button gtm-atualizar-km-salvar">Salvar
                            </button>
                        </div>
                    </div>
                   </div>
                );
        }
        return null
    }
}

function TratarData(data) {
    let dataDatePicker = new Date(data._d);
    return new Date(dataDatePicker.getFullYear(), dataDatePicker.getMonth(), dataDatePicker.getDate());

}

const mapStateToProps = state => {
    return {        
        cabecalho: state.CabecalhoKm.cabecalho,
        resposta: state.CabecalhoKm.resposta,
        erro: state.CabecalhoKm.erro,
        loadingInserirKM: state.CabecalhoKm.loading,
        placa: state.menu.placa,
    }
};

export default connect(mapStateToProps, {
    buscarCabecalho,
    inserirKm,
    listaKM,
    DispatchErro,
    limparMensageErro
})(CabecalhoKm)