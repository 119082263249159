import React, { Component } from 'react';
import { connect } from 'react-redux';
import { criarModal } from './SinistroActions';
import Tracking from '../../hooks/Tracking';

class UploadLink extends Component {  
    render(){
        if(this.props.upload)
            return <button className="button" 
            onClick={() =>this.props.OnOpenModal(this.props.numero, 
                this.props.sequencia, this.props.data, localStorage.getItem("placa-atual"))}>Enviar documentos</button> 
        else return null;
    }
}

const TextoStatus = (props) => {
    if(props.concluido)
        return <div className="info-secondary">Processo concluído</div>
    else if(props.enviado)
        return <div className="info-secondary">Documentos enviados</div>
    else 
        return <div className="info-secondary">Aguardando envio de documentos</div>
}

class Historico extends Component {
    constructor(props){
        super(props);
        this.handleOpenModal = this.handleOpenModal.bind(this);
    }

    handleOpenModal = (numero, sequencia, data, placa) => {
        var modal = {placa: placa, sequencia:sequencia, data:data, numero:numero};
        this.props.criarModal(modal);

        const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
        if(isSafari){
            document.querySelector('body').style.position = 'fixed' 
        }
    }

    componentDidMount() {
        Tracking.sendPageview('Histórico de Sinistros');
    }

    handleClick(placa, num, seq, enviado, data){
        if(!enviado || seq <= 0) {//roubo
            if(!enviado)
                this.handleOpenModal(num, seq, data, placa)
            else    
                return;
        }
        else if(this.props.OnExibirAgendamento){ //avaria
            if(!enviado)
                this.handleOpenModal(num, seq, data, placa)
            else
                this.props.OnExibirAgendamento(placa, num, seq);
        }
    }

    render() {
        let i = 0;
        return (
            <section className="historic">              
                <div className="historic-header">
                    <h1 className="title">HISTÓRICO</h1>
                </div>
                {
                    this.props.sinistros.length > 0 ? this.props.sinistros.map(s => 
                        <section key={i++} className="wrapper-card" onClick={() => this.handleClick(localStorage.getItem("placa-atual"), s.Numero, s.Sequencia, s.DocEnviado, s.DataFormatada )}>
                            <div className={`card card-historic ${s.DocEnviado || s.ProcessoConcluido ? "" : "amarelo"}`}>
                                <div className="section-left">
                                    <div className="info-primary">{s.DataFormatada}</div>
                                     <TextoStatus enviado={s.DocEnviado} concluido={s.ProcessoConcluido}/> 
                                </div>
                                <div className="section-right">
                                    <span className="action">
                                        <UploadLink 
                                        upload={!s.DocEnviado}
                                        placa={localStorage.getItem("placa-atual")}
                                        data={s.DataFormatada}
                                        numero={s.Numero}
                                        sequencia={s.Sequencia}
                                        OnOpenModal={(n, s, d, p) => this.handleOpenModal(n, s, d, p)}/>
                                    </span>
                                    <div>{s.Sequencia > 0 ? `${s.Numero}/${s.Sequencia}` : s.Numero}</div>
                                </div>
                            </div>
                        </section> 
                    ) : null
                }     
            </section>
        );
    }
}

export default connect(null, {
    criarModal
})(Historico)