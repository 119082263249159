import React, { Component } from 'react';
import { connect } from 'react-redux';
import InputAutoComplete from '../InputAutoComplete'
import {listaCidades} from './InputCidadesAction'

class InputCidades extends Component {

    componentDidMount() {
        this.props.listaCidades('');
    }

    render() {
        return (
            <span>
                    <InputAutoComplete valorInicial={this.props.valorInicial} erro={this.props.erro} data={this.props.cidades} name={this.props.name} complement={this.props.complement}  />
            </span>
        )
    }
}

const mapStateToProps = state => {
    return {
        cidades: state.listaCidades.cidades
    }
};

export default connect(mapStateToProps, {
    listaCidades
})(InputCidades)