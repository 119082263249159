import React, { Component } from "react";
import { connect } from "react-redux";
import CardRoubo from "../../components/Roubos/CardRoubo";
import CardRouboDesktop from "../../components/Roubos/CardRouboDesktop";
import ModalMensagem from "../../components/ModalMensagem/ModalMensagem";
import Multas from "../../components/Multas/Lista";
import MediaQuery from "react-responsive";
import "../../styles/css/cards.css";
import "../../styles/css/header-home.css";
import { Redirect } from "react-router-dom";
import Agendamentos from "../../components/Agendamentos/ListaAgendamentos";
import { verificarPlacaAtiva } from "../Login/LoginActions.js";
import Loading from "../../components/Loading";
import { WebContainer } from "../../components/Home/WebContainer";
import { MobileContainerHome } from "../../components/Home/MobileContainer";
import Menu from "../../components/Menu/Menu";
import MenuMobile from "../../components/Menu/MenuMobile";
import PlacaHomeMobile from "../../components/Menu/PlacaHomeMobile";
import BottomNavigation from "../../components/BottomNavigation";
import ErrorBoundary from "../../components/ErrorBoundary";
import ErrorBoundaryRedirect from "../../components/ErrorBoundaryRedirect";

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      exibirModal: false,
      loading: false,
    };
  }

  componentWillMount() {
    this.setState({
      loading: true,
    });
    setTimeout(() => {
      this.setState({
        loading: false,
      });
    }, 1500);
  }

  render() {
    return (
      <React.Fragment>
        <MediaQuery minDeviceWidth={1025}>
          <Menu />
          <MenuMobile />
          <WebContainer>
            {this.state.loading === true ? (
              <Loading />
            ) : (
              <div className="home-desktop">
                <ErrorBoundary texto={"no card de roubos"}>
                  <CardRouboDesktop />
                </ErrorBoundary>
                <ErrorBoundary texto={"na listagem de multas"}>
                  <Multas mobile={false} listar={true} />
                </ErrorBoundary>
                <ErrorBoundary texto={"na listagem de agendamentos"}>
                  <Agendamentos mobile={false} />
                </ErrorBoundary>
              </div>
            )}

            {(() => {
              if (
                localStorage.getItem("wizardSeen") === null &&
                localStorage.getItem("placa-atual")
              ) {
                return <Redirect to="/wizard" />;
              } else {
                return null;
              }
            })()}

            {(() => {
              if (this.props.location.state === "Agendamentos") {
                return <Redirect to="Agendamentos" />;
              } else if (this.props.location.state === "EditarKM") {
                return <Redirect to="EditarKM" />;
              } else if (this.props.location.state === "ServicosContratados") {
                return <Redirect to="/ServicosContratados" />;
              } else if (this.props.location.state === "Avarias") {
                return <Redirect to="Avarias" />;
              } else if (this.props.location.state === "Roubos") {
                return <Redirect to="Roubos" />;
              } else if (this.props.location.state === "PoliticaFrota") {
                return <Redirect to="PoliticaFrota" />;
              } else if (this.props.location.state === "ManualCondutor") {
                return <Redirect to="GuiaCondutor" />;
              } else {
                return null;
              }
            })()}

            {this.props.erroMultas !== "" && !isNaN(this.props.erroMultas) ? (
              <ModalMensagem
                tipoModalMensagem="warning"
                icon="icon-x-circle"
                mensagemPrincipal={this.props.erroMultas.mensagem}
                mensagemSecundaria={this.props.erroMultas.mensagemSecundaria}
                textoBotao="OK"
              />
            ) : null}
            {this.props.erroRoubos !== null && !isNaN(this.props.erroRoubos) ? (
              <ModalMensagem
                tipoModalMensagem="warning"
                icon="icon-x-circle"
                mensagemPrincipal={this.props.erroRoubos.message}
                mensagemSecundaria=""
                textoBotao="OK"
              />
            ) : null}
          </WebContainer>
        </MediaQuery>
        <MediaQuery maxDeviceWidth={1025}>
          <MenuMobile>
            <ErrorBoundaryRedirect>
              <PlacaHomeMobile />
            </ErrorBoundaryRedirect>
          </MenuMobile>
          <MobileContainerHome>
            {this.state.loading === true ? (
              <Loading />
            ) : (
              <React.Fragment>
                <ErrorBoundary texto={"no card de roubos"}>
                  <CardRoubo />
                </ErrorBoundary>
                <ErrorBoundary texto={"na listagem de multas"}>
                  <Multas mobile={true} listar={false} />
                </ErrorBoundary>
                <ErrorBoundary texto={"na listagem de agendamentos"}>
                  <Agendamentos mobile={true} />
                </ErrorBoundary>
              </React.Fragment>
            )}
            {(() => {
              if (
                localStorage.getItem("wizardSeen") === null &&
                localStorage.getItem("placa-atual")
              ) {
                return <Redirect to="/wizard" />;
              } else {
                return null;
              }
            })()}

            {(() => {
              if (this.props.location.state === "Agendamentos") {
                return <Redirect to="Agendamentos" />;
              } else if (this.props.location.state === "EditarKM") {
                return <Redirect to="EditarKM" />;
              } else if (this.props.location.state === "ServicosContratados") {
                return <Redirect to="/ServicosContratados" />;
              } else if (this.props.location.state === "Avarias") {
                return <Redirect to="Avarias" />;
              } else if (this.props.location.state === "Roubos") {
                return <Redirect to="Roubos" />;
              } else if (this.props.location.state === "PoliticaFrota") {
                return <Redirect to="PoliticaFrota" />;
              } else if (this.props.location.state === "NovoAgendamento") {
                return <Redirect to="NovoAgendamento" />;
              } else if (this.props.location.state === "ManualCondutor") {
                return <Redirect to="GuiaCondutor" />;
              } else {
                return null;
              }
            })()}

            {this.props.erroMultas !== "" && !isNaN(this.props.erroMultas) ? (
              <ModalMensagem
                tipoModalMensagem="warning"
                icon="icon-x-circle"
                mensagemPrincipal={this.props.erroMultas.mensagem}
                mensagemSecundaria={this.props.erroMultas.mensagemSecundaria}
                textoBotao="OK"
              />
            ) : null}
            {this.props.erroRoubos !== null && !isNaN(this.props.erroRoubos) ? (
              <ModalMensagem
                tipoModalMensagem="warning"
                icon="icon-x-circle"
                mensagemPrincipal={this.props.erroRoubos.message}
                mensagemSecundaria=""
                textoBotao="OK"
              />
            ) : null}
          </MobileContainerHome>
          <BottomNavigation />
        </MediaQuery>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    placa: state.menu.placa,
    codigoIdentificador: state.menu.codigoIdentificador,
  };
};

export default connect(mapStateToProps, {
  verificarPlacaAtiva,
})(Home);
