import React, { Component } from 'react';
import MaskedInput from 'react-text-mask';
import insertLabelDatePicker from '../../helpers/formatDatePickers';
import moment from 'moment';
import InputCidades from '../../components/InputCidades/InputCidades';
import { selecionarCidadePorNomeEUf, limparCidadeSelecionada, inputarCidadeSelecionada } from '../../components/InputCidades/InputCidadesAction';
import { connect } from 'react-redux';
import { horaValida, somenteNumerosFromString, dataValida } from '../../functions/manutencaoGeralValidacoes';

class DadosDoEvento extends Component {

    constructor(props) {
        super(props);
        this.handleVoltar = this.handleVoltar.bind(this);
        this.handleAvancar = this.handleAvancar.bind(this);
        this.state = {
            erroValidacoes: {
                data: false,
                hora: false,
                endereco: false,
                bairro: false,
                cidade: false,
                descricao: false
            }
        };

        if (this.props.modelo.cidadeAcidente !== null) {
            this.props.inputarCidadeSelecionada(this.props.modelo.cidadeAcidente);
        } else {
            this.props.limparCidadeSelecionada();
        }
    }

    componentDidMount() {
        insertLabelDatePicker();
        window.scrollTo(0, 0);
    }


    handleAvancar() {
        if (this.props.modelo.dataAcidente === "" || !moment.isMoment(this.props.modelo.dataAcidente) ) {
            let newState = { ...this.state }
            newState.erroValidacoes.dataAcidente = true;
            this.setState(newState);
        } else if( !this.props.modelo.dataAcidente.isValid() || this.props.modelo.dataAcidente > moment() ){
            let newState = { ...this.state }
            newState.erroValidacoes.dataAcidente = true;
            this.setState(newState);
        }else
        {
            let newState = { ...this.state }
            newState.erroValidacoes.dataAcidente = false;
            this.setState(newState);
        }

        if (this.props.modelo.dataAcidente !== null && horaValida(this.props.modelo.horaAcidente)) {
            let d2 = moment(this.props.modelo.dataAcidente);
            let _hora = this.props.modelo.horaAcidente.split(":");

            let newState = { ...this.state }
            newState.erroValidacoes.hora = false;
            this.setState(newState);

            d2.set({
                'hour': _hora[0],
                'minute': _hora[1],
                'second': "00"
            });

            if (moment() < d2) {
                let newState = { ...this.state }
                newState.erroValidacoes.hora = true;
                this.setState(newState);

            } else {
                let newState = { ...this.state }
                newState.erroValidacoes.hora = false;
                this.setState(newState);
            }

        } else {
            let newState = { ...this.state }
            newState.erroValidacoes.hora = true;
            this.setState(newState);
        }

        if (this.props.modelo.enderecoAcidente === "") {
            let newState = { ...this.state }
            newState.erroValidacoes.endereco = true;
            this.setState(newState);

        } else {
            let newState = { ...this.state }
            newState.erroValidacoes.endereco = false;
            this.setState(newState);
        }

        if (!this.props.modelo.cidadeAcidente) {
            let newState = { ...this.state }
            newState.erroValidacoes.cidade = true;
            this.setState(newState);

        } else {
            let newState = { ...this.state }
            newState.erroValidacoes.cidade = false;
            this.setState(newState);
        }

        if (this.props.modelo.bairroAcidente === "") {
            let newState = { ...this.state }
            newState.erroValidacoes.bairro = true;
            this.setState(newState);

        } else {
            let newState = { ...this.state }
            newState.erroValidacoes.bairro = false;
            this.setState(newState);
        }

        if (this.props.modelo.descricaoAcidente === "") {
            let newState = { ...this.state }
            newState.erroValidacoes.descricao = true;
            this.setState(newState);

        } else {
            let newState = { ...this.state }
            newState.erroValidacoes.descricao = false;
            this.setState(newState);
        }

        if (this.handleValidacao()) {            
            if(this.props.modelo.tipoEvento === "acidente" && this.props.modelo.terceiroEnvolvido === "sim")
                this.props.onIrParaEtapa(6);
            else
                this.props.onIrParaEtapa(9);
        }
    }

    handleVoltar() {
        if(this.props.modelo.tipoEvento === "acidente" && this.props.modelo.terceiroEnvolvido === "nao"){
            this.handleLimpaCampos();
            this.props.onIrParaEtapa(5);
        }else{
            this.handleLimpaCampos();
            this.props.onIrParaEtapa(3);
        }
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.cidade) {
            if (nextProps.cidade.length > 0 && this.props.modelo.cidadeAcidente !== nextProps.cidade[0]) {
                this.props.onChangeCidadeAcidente(this.props.cidade[0]);
                let newState = { ...this.state }
                newState.erroValidacoes.cidade = false;
                this.setState(newState);
            } else if (nextProps.cidade.length === 0 && this.props.modelo.cidadeAcidente !== null) {
                this.props.onChangeCidadeAcidente(null);
            }
        }
    }

    handleAlteraDataAcidente = (event) => {

        this.props.onChangeDataAcidente(event.target.value);

        if (somenteNumerosFromString(event.target.value).length === 8) {
            if(dataValida(event.target.value)){
                let data = moment(event.target.value, ["DD/MM/YYYY"], 'pt-br');
                this.props.onChangeDataAcidente(data);

                if (data > moment()) {
                    let newState = { ...this.state }
                    newState.erroValidacoes.dataAcidente = true;
                    this.setState(newState);
                } else {
                    let newState = { ...this.state }
                    newState.erroValidacoes.dataAcidente = false;
                    this.setState(newState);
                }
            } else {
                let newState = { ...this.state }
                newState.erroValidacoes.dataAcidente = true;
                this.setState(newState);
            }
        }

        this.props.onChangeHoraAcidente("");
    }

    handleAlteraHoraAcidente = (event) => {
        if (event.target.value !== null) {
            this.props.onChangeHoraAcidente(event.target.value);

            let validacaoData = horaValida(event.target.value);
            
            if(validacaoData){
                let newState = { ...this.state }
                newState.erroValidacoes.hora = false;
                this.setState(newState);
            } else{
                let newState = { ...this.state }
                newState.erroValidacoes.hora = true;
                this.setState(newState);
            }
        }
    }

    handleAlteraEnderecoAcidente = (event) => {
        if (event.target.value !== null) {
            this.props.onChangeEnderecoAcidente(event.target.value);
            let newState = { ...this.state }
            newState.erroValidacoes.endereco = false;
            this.setState(newState);
        }
    }

    handleAlteraBairroAcidente = (event) => {
        if (event.target.value !== null) {
            this.props.onChangeBairroAcidente(event.target.value);
            let newState = { ...this.state }
            newState.erroValidacoes.bairro = false;
            this.setState(newState);
        }
    }

    handleAlteraDescricaoAcidente = (event) => {
        if (event.target.value !== null) {
            this.props.onChangeDescricaoAcidente(event.target.value);
            let newState = { ...this.state }
            newState.erroValidacoes.descricao = false;
            this.setState(newState);
        }
    }

    handleAlteraCidade = (event) => {
        if (event.target.value !== null) {
            let newState = { ...this.state }
            newState.erroValidacoes.cidade = false;
            this.setState(newState);
        }
    }

    handleValidacao() {
        return (this.state.erroValidacoes.data === false &&
            this.state.erroValidacoes.hora === false &&
            this.state.erroValidacoes.endereco === false &&
            this.state.erroValidacoes.bairro === false &&
            this.state.erroValidacoes.cidade === false &&
            this.state.erroValidacoes.descricao === false);
    }

    handleLimpaCampos = () => {
        this.props.onChangeDataAcidente("");
        this.props.onChangeHoraAcidente("");
        this.props.onChangeBairroAcidente("");
        this.props.onChangeDescricaoAcidente("");
        this.props.onChangeCidadeAcidente("");
        this.props.onChangeEnderecoAcidente("");
    }

    render() {
        let exibicaoAcidente = "";

        if (this.props.modelo.dataAcidente === "" || this.props.modelo.dataAcidente === null) {
            exibicaoAcidente = this.props.modelo.dataAcidente;
        } else if(moment.isMoment(this.props.modelo.dataAcidente) || this.props.modelo.dataAcidente.length > 10){
            exibicaoAcidente = moment(this.props.modelo.dataAcidente).format("DD/MM/YYYY");
        } else {
            exibicaoAcidente = this.props.modelo.dataAcidente;
        }

        return (
            <React.Fragment>
                <div className="row w-xl-66">
                    <div className="form-intro col-12">
                        <p className="text-justify">Dados do Evento</p>
                    </div>

                    <div className="col-6 col-md-3">
                        <div className={`wrapper-input ${(this.state.erroValidacoes.dataAcidente === true) ? 'has-error' : ""}`}>
                            <div className="group-input">
                            <MaskedInput type="tel" name="dataEvento" id="dataEvento" className='input' inputMode="numeric" required
                                    onChange={this.handleAlteraDataAcidente}
                                    value={ exibicaoAcidente }
                                    mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
                                />
                                <label htmlFor="dataEvento" className="label" >Data do Evento</label>

                            </div>
                        </div>
                    </div>
                    <div className="col-6 col-md-3">
                        <div className={`wrapper-input ${(this.state.erroValidacoes.hora === true) ? 'has-error' : ""}`}>
                            <div className="group-input">
                                <MaskedInput name="hora" id="hora" className="input"
                                    mask={[/[0-2]/, /[0-9]/, ':', /[0-5]/, /[0-9]/]}
                                    onChange={this.handleAlteraHoraAcidente} value={this.props.modelo.horaAcidente} required />
                                <label htmlFor="hora" className="label">Hora</label>
                            </div>
                        </div>
                    </div>

                    <div className="w-100"></div>

                    <div className="col-md-12">
                        <div className={`wrapper-input ${(this.state.erroValidacoes.endereco === true) ? 'has-error' : ""}`}>
                            <div className="group-input">
                                <input type="text" name="endereco" id="endereco" className="input"
                                    onChange={this.handleAlteraEnderecoAcidente} value={this.props.modelo.enderecoAcidente} maxLength={70} required />
                                <label htmlFor="endereco" className="label">Endereço onde estava o carro Localiza</label>
                            </div>
                        </div>
                    </div>

                    <div className="col-md-6">
                        <div className={`wrapper-input ${(this.state.erroValidacoes.bairro === true) ? 'has-error' : ""}`}>
                            <div className="group-input">
                                <input type="text" name="bairro" id="bairro" className="input"
                                    onChange={this.handleAlteraBairroAcidente} maxLength={40} value={this.props.modelo.bairroAcidente} required />
                                <label htmlFor="bairro" className="label">Bairro</label>
                            </div>
                        </div>
                    </div>

                    <div className="col-md-6">
                        <InputCidades erro={(this.state.erroValidacoes.cidade === true)} name='mNomeCidadeField' placeHolder='Cidade' complement='mUfField'
                        valorInicial={this.props.modelo.cidadeAcidente && this.props.modelo.cidadeAcidente.mNomeCidadeField ? this.props.modelo.cidadeAcidente.mNomeCidadeField + " - " + this.props.modelo.cidadeAcidente.mUfField : null} />
                    </div>


                    <div className="col-12">
                        <div className={`wrapper-input ${(this.state.erroValidacoes.descricao === true) ? 'has-error' : ""}`}>
                            <div className="group-input">
                                <textarea name="descricao" id="descricao" className="input"
                                    onChange={this.handleAlteraDescricaoAcidente} maxLength={700} value={this.props.modelo.descricaoAcidente} required></textarea>
                                <label htmlFor="descricao" className="label">O que ocorreu / Como ocorreu ?</label>
                            </div>
                        </div>
                    </div>

                    <div className="w-100"></div>
                    <div className="ml-auto mt-3 col-md-6">
                        <div className="row">
                            <div className="col">
                                <button className="button" onClick={this.handleVoltar}>Voltar</button>
                            </div>
                            <div className="col">
                                <button className="button" onClick={this.handleAvancar}>Continuar</button>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
        cidade: state.listaCidades.codigo
    }
};


export default connect(mapStateToProps, {
    selecionarCidadePorNomeEUf, limparCidadeSelecionada, inputarCidadeSelecionada
})(DadosDoEvento)