import React, { Component } from "react";
import { connect } from "react-redux";
import {
  TopMenuMobile,
  NomeEmpresa,
  NomeUsuario,
  ContainerUsuario,
  ContainerEmpresa,
} from "./styles";

class CabecalhoMenuMobile extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const name = localStorage.getItem("user-name").split(" ");
    const razaoSocial = localStorage.getItem("razao-social");
    const data = JSON.parse(localStorage.getItem("user-data"));
    return (
      <React.Fragment>
        <TopMenuMobile>
          <ContainerUsuario>
            <NomeUsuario>
              Olá,{" "}
              {`${name[0].toLowerCase()} ${
                name[1] ? name[1].toLowerCase() : ""
              }`}
            </NomeUsuario>
          </ContainerUsuario>
          <ContainerEmpresa>
            <NomeEmpresa>{razaoSocial}</NomeEmpresa>
          </ContainerEmpresa>
        </TopMenuMobile>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    /* placa: state.menu.placa,
    loading: state.menu.loading,
    resposta: state.menu.resposta,
    carregar: state.menu.carregar,
    reload: state.menu.reload,
    erro: state.menu.erro,
    situacao: state.login.usuario.Situacao, */
  };
};

export default connect(mapStateToProps, {})(CabecalhoMenuMobile);
