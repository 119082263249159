import React, { Component } from "react";
import { connect } from "react-redux";
import { setPlacaAndCode, carregarCabecalho } from "./MenuAction";
import {
  TopMenuWeb,
  NomeEmpresa,
  NomeUsuario,
  WrapperPlaca,
  Placa,
  Modelo,
  ListaPlacas,
} from "./styles";
import { listaMulta } from "../Multas/CardMultaAction";
import { listarAgendamentos } from "../Agendamentos/CardAgendamentoAction";
import listaServicosContratados from "../../pages/ServicosContratados/ListaServicosContratados/ListaServicosContratadosActions";
import { buscarCabecalho } from "../../pages/EditarKM/Cabecalho/CabecalhoActions";
import { listaKM } from "../../pages/EditarKM/EditarKMActions";
import {
  listaAvarias,
  verMais,
  listaRoubos,
} from "../../pages/Sinistro/SinistroActions";
import { buscarDadosPlaca } from "../../pages/Rea/ReaActions";
import { dadosUsuario } from "../../pages/Agendamento/Novo/AgendamentoActions";
class Cabecalho extends Component {
  constructor(props) {
    super(props);

    this.state = {
      placa: null,
      modelo: null,
      codigoIdentificador: null,
      cnpjCliente: null,
      outrasPlacas: false,
    };
  }

  handlePlacas = () => {
    this.state.outrasPlacas === false
      ? this.setState({
          outrasPlacas: true,
        })
      : this.setState({
          outrasPlacas: false,
        });
  };

  componentWillMount() {
    const data = JSON.parse(localStorage.getItem("user-data"));
    const placa = localStorage.getItem("placa-atual")
      ? localStorage.getItem("placa-atual")
      : data[0].Placa;
    const renavam = localStorage.getItem("renavam")
      ? localStorage.getItem("renavam")
      : data[0].Renavam;
    const modelo = localStorage.getItem("modelo-atual")
      ? localStorage.getItem("modelo-atual")
      : data[0].Modelo;
    const codigo = localStorage.getItem("codigo-identificador-atual")
      ? localStorage.getItem("codigo-identificador-atual")
      : data[0].CodigoIdentificadorCliente;
    const cnpjCliente = localStorage.getItem("cnpj-atual")
      ? localStorage.getItem("cnpj-atual")
      : data[0].CnpjCliente;
    localStorage.setItem("placa-atual", placa);
    localStorage.setItem("renavam", renavam);
    localStorage.setItem("modelo-atual", modelo);
    localStorage.setItem("codigo-identificador-atual", codigo);
    localStorage.setItem("cnpj-atual", cnpjCliente);
    this.setState(
      {
        placa: placa,
        modelo: modelo,
        codigoIdentificador: codigo,
      },
      () => {
        this.props.setPlacaAndCode(
          this.state.placa,
          this.state.codigoIdentificador
        );
      }
    );
  }

  trocaPlaca = (placa, renavam, modelo, codigoIdentificador, cnpj) => {
    this.setState({
      placa: placa,
      renavam: renavam,
      modelo: modelo,
      codigoIdentificador: codigoIdentificador,
      cnpjCliente: cnpj,
      outrasPlacas: false,
    });
    localStorage.setItem("placa-atual", placa);
    localStorage.setItem("renavam", renavam);
    localStorage.setItem("modelo-atual", modelo);
    localStorage.setItem("codigo-identificador-atual", codigoIdentificador);
    localStorage.setItem("cnpj-atual", cnpj);
  };

  componentDidUpdate() {
    this.props.listaMulta(
      localStorage.getItem("placa-atual"),
      this.state.codigoIdentificador
    );
    this.props.listarAgendamentos(localStorage.getItem("placa-atual"), true);
    this.props.listaServicosContratados(localStorage.getItem("placa-atual"));
    this.props.buscarCabecalho(localStorage.getItem("placa-atual"));
    this.props.listaKM(localStorage.getItem("placa-atual"));
    this.props.listaAvarias(localStorage.getItem("placa-atual"));
    this.props.buscarDadosPlaca(localStorage.getItem("placa-atual"));
    this.props.listaRoubos(localStorage.getItem("placa-atual"));
    this.props.carregarCabecalho(localStorage.getItem("placa-atual"));
    this.props.dadosUsuario(localStorage.getItem("placa-atual"));
  }

  render() {
    const name = localStorage.getItem("user-name").split(" ");
    const razaoSocial = localStorage.getItem("razao-social");
    const data = JSON.parse(localStorage.getItem("user-data"));
    return (
      <React.Fragment>
        <TopMenuWeb>
          <NomeUsuario>
            Olá,{" "}
            {`${name[0].toLowerCase()} ${name[1] ? name[1].toLowerCase() : ""}`}
          </NomeUsuario>
          <NomeEmpresa>{razaoSocial}</NomeEmpresa>
        </TopMenuWeb>
        <WrapperPlaca>
          <Placa>
            {this.state.placa}
            {data.length > 1 ? (
              <span
                className="icon icon-chevron-down"
                onClick={this.handlePlacas}
              ></span>
            ) : null}
          </Placa>
          <Modelo>{this.state.modelo.substr(0, 45)}</Modelo>
          {this.state.outrasPlacas === true ? (
            <ListaPlacas>
              {data.map((p, i) => (
                <div key={i}>
                  <div
                    style={{ paddingLeft: "10px" }}
                    onClick={() =>
                      this.trocaPlaca(
                        p.Placa,
                        p.Renavam,
                        p.Modelo,
                        p.CodigoIdentificadorCliente,
                        p.CnpjCliente
                      )
                    }
                  >
                    {p.Placa}
                  </div>
                </div>
              ))}
            </ListaPlacas>
          ) : (
            ""
          )}
        </WrapperPlaca>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    placa: state.menu.placa,
    codigoIdentificador: state.menu.codigoIdentificador,
  };
};

export default connect(mapStateToProps, {
  setPlacaAndCode,
  listaMulta,
  listarAgendamentos,
  listaServicosContratados,
  buscarCabecalho,
  listaKM,
  listaAvarias,
  buscarDadosPlaca,
  listaRoubos,
  carregarCabecalho,
  dadosUsuario,
})(Cabecalho);
