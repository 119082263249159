import React, { Component } from "react";
import { connect } from "react-redux";
import { exibirDetalheAgendamento } from "./CardAgendamentoAction";
import DetalhesAgendamento from "./DetalhesAgendamento";
import { SituacaoNovoAgendamento } from "./ListaAgendamentos";
import { withRouter } from "react-router-dom";
import arrow from "../../images/arrow.svg";
import {Titulo} from './styles'
import ErrorBoundary from "../ErrorBoundary"

export class CabecalhoAgendamento extends Component {
  constructor() {
    super();
    this.state = {
      click: false,
    };
  }

  OnClick = function (e) {
    if (e.target.classList.contains("icon-navigation")) return;

    if (this.props.cabecalho.NumeroSS && this.props.cabecalho.SequencialSS) {
      this.props.exibirDetalheAgendamento(
        localStorage.getItem("placa-atual"),
        this.props.cabecalho.NumeroSS,
        this.props.cabecalho.SequencialSS
      );
    } else if (this.props.cabecalho.OnClick) {
      if (this.props.cabecalho.OnClick.toUpperCase() === "/NOVOAGENDAMENTO")
        this.setState({ click: true });
      else this.props.history.push(this.props.cabecalho.OnClick);
    } else if (
      this.props.cabecalho.OnClickMobile &&
      this.props.mobile === true
    ) {
      this.props.history.push(this.props.cabecalho.OnClickMobile);
    }
  };

  render() {
    if (this.props.mobile === true) {
      if (!this.props.cabecalho) return null;
      return (
        <section 
          className={`wrapper-card ${
            this.props.situacao === SituacaoNovoAgendamento.Ocultar ||
            this.props.mobile === false
              ? "cursor-none"
              : "cursor-pointer"
          }`}
        >
          <div className="card-historico" onClick={(e) => this.OnClick(e)}  style={{height: "130px"}}>
            <div className="title">
              <span className="icon icon-tools"></span>
              <span className="text">Serviços</span>
            </div>
            <div className="description">
              <div className="primary-info" style={{margin: "16px 0 0 0 "}}>
                {this.props.cabecalho.Mensagem}
              </div>
            </div>
            <div>
              <div style={{ padding: "1px 0 0 1px"}}>
                <div style={{ margin: "0 0 8px 0"}}>
                <div>{this.props.cabecalho.LinkEndereco === null || undefined ? 'Último serviço realizado:' : 'Próximo serviço:'} </div>
                {this.props.cabecalho.LinkEndereco === null || undefined ? '' : this.props.cabecalho.Fornecedor.substr(0, 25)} 
                </div>
                {this.props.cabecalho.AdicionalFornecedor ? (
                  <div style={{ margin: "5px 0 15px 0", textTransform: "uppercase" }}> 
                    {this.props.cabecalho.Endereco}{" "}
                    {this.props.cabecalho.AdicionalFornecedor.substr(0, 13)}
                  </div>
                ) : null}
              </div>
            </div>
          </div>
          <ErrorBoundary texto={"nos detalhes do agendamento"}>
          <DetalhesAgendamento
            detalheAgendamento={this.props.detalheAgendamento}
            onClick={(e) => this.OnClick(e)}
          />
          </ErrorBoundary>
        </section>
      );
    } else {
      if (!this.props.cabecalho) return null;
      return (
        <section
          className={`wrapper-card ${
            this.props.situacao === SituacaoNovoAgendamento.Ocultar ||
            this.props.mobile === false
              ? "cursor-none"
              : "cursor-pointer"
          }`}
        >
          <div className="card" onClick={(e) => this.OnClick(e)}>
            <div className="title">
              <span className="icon icon-tools"></span>
              <span className="text">Serviços</span>
            </div>
            <Titulo>
                {this.props.cabecalho.Mensagem}
            </Titulo>
            <div className="bottom-info_icon">
              <div className="wrapper-info">
                <div className="info-title" style={{ color: "#7B808C"}}>
                <div>{this.props.cabecalho.LinkEndereco === null || undefined ? 'Último serviço realizado:' : 'Próximo serviço:'} </div>
                {this.props.cabecalho.LinkEndereco === null || undefined ? '' : this.props.cabecalho.Fornecedor.substr(0, 25)} 
                </div>
               
                {this.props.cabecalho.AdicionalFornecedor ? (
                  <div style={{textTransform: "uppercase"}}> 
                    {this.props.cabecalho.Endereco}{" "}
                    {this.props.cabecalho.AdicionalFornecedor.substr(0, 25)}
                  </div>
                ) : null}
              </div>
            </div>
          </div>
          <ErrorBoundary texto={"nos detalhes do agendamento"}>
          <DetalhesAgendamento
            detalheAgendamento={this.props.detalheAgendamento}
            onClick={(e) => this.OnClick(e)}
          />
          </ErrorBoundary>
        </section>
      );
    }
  }
}

const mapStateToProps = (state) => {
  return {
    detalheAgendamento: state.listaAgendamento.detalheAgendamento,
  };
};

export default connect(mapStateToProps, {
  exibirDetalheAgendamento,
})(withRouter(CabecalhoAgendamento));
