import React, { useState, useEffect } from "react";
import "./styles";
import { Redirect, useHistory } from "react-router-dom";
import {
  FormContainer,
  LabelWrapper,
  InputContainer,
  TextFieldMaterial,
  ButtonContainer,
  Button,
  ErrorContainer,
  MessageError,
  Ancora,
  AvisoContainer,
  Aviso,
  WrapperAvisoTitle,
  WrapperAviso,
  OkLinkAncor,
  OkLinkButton,
  ContainerOfButtons,
} from "./styles";
import { useAuth } from "../../../providers/auth";
import Loading from "../../Loading";
import fire from "../../fire";
import ReCAPTCHA from "react-google-recaptcha";
import { CaptchaSiteKey } from "../../../config/consts";
import Warning from "../../../images/Warning.png";
import checkAviso from "../../../images/checkAviso.svg";
import Tracking from "../../../hooks/Tracking";
import { isIOS, isMobile } from "react-device-detect";

export const LoginRenavamMain = () => {
  const [placa, setPlaca] = useState("");
  const [renavam, setRenavam] = useState("");
  const [buttonDisabled, setButtonDisabled] = useState(false);

  const {
    placaRegistrationMain,
    getTokenLocalStorage,
    error,
    home,
    loading,
    resetState,
    reCaptchaRef,
    redoCaptcha,
    setError,
    clickCaptcha,
    clearErrors,
    notification400,
    setNotification400,
    signInWithEmailAndPasswordFb,
  } = useAuth();

  const history = useHistory();

  useEffect(() => {
    clearErrors();
    localStorage.removeItem("token-id");
    localStorage.removeItem("recaptcha-token");
    localStorage.removeItem("user-email");
    localStorage.removeItem("refresh-token");
  }, []);

  const handleClick = async () => {
    if (!placa || !renavam) {
      setError("Campo obrigatório");
    } else if (placa.length < 7 || renavam.length < 4) {
      setError("Placa ou renavam inválidos.");
    } else if (clickCaptcha === false) {
      setError("Por favor, clique em não sou um robô");
    } else {
      setButtonDisabled(true);
      signInWithEmailAndPasswordFb("Placa", placa, renavam).then((result) => {
        localStorage.setItem(
          "token-id",
          result.data.Authenticated.FirebaseToken
        );
        localStorage.setItem(
          "refresh-token",
          result.data.Authenticated.RefreshToken
        );
        try {
          placaRegistrationMain(
            placa,
            renavam,
            localStorage.getItem("token-id"),
            localStorage.getItem("recaptcha-token")
          );
          setButtonDisabled(false);
        } catch (error) {}
      }).catch((err) => {
        setButtonDisabled(false);
      });;
    }
  };

  const redirectToLogin = () => {
    resetState();
    history.push("/Login");
  };

  const sendAnalytics = (action) => {
    Tracking.sendEvent({
      category: "Login_placa",
      action: action,
      label: "Redirecionamento frota 360",
    });
  };

  return (
    <FormContainer>
      <LabelWrapper>Acesse com placa e renavam</LabelWrapper>
      <InputContainer>
        <TextFieldMaterial
          className="textFieldPassword"
          id="Placa"
          label="Placa"
          variant="outlined"
          name="placa"
          type="text"
          onChange={(e) => setPlaca(e.target.value.toUpperCase())}
          value={placa}
          inputProps={{ maxLength: 7 }}
          InputLabelProps={{
            style: { color: "#3A3D42", fontSize: "14px" },
          }}
        />
        <TextFieldMaterial
          className="textFieldPassword"
          id="Renavam"
          label="4 ultimos digitos do renavam"
          variant="outlined"
          name="renavam"
          type="text"
          onChange={(e) => setRenavam(e.target.value)}
          value={renavam}
          inputProps={{ maxLength: 4 }}
          InputLabelProps={{
            style: { color: "#3A3D42", fontSize: "14px" },
          }}
        />
      </InputContainer>
      {error ? (
        <ErrorContainer>
          <MessageError>{error}</MessageError>
        </ErrorContainer>
      ) : (
        ""
      )}
      {clickCaptcha === true && notification400 === true ? (
        <React.Fragment>
          {sendAnalytics("REDIRECT_PLACA_FROTA360_POPUP")}
          <AvisoContainer>
            <Aviso>
              <img src={checkAviso} alt="" style={{ width: 34, height: 34 }} />
              <WrapperAvisoTitle>Acesse o Frota360</WrapperAvisoTitle>
              {isMobile ? (
                <WrapperAviso>
                  Opa! Para essa placa, por favor acesse o aplicativo Frota360.
                </WrapperAviso>
              ) : (
                <WrapperAviso>
                  Opa! Para essa placa, por favor acesse o site Frota360.
                </WrapperAviso>
              )}
              <ContainerOfButtons>
                <OkLinkButton
                  onClick={() => {
                    setNotification400(false);
                    sendAnalytics("REDIRECT_PLACA_FROTA360_FECHAR");
                  }}
                >
                  Fechar
                </OkLinkButton>
                {isMobile ? (
                  isIOS ? (
                    <OkLinkAncor
                      href="https://apps.apple.com/br/app/localiza-frota-360/id1521903141"
                      onClick={() => {
                        setNotification400(false);
                        sendAnalytics("REDIRECT_PLACA_FROTA360_IOS");
                      }}
                    >
                      Baixar o app
                    </OkLinkAncor>
                  ) : (
                    <OkLinkAncor
                      href="https://play.google.com/store/apps/details?id=com.unidas.frotas360app"
                      onClick={() => {
                        setNotification400(false);
                        sendAnalytics("REDIRECT_PLACA_FROTA360_ANDROID");
                      }}
                    >
                      Baixar o app
                    </OkLinkAncor>
                  )
                ) : (
                  <OkLinkAncor
                    href="https://autenticacao.localiza.com/login?source_system=https:%2F%2Ffrota360.localiza.com%2Fhome&system_code=PORTAL_CLIENTE"
                    onClick={() => {
                      setNotification400(false);
                      sendAnalytics("REDIRECT_PLACA_FROTA360_SITE");
                    }}
                  >
                    Acessar o site
                  </OkLinkAncor>
                )}
              </ContainerOfButtons>
            </Aviso>
          </AvisoContainer>
        </React.Fragment>
      ) : (
        ""
      )}
      <ReCAPTCHA
        onChange={getTokenLocalStorage}
        ref={reCaptchaRef}
        sitekey={CaptchaSiteKey}
        onExpired={() => redoCaptcha}
      />
      <ButtonContainer>
        <Button onClick={handleClick} disabled={buttonDisabled}>
          Prosseguir
        </Button>
      </ButtonContainer>
      <Ancora onClick={redirectToLogin}>Voltar para a tela de login</Ancora>
      {loading === true ? <Loading /> : ""}
      {clickCaptcha === true && home === true ? (
        <Redirect
          to={{
            pathname: "/Home",
            state: { id: "Login" },
          }}
        />
      ) : (
        ""
      )}
    </FormContainer>
  );
};
