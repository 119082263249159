import styled, { css } from "styled-components";
import { TextField, withStyles } from "@material-ui/core";

export const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 330px;
  padding: 0 14px 0 14px;
  margin: 10px 0 0 0;
`;

export const LabelWrapper = styled.label`
  font-family: Inter;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 150%;
  text-align: center;
  color: #3a3d42;
  white-space: nowrap;

  @media (max-width: 1025px) {
    font-size: 14px;
    white-space: normal;
  }
`;

export const InputContainer = styled.div`
  width: 300px;
  margin: 20px 0 0 0;
`;

export const TextFieldMaterial = withStyles({
  root: {
    width: "100%",
    marginBottom: 16,
    "& label.Mui-focused": {
      color: "#01602A",
      borderRadius: 12,
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#3A3D42",
      borderRadius: 12,
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#3A3D42",
        borderRadius: 12,
        height: 59,
      },
      "&:hover fieldset": {
        borderColor: "#01602A",

        borderRadius: 12,
      },
      "&.Mui-focused fieldset": {
        borderColor: "#01602A",

        borderRadius: 12,
      },
    },
  },
  label: {
    left: 40,
  },
})(TextField);

export const ButtonContainer = styled.div`
  width: 100%;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin: 20px 0 0 0;
`;

export const Button = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  background: #01602a;
  border-radius: 16px;
  border: transparent;
  color: #FFFFFF;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 15px;
  font-family: "Inter", sans-serif;
  text-align: center;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  padding: 16px;
  width: 100%;
  height: 48px;
  margin-bottom: 16px;

  ${({ isOutlined }) =>
    isOutlined === true &&
    css`
      background: #fff;
      border: 1px solid #01602A;
      color: #01602a;
    `}
`;

export const ErrorContainer = styled.div`
  min-height: 60px;
  max-height: 90px;
  width: 292px;
  border-radius: 16px;
  background: #edc2c2;
  color: #ae0000;
  padding: 0 15px 0 15px;
`;

export const MessageError = styled.p`
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.15px;
`;
export const Ancora = styled.a`
  font-family: Inter;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.15px;
  text-decoration-line: underline;
  color: #5f5e57;
  cursor: pointer;
`;
