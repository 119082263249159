import React from 'react';

const Modal = (props) => {
    return (
        <div className="modal-backdrop modal-open">
            <div className="modal">
                <div className="modal-hearder">
                {/* <h2 className="modal-title"> { props.title } </h2> */}
                <span className="icon icon icon-x" onClick={() => props.onClickCloseModal()}></span>
                </div>
                <div className="modal-body">
                    {props.children}
                </div>
            </div>
        </div>
    )
}

export default Modal;
