import {
  ON_LOGIN_FAIL,
  ON_LOGIN,
  SET_PLATE,
  DESLOGAR,
  RESET,
  VALIDAR_CODIGO_SMS,
  DESLOGAR_COM_LINK,
  LOGIN_LOADING,
  LOGIN_LOADED,
} from "./LoginTypes";

import { HttpGet, HttpPost } from "../../functions/default";
import * as Pagina from "./PagesEnumeration";
import qs from "qs";
import {
  NomeCookie,
  NomeCookiePlaca,
  NomeCookieUrlForcada,
  MinutosEsperaCookieSms,
  NomeCookieSessaoRea,
  NomeCookieTipoLogin,
} from "../../config/consts";
import * as Cookie from "../../functions/Cookies";

export const salvarPlaca = (placa) => {
  return {
    type: SET_PLATE,
    payload: placa,
  };
};

export const OnLoginCelular = (value) => {
  return {
    type: ON_LOGIN,
    payload: value,
  };
};

export const limparEstado = () => {
  return {
    type: RESET,
  };
};

export const esconderLoading = () => {
  return {
    type: LOGIN_LOADED,
  };
};

//#region [ Celular / SMS ]

export const enviarSMS = (model) => {
  return (dispatch) => {
    dispatch({ type: LOGIN_LOADING });
    var param = qs.stringify(model);
    HttpPost(`LoginSMS`, null, param)
      .then(function (resposta) {
        dispatch({ type: LOGIN_LOADED });
        if (resposta.data.sucesso) {
          Cookie.set(
            NomeCookieUrlForcada,
            JSON.stringify(TransformarResposta(resposta.data, "/Login")),
            MinutosEsperaCookieSms
          );
          dispatch({
            type: ON_LOGIN,
            payload: TransformarResposta(resposta.data, "/Login"),
          });
          return;
        }
        dispatch({
          type: DESLOGAR,
          payload: {
            message:
              "Não foi possível concluir ação. " + resposta.data.mensagem,
          },
        });
      })
      .catch(function (erro) {
        dispatch({ type: LOGIN_LOADED });
        if (erro.response && erro.response.status === 401) {
          dispatch({
            type: DESLOGAR,
            payload: { message: "Usuário não autenticado." },
          });
          return;
        }
        dispatch({
          type: ON_LOGIN_FAIL,
          payload: { message: "Não foi possível concluir ação. " },
        });
      });
  };
};

export const reenviarSms = (model) => {
  return (dispatch) => {
    dispatch({ type: LOGIN_LOADING });
    var param = qs.stringify(model);

    HttpPost(`ReenviarSms`, null, param)
      .then(function (resposta) {
        dispatch({ type: LOGIN_LOADED });
        if (resposta.data.sucesso) {
          let data = TransformarResposta(resposta.data, "/LoginSMS");
          data["SmsEnviado"] = true;
          Cookie.set(
            NomeCookieUrlForcada,
            JSON.stringify(data),
            MinutosEsperaCookieSms
          );
          dispatch({ type: ON_LOGIN, payload: data });
          return;
        }
        dispatch({
          type: DESLOGAR,
          payload: {
            message:
              "Não foi possível concluir ação. " + resposta.data.mensagem,
          },
        });
      })
      .catch(function (erro) {
        dispatch({ type: LOGIN_LOADED });
        if (erro.response && erro.response.status === 401) {
          dispatch({
            type: DESLOGAR,
            payload: { message: "Usuário não autenticado." },
          });
          return;
        }
        dispatch({
          type: ON_LOGIN_FAIL,
          payload: { message: "Não foi possível concluir ação." },
        });
      });
  };
};

export const primeiroAcesso = (ait, orgao, placa) => {
  return (dispatch) => {
    var objeto = { ait, orgao, placa };
    var param = qs.stringify(objeto);
    dispatch({ type: LOGIN_LOADING });
    HttpGet(`PrimeiroAcessoCelular?${param}`, null, null)
      .then(function (resposta) {
        dispatch({ type: LOGIN_LOADED });
        if (resposta.data.sucesso) {
          Cookie.set(
            NomeCookieUrlForcada,
            JSON.stringify(TransformarResposta(resposta.data, "/LoginSMS")),
            MinutosEsperaCookieSms
          );
          Cookie.set(NomeCookieTipoLogin, resposta.data.usuario.S, null, true);
          dispatch({
            type: ON_LOGIN,
            payload: TransformarResposta(resposta.data, "/LoginSMS"),
          });
          return;
        }
        dispatch({
          type: DESLOGAR,
          payload: {
            message:
              "Não foi possível concluir ação. " + resposta.data.mensagem,
          },
        });
      })
      .catch(function (erro) {
        dispatch({ type: LOGIN_LOADED });
        if (erro.response && erro.response.status === 401) {
          dispatch({
            type: DESLOGAR,
            payload: { message: "Usuário não autenticado." },
          });
          return;
        }
        dispatch({
          type: ON_LOGIN_FAIL,
          payload: { message: "Não foi possível concluir ação. " },
        });
      });
  };
};

export const validarCodigoSms = (codigo, chave) => {
  return (dispatch) => {
    dispatch({ type: LOGIN_LOADING });
    var objeto = { codigo, chave };
    var param = qs.stringify(objeto);
    HttpGet(`ValidarCodigoSms?${param}`, null, null)
      .then(function (resposta) {
        dispatch({ type: LOGIN_LOADED });
        var data = TransformarResposta(resposta.data, "/LoginSMS");
        data["valido"] = resposta.data.valido;
        dispatch({ type: VALIDAR_CODIGO_SMS, payload: data });
      })
      .catch(function (erro) {
        if (erro.response && erro.response.status === 401) {
          dispatch({
            type: DESLOGAR,
            payload: { message: "Usuário não autenticado." },
          });
          return;
        }
        dispatch({
          type: ON_LOGIN_FAIL,
          payload: { message: "Não foi possível concluir ação. " },
        });
        dispatch({ type: LOGIN_LOADED });
      });
  };
};

//#endregion

//#region [ Links diretos ]

export const tratarLinkMulta = (objeto) => {
  return (dispatch) => {
    var param = qs.stringify(objeto);

    HttpGet(`TratarLinkMulta?${param}`, null, null)
      .then(function (resposta) {
        if (resposta.data.sucesso) {
          dispatch({
            type: ON_LOGIN,
            payload: TransformarResposta(resposta.data, "/Login"),
          });
        } else {
          logout();
          dispatch({
            type: DESLOGAR_COM_LINK,
            payload: TransformarResposta(resposta.data, "/Login"),
          });
        }
      })
      .catch(function (erro) {
        if (erro.response && erro.response.status === 401)
          dispatch({
            type: DESLOGAR,
            payload: { message: "Usuário não autenticado." },
          });
        else
          dispatch({
            type: ON_LOGIN_FAIL,
            payload: { message: "Não foi possível concluir ação. " },
          });
      });
  };
};

export const verificarPlacaAtiva = (objeto) => {
  return (dispatch) => {
    var param = qs.stringify(objeto);
    HttpGet(
      "/VerificarPlacaAtiva?placa=" +
        objeto.placa +
        "&codigoUsuario=" +
        objeto.codigoUsuario,
      null,
      null
    )
      .then(function (result) {
        if (result.data.sucesso) {
          if (!result.data.placaAtiva) {
            logout();
            dispatch({
              type: DESLOGAR,
              payload: {
                message:
                  "Placa " + objeto.Placa + " inativa. Efetue login novamente.",
              },
            });
            dispatch({
              type: DESLOGAR_COM_LINK,
              payload: TransformarResposta(result.data, "/Login"),
            });
            return;
          }
        }
      })
      .catch(function (erro) {});
  };
};

export const tratarLinkDireto = (objeto) => {
  return (dispatch) => {
    var param = qs.stringify(objeto);
    HttpGet(`TratarLinkDireto?${param}`, null, null)
      .then(function (resposta) {
        if (resposta.data.sucesso) {
          dispatch({
            type: ON_LOGIN,
            payload: TransformarResposta(resposta.data, "/Login"),
          });
        } else {
          logout();
          dispatch({
            type: DESLOGAR_COM_LINK,
            payload: TransformarResposta(resposta.data, "/Login"),
          });
        }
      })
      .catch(function (erro) {
        if (erro.response && erro.response.status === 401)
          dispatch({
            type: DESLOGAR,
            payload: { message: "Usuário não autenticado." },
          });
        else
          dispatch({
            type: ON_LOGIN_FAIL,
            payload: { message: "Não foi possível concluir ação. " },
          });
      });
  };
};

//#endregion

//#region [ Placa Renavam ]

export const logarPlacaRenavam = (model) => {
  return (dispatch) => {
    dispatch({ type: LOGIN_LOADING });
    var param = qs.stringify(model);

    if (model.VincularCelularPlacaRenavam) {
      HttpPost(`VincularPlacaRenavam`, null, param)
        .then(function (resposta) {
          dispatch({ type: LOGIN_LOADED });
          if (resposta.data.sucesso) {
            dispatch({
              type: ON_LOGIN,
              payload: TransformarResposta(resposta.data, "/LoginPlacaRenavam"),
            });
            return;
          }
          dispatch({
            type: DESLOGAR,
            payload: {
              message:
                "Não foi possível concluir ação. " + resposta.data.mensagem,
            },
          });
        })
        .catch(function (erro) {
          dispatch({ type: LOGIN_LOADED });
          if (erro.response && erro.response.status === 401) {
            dispatch({
              type: DESLOGAR,
              payload: { message: "Usuário não autenticado." },
            });
            return;
          }
          dispatch({
            type: ON_LOGIN_FAIL,
            payload: { message: "Não foi possível concluir ação. " },
          });
        });
    } else {
      HttpPost(`LoginPlacaRenavam`, null, param)
        .then(function (resposta) {
          dispatch({ type: LOGIN_LOADED });
          if (resposta.data.sucesso) {
            dispatch({
              type: ON_LOGIN,
              payload: TransformarResposta(resposta.data, "/LoginPlacaRenavam"),
            });
            return;
          }
          dispatch({
            type: DESLOGAR,
            payload: {
              message:
                "Não foi possível concluir ação. " + resposta.data.mensagem,
            },
          });
        })
        .catch(function (erro) {
          dispatch({ type: LOGIN_LOADED });
          if (erro.response && erro.response.status === 401) {
            dispatch({
              type: DESLOGAR,
              payload: { message: "Usuário não autenticado." },
            });
            return;
          }
          dispatch({
            type: ON_LOGIN_FAIL,
            payload: { message: "Não foi possível concluir ação. " },
          });
        });
    }
  };
};

function TransformarResposta(data, pg_atual) {
  var payload = {};
  var usuario = null;
  var codigoUsuario = null;

  if (data.usuario) {
    usuario = { Situacao: data.usuario.S, CodigoUsuario: data.usuario.CDU };
    codigoUsuario = data.usuario.CDU;
  }

  if (data.autenticacao) {
    Cookie.set(
      NomeCookie,
      data.autenticacao.Token,
      data.autenticacao.TempoExpiracao
    );

    if (data.autenticacao.Placa) {
      Cookie.set(
        NomeCookiePlaca,
        data.autenticacao.Placa,
        data.autenticacao.TempoExpiracao
      );

      if (usuario) {
        usuario.P = data.autenticacao.Placa;
      } else {
        usuario = { P: data.autenticacao.Placa };
      }
    }
  }

  switch (data.pagina) {
    case Pagina.PG_HOME.valor: {
      payload["url"] = Pagina.PG_HOME.url;
      payload["model"] = data.model;
      payload["usuario"] = usuario;
      payload["codigoUsuario"] = codigoUsuario;
      break;
    }
    case Pagina.PG_AUTORIZAR_COOKIES.valor: {
      payload["url"] = Pagina.PG_AUTORIZAR_COOKIES.url;
      payload["model"] = data.model;
      payload["usuario"] = usuario;
      payload["codigoUsuario"] = codigoUsuario;
      break;
    }
    case Pagina.PG_LOGIN_CELULAR.valor: {
      payload["url"] = Pagina.PG_LOGIN_CELULAR.url;
      payload["model"] = data.model;
      payload["usuario"] = usuario;
      payload["codigoUsuario"] = codigoUsuario;
      break;
    }
    case Pagina.PG_LOGIN_MENSAGEM.valor: {
      payload["url"] = Pagina.PG_LOGIN_MENSAGEM.url;
      payload["model"] = data.model;
      payload["usuario"] = usuario;
      payload["codigoUsuario"] = codigoUsuario;
      break;
    }
    case Pagina.PG_LOGIN_PLACA_RENAVAM.valor: {
      payload["url"] = Pagina.PG_LOGIN_PLACA_RENAVAM.url;
      payload["model"] = data.model;
      payload["usuario"] = usuario;
      payload["codigoUsuario"] = codigoUsuario;
      break;
    }
    case Pagina.PG_LOGIN_SMS.valor: {
      payload["url"] = Pagina.PG_LOGIN_SMS.url;
      payload["model"] = data.model;
      payload["usuario"] = usuario;
      payload["codigoUsuario"] = codigoUsuario;
      break;
    }
    case Pagina.PG_MULTAS_MY_FLEET.valor: {
      payload["url"] = data.url;
      payload["usuario"] = usuario;
      payload["MyFleet"] = true;
      payload["codigoUsuario"] = codigoUsuario;
      break;
    }
    default: {
      payload["url"] = pg_atual;
      break;
    }
  }

  return payload;
}

const logout = () => {
  Cookie.set(NomeCookie, "", -1);
  Cookie.set(NomeCookiePlaca, "", -1);
  Cookie.set(NomeCookieUrlForcada, "", -1);
  Cookie.set(NomeCookieSessaoRea, "", -1, true);
};

//#endregion
