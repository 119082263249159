import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0 15px 0 15px;
  margin: 52px 0 0 0;

  @media (max-width: 1025px) {
    margin: 25px 0 20px 0;
    padding: 0 6px 0 6px;
  }
`;

export const Historico = styled.div`
  width: 46.5%;
  height: 26px;
  border-radius: 24px;
  border: 1px solid #d8d8d8;
  cursor: pointer;
  @media (max-width: 1025px) {
    width: 48%;
    max-width: 170px;
    height: 25px;
  }
`;

export const Abertas = styled.div`
  width: 46.5%;
  height: 26px;
  border-radius: 24px;
  border: 1px solid #d8d8d8;
  cursor: pointer;
  @media (max-width: 1025px) {
    width: 48%;
    max-width: 170px;
    height: 26px;
  }
`;

export const Texto = styled.h1`
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  text-transform: uppercase;
  text-align: center;
  position: relative;
  bottom: 1px;
  @media (max-width: 1025px) {
  }
`;

export const MultasMobile = styled.section`
  background: #ffffff;
  box-shadow: 0px 6px 30px rgba(28, 76, 97, 0.2);
  border-radius: 16px;
`;

export const ImgContainer = styled.div`
  padding: 80px 0 0 0;
  text-align: center;
`;

export const ContainerCard = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;
export const Arrow = styled.div`
  position: absolute;
  top: 75px;
  right: 25px;
`;

export const Titulo = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
  color: #3a3d42;
  margin: 15px 0 0 0;
  @media (max-width: 1025px) {
    margin: 16px 0 10px 0;
  }
`;

export const SubTitulo = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 12px;
  color: #7b808c;
  position: absolute;
  top: 82px;
  @media (max-width: 1025px) {
    padding: 0 0 0 1px;
    top: 133px;
  }
`;

export const StatusContainer = styled.div`
  display: flex;
  flex-direction: row;
  padding: 25px 0 15px 0;
`;
export const MarcadorRed = styled.div`
  width: 12px;
  height: 12px;
  border-radius: 16px;
  background: #f85843;
`;
export const MarcadorYellow = styled.div`
  width: 12px;
  height: 12px;
  border-radius: 16px;
  background: #d6a300;
  margin: 0 0 0 50px;
`;

export const Status = styled.span`
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 12px;
  color: #7b808c;
  margin: 0 10px 0 10px;
`;

export const QuantidadePendentes = styled.span`
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #7b808c;
  margin: 0 5px 0 5px;
  position: absolute;
  top: 159px;
  left: 108px;
`;

export const QuantidadeAbertas = styled.span`
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #7b808c;
  margin: 0 5px 0 5px;
  position: absolute;
  top: 159px;
  left: 228px;
`;
