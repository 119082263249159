import React, { Component } from 'react';
import '../styles/css/info-read.css';

export default class InfoRead extends Component {
    render() {
        return (
            <div className="info-read">
                <div className="title">{this.props.title}</div>
                <div className="info">{this.props.info}<span className="secondary-info">{this.props.secondary}</span></div>
            </div>
        );
    }
}