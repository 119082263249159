import { 
    SET_TOKEN_USER_LOGGED, 
} from '../types'

const INITIAL_STATE = {
    userJWT : null,
}
export default (state = INITIAL_STATE, action) => {
    switch (action.type){
        case SET_TOKEN_USER_LOGGED:
            return  { ...state, userJWT: action.payload };
        default:
            return state;
    }
}