import React, { Component } from 'react';
import { connect } from 'react-redux';

class ExluirLink extends Component{
    render(){
        if(this.props.flgExcluir)
        {
            return (
                    <div onClick={() => this.props.OnExcluir(this.props.numVeiculo, this.props.data, this.props.terceiro)}>
                        <span className="icon icon-trash-2"></span>
                    </div>    
                
            )
        }
        return null;
    }
}

export default class Historico extends Component {
    render() {
        return (
            
            <section className="historic">
                <div className="historic-header">
                    <h1 className="title">HISTÓRICO</h1>
                </div>
                {
                    this.props.kms.map(km => 
                                        <section key={km.DataMedicao} className="wrapper-card">
                                            <div className={km.CardAmarelo == true ? "card card-historic amarelo" : "card card-historic azul"}>
                                                <div className="section-left">
                                                    <div className="info-primary">{km.Km}KM</div>
                                                    <div className="info-secondary">{km.DescOrigemSite}</div>
                                                </div>
                                                <div className="section-right">
                                                    <span className="action">
                                                        <ExluirLink 
                                                            flgExcluir={km.PodeExcluir}                                                         
                                                            OnExcluir={(n, d, t)=> this.props.OnExcluir(n, d, t)}
                                                            numVeiculo={km.NumeroVeiculo}
                                                            data={km.DataMedicao}
                                                            terceiro={km.CarroTerceiro}/> 
                                                    </span>
                                                    <div>{km.DataMedicaoFormatado}</div>
                                                </div>
                                            </div>
                                        </section>  
                                    ) 
                }     
            </section>
            
        );
    }
}
