import { createStore, compose, applyMiddleware } from "redux";
import ReduxThunk from "redux-thunk";
import Reducers from "../reducers";

const middlewareList = [ReduxThunk];

if (process.env.NODE_ENV === `development`) {
  const { logger } = require(`redux-logger`);

  middlewareList.push(logger);
}

const composeEnhancers =
  typeof window === "object" && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({})
    : compose;

const enhancer = composeEnhancers(applyMiddleware(...middlewareList));

const store = createStore(Reducers, enhancer);

export default store;
