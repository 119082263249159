export const ERRO_AGENDAMENTO = 'erro_agendamento';
export const BUSCAR_SERVICOS_ATIVOS = 'buscar_servicos_ativos';
export const VALIDAR_KM = 'validar_km';
export const BUSCAR_ULTIMA_MEDICAO = 'buscar_ultima_medicao';
export const SELECIONAR_SERVICOS = 'selecionar_servicos';
export const BUSCAR_FORNECEDORES = 'buscar_fornecedores';
export const LIMPAR_FORNECEDORES = 'limpar_fornecedores';
export const LISTAR_FERIADOS = 'listar_feriados';
export const SALVAR_AGENDAMENTO = 'salvar_agendamento';
export const MOSTRAR_LOADING = 'mostrar_loading';
export const ESCONDER_LOADING = 'esconder_loading';
export const IS_HOLIDAY = 'is_holiday';
export const DADOS_USUARIO = 'dados_usuario';
export const AGENDAMENTO_DATAS_DO_DIA = 'agendamento_datas_do_dia';
export const BUSCAR_SS_MAE = 'buscar_ss_mae';

