export const set = (cname, cvalue, minutes, islocalStorage) => {

    if (islocalStorage === true) {
        localStorage.setItem(cname, cvalue);
    }
    else {
        var d = new Date();
        d.setTime(d.getTime() + (minutes * 60 * 1000));
        var expires = "expires=" + d.toUTCString();
        document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
    }
}

export const get = (cname, islocalStorage) => {

    if (islocalStorage === true) {
        return localStorage.getItem(cname);
    } else {
        var name = cname + "=";
        var decodedCookie = decodeURIComponent(document.cookie);

        var ca = decodedCookie.split(';');
        for (var i = 0; i < ca.length; i++) {
            var c = ca[i];
            while (c.charAt(0) === ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) === 0) {
                return c.substring(name.length, c.length);
            }
        }
        if (cname === "U_forced_rea") {
        }
        return "";
    }
}

export const remove = (cname) => {
    localStorage.removeItem(cname);
}