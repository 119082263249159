import React, { Component } from 'react';

export default class CardAgendamento extends Component {
    render() {
        return (
            <section key={this.props.id} className="wrapper-card gtm-agendamentos-agendamentos">               
                    <div className={`card card-list-agendamentos gtm-agendamentos-agendamentos ${this.props.corcard}`}>
                        <div className="agendamento-data">
                            <div className="data">{this.props.data} </div>
                            <div className="hora">{this.props.hora}</div>
                        </div>
                        <div className="agendamento-fornecedor-endereco">
                            <div className="fornecedor">{this.props.fornecedor}</div>
                            <div className="endereco">{this.props.endereco}</div>
                        </div>                        
                    </div>            
            </section>
        );
    }
}