import React from 'react'

function ImprimirTermo() {
    return (
        <div>
            <div style={{ textAlign: 'center' }}>
                <h1>Imprima seu termo, assine, depois envie.</h1>
            </div>
            <div style={{ paddingTop: '15px', textAlign: 'center' }}>
                <h4>Imprima o termo, assine, depois retorne para carregar junto com a CNH e finalizar o processo.</h4>
            </div>

            

        </div>
    );

}

export default ImprimirTermo;