import {
  LISTA_AGENDAMENTOS,
  ERRO_AGENDAMENTO,
  SLIDER_DETALHE_AGENDAMENTO,
  SLIDER_ACTIVE,
  AGENDAMENTOS_LOADING,
  AGENDAMENTOS_LOADED,
} from "./CardAgendamentoTypes";
import { HttpGet } from "../../functions/default";
import { DESLOGAR } from "../../pages/Login/LoginTypes";
import qs from "qs";
import Tracking from "../../hooks/Tracking";

const GACATERGORIAAGEDAMENTO = "agendamento_servico";

export const limparMensageErro = () => {
  return (dispatch) => {
    dispatch({ type: ERRO_AGENDAMENTO, payload: null });
  };
};

export const listarAgendamentos = (placa) => {
  return (dispatch) => {
    if (navigator.onLine) {
      dispatch({ type: AGENDAMENTOS_LOADING });
      HttpGet(`/BuscaServicosAgendados/${placa}`, null)
        .then(function (result) {
          dispatch({ type: AGENDAMENTOS_LOADED });
          Tracking.sendEvent({
            category: GACATERGORIAAGEDAMENTO,
            action: "AGENDAMENTO_LISTA_SUCESSO",
            label: "Sucesso ao buscar lista dos agendamentos",
          });
          if (result.data) {
            dispatch({ type: LISTA_AGENDAMENTOS, payload: result.data });
          }
        })
        .catch(function (erro) {
          dispatch({ type: AGENDAMENTOS_LOADED });
          if (
            (erro.response && erro.response.status === 401) ||
            (erro.response && erro.response.status === 403)
          ) {
            dispatch({
              type: DESLOGAR,
              payload: { message: "Usuário não autenticado." },
            });
          }
          if (erro.response.status !== 401 || erro.response.status !== 403) {
            Tracking.sendEvent({
              category: GACATERGORIAAGEDAMENTO,
              action: "AGENDAMENTO_LISTA_ERRO",
              label: "Erro ao buscar lista dos agendamentos",
            });
            dispatch({
              type: ERRO_AGENDAMENTO,
              payload: {
                BuscaServicosAgendados:
                  "Não foi possível conectar ao servidor.",
              },
            });
          }
          dispatch({
            type: ERRO_AGENDAMENTO,
            payload: {
              BuscaServicosAgendados: "Não foi possível conectar ao servidor.",
            },
          });
        });
    }
  };
};

export const exibirDetalheAgendamento = (
  placa,
  numeroSS,
  sequencialSS,
  mobile
) => {
  return (dispatch) => {
    if (navigator.onLine) {
      dispatch({ type: AGENDAMENTOS_LOADING });
      var param = qs.stringify({ placa, numeroSS, sequencialSS, mobile });
      HttpGet(`/BuscaServicoAgendado?${param}`, null)
        .then(function (result) {
          dispatch({ type: AGENDAMENTOS_LOADED });
          Tracking.sendEvent({
            category: GACATERGORIAAGEDAMENTO,
            action: "AGENDAMENTO_DETALHES_SUCESSO",
            label: "Sucesso ao buscar detalhe do agendamento",
          });
          dispatch({ type: SLIDER_DETALHE_AGENDAMENTO, payload: result.data });
          dispatch({ type: SLIDER_ACTIVE, payload: true });
        })
        .catch(function (erro) {
          dispatch({ type: AGENDAMENTOS_LOADED });
          if (
            (erro.response && erro.response.status === 401) ||
            (erro.response && erro.response.status === 403)
          ) {
            dispatch({
              type: DESLOGAR,
              payload: { mensagem: "Usuário não autenticado." },
            });
          } else {
            Tracking.sendEvent({
              category: GACATERGORIAAGEDAMENTO,
              action: "AGENDAMENTO_DETALHES_ERRO",
              label: "Erro ao buscar detalhe do agendamento",
            });
            dispatch({ type: SLIDER_ACTIVE, payload: false });
            dispatch({
              type: ERRO_AGENDAMENTO,
              payload: {
                BuscaServicosAgendados:
                  "Não foi possível conectar ao servidor.",
              },
            });
          }
        });
    } else
      dispatch({
        type: ERRO_AGENDAMENTO,
        payload: {
          mensagem: "Para prosseguir, conecte seu celular à internet.",
        },
      });
  };
};
