import React, { Component } from 'react';

export default class ListTwoText extends Component {
    render() {
        return (
            <div className="wrapper-list-two-texts">
                <span className="icon icon-list"></span>
                <ul className="list-two-text">
                    {
                        this.props.itens.map(item => {
                            return <li key={item.id}>
                                        <span className="text-one">{item.textOne}</span>
                                        <span className="text-two">{item.textTow}</span>
                                    </li>
                        })
                    }
                </ul>
            </div>
        );
    }
}