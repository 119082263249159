import React, { useEffect, useState } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { connect } from "react-redux";
import Home from "./pages/Home/HomePage";
import EditarKM from "./pages/EditarKM/EditarKMPage";
import GridMulta from "./pages/Multa/MultaPage";
import ListaAgendamentos from "./pages/Agendamento/ListaAgendamentos/ListaAgendamentosPage";
import ServicosContratados from "./pages/ServicosContratados/ListaServicosContratados/ListaServicosContratadosPage";
import MediaQuery from "react-responsive";
import Avarias from "./pages/Sinistro/AvariaPage";
import Roubos from "./pages/Sinistro/RouboPage";
import PoliticaFrota from "./pages/PoliticaFrota/PoliticaFrotaPage";
import FornecedoresPage from "./pages/Agendamento/Novo/FornecedoresPage";
import AutorizarCookies from "./pages/AutorizarCookies/AutorizarCookiesPage";
import ReaPage from "./pages/Rea/ReaPage";
import InformacaoMultaPage from "./pages/InformacaoMulta/InformacaoMultaPage";
import ManualCondutorPage from "./pages/ManualCondutor/ManualCondutorPage";
import CRLVPage from "./pages/Crlv/CrlvPage";
import AssinaturaPage from "./pages/Assinatura/AssinaturaPage";
import LoginEmailPage from "./pages/novo-login/LoginEmailPage";
import CadastroSenhaPage from "./pages/novo-login/CadastroSenhaPage";
import LoginCelularPage from "./pages/novo-login/LoginCelularPage";
import EsqueciSenhaPage from "./pages/novo-login/EsqueciSenhaPage";
import LoginRenavamEmailPage from "./pages/novo-login/LoginRenavamEmailPage";
import LoginRenavamMainPage from "./pages/novo-login/LoginRenavamMainPage";
import Wizard from "./pages/Wizard";
import ReenvioEmailPage from "./pages/novo-login/ReenvioEmailPage";
import RedirecionamentoPage from "./pages/Redirecionamento/RedirecionamentoPage";
import ManutencaoPage from "./pages/Manutencao/ManutencaoPage";
import RedirectAppPage from "./pages/RedirectApp/RedirectAppPage";
import RedirectAppMobilePage from "./pages/RedirectApp/RedirectAppMobilePage";
import { defaultConfig } from "./remote_config/remote_keys";
import { fetchRemoteConfig } from "./remote_config/remote_config_call";
import { checkIsWebView } from "./helpers/checkPlatform";

export const Router = () => {
  const [isOnMaintenance, setIsOnMaintenance] = useState(false);
  const [RedirectApp, setRedirectApp] = useState(false);

  useEffect(() => {
    const checkRedirectMode = async () => {
      try {
        const RedirectAppMode = true;
        //DEIXANDO FIXO PARA DESATIVACAO DAS ROTAS NAO USADAS
        //await fetchRemoteConfig(
        //"redirect_mode",
        //`${defaultConfig.maintenanceMode}`,
        //"bool"
        //);
        setRedirectApp(RedirectAppMode);
      } catch (error) {
        console.error("Error fetching redirect app mode:", error);
        setRedirectApp(true);
      }
    };
    checkRedirectMode();

    const checkMaintenanceMode = async () => {
      try {
        const maintenanceMode = false;
        // await fetchRemoteConfig(
        //   "maintenance_mode",
        //   `${defaultConfig.maintenanceMode}`,
        //   "bool"
        // );
        setIsOnMaintenance(maintenanceMode);
      } catch (error) {
        console.error("Error fetching maintenance mode:", error);
        setIsOnMaintenance(false);
      }
    };

    checkMaintenanceMode();
  }, []);

  return (
    <span>
      <MediaQuery minDeviceWidth={1025}>
        <BrowserRouter>
          <Switch>
            <Route
              exact
              path="/wizard"
              component={!checkIsWebView && RedirectApp ? RedirectAppPage : isOnMaintenance ? ManutencaoPage : Wizard}
            />
            <Route
              exact
              path="/"
              component={!checkIsWebView && RedirectApp ? RedirectAppPage : isOnMaintenance ? ManutencaoPage : Home}
            />
            <Route
              exact
              path="/Login"
              component={!checkIsWebView && RedirectApp ? RedirectAppPage : isOnMaintenance ? ManutencaoPage : LoginEmailPage}
            />
            <Route exact path="/Manutencao" component={ManutencaoPage} />
            <Route
              exact
              path="/LoginSenhaCadastro"
              component={!checkIsWebView && RedirectApp ? RedirectAppPage : isOnMaintenance ? ManutencaoPage : CadastroSenhaPage}
            />
            <Route
              exact
              path="/LoginReenviarEmail"
              component={!checkIsWebView && RedirectApp ? RedirectAppPage : isOnMaintenance ? ManutencaoPage : ReenvioEmailPage}
            />
            <Route
              exact
              path="/LoginCelular"
              component={!checkIsWebView && RedirectApp ? RedirectAppPage : isOnMaintenance ? ManutencaoPage : LoginCelularPage}
            />
            <Route
              exact
              path="/LoginNovaSenha"
              component={!checkIsWebView && RedirectApp ? RedirectAppPage : isOnMaintenance ? ManutencaoPage : EsqueciSenhaPage}
            />
            <Route
              exact
              path="/LoginRenavamMain"
              component={!checkIsWebView && RedirectApp ? RedirectAppPage : isOnMaintenance ? ManutencaoPage : LoginRenavamMainPage}
            />
            <Route
              exact
              path="/LoginRenavamEmail"
              component={!checkIsWebView && RedirectApp ? RedirectAppPage : isOnMaintenance ? ManutencaoPage : LoginRenavamEmailPage}
            />
            <Route
              exact
              path="/Home"
              component={!checkIsWebView && RedirectApp ? RedirectAppPage : isOnMaintenance ? ManutencaoPage : Home}
            />
            <Route
              exact
              path="/EditarKM"
              component={!checkIsWebView && RedirectApp ? RedirectAppPage : isOnMaintenance ? ManutencaoPage : EditarKM}
            />
            <Route
              exact
              path="/ServicosContratados"
              component={!checkIsWebView && RedirectApp ? RedirectAppPage : isOnMaintenance ? ManutencaoPage : ServicosContratados}
            />
            <Route
              exact
              path="/Avarias"
              component={!checkIsWebView && RedirectApp ? RedirectAppPage : isOnMaintenance ? ManutencaoPage : Avarias}
            />
            <Route
              exact
              path="/Roubos"
              component={!checkIsWebView && RedirectApp ? RedirectAppPage : isOnMaintenance ? ManutencaoPage : Roubos}
            />
            <Route
              exact
              path="/PoliticaFrota"
              component={!checkIsWebView && RedirectApp ? RedirectAppPage : isOnMaintenance ? ManutencaoPage : PoliticaFrota}
            />
            <Route
              exact
              path="/FornecedoresPage"
              component={!checkIsWebView && RedirectApp ? RedirectAppPage : isOnMaintenance ? ManutencaoPage : FornecedoresPage}
            />
            <Route
              exact
              path="/AutorizarCookies"
              component={AutorizarCookies}
            />
            <Route
              exact
              path="/PreencherRea"
              component={!checkIsWebView && RedirectApp ? RedirectAppPage : isOnMaintenance ? ManutencaoPage : ReaPage}
            />
            <Route
              exact
              path="/InformacaoMulta/:id/:orgao"
              component={!checkIsWebView && RedirectApp ? RedirectAppPage : isOnMaintenance ? ManutencaoPage : InformacaoMultaPage}
            />
            <Route
              exact
              path="/GuiaCondutor"
              component={!checkIsWebView && RedirectApp ? RedirectAppPage : isOnMaintenance ? ManutencaoPage : ManualCondutorPage}
            />
            <Route
              exact
              path="/Crlv"
              component={!checkIsWebView && RedirectApp ? RedirectAppPage : isOnMaintenance ? ManutencaoPage : CRLVPage}
            />
            <Route
              exact
              path="/Assinatura"
              component={!checkIsWebView && RedirectApp ? RedirectAppPage : isOnMaintenance ? ManutencaoPage : AssinaturaPage}
            />
            <Route
              path="*"
              component={!checkIsWebView && RedirectApp ? RedirectAppPage : isOnMaintenance ? ManutencaoPage : Home}
            />
          </Switch>
        </BrowserRouter>
      </MediaQuery>
      <MediaQuery maxDeviceWidth={1025}>
        <BrowserRouter>
          <Switch>
            <Route
              exact
              path="/wizard"
              component={!checkIsWebView && RedirectApp ? RedirectAppPage : isOnMaintenance ? ManutencaoPage : Wizard}
            />
            <Route
              exact
              path="/Login"
              component={!checkIsWebView && RedirectApp ? RedirectAppMobilePage : isOnMaintenance ? ManutencaoPage : LoginEmailPage}
            />
            <Route exact path="/Manutencao" component={ManutencaoPage} />
            <Route
              exact
              path="/LoginSenhaCadastro"
              component={!checkIsWebView && RedirectApp ? RedirectAppPage : isOnMaintenance ? ManutencaoPage : CadastroSenhaPage}
            />
            <Route
              exact
              path="/LoginReenviarEmail"
              component={!checkIsWebView && RedirectApp ? RedirectAppPage : isOnMaintenance ? ManutencaoPage : ReenvioEmailPage}
            />
            <Route exact path="/LoginCelular" component={LoginCelularPage} />

            <Route
              exact
              path="/LoginNovaSenha"
              component={!checkIsWebView && RedirectApp ? RedirectAppPage : isOnMaintenance ? ManutencaoPage : EsqueciSenhaPage}
            />
            <Route
              exact
              path="/LoginRenavamMain"
              component={!checkIsWebView && RedirectApp ? RedirectAppPage : isOnMaintenance ? ManutencaoPage : LoginRenavamMainPage}
            />
            <Route
              exact
              path="/LoginRenavamEmail"
              component={!checkIsWebView && RedirectApp ? RedirectAppPage : isOnMaintenance ? ManutencaoPage : LoginRenavamEmailPage}
            />
            <Route
              exact
              path="/Redirecionamento/:rota"
              component={!checkIsWebView && RedirectApp ? RedirectAppPage : isOnMaintenance ? ManutencaoPage : RedirecionamentoPage}
            />
            <Route
              exact
              path="/"
              component={!checkIsWebView && RedirectApp ? RedirectAppPage : isOnMaintenance ? ManutencaoPage : Home}
            />
            <Route exact path="/Manutencao" component={ManutencaoPage} />
            <Route
              exact
              path="/LoginSenhaCadastro"
              component={!checkIsWebView && RedirectApp ? RedirectAppPage : isOnMaintenance ? ManutencaoPage : CadastroSenhaPage}
            />
            <Route
              exact
              path="/LoginReenviarEmail"
              component={!checkIsWebView && RedirectApp ? RedirectAppPage : isOnMaintenance ? ManutencaoPage : ReenvioEmailPage}
            />
            <Route
              exact
              path="/LoginCelular"
              component={!checkIsWebView && RedirectApp ? RedirectAppPage : isOnMaintenance ? ManutencaoPage : LoginCelularPage}
            />

            <Route
              exact
              path="/LoginNovaSenha"
              component={!checkIsWebView && RedirectApp ? RedirectAppPage : isOnMaintenance ? ManutencaoPage : EsqueciSenhaPage}
            />
            <Route
              exact
              path="/LoginRenavamMain"
              component={!checkIsWebView && RedirectApp ? RedirectAppPage : isOnMaintenance ? ManutencaoPage : LoginRenavamMainPage}
            />
            <Route
              exact
              path="/LoginRenavamEmail"
              component={
                isOnMaintenance === true ? ManutencaoPage : LoginRenavamEmailPage
              }
            />
            <Route
              exact
              path="/Redirecionamento/:rota"
              component={!checkIsWebView && RedirectApp ? RedirectAppPage : isOnMaintenance ? ManutencaoPage : RedirecionamentoPage}
            />
            <Route
              exact
              path="/Home"
              component={!checkIsWebView && RedirectApp ? RedirectAppPage : isOnMaintenance ? ManutencaoPage : Home}
            />
            <Route
              exact
              path="/EditarKM"
              component={!checkIsWebView && RedirectApp ? RedirectAppPage : isOnMaintenance ? ManutencaoPage : EditarKM}
            />
            <Route
              exact
              path="/Multas"
              component={!checkIsWebView && RedirectApp ? RedirectAppPage : isOnMaintenance ? ManutencaoPage : GridMulta}
            />
            <Route
              exact
              path="/Agendamentos"
              component={!checkIsWebView && RedirectApp ? RedirectAppPage : isOnMaintenance ? ManutencaoPage : ListaAgendamentos}
            />
            <Route
              exact
              path="/ServicosContratados"
              component={!checkIsWebView && RedirectApp ? RedirectAppPage : isOnMaintenance ? ManutencaoPage : ServicosContratados}
            />
            <Route
              exact
              path="/Avarias"
              component={!checkIsWebView && RedirectApp ? RedirectAppPage : isOnMaintenance ? ManutencaoPage : Avarias}
            />
            <Route
              exact
              path="/Roubos"
              component={!checkIsWebView && RedirectApp ? RedirectAppPage : isOnMaintenance ? ManutencaoPage : Roubos}
            />
            <Route
              exact
              path="/PoliticaFrota"
              component={!checkIsWebView && RedirectApp ? RedirectAppPage : isOnMaintenance ? ManutencaoPage : PoliticaFrota}
            />
            <Route
              exact
              path="/FornecedoresPage"
              component={!checkIsWebView && RedirectApp ? RedirectAppPage : isOnMaintenance ? ManutencaoPage : FornecedoresPage}
            />
            <Route
              exact
              path="/AutorizarCookies"
              component={AutorizarCookies}
            />
            <Route
              exact
              path="/PreencherRea"
              component={!checkIsWebView && RedirectApp ? RedirectAppPage : isOnMaintenance ? ManutencaoPage : ReaPage}
            />
            <Route
              exact
              path="/InformacaoMulta/:id/:orgao"
              component={!checkIsWebView && RedirectApp ? RedirectAppPage : isOnMaintenance ? ManutencaoPage : InformacaoMultaPage}
            />
            <Route
              exact
              path="/GuiaCondutor"
              component={!checkIsWebView && RedirectApp ? RedirectAppPage : isOnMaintenance ? ManutencaoPage : ManualCondutorPage}
            />
            <Route
              exact
              path="/Crlv"
              component={!checkIsWebView && RedirectApp ? RedirectAppPage : isOnMaintenance ? ManutencaoPage : CRLVPage}
            />
            <Route
              exact
              path="/Assinatura"
              component={!checkIsWebView && RedirectApp ? RedirectAppPage : isOnMaintenance ? ManutencaoPage : AssinaturaPage}
            />
          </Switch>
        </BrowserRouter>
      </MediaQuery>
    </span>
  );
};

const mapStateToProps = (state) => {
  return {
    placa: state.menu.placa,
  };
};

export default connect(mapStateToProps, null)(Router);
