import React, { Component } from 'react';
import { buscarUltimoRouboPendente } from '../../pages/Sinistro/SinistroActions';
import ModalMensagem from '../ModalMensagem/ModalMensagem';
import { connect } from 'react-redux';

class CardRouboDesktop extends Component {
    componentDidMount() {
        if(localStorage.getItem("placa-atual"))
            this.props.buscarUltimoRouboPendente(localStorage.getItem("placa-atual"), false);
    }

    render() {
        if(this.props.ultimoroubo) {
            return (
                <section className="wrapper-card card-roubo-home">
                    {
                        this.props.erro ?
                            <ModalMensagem tipoModalMensagem='warning' icon='icon-x-circle'
                                mensagemPrincipal={this.props.erro.mensagem}
                                mensagemSecundaria=""
                                textoBotao="OK" />
                            : null
                    }
                    <a href="/Roubos">
                        <div className="card card-historic amarelo">
                            <div className="section-left">
                                <div className="left">
                                    <span className="icon icon-police2"></span>
                                    <span className="text">Roubo</span>
                                </div>
                                <div>
                                <span className="text">{this.props.ultimoroubo.DataFormatada}</span>
                                </div>                            
                            </div>
                            <div className="section-right">
                                <div>Aguardando envio de documentos</div>
                                <div>{this.props.ultimoroubo.Sequencia > 0 ? 
                                    `${this.props.ultimoroubo.Numero}/${this.props.ultimoroubo.Sequencia}` : this.props.ultimoroubo.Numero}</div>
                            </div>
                        </div>
                    </a>
                </section> 
            )
        }
        return null;
    }
}

const mapStateToProps = state => {
    return {
        ultimoroubo: state.sinistro.ultimoroubo,
        erro: state.sinistro.erro,
        placa: state.menu.placa
    }
};

export default connect(mapStateToProps, {
    buscarUltimoRouboPendente
})(CardRouboDesktop)