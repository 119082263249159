import axios from "axios"
import { getApiGeeAccessToken } from "../services/AuthApigeeToken";


export const api = axios.create({
  baseURL: process.env.REACT_APP_URL,
  headers:
  {
    //'Access-Control-Allow-Origin':'*',
    'client_id': process.env.REACT_APP_CLIENT_ID,
    'Content-Type': 'application/json',
  }
});

api.interceptors.request.use(async config => {
  const accessToken = await getApiGeeAccessToken();
  config.headers.Authorization = `Bearer ${accessToken}`;
  return config;
});






