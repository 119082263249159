import React, { useEffect } from "react";
import { Redirect, useParams } from "react-router-dom";
import {
  ErrorMessage,
  Button,
  SectionContainerRedirectApp,
  LogoContainerRedirectApp,
  Img,
  MsgContainer,
  Message,
} from "./styles";
import { useAuth } from "../../providers/auth";
import Loading from "../Loading";
import logo from "../../images/image-localiza-white.png";

export const Redirecionamento = () => {
  const {
    loading,
    home,
    placaRegistrationRedirectApp,
    errorRedirect,
  } = useAuth();

  const { rota } = useParams();

  const redirect = () => {
    const email = localStorage.getItem("user-email");
    const token = localStorage.getItem("token-id");
    const placa = localStorage.getItem("placa-atual");
    const renavam = localStorage.getItem("renavam");
    placaRegistrationRedirectApp(placa, renavam, token);
  };

  useEffect(() => {
    localStorage.removeItem("user-name");
    localStorage.removeItem("user-data");
    localStorage.removeItem("modelo-atual");
    localStorage.removeItem("codigo-identificador-atual");
    localStorage.removeItem("cnpj-atual");
    localStorage.removeItem("nome-empresa");
    localStorage.removeItem("razao-social");
    localStorage.removeItem("sigla-contrato");
    setTimeout(() => {
      redirect();
    }, 650);
  }, []);

  return (
    <SectionContainerRedirectApp>
      {loading === true ? <Loading /> : ""}
      <LogoContainerRedirectApp>
        {errorRedirect === true ? (
          <Img src={logo} alt="Localiza Gestão de Frotas" errorMessage />
        ) : (
          <React.Fragment>
            <Img src={logo} alt="Localiza Gestão de Frotas" />
            <MsgContainer>
              {loading === true ? (
                <Message loading>Aguarde, a página está carregando.</Message>
              ) : (
                <Message>Aguarde, a página está carregando.</Message>
              )}
            </MsgContainer>
          </React.Fragment>
        )}
      </LogoContainerRedirectApp>
      {errorRedirect === true ? (
        <React.Fragment>
          <MsgContainer>
            <ErrorMessage>
              Houve um erro ao realizar o redirecionamento.
            </ErrorMessage>
            <Button onClick={redirect}>Tentar novamente</Button>
          </MsgContainer>
        </React.Fragment>
      ) : (
        ""
      )}
      {home === true ? (
        <Redirect
          to={{
            pathname: "/Home",
            state: rota,
          }}
        />
      ) : (
        ""
      )}
    </SectionContainerRedirectApp>
  );
};
