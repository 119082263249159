import React, { Component } from 'react';
import { validaEmail } from '../../functions/manutencaoGeralValidacoes';

export default class ModalConfirmaEmail extends Component {
    constructor(props) {
        super(props);

        this.state = { modalOpened: true, destroy: false, erroEmail: false, email: "", emails: [] };
        this.modalToggle = this.modalToggle.bind(this);
        this.handleEmailChange = this.handleEmailChange.bind(this);
        this.inserirEmail = this.inserirEmail.bind(this);
    }


    handleEmailChange = (event) => {
        if (event.target.value !== null)
            this.setState({ email: event.target.value });
    }

    validarEmail(email) {
        return validaEmail(email);
    }


    modalToggle(resposta) {
        if (!this.props.dontClose) {
            this.setState({ modalOpened: !this.state.modalOpened })
            this.state.destroy = true;
        }

        //função a ser executada depois do Toggle
        if (this.props.OnToggled)
            this.props.OnToggled(resposta);
    }

    inserirEmail() {
        let email = this.state.email;
        if (this.validarEmail(email)) {
            var array = [...this.state.emails];
            array.push(email);
            this.setState({ emails: array, erroEmail: false, email: "" });
        } else {
            this.setState({ erroEmail: true });
        }
    }

    deletarEmail(indice) {
        var array = [...this.state.emails];
        array.splice(indice, 1);
        this.setState({ emails: array });
    }

    render() {
        /* configura flag para que o modal apareça da próxima vez que for chamado */
        if (this.state.destroy) {
            this.state.destroy = false;
            this.state.modalOpened = true;
            return null;
        }

        let _gridEmails = [];

        for (let i = 0; i < this.state.emails.length; i++) {
            _gridEmails.push(<React.Fragment>
                <div className="col-12">
                    <div className="divLeftModalEmail text-left">{this.state.emails[i]} <br /></div>
                    <div className="divRightModalEmail"><button className="bton-excluir-grid-emails" onClick={() => this.deletarEmail(i)}> x </button></div>
                </div><br />
                <hr />
            </React.Fragment>);
        }

        return (
            <div className={`modal-backdrop ${this.state.modalOpened ? 'modal-open' : 'modal-close'}`}>
                <div className={`modal-mensagem ${this.props.tipoModalMensagem}`}>
                    <span className="icon icon icon-x" onClick={this.modalToggle}></span>
                    <div className="modal-mensagem-body">
                        <span className={`icon ${this.props.icon}`}></span>
                        <div className="wrapper-mensagem">
                            <div className="mensagem-principal">
                                {this.props.mensagemPrincipal}
                                <br /><br />
                                <div className="form-intro col-12">
                                    <div className="col-md-7 text-left">
                                        <div className={`wrapper-input wapper-email-modal ${(this.state.erroEmail === true) ? 'has-error' : ""}`} >
                                            <div className="group-input">
                                                <input type="text" name="email" id="email" className="input" value={this.state.email} onChange={this.handleEmailChange} required />
                                                <label htmlFor="email" className="label">E-mail</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-2 text-left">
                                        <button className="botaoSomarEmail" onClick={this.inserirEmail}> + </button>
                                    </div>
                                    <div className="gridEmailLista">
                                        <br /><br />
                                        {_gridEmails}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="modal-bottom">
                        <button className="button" onClick={() => this.modalToggle({ emails: this.state.emails })} disabled={this.state.emails.length === 0}>{this.props.textoBotao}</button>
                    </div>
                    {
                        this.props.secondButton ? (
                            <div className="modal-bottom">
                                <button className="button"
                                    onClick={() => this.modalToggle(false)}>
                                    {this.props.textoSecondButton}</button>
                            </div>) : null
                    }
                </div>
            </div>
        )
    }
}


