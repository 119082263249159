import React from "react";
import { makeStyles, Grid, Typography, Container } from "@material-ui/core";
import Tracking from "../../hooks/Tracking";

import LayoutInternal from "../LayoutInternal";
import PaginaEnum from "../../enums/PaginaEnum";
import { RegistrarAcesso } from "../Multas/CardMultaAction";

const useStyles = makeStyles({
  gridItem: {
    border: "1px solid #eeeeee",
    margin: 20,
    padding: 15,
    cursor: "pointer",
    borderRadius: 5,
  },
  gridTitle: {
    fontFamily: "Metropolis-Semibold",
    fontSize: 12,
    textAlign: "center",
  },
  gridSubtitle: {
    fontSize: 10,
    fontFamily: "Metropolis",
    color: "#7B808C",
    textAlign: "center",
  },
});

function SelecaoProcessoMulta({ handleSetarProcesso }) {
  const classes = useStyles();

  const handleAssinaturaDigitalizada = () => {
    Tracking.sendEvent({
      category: "selecao_processo_multa",
      action: "SELECAO_PROCESSO_MULTA_ASSINATURA",
      label: "Selecionada opção por assinatura",
    });
    RegistrarAcesso(
      PaginaEnum.properties[PaginaEnum.MULTA].indicacaoCondutorAssinatura
    );
    handleSetarProcesso("assinatura");
  };

  const handleTermoResponsabilidade = () => {
    Tracking.sendEvent({
      category: "selecao_processo_multa",
      action: "SELECAO_PROCESSO_MULTA_TERMO",
      label: "Selecionada opção por termo",
    });
    RegistrarAcesso(
      PaginaEnum.properties[PaginaEnum.MULTA].indicacaoCondutorTermo
    );
    handleSetarProcesso("termo", 0);
  };

  return (
    <LayoutInternal title="Multa">
      <main>
        {localStorage.getItem("user-name") !== "boas-vindas" ? (
          <Container maxWidth="lg">
            <Grid container justify="center" alignItems="center" spacing={2}>
              <Grid item>
                <Typography
                  variant="subtitle1"
                  style={{
                    fontFamily: "Metropolis-Semibold",
                    margin: 20,
                    textAlign: "center",
                  }}
                >
                  Como você gostaria de indicar o condutor?
                </Typography>
              </Grid>
            </Grid>

            <Grid container justify="center" alignItems="center" spacing={2}>
              <Grid
                item
                sm={4}
                xs={12}
                className={classes.gridItem}
                onClick={handleAssinaturaDigitalizada}
              >
                <Typography className={classes.gridTitle}>
                  Assinatura Digitalizada
                </Typography>
                <Typography className={classes.gridSubtitle}>
                  Tirar foto ou carregar o documento digitalizado
                </Typography>
              </Grid>
              <Typography
                variant="subtitle2"
                style={{ fontFamily: "Metropolis" }}
              >
                ou
              </Typography>
              <Grid
                item
                sm={4}
                xs={12}
                className={classes.gridItem}
                onClick={handleTermoResponsabilidade}
              >
                <Typography className={classes.gridTitle}>
                  Gerar termo de responsabilidade
                </Typography>
                <Typography className={classes.gridSubtitle}>
                  Gerar documento em PDF, imprimir, assinar e enviar
                </Typography>
              </Grid>
            </Grid>
          </Container>
        ) : (
          <Container maxWidth="lg">
            <Grid container justify="center" alignItems="center" spacing={2}>
              <Grid
                item
                sm={4}
                xs={12}
                className={classes.gridItem}
                onClick={handleTermoResponsabilidade}
                style={{ marginTop: 50 }}
              >
                <Typography
                  className={classes.gridTitle}
                  style={{ fontSize: 18, paddingBottom: 10 }}
                >
                  Gerar termo de responsabilidade
                </Typography>
                <Typography
                  className={classes.gridSubtitle}
                  style={{ fontSize: 13 }}
                >
                  Gerar documento em PDF, imprimir, assinar e enviar
                </Typography>
              </Grid>
            </Grid>
          </Container>
        )}
      </main>
    </LayoutInternal>
  );
}

export default SelecaoProcessoMulta;
