import {
    LISTA_AGENDAMENTOS,
    SEM_AGENDAMENTOS,
    AGENDAMENTOS_LOADING,
    AGENDAMENTOS_LOADED,
    ERRO_AGENDAMENTO,
    SLIDER_ACTIVE,
    SLIDER_DETALHE_AGENDAMENTO
} from './CardAgendamentoTypes'

const INITIAL_STATE = {
    agendamentos: null,
    detalheAgendamento: null,
    erro: null,
    loading: false
}

export default (state = INITIAL_STATE, action) => {

    switch (action.type) {
        case LISTA_AGENDAMENTOS:
            return { ...state, agendamentos: action.payload, erro: null };
        case SLIDER_ACTIVE:
            return { ...state, sliderActive: action.payload, erro: null };
        case SLIDER_DETALHE_AGENDAMENTO:
            return { ...state, detalheAgendamento: action.payload, erro: null };
        case ERRO_AGENDAMENTO:
            //return { ...state, agendamentos: null, erro: action.payload };
            return { ...state, erro: action.payload };
        case AGENDAMENTOS_LOADING:
            return { ...state, loading: true };
        case  AGENDAMENTOS_LOADED:
            return { ...state, loading: false };
        default:
            return state;
    }
}