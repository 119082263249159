import React from "react";
import styled from "styled-components";
import ErrorIcon from "@material-ui/icons/ErrorOutline";

const ErrorContainer = styled.div`
  display: flex;
  margin: 0 8px 0 8px;
`;

const TextoContainer = styled.div`
  text-align: center;
  border-radius: 16px;
  background: #edc0c0;
  color: #ae0000;
  width: 100%;
  height: 100px;
  padding: 8px 8px 8px 8px;
  margin: 8px 8px 8px 8px;
  overflow: hidden;
  text-overflow: ellipsis; 
`;

const ErrorTexto = styled.p`
  font-family: Inter;
  font-size: 14px;
  margin-left: auto;
  margin-right: auto;
`;

class ErrorBoundary extends React.Component {
  state = { hasError: false };

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, info) {
    //this.setState({ hasError: true });
  }

  render() {
    if (this.state.hasError) {
      return (
        <ErrorContainer>
          <TextoContainer>
            <ErrorIcon />
            <ErrorTexto>{`Algo deu errado ${this.props.texto}, tente novamente mais tarde.`}</ErrorTexto>
          </TextoContainer>
        </ErrorContainer>
      );
    }
    return this.props.children;
  }
}

export default ErrorBoundary;
