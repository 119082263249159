import {
    AVANCAR,
    ERRO,
    REA_CARREGADO,
    REA_CARREGANDO,
    ALTERAR_TIPO_EVENTO,
    VOLTAR,
    ALTERAR_CHAVE_PRINCIPAL,
    ALTERAR_CHAVE_RESERVA,
    ALTERAR_DOCUMENTOS,
    RESETAR_ESTADO,
    ALTERAR_CPF_CLIENTE,
    SOLICITAR_EMAIL,
    RETIRA_SOLICITAR_EMAIL,
    ALTERAR_DATA_NASCIMENTO_CLI,
    ALTERAR_NOME_CONDUTOR,
    ALTERAR_EMAIL_CONDUTOR,
    ALTERAR_HABILITADO,
    ALTERAR_CNH,
    ALTERAR_CATEGORIA_CNH,
    ALTERAR_VALIDADE_CNH,
    ALTERAR_DATA_ACIDENTE,
    ALTERAR_HORA_ACIDENTE,
    ALTERAR_ENDERECO_ACIDENTE,
    ALTERAR_BAIRRO_ACIDENTE,
    ALTERAR_CIDADE_ACIDENTE,
    ALTERAR_DESCRICAO_ACIDENTE,
    ALTERAR_OCORRENCIA,
    ALTERAR_NUMERO_OCORRENCIA,
    ALTERAR_NOME_DECLARANTE,
    ALTERAR_TERCEIRO_ENVOLVIDO,
    ALTERAR_OUTRO_ENVOLVIDO,
    ALTERAR_ENDERECO_TERCEIRO,
    ALTERAR_BAIRRO_TERCEIRO,
    ALTERAR_CIDADE_TERCEIRO,
    ALTERAR_CAUSADOR_ACIDENTE,
    ALTERAR_LAUDO_PERICIA,
    ALTERAR_NUMERO_LAUDO,
    ALTERAR_PLACA,
    ALTERAR_MODELO,
    ALTERAR_ANO,
    ALTERAR_PROPRIETARIO,
    ALTERAR_CPF_CNPJ,
    ALTERAR_CEP,
    ALTERAR_ENDERECO_PROPRIETARIO,
    ALTERAR_NUMERO_ENDERECO_PROPRIETARIO,
    ALTERAR_BAIRRO_PROPRIETARIO,
    ALTERAR_CIDADE_PROPRIETARIO,
    ALTERAR_TELEFONE_PROPRIETARIO,
    ALTERAR_CONDUTOR_PROPRIETARIO,
    ALTERAR_CONDUTOR,
    ALTERAR_CPF_CONDUTOR,
    ALTERAR_TELEFONE_CONDUTOR,
    ALTERAR_EMAIL_CONDUTOR_TERCEIRO,
    ALTERAR_CNH_CONDUTOR,
    ALTERAR_CATEGORIA_CNH_CONDUTOR,
    ALTERAR_VALIDADE_CNH_CONDUTOR,
    ALTERAR_SEGURO,
    ALTERAR_SEGURADORA,
    ALTERAR_NUMERO_SINISTRO,
    IR_PARA_ETAPA,
    ATUALIZAR_ESTADO_COOKIE,
    BUSCAR_DADOS_PLACA,
    BUSCAR_DADOS_CEP,
    BUSCAR_DADOS_CPF,
    BUSCAR_DADOS_CPF_DATA_INVALIDA,
    REA_FINALIZADO,
    ALTERAR_EMAIL_PROPRIETARIO,
    ALTERAR_CLIENTE_ISENCAO_PREFIXACAO,
    ALTERAR_TIPO_EMAIL,
    ALTERAR_SS,
    ALTERAR_SS_SEQ

} from './ReaTypes'
import { HttpGet, HttpPost, HttpPostNew } from '../../functions/default';
import { urlAPI, NomeCookieSessaoRea } from '../../config/consts'
import { SELECIONAR_CIDADE_NOME_UF } from '../../components/InputCidades/InputCidadesTypes';
import moment from 'moment';
import * as Cookie from '../../functions/Cookies';
import { ObterUsuarioPorCPFDataNascimento } from '../../services/UsuarioService';
import Tracking from "../../hooks/Tracking";

const ENVIOREA = "envio_rea";

export const avancarEtapa = () => {
    return (dispatch) => {
        dispatch({ type: AVANCAR });
        dispatch({ type: ERRO, payload: null });

    }
}

export const limparMensageErro = () => {
    return (dispatch) => {
        dispatch({ type: ERRO, payload: null });
    }
}

export const voltarEtapa = () => {
    return (dispatch) => {
        dispatch({ type: VOLTAR });
        dispatch({ type: ERRO, payload: null });
    }
}

export const buscarDadosPlaca = (placa) => {
    return (dispatch) => {
        if(navigator.onLine){
            HttpGet(`/BuscaDadosCabecalho?placa=${placa}`, null, null)
            .then(function (result) {
                dispatch({ type: BUSCAR_DADOS_PLACA, payload: result.data });
            })
            .catch(function () {
                dispatch({ type: ERRO, payload: { message: "Erro ao recuperar os dados da placa" } });
            });
        }else dispatch({ type: ERRO, payload: { message: "Para prosseguir, conecte seu celular à internet." } });
    }
}

export const buscarDadosCep = (cep) => {
    return (dispatch) => {
        dispatch({ type: REA_CARREGANDO });
        HttpGet(`/ObterEnderecoPorCep?cep=${cep}`, null, null)
            .then(function (result) {
                dispatch({ type: SELECIONAR_CIDADE_NOME_UF, payload: { nomeCidade: result.data.Municipio.toUpperCase(), uf: result.data.UF.toUpperCase() } });
                dispatch({ type: BUSCAR_DADOS_CEP, payload: result.data });
                dispatch({ type: REA_CARREGADO });
            })
            .catch(function () {
                dispatch({ type: REA_CARREGADO });
            });
    }
}

export const buscarDadosCpf = (cpf, dataNascimento) => {
    return (dispatch) => {
        dispatch({ type: REA_CARREGANDO });
        ObterUsuarioPorCPFDataNascimento(cpf, dataNascimento)
            .then(function (result) {
                if (result.data.CodigoRetorno === 1) {
                    // dispatch({ type: ERRO, payload: { message: "Os dados estão inconsistentes. Verifique se estão corretos ou entre em contato com o nosso atendimento" } });
                    dispatch({ type: BUSCAR_DADOS_CPF_DATA_INVALIDA });
                } else if (result.data.CodigoRetorno === 2 || result.data.CodigoRetorno === 3 || result.data.CodigoRetorno === 4) {
                    let _dados = {
                        nomeCondutor: result.data.Nome !== null ? result.data.Nome : "",
                        cnh: result.data.Cnh !== null ? result.data.Cnh : "",
                        categoriaCnh: result.data.CnhCategoria !== null ? result.data.CnhCategoria : "",
                        validadeCnh: result.data.CnhVencimento !== null ? moment(result.data.CnhVencimento) : null
                    };

                    dispatch({ type: BUSCAR_DADOS_CPF, payload: _dados });
                }
                dispatch({ type: REA_CARREGADO });
            })
            .catch(function () {
                dispatch({ type: ERRO, payload: { message: "Erro ao recuperar os dados do condutor" } });
                dispatch({ type: REA_CARREGADO });
            });
    }
}

export const confirmarEmail = () => {
    return (dispatch) => {
        dispatch({ type: SOLICITAR_EMAIL });
    }
}

export const resetarEstado = () => {
    return (dispatch) => {
        dispatch({ type: RESETAR_ESTADO });
    }
}

export const alterarDataNascimentoCliente = (value) => {
    return (dispatch) => {
        dispatch({ type: ALTERAR_DATA_NASCIMENTO_CLI, payload: value });
    }
}

export const alterarTipoEvento = (tipoEvento) => {
    return (dispatch) => {
        dispatch({ type: ALTERAR_TIPO_EVENTO, payload: tipoEvento });
    }
}

export const alterarChavePrincipal = (value) => {
    return (dispatch) => {
        dispatch({ type: ALTERAR_CHAVE_PRINCIPAL, payload: value });
    }
}

export const alterarChaveReserva = (value) => {
    return (dispatch) => {
        dispatch({ type: ALTERAR_CHAVE_RESERVA, payload: value });
    }
}

export const alterarEstadoPeloCookie = (value) => {
    return (dispatch) => {
        dispatch({ type: ATUALIZAR_ESTADO_COOKIE, payload: value });
    }
}

export const alterarValidadeCnh = (value) => {
    return (dispatch) => {
        dispatch({ type: ALTERAR_VALIDADE_CNH, payload: value });
    }
}

export const alterarCpfCliente = (value) => {
    return (dispatch) => {
        dispatch({ type: ALTERAR_CPF_CLIENTE, payload: value });
    }
}


export const alterarDocumentos = (value) => {
    return (dispatch) => {
        dispatch({ type: ALTERAR_DOCUMENTOS, payload: value });
    }
}

export const alterarHabilitado = (value) => {
    return (dispatch) => {
        dispatch({ type: ALTERAR_HABILITADO, payload: value });
    }
}

export const irParaEtapaSelecionada = (value) => {
    return (dispatch) => {
        dispatch({ type: IR_PARA_ETAPA, payload: value });
        dispatch({ type: ERRO, payload: null });
    }
}

export const alterarCnh = (value) => {
    return (dispatch) => {
        dispatch({ type: ALTERAR_CNH, payload: value });
    }
}

export const retiraConfirmacaoEmail = () => {
    return (dispatch) => {
        dispatch({ type: RETIRA_SOLICITAR_EMAIL });
    }
}

export const alterarCategoriaCnh = (value) => {
    return (dispatch) => {
        dispatch({ type: ALTERAR_CATEGORIA_CNH, payload: value });
    }
}

export const alterarNomeCondutor = (value) => {
    return (dispatch) => {
        dispatch({ type: ALTERAR_NOME_CONDUTOR, payload: value });
    }
}

export const alterarEmailCondutor = (value) => {
    return (dispatch) => {
        dispatch({ type: ALTERAR_EMAIL_CONDUTOR, payload: value });
    }
}

export const alterarDataAcidente = (value) => {
    return (dispatch) => {
        dispatch({ type: ALTERAR_DATA_ACIDENTE, payload: value });
    }
}

export const alterarHoraAcidente = (value) => {
    return (dispatch) => {
        dispatch({ type: ALTERAR_HORA_ACIDENTE, payload: value });
    }
}

export const alterarEnderecoAcidente = (value) => {
    return (dispatch) => {
        dispatch({ type: ALTERAR_ENDERECO_ACIDENTE, payload: value });
    }
}

export const alterarBairroAcidente = (value) => {
    return (dispatch) => {
        dispatch({ type: ALTERAR_BAIRRO_ACIDENTE, payload: value });
    }
}

export const alterarCidadeAcidente = (value) => {
    return (dispatch) => {
        dispatch({ type: ALTERAR_CIDADE_ACIDENTE, payload: value });
    }
}

export const alterarDescricaoAcidente = (value) => {
    return (dispatch) => {
        dispatch({ type: ALTERAR_DESCRICAO_ACIDENTE, payload: value });
    }
}

export const alterarOcorrencia = (value) => {
    return (dispatch) => {
        dispatch({ type: ALTERAR_OCORRENCIA, payload: value });
    }
}

export const alterarNumeroOcorrencia = (value) => {
    return (dispatch) => {
        dispatch({ type: ALTERAR_NUMERO_OCORRENCIA, payload: value });
    }
}

export const alterarNomeDeclarante = (value) => {
    return (dispatch) => {
        dispatch({ type: ALTERAR_NOME_DECLARANTE, payload: value });
    }
}

export const alterarTerceiroEnvolvido = (value) => {
    return (dispatch) => {
        dispatch({ type: ALTERAR_TERCEIRO_ENVOLVIDO, payload: value });
    }
}

export const alterarOutroEnvolvido = (value) => {
    return (dispatch) => {
        dispatch({ type: ALTERAR_OUTRO_ENVOLVIDO, payload: value });
    }
}

export const alterarEnderecoTerceiro = (value) => {
    return (dispatch) => {
        dispatch({ type: ALTERAR_ENDERECO_TERCEIRO, payload: value });
    }
}

export const alterarBairroTerceiro = (value) => {
    return (dispatch) => {
        dispatch({ type: ALTERAR_BAIRRO_TERCEIRO, payload: value });
    }
}

export const alterarCidadeTerceiro = (value) => {
    return (dispatch) => {
        dispatch({ type: ALTERAR_CIDADE_TERCEIRO, payload: value });
    }
}

export const alterarCausadorAcidente = (value) => {
    return (dispatch) => {
        dispatch({ type: ALTERAR_CAUSADOR_ACIDENTE, payload: value });
    }
}

export const alterarLaudoPericia = (value) => {
    return (dispatch) => {
        dispatch({ type: ALTERAR_LAUDO_PERICIA, payload: value });
    }
}

export const alterarNumeroLaudo = (value) => {
    return (dispatch) => {
        dispatch({ type: ALTERAR_NUMERO_LAUDO, payload: value });
    }
}

export const alterarPlaca = (value) => {
    return (dispatch) => {
        dispatch({ type: ALTERAR_PLACA, payload: value.toUpperCase() });
    }
}

export const alterarModelo = (value) => {
    return (dispatch) => {
        dispatch({ type: ALTERAR_MODELO, payload: value });
    }
}

export const alterarAno = (value) => {
    return (dispatch) => {
        dispatch({ type: ALTERAR_ANO, payload: value });
    }
}

export const alterarProprietario = (value) => {
    return (dispatch) => {
        dispatch({ type: ALTERAR_PROPRIETARIO, payload: value });
    }
}

export const alterarCpfCnpj = (value) => {
    return (dispatch) => {
        dispatch({ type: ALTERAR_CPF_CNPJ, payload: value });
    }
}

export const alterarCep = (value) => {
    return (dispatch) => {
        dispatch({ type: ALTERAR_CEP, payload: value });
    }
}

export const alterarEnderecoProprietario = (value) => {
    return (dispatch) => {
        dispatch({ type: ALTERAR_ENDERECO_PROPRIETARIO, payload: value });
    }
}

export const alterarNumeroEnderecoProprietario = (value) => {
    return (dispatch) => {     

        dispatch({ type: ALTERAR_NUMERO_ENDERECO_PROPRIETARIO, payload: value });
    }
}

export const alterarBairroProprietario = (value) => {
    return (dispatch) => {
        dispatch({ type: ALTERAR_BAIRRO_PROPRIETARIO, payload: value });
    }
}

export const alterarCidadeProprietario = (value) => {
    return (dispatch) => {
        dispatch({ type: ALTERAR_CIDADE_PROPRIETARIO, payload: value });
    }
}

export const alterarTelefoneProprietario = (value) => {
    return (dispatch) => {
        dispatch({ type: ALTERAR_TELEFONE_PROPRIETARIO, payload: value });
    }
}

export const alterarCondutorProprietario = (value) => {
    return (dispatch) => {
        dispatch({ type: ALTERAR_CONDUTOR_PROPRIETARIO, payload: value });
    }
}

export const alterarCondutor = (value) => {
    return (dispatch) => {
        dispatch({ type: ALTERAR_CONDUTOR, payload: value });
    }
}

export const alterarCpfCondutor = (value) => {
    return (dispatch) => {
        dispatch({ type: ALTERAR_CPF_CONDUTOR, payload: value });
    }
}

export const alterarTelefoneCondutor = (value) => {
    return (dispatch) => {
        dispatch({ type: ALTERAR_TELEFONE_CONDUTOR, payload: value });
    }
}

export const alterarEmailCondutorTerceiro = (value) => {
    return (dispatch) => {
        dispatch({ type: ALTERAR_EMAIL_CONDUTOR_TERCEIRO, payload: value });
    }
}

export const alterarCnhCondutor = (value) => {
    return (dispatch) => {
        dispatch({ type: ALTERAR_CNH_CONDUTOR, payload: value });
    }
}

export const alterarCategoriaCnhCondutor = (value) => {
    return (dispatch) => {
        dispatch({ type: ALTERAR_CATEGORIA_CNH_CONDUTOR, payload: value });
    }
}

export const alterarValidadeCnhCondutor = (value) => {
    return (dispatch) => {
        dispatch({ type: ALTERAR_VALIDADE_CNH_CONDUTOR, payload: value });
    }
}

export const alterarSeguro = (value) => {
    return (dispatch) => {
        dispatch({ type: ALTERAR_SEGURO, payload: value });
    }
}

export const alterarSeguradora = (value) => {
    return (dispatch) => {
        dispatch({ type: ALTERAR_SEGURADORA, payload: value });
    }
}

export const alterarNumeroSinistro = (value) => {
    return (dispatch) => {
        dispatch({ type: ALTERAR_NUMERO_SINISTRO, payload: value });
    }
}

export const alterarEmailProprietario = (value) => {
    return (dispatch) => {
        dispatch({type: ALTERAR_EMAIL_PROPRIETARIO, payload: value});
    }
}

export const alterarClienteIsencaoPrefixacao = (value) => {
    return (dispatch) => {
        dispatch({ type: ALTERAR_CLIENTE_ISENCAO_PREFIXACAO, payload: value });
    }
}

export const alterarTipoEmail = (value) => {
    return (dispatch) => {
        dispatch({ type: ALTERAR_TIPO_EMAIL, payload: value });
    }
}

export const alterarSs = (value) => {
    return (dispatch) => {
        dispatch({ type: ALTERAR_SS, payload: value.ss });
        dispatch({ type: ALTERAR_SS_SEQ, payload: value.ss_seq });
    }
}

export const downloadFormularioRea = (request) => {
    return (dispatch) => {

        dispatch({ type: REA_CARREGANDO });

        HttpPost(`/DownloadRea`, null, request)
            .then(function (response) {                   
            if(response.data && response.data !== null && response.data !== ""){
                Cookie.set(NomeCookieSessaoRea,'', -1, true);

                var iOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
                var isPWA = (window.matchMedia('(display-mode: standalone)').matches) || (window.navigator.standalone);
                isPWA =  isPWA === true ? true : false;
               
                const link = document.createElement('a');
                link.setAttribute('href', urlAPI + '/DownloadReaPDF/?request='+response.data);

                if(!isPWA && iOS){    
                    link.setAttribute('target', '_blank');
                    
                    document.body.appendChild(link); 

                    setTimeout(function(){ 
                        link.click();                       
                    }, 1000);    

                    setTimeout(function(){ 
                        dispatch({ type: IR_PARA_ETAPA, payload: 7 });                        
                    }, 3000);   

                }else{
                    document.body.appendChild(link);                  
                    link.click();

                    if(!iOS){
                        dispatch({ type: REA_FINALIZADO, payload: {message:"Documento baixado com sucesso"}});
                    }
                 }                     
               }     
              dispatch({ type: REA_CARREGADO });        
            })
            .catch(function (erro) {
                dispatch({ type: REA_CARREGADO });     
            });
   }
}


export const registrarLogReaEmBranco = () => {
    return (dispatch) => {
        if(navigator.onLine){
            HttpPost(`/RegistraDownloadReaBranco`, null, null)
            .then(function () {         
            })
            .catch(function (erro) {
            });
        }else dispatch({ type: ERRO, payload: { message: "Para prosseguir, conecte seu celular à internet." } });
   }
}

export const enviarEmailFormularioRea = (request) => {
    return (dispatch) => {
        dispatch({ type: REA_CARREGANDO });
            HttpPostNew(`/EnviarReaEmail`, request)
            .then(response => {
                if(response.data){
                    Tracking.sendEvent({
                        category: ENVIOREA,
                        action: "ENVIO_REA_ERRO",
                        label: "Erro ao tentar enviar documento",
                    });
                    dispatch({ type: ERRO, payload: { message: "Erro ao tentar enviar o documento" } });                    
                }
                else{
                    Tracking.sendEvent({
                        category: ENVIOREA,
                        action: "ENVIO_REA_SUCESSO",
                        label: "Documento enviado",
                    });
                    if(request.TipoEmail === 'email-2'){
                        dispatch({ type: REA_FINALIZADO, payload: {message:"Documento enviado à Localiza", mensagemSecondaria:"Uma cópia do documento foi enviada para seu e-mail."}});
                    }
                    else 
                    {
                        dispatch({ type: REA_FINALIZADO, payload: {message:"Primeira etapa concluída", mensagemSecondaria:"Você receberá um e-mail com instruções para dar continuidade ao processo"}});
                    }
                }
                dispatch({ type: REA_CARREGADO });                
            })
            .catch(erro => {
                Tracking.sendEvent({
                    category: ENVIOREA,
                    action: "ENVIO_REA_ERRO",
                    label: "Erro ao tentar enviar documento",
                });
                dispatch({ type: ERRO, payload: { message: "Erro ao tentar enviar o documento" } });                
                dispatch({ type: REA_CARREGADO });
            });     
    }
}

