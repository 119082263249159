import qs from "qs";
import {
  BUSCAR_SERVICOS_ATIVOS,
  ERRO_AGENDAMENTO,
  VALIDAR_KM,
  SELECIONAR_SERVICOS,
  BUSCAR_FORNECEDORES,
  LISTAR_FERIADOS,
  SALVAR_AGENDAMENTO,
  MOSTRAR_LOADING,
  ESCONDER_LOADING,
  IS_HOLIDAY,
  DADOS_USUARIO,
  AGENDAMENTO_DATAS_DO_DIA,
  BUSCAR_SS_MAE,
} from "./AgendamentoTypes";

import { SLIDER_ACTIVE } from "../../../components/Slider/SliderTypes";
import { HttpGet, HttpPost, HttpPostNew } from "../../../functions/default";
import { DESLOGAR } from "../../Login/LoginTypes";
import Tracking from "../../../hooks/Tracking";

const GACATERGORIAAGEDAMENTO = "agendamento_servico";

/** TODO: Criar uma chamada async para buscar o link do formulário de agendamento
  export const getLinkOfSchedulingForm = async () => {
  const placa = localStorage.getItem("placa-atual");
  const response = await HttpGet(`/link-fomulario-agendamentos-servicos?placa=${placa}`)
  return response.data.Link;
};**/

export const gerarLinkAgendamento = () => {
  return (dispatch) => {
    if (navigator.onLine) {
      dispatch({ type: MOSTRAR_LOADING });
      const placa = localStorage.getItem("placa-atual");
      HttpGet(`/link-fomulario-agendamentos-servicos?placa=${placa}`)
        .then(function (result) {
          localStorage.setItem("link-form", result.data.Link);
          dispatch({ type: ESCONDER_LOADING });
        })
        .catch(function (erro) {
          dispatch({ type: ESCONDER_LOADING });
          if (
            (erro.response && erro.response.status === 401) ||
            (erro.response && erro.response.status === 403)
          )
            dispatch({
              type: DESLOGAR,
              payload: { message: "Usuário não autenticado." },
            });
          else
            dispatch({
              type: ERRO_AGENDAMENTO,
              payload: {
                message: "Ocorreu um erro ao acessar a página de agendamentos.",
              },
            });
        });
    } else dispatch({ type: ESCONDER_LOADING });
  };
};

export const buscarServicosAtivos = (km, validado) => {
  return (dispatch) => {
    if (navigator.onLine) {
      if (validado === false) dispatch({ type: MOSTRAR_LOADING });
      const placa = localStorage.getItem("placa-atual");
      var param = qs.stringify({ placa, km, validado });
      HttpGet(`/GetEntireAttendanceTree?${param}`)
        .then(function (result) {
          dispatch({ type: MOSTRAR_LOADING });
          if (result.data) {
            dispatch({ type: ESCONDER_LOADING });
            if (result.data.Arvore) {
              dispatch({
                type: BUSCAR_SERVICOS_ATIVOS,
                payload: result.data.Arvore,
              });
            } else {
              dispatch({ type: VALIDAR_KM, payload: result.data });
            }
          }
        })
        .catch(function (erro) {
          dispatch({ type: ESCONDER_LOADING });
          if (
            (erro.response && erro.response.status === 401) ||
            (erro.response && erro.response.status === 403)
          )
            dispatch({
              type: DESLOGAR,
              payload: { message: "Usuário não autenticado." },
            });
          else
            dispatch({
              type: ERRO_AGENDAMENTO,
              payload: {
                message:
                  "Ocorreu um erro ao buscar serviços ativos, tente novamente mais tarde.",
              },
            });
        });
    } else dispatch({ type: ESCONDER_LOADING });
  };
};

export const buscarAgendamentosDoDia = (data, cnpjFornecedor) => {
  return (dispatch) => {
    dispatch({ type: ERRO_AGENDAMENTO, payload: null });
    dispatch({ type: MOSTRAR_LOADING });

    HttpGet(
      `/GetDaySchedules?${qs.stringify({ data, cnpjFornecedor })}`,
      null,
      null
    )
      .then((result) => {
        dispatch({ type: ESCONDER_LOADING });
        let datas = null;
        if (result.data) {
          datas = result.data;
        }
        dispatch({ type: AGENDAMENTO_DATAS_DO_DIA, payload: datas });
      })
      .catch((erro) => {
        dispatch({ type: ESCONDER_LOADING });
        if (
          (erro.response && erro.response.status === 401) ||
          (erro.response && erro.response.status === 403)
        )
          dispatch({
            type: DESLOGAR,
            payload: { message: "Usuário não autenticado." },
          });
        else
          dispatch({
            type: ERRO_AGENDAMENTO,
            payload: {
              message:
                "Ocorreu um erro ao buscar os agendamentos do dia, tente novamente mais tarde.",
            },
          });
      });
  };
};

export const selecionarServicos = (servicos) => {
  return (dispatch) => {
    dispatch({ type: ERRO_AGENDAMENTO, payload: null });
    dispatch({ type: SELECIONAR_SERVICOS, payload: servicos });
  };
};

export const buscarFornecedor = (idTree, idCidade, ultimoKM) => {
  return (dispatch) => {
    dispatch({ type: ERRO_AGENDAMENTO, payload: null });
    const placa = localStorage.getItem("placa-atual");
    var param = qs.stringify({ placa, idTree, idCidade, ultimoKM });
    HttpGet(`/ListSuppliers?${param}`)
      .then(function (result) {
        if (result.data)
          dispatch({ type: BUSCAR_FORNECEDORES, payload: result.data });
      })
      .catch(function (erro) {
        if (
          (erro.response && erro.response.status === 401) ||
          (erro.response && erro.response.status === 403)
        )
          dispatch({
            type: DESLOGAR,
            payload: { message: "Usuário não autenticado." },
          });
        else
          dispatch({
            type: ERRO_AGENDAMENTO,
            payload: {
              message:
                "Ocorreu um erro ao buscar os dados do fornecedor, tente novamente mais tarde.",
            },
          });
      });
  };
};

export const limparFornecedores = () => {
  return {
    type: BUSCAR_FORNECEDORES,
    payload: null,
  };
};

export const listarFeriados = () => {
  return (dispatch) => {
    dispatch({ type: ERRO_AGENDAMENTO, payload: null });
    HttpGet("/ListarFeriados", null, null)
      .then(function (result) {
        if (result.data) {
          dispatch({ type: LISTAR_FERIADOS, payload: result.data });
        }
      })
      .catch(function (erro) {
        if (
          (erro.response && erro.response.status === 401) ||
          (erro.response && erro.response.status === 403)
        )
          dispatch({
            type: DESLOGAR,
            payload: { message: "Usuário não autenticado." },
          });
        else
          dispatch({
            type: ERRO_AGENDAMENTO,
            payload: {
              message:
                "Ocorreu um erro ao buscar os dados, tente novamente mais tarde. ",
            },
          });
      });
  };
};

export const salvarAgendamento = (param) => {
  return (dispatch) => {
    dispatch({ type: ERRO_AGENDAMENTO, payload: null });
    dispatch({ type: MOSTRAR_LOADING });
    Tracking.sendEvent({
      category: GACATERGORIAAGEDAMENTO,
      action: "AGENDAMENTO_SALVANDO",
      label: "Salvando Agendamento",
    });
    HttpPost(`/CreateServiceSS?dadosSS=${qs.stringify(param)}`, null, param)
      .then(function (result) {
        dispatch({ type: ESCONDER_LOADING });
        if (result.data.sucesso) {
          dispatch({ type: SALVAR_AGENDAMENTO, payload: "Agendado!" });
          Tracking.sendEvent({
            category: GACATERGORIAAGEDAMENTO,
            action: "AGENDAMENTO_SALVO_SUCESSO",
            label: "Agendamento realizado com sucesso",
          });
          return;
        }
        dispatch({
          type: ERRO_AGENDAMENTO,
          payload: {
            message:
              "Ocorreu um erro ao processar sua solicitação. Tente novamente mais tarde",
          },
        });
        Tracking.sendEvent({
          category: GACATERGORIAAGEDAMENTO,
          action: "AGENDAMENTO_SALVO_ERRO",
          label: "Erro ao salvar agendamento",
        });
      })
      .catch(function (erro) {
        dispatch({ type: ESCONDER_LOADING });
        Tracking.sendEvent({
          category: GACATERGORIAAGEDAMENTO,
          action: "AGENDAMENTO_SALVO_ERRO",
          label: "Erro ao salvar agendamento",
        });
        if (
          (erro.response && erro.response.status === 401) ||
          (erro.response && erro.response.status === 403)
        ) {
          dispatch({
            type: DESLOGAR,
            payload: { message: "Usuário não autenticado." },
          });
          return;
        }
        dispatch({
          type: ERRO_AGENDAMENTO,
          payload: {
            message:
              "Ocorreu um erro ao processar sua solicitação. Tente novamente mais tarde",
          },
        });
      });
  };
};

export const buscarSSMae = (param) => {
  return (dispatch) => {
    dispatch({ type: ERRO_AGENDAMENTO, payload: null });
    dispatch({ type: MOSTRAR_LOADING });
    const placa = localStorage.getItem("placa-atual");
    HttpPostNew(`/GetMotherCandidateSS?placa=${placa}`, param)
      .then(function (result) {
        dispatch({ type: ESCONDER_LOADING });
        dispatch({ type: BUSCAR_SS_MAE, payload: result.data });
      })
      .catch(function (erro) {
        dispatch({ type: ESCONDER_LOADING });
        if (
          (erro.response && erro.response.status === 401) ||
          (erro.response && erro.response.status === 403)
        ) {
          dispatch({
            type: DESLOGAR,
            payload: { message: "Usuário não autenticado." },
          });
          return;
        }
        dispatch({
          type: ERRO_AGENDAMENTO,
          payload: {
            message:
              "Ocorreu um erro ao processar sua solicitação. Tente novamente mais tarde",
          },
        });
      });
  };
};

export const isHolidayFunc = (data, cidade) => {
  return (dispatch) => {
    dispatch({ type: ERRO_AGENDAMENTO, payload: null });
    dispatch({ type: MOSTRAR_LOADING });
    var param = qs.stringify({ data, cidade });
    HttpGet(`/IsHoliday?${param}`, null, null)
      .then(function (result) {
        dispatch({ type: IS_HOLIDAY, payload: result.data });
        dispatch({ type: ESCONDER_LOADING });
      })
      .catch(function (erro) {
        dispatch({ type: ESCONDER_LOADING });
        if (
          (erro.response && erro.response.status === 401) ||
          (erro.response && erro.response.status === 403)
        )
          dispatch({
            type: DESLOGAR,
            payload: { message: "Usuário não autenticado." },
          });
        else
          dispatch({
            type: ERRO_AGENDAMENTO,
            payload: {
              message:
                "Ocorreu um erro ao buscar os dados, tente novamente mais tarde. ",
            },
          });
      });
  };
};

export const resetIsHoliday = () => {
  return (dispatch) => {
    dispatch({ type: IS_HOLIDAY, payload: false });
  };
};

export const exibirSlider = () => {
  return (dispatch) => {
    dispatch({ type: SLIDER_ACTIVE, payload: true });
  };
};

export const dadosUsuario = (placa) => {
  return (dispatch) => {
    if (navigator.onLine) {
      dispatch({ type: ERRO_AGENDAMENTO, payload: null });
      dispatch({ type: MOSTRAR_LOADING });
      dispatch({ type: DADOS_USUARIO, payload: null });
      var param = qs.stringify({ placa });
      HttpGet(`/DadosUsuario?${param}`, null, null)
        .then(function (result) {
          dispatch({ type: DADOS_USUARIO, payload: result.data });
          dispatch({ type: ESCONDER_LOADING });
        })
        .catch(function (erro) {
          dispatch({ type: ESCONDER_LOADING });
          if (
            (erro.response && erro.response.status === 401) ||
            (erro.response && erro.response.status === 403)
          )
            dispatch({
              type: DESLOGAR,
              payload: { message: "Usuário não autenticado." },
            });
          else
            dispatch({
              type: ERRO_AGENDAMENTO,
              payload: {
                message:
                  "Ocorreu um erro ao buscar os dados do usuário, tente novamente mais tarde.",
              },
            });
        });
    } else dispatch({ type: ESCONDER_LOADING });
  };
};
